const { themeReferencesWithRootsCmd } = require('../../../reduxLoop/commands/documentCommands');

module.exports = {
  information: {
    single: 'Vak',
    plural: 'Vakken'
  },
  node: {
    $$meta: {
      type: 'COMMONS_SUBJECT'
    }
  },
  indentation: 3,
  buildingBlocks: [
  ],
  edit: [
    {
      component: 'title',
      width: '8'
    }
  ],
  isSearchable: false,
  editInModal: false,
  isExternal: true,
  relation: {
    placeholder: 'Vak...',
    filterUrls: [
      {
        path: '/sam/commons/subjects',
      },
      {
        path: '/vakken',
        label: '(oude structuur)',
        type: 'COMMONS_SUBJECT'
      },
      {
        path: '/vakken?offset=500',
        label: '(oude structuur)',
        type: 'COMMONS_SUBJECT'
      }
    ],
    preLoad: true
  },
  deleteValidations: [
    (node) => {
      return {
        cmd: themeReferencesWithRootsCmd,
        params: [node.$$meta.permalink],
        message: 'validation.themeReferenced'
      };
    }
  ],
  confirmDelete: true,
  isUniqueInDienstverleningKov: true,
  themesDisplaySuffix: '(vak)',
};
