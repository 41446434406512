class previousVersionItems {
  constructor() {
    'ngInject';
  }
}

export default {
  template: require('./previousVersionItems.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<',
  },
  controller: previousVersionItems,
};
