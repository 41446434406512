
require('./applicabilityPicker.scss');

class ApplicabilityPicker {
  constructor($scope, LlinkidService) {
    this.$scope = $scope;
    this.service = LlinkidService;
    this.selectedStudyProgrammes = [];
    this.loading = true;
  }

  async $onInit() {
    try {
      this.studyProgrammes = await this.service.loadStudyProgrammes();

      if (this.sModel && this.sModel.studyProgrammes) {
        this.selectedStudyProgrammes = this.studyProgrammes.filter((sp) => {
          return this.sModel.studyProgrammes.map(msp => msp.href).includes(sp.$$meta.permalink);
        });
      }
    } catch (e) {
      console.log(e);
    }
    this.loading = false;
  }

  studyProgrammesChanged() {
    this.$scope.$emit('studyProgrammesSelected', {
      values: this.selectedStudyProgrammes
    });
  }

  clearAll() {
    this.selectedStudyProgrammes = [];
    this.studyProgrammesChanged();
  }

  selectAll() {
    this.selectedStudyProgrammes = this.studyProgrammes;
    this.studyProgrammesChanged();
  }
}

ApplicabilityPicker.$inject = ['$scope', 'LlinkidService'];

module.exports = {
  template: require('./applicabilityPicker.html'),
  controllerAs: 'ctrl',
  bindings: {
    sModel: '<'
  },
  controller: ApplicabilityPicker
};
