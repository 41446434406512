module.exports = {
  information: {
    single: 'Subsectie',
    plural: 'Subsectie\'s'
  },
  node: {
    type: 'CURRICULUM_ODET_SUBSECTION'
  },
  indentation: 3,
  buildingBlocks: [
    'CURRICULUM_ODET_SUBSECTION',
    'CURRICULUM_ODET_DEVELOPMENT_GOAL',
    'CURRICULUM_ODET_ENDTERM'
  ],
  edit: [
    {
      component: 'identifier',
      width: 2
    },
    {
      component: 'title',
      width: 10
    }
  ],
  allowSibling: true,
  isSearchable: false,
  documentViewIdentifier: 'i'
};
