import { call, takeLatest, put, select } from 'redux-saga/effects';
import * as ACTION_TYPES from '../../reduxLoop/constants/actionTypes';
import { createQuery } from '@store/helpers/searchHelpers';
import { types } from '../../config/types';
import {
  loadMoreCmd,
  loadProposalsToReviewCmd,
  searchCmd,
} from '@store/commands/documentListCommands';
import {
  loadMore,
  setFilterProperty,
  setProposalsToReview,
  setSearchResults,
  sortByColumn,
} from './newDocumentListState';
import { setAuthors } from '../externalData/externalDataState';
import { loadHrefsCmd } from '@store/commands/documentCommands';
import { getUiType, getTypeConfig } from '../../services/types';
import { AuthorHrefsToLoad, selectAuthorHrefsToLoad } from './newDocumentListSelectors';
import { waitFor } from '@store/helpers/sagaUtils';
import {
  selectHasUserReviewAbility,
  selectUserVmForDocumentList,
} from '@newStore/user/userSelectors';
import { SriContentList } from '../../types/apiTypes';
import { RootState } from '../../types/rootStateTypes';

// TODO: add error handling: show a notification message (bottom left) with the error
function* documentListSearch() {
  try {
    yield call(
      waitFor,
      (state: RootState) => selectUserVmForDocumentList(state).searchables.length > 0
    );
    const searchParams = yield select((state: RootState) => ({
      ...state.newDocumentList.searchParams,
      searchables: selectUserVmForDocumentList(state).searchables,
    }));
    const query = createQuery(searchParams, types);
    const pageResults: SriContentList = yield call(searchCmd, query);

    yield put(setSearchResults({ pageResults }));
  } catch (ex) {
    console.error(ex);
    // yield put(initFailed(ex.message));
  }
}

function* getAuthorsSaga() {
  try {
    const creatorsHrefs: AuthorHrefsToLoad = yield select(selectAuthorHrefsToLoad);
    if (creatorsHrefs.length === 0) {
      return;
    }
    const authorsResponse = yield call(loadHrefsCmd, creatorsHrefs);
    yield put(setAuthors({ authors: authorsResponse.results }));
  } catch (ex) {
    console.error(ex);
  }
}

function* getProposalsForListSaga({ payload }) {
  try {
    const { pageResults } = payload;
    const allowedToReview = yield select(selectHasUserReviewAbility);

    if (!allowedToReview) {
      return;
    }

    const hrefsToLoadProposals = pageResults
      .filter((r) => {
        const documentType = getUiType(r);
        const typeConfig = getTypeConfig(documentType);
        return typeConfig.allowSuggestions;
      })
      .map((r) => r.$$meta.permalink);

    const proposalsToReview: { contentHref: string; count: number }[] = yield call(
      loadProposalsToReviewCmd,
      hrefsToLoadProposals
    );
    yield put(
      setProposalsToReview({
        proposalsToReview: proposalsToReview,
      })
    );
  } catch (ex) {
    console.error(ex);
  }
}

function* loadMoreSaga({ payload }) {
  try {
    const { href } = payload;
    const pageResults = yield call(loadMoreCmd, href);
    yield put(setSearchResults({ pageResults }));
  } catch (ex) {
    console.error(ex);
  }
}

export function* watchNewDocumentListSaga() {
  yield takeLatest(
    [ACTION_TYPES.DOCUMENT_LIST_SEARCH, sortByColumn, setFilterProperty],
    documentListSearch
  );
  yield takeLatest(setSearchResults, getAuthorsSaga);
  yield takeLatest(setSearchResults, getProposalsForListSaga);
  yield takeLatest(loadMore, loadMoreSaga);
}

// export function* watchAndLog() {
//   while (true) {
//     const action = yield take('*');
//     console.log('saga action', action);
//   }
// }
