import { getInheritedCoverage } from '../reduxLoop/viewmodels/viewModelHelpers';

export const coverageInconsistent = (n) => {
  function isInconsistentCoverage(node, inheritedCoverage) {
    if (inheritedCoverage && node.coverage) {
      if (node.coverage.length > inheritedCoverage.length) {
        return true;
      }

      // check that all children coverage are present in parentDocument.coverage
      return !node.coverage.every((child) => {
        return inheritedCoverage.includes(child);
      });
    }
    return false;
  }

  return !isInconsistentCoverage(n, getInheritedCoverage(n)) ? true : {
    type: 'WARNING',
    message: 'components.coverage.error.inconsistent'
  };
};
