import { settings } from '../settings';

export default {
  information: {
    definiteArticle: true,
    single: 'Zill-praktijkvoorbeeld',
    plural: 'Zill-praktijkvoorbeelden',
    icon: require('../../../img/icons/practicalExample.svg'),
    category: 'ZILL'
  },
  node: {
    type: 'STRUCTURED_DOCUMENT',
    tags: [
      'PRACTICAL_EXAMPLE'
    ]
  },
  createModal: {
    authors: {
      addUser: true
    }
  },
  edit: [
    'title',
    'description',
    'issued',
    'authors',
    'ageRange',
    'contributors',
    {
      component: 'image',
      options: {
        type: 'THUMBNAIL'
      }
    },
    'links',
    'zillIllustrations',
    'sources'
  ],
  buildingBlocks: [
    'SECTION',
    'PARAGRAPH',
    'IMAGE',
    'IMAGE_GROUP',
    'ATTACHMENTS_GROUP',
    'MUSIC',
    'VIDEO',
    'VIDEO_GROUP',
    'QUOTE'
  ],
  tocTypes: [
    'SECTION'
  ],
  asDocumentView: true,
  isCreatable: true,
  isSearchable: true,
  isImportable: false,
  previewModes: [
    {
      type: 'ZILL',
      name: 'Zill Leerplansite',
      location: settings.apisAndUrls.zill + '/#!/bib/{%key}?preview&version={%version}',
    }
  ]
};
