import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { getBase64 } from '../../../reduxLoop/helpers/documentHelpers';
import uuidv4 from 'uuid/v4';

class asideMusic {
  constructor($scope, $ngRedux, searchApi) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.searchApi = searchApi;

    this.resourcePickerAuthorTemplate = require('../../../screen/optionTemplates/authorOption.html');
    this.searchParameters = {
      expand: 'SUMMARY',
      types: 'PERSON,CVO,SCHOOL,SCHOOLCOMMUNITY,CLB,ORGANISATION,BOARDING,GOVERNINGINSTITUTION',
      highlight: 'false'
    };
  }

  $onInit() {
    this.music = this.sDocument.$$attachments.has(this.sConfig.options.type) ? { ...this.sDocument.$$attachments.get(this.sConfig.options.type).original } : {};
    console.log('music', this.music);
    this.attachments = [...this.sDocument.attachments];
  }

  showField(field) {
    return this.sConfig.options.fields && this.sConfig.options.fields.includes(field);
  }

  updatedField(field) {
    let newAttachments = [...this.sDocument.attachments];
    let attachment = newAttachments.find(a => a.type === this.sConfig.options.type);
    if (attachment) {
      attachment[field] = this.music[field];

      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(this.sDocument.key, {
        attachments: newAttachments
      }));
    }
  }

  changeRightsHolder(rightsHolder) {
    let changed = false;

    // console.log('CHANGE rightsholder:',rightsHolder)

    let newAttachments = [...this.$parent.ctrl.sDocument.attachments];
    let attachment = newAttachments.find(a => a.type === this.$parent.ctrl.sConfig.options.type);

    if (attachment) {
      if (rightsHolder) {
        attachment.rightsHolder = { href: rightsHolder.$$meta.permalink };
        changed = true;
      } else if (!rightsHolder && attachment && attachment.rightsHolder) {
        delete attachment.rightsHolder;
        changed = true;
      }
    }

    if (changed) {
      this.$parent.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeAttachment(this.$parent.ctrl.sDocument.key, {
        attachments: this.$parent.ctrl.sDocument.attachments
      }));
    }
  }

  async musicChanged(scope, event, data) {
    let base64 = await getBase64(data.file);
    let newAttachment = {
      key: uuidv4(),
      type: data.type,
      name: data.file.name,
      size: data.file.size,
      $$base64: base64,
      isNew: true
    };

    if (scope.ctrl.music.rightsHolder) {
      newAttachment.rightsHolder = { href: scope.ctrl.music.rightsHolder };
    }
    if (scope.ctrl.music.description) {
      newAttachment.description = scope.ctrl.music.description;
    }

    // action to update state.apiPendingUpload and then patchNode
    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.addAttachment(
        scope.ctrl.sDocument.key,
        newAttachment,
        data.file
      )
    );
    scope.ctrl.music = newAttachment;
  }

  musicRemoved(scope, event, data) {
    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.removeAttachment(scope.ctrl.sDocument.key, scope.ctrl.music.key)
    );
  }

  author2String(resource) {
    if (resource) {
      if (resource.firstName) {
        return resource.firstName + ' ' + resource.lastName;
      }

      if (resource.$$name) {
        return resource.$$name;
      }

      if (resource.name) {
        return resource.name;
      }
    }
    return '<NONE>';
  }
}

export default {
  template: require('./asideMusic.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<'
  },
  controller: asideMusic
};
