import { createSelector } from '@reduxjs/toolkit';
import { getUiType, getIcon, getTypeConfig, getTitle } from '../../services/types';
import { allowPublishItemSelected, formatDate } from '../../reduxLoop/helpers/documentHelpers';
import { config as documentTypes } from '../../reduxLoop/constants/documentTypes';
import { isGlobalDocument } from '@store/viewmodels/viewModelHelpers';
import {
  selectHasUserReviewAbility,
  selectUserVmForDocumentList,
} from '@newStore/user/userSelectors';
import { areAllResultsSelected, getSelectedResults } from './newDocumentListHelpers';
import { allowVersionItemSelected, isCloneableItemSelected } from '@store/helpers/copyHelpers';
import constants from '../../reduxLoop/constants/constants';
import { RootState } from '../../types/rootStateTypes';

// Define input selectors so that we can reuse them in the output selectors and they only have to be typed once
const selectDocumentListResults = (state: RootState) => state.newDocumentList.results;
const selectDocumentListSelected = (state: RootState) => state.newDocumentList.selected;

export const selectSearchResults = createSelector(
  [
    selectDocumentListResults,
    (state: RootState) => state.externalData.persons,
    (state: RootState) => state.externalData.ous,
    (state: RootState) => state.newDocumentList.proposalsToReviewCount,
    selectDocumentListSelected,
  ],
  (
    results,
    persons: Record<string, any>,
    ous: Record<string, any>,
    proposalsToReviewCount,
    selectedItems
  ) => {
    const vmresults = results.map((item) => {
      const $$titleAsHtml = getTitle(item, { addIdentifier: true });
      const $$uiType = getUiType(item);
      const fullTypeConfig = getTypeConfig($$uiType);
      // trying to avoid sending the whole typeconfig.
      const $$typeConfig = {
        information: fullTypeConfig.information,
        allowSuggestions: fullTypeConfig.allowSuggestions,
      };
      const $$iconSrc = getIcon($$uiType);
      const issued = formatDate(item.issued, false);
      const modifiedTZ = item.$$meta.modified;
      const metaModified = formatDate(item.$$meta.modified, false);
      const $$selected = selectedItems[item.$$meta.permalink] || false;
      const authors = item.creators?.map((z) => persons[z] || ous[z]).filter((z) => z) || [];

      const authorNames = authors
        .map((author) => {
          // We have authors of different types. So, depending on the type, we
          // will get the information from one place or another:
          // - authorItem.$$meta.type === 'ORGANISATIONAL_UNIT' => We get it in `$$displayName`
          // - author.$$meta.type === 'ORGANISATION' => We get it in `$$name`
          // - authorItem.$$meta.type === 'PERSON' => We get it in `firstname` + `lastname`
          return author.$$displayName || author.$$name || `${author.lastName} ${author.firstName}`;
        })
        .join(', ');

      const hasProposalsToReview = proposalsToReviewCount[item.$$meta.permalink] > 0;
      return {
        ...item,
        $$titleAsHtml,
        $$uiType,
        $$typeConfig,
        $$iconSrc,
        issued,
        modifiedTZ,
        $$meta: { ...item.$$meta, modified: metaModified },
        expandedAuthors: authorNames,
        hasProposalsToReview,
        $$selected,
      };
    });

    const zills = vmresults
      .filter((result) => result.type === documentTypes.curriculumZill && result.issued)
      .sort((z1, z2) => (z1.issued < z2.issued ? 1 : 0));

    if (zills.length > 0) {
      // @ts-ignore
      zills[0].$$isLastPublishedZill = true;
    }

    return vmresults;
  }
);

export const selectAreAllResultsSelected = createSelector(
  [selectDocumentListResults, selectDocumentListSelected],
  (results, selected) => {
    return areAllResultsSelected(results, selected);
  }
);

export const selectSelectedResults = createSelector(
  [selectSearchResults, selectDocumentListSelected],
  (results, selected) => {
    return getSelectedResults(results, selected);
  }
);

export const selectAreCloneableItemsSelected = createSelector(
  [selectSelectedResults, (state) => selectUserVmForDocumentList(state).cloneables],
  (selectedResults, cloneables) => {
    return isCloneableItemSelected(selectedResults, cloneables);
  }
);

export const selectIsPublishDisabled = createSelector(
  [selectSelectedResults, (state) => selectUserVmForDocumentList(state).publishedEditables],
  (selectedResults, publishedEditables) => {
    return (
      selectedResults.length === 0 ||
      selectedResults.length > constants.maxOperationsAllowed ||
      !allowPublishItemSelected(selectedResults, publishedEditables)
    );
  }
);

export const selectIsPublishHidden = createSelector([selectSelectedResults], (selectedResults) => {
  return (
    selectedResults.length === 0 ||
    selectedResults.some((result) => !result.tags?.includes('PRACTICAL_EXAMPLE'))
  );
});

export const selectIsDeleteDisabled = createSelector([selectSelectedResults], (selectedResults) => {
  return (
    selectedResults.length === 0 ||
    selectedResults.length > constants.maxOperationsAllowed ||
    selectedResults.some((result) => result.issued)
  );
});

export const selectAreVersionableItemsSelected = createSelector(
  [selectSelectedResults],
  (selectedResults) => {
    return allowVersionItemSelected(selectedResults);
  }
);

export const selectColumnsToShow = createSelector(
  [selectHasUserReviewAbility, selectSearchResults],
  (hasReviewerRole, results) => {
    const hasAnyElementWithSuggestions = results && results.find((x) => x.hasProposalsToReview);
    const souldShowProposalsLabel = hasAnyElementWithSuggestions && hasReviewerRole;

    return {
      hasSomeProThemaResult: results.some((result) => result.$$uiType === documentTypes.prothema),
      // @ts-ignore
      hasSomeZillLastPublishedResult: results.some((result) => result.$$isLastPublishedZill),
      hasAllGlobalDocumentResults: results.every((result) => isGlobalDocument(result)),
      souldShowProposalsLabel,
    };
  }
);

export const selectAuthorHrefsToLoad = createSelector(
  [
    selectDocumentListResults,
    (state: RootState) => state.externalData.persons,
    (state: RootState) => state.externalData.ous,
  ],
  (results, persons, ous): string[] => {
    const authors = { ...persons, ...ous };
    const hrefs = [
      ...new Set(
        results
          .filter((x) => x.creators)
          .map((x) => x.creators)
          .flat()
          .filter((z) => z && !authors[z])
      ),
    ] as string[];

    return hrefs;
  }
);

export type AuthorHrefsToLoad = ReturnType<typeof selectAuthorHrefsToLoad>;
