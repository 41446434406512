import { types } from '../../config/types';
const isEqual = require('lodash/isEqual');

require('./ribbon.scss');

function chunkArray(myArray, chunkSize) {
  if (chunkSize < 1) {
    chunkSize = 1;
  }
  const tempArray = [];

  for (let index = 0; index < myArray.length; index += chunkSize) {
    const myChunk = myArray.slice(index, index + chunkSize);
    tempArray.push(myChunk);
  }

  return tempArray;
}

class ribbon {
  constructor($scope, $timeout, $element, $window) {
    'ngInject';

    this.$scope = $scope;
    this.$timeout = $timeout;
    this.heigthOfButton = 87;
    this.paddingOfContainer = 30;
    this.widthOfRow = 130 + 15;
    this.$element = $element;
    this.$window = $window;
  }

  $onInit() {
    console.log('ribbonButtons', this.ribbonButtons);
    this.$timeout(this.calculateRows.bind(this));
    this.$timeout(this.calculateOriginalOffset.bind(this));
    angular.element(this.$window).bind('resize', () => {
      this.calculateOriginalOffset();
      $('.ribbon-button.ui-draggable').draggable('destroy');
      this.calculateRows();
      this.leaveRibbon();
      setTimeout(() => {
        this.createDragables();
      }, 300);
    });
  }

  $onChanges() {
    const sameButtons = this.ribbonButtons
      && isEqual(this.ribbonButtons.map(b => b.type), this.sBuildingBlocks);

    this.ribbonButtons = this.sBuildingBlocks.map(o => ({
      icon: types[o] && types[o].information ? types[o].information.ribonIcon : null,
      name: types[o] && types[o].information ? types[o].information.ribbonTitle || types[o].information.single : o,
      type: o
    }));

    if (!sameButtons) {
      this.calculateRows();
    }
  }

  createDragables() {
    const ctrl = this;

    $('.ribbon-button').draggable({
      revert: 'invalid',
      stack: '.draggable',
      helper: 'clone',
      cursor: 'move',
      refreshPositions: true,
      start: (event) => {
        ctrl.isDragging = true;
        ctrl.leaveRibbon();
      },
      stop: () => {
        ctrl.isDragging = false;
        ctrl.leaveRibbon();
      }
    });
  }

  calculateOriginalOffset() {
    this.originalOffset = this.$element[0].offsetLeft;
  }

  calculateRows() {
    const heigthOfContainer = this.$element.find('.ribbon-inner')[0].offsetHeight - this.paddingOfContainer;
    const amountPossible = Math.floor(heigthOfContainer / this.heigthOfButton);
    this.ribbonButtonsRows = chunkArray(this.ribbonButtons, amountPossible);

    this.$timeout(this.createDragables.bind(this), 200);
  }

  clickedOnMore() {
    this.cancelHoveredOnRibbon();
    this.hideOverflowButton = true;
    this.$element[0].classList.add('expanded');
    this.$element[0].style.left = (this.originalOffset - (this.widthOfRow * (this.ribbonButtonsRows.length - 1)) + 5) + 'px';
  }

  hoveredOnRibbon() {
    if (!this.isDragging) {
      this.cancelHoveredOnRibbon();
      this.hoveredOnRibbonTimeout = this.$timeout(this.clickedOnMore.bind(this), 2000);
    } else {
      this.cancelHoveredOnRibbon();
    }
  }

  cancelHoveredOnRibbon() {
    if (this.hoveredOnRibbonTimeout) {
      this.$timeout.cancel(this.hoveredOnRibbonTimeout);
    }
  }

  leaveRibbon() {
    this.cancelHoveredOnRibbon();
    if (this.hideOverflowButton) {
      this.$element[0].classList.remove('expanded');
      this.$element[0].style.left = null;
      this.hideOverflowButton = false;
    }
  }
}

export default {
  template: require('./ribbon.html'),
  controllerAs: 'ctrl',
  bindings: {
    sBuildingBlocks: '<'
  },
  controller: ribbon
};
