export const documentTags = {
  jobOffer: 'JOB_OFFER',
  newsItem: 'NEWS_ITEM',
  pressRelease: 'PRESS_RELEASE',
  proNewsItem: 'PRONEWSITEM',
  teaser: 'TEASER',
  webPage2: 'WEBPAGE2',
  wwwContent: 'WWW_CONTENT',
  sharedMiniDatabaseItem: 'SHARED_MINI_DATABASE_ITEM',
  sharedFaq: 'SHARED_FAQ',
  sharedAttachmentsGroup: 'SHARED_ATTACHMENTS_GROUP',
};
