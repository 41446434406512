module.exports = {
  information: {
    single: 'Pedagogisch didactische duiding',
    plural: 'Pedagogisch didactische duiding'
  },
  node: {
    type: 'LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT'
  },
  indentation: 4,
  buildingBlocks: [
    'SECTION',
    'PARAGRAPH',
    'IMAGE',
    'IMAGE_GROUP',
    'REFERENCE_GROUP',
    'EXAMPLE',
    'ATTACHMENTS_GROUP',
    'MUSIC',
    'VIDEO',
    'VIDEO_GROUP',
    'QUOTE'
  ],
  showPlaceholder: true,
  editInModal: false,
  createInModal: false,
  isDraggable: false
};
