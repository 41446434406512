import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { customlinkButtonClicked, footnoteButtonClicked } from '../../../services/editorCustomButtonsService';

class asideAttachedContent {
  constructor($scope, $ngRedux, ModalWindowService) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.modalWindowService = ModalWindowService;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.document.viewModel.aside
      };
    })(this);

    this.characters = this.charactersCount(
      this.editDocument.html ? this.editDocument.html.value : null
    );
    // TODO load node footnote if they are not present yet $$footnotes
  }

  $onDestroy() {
    this.unsubscribe();
  }

  change(scope, event, data) {
    // key pressed
    scope.ctrl.characters = scope.ctrl.charactersCount(data.value);
    scope.$apply();
  }

  blur(scope, event, data) {
    // TODO send new action update node text
    scope.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(
      scope.ctrl.sDocument.key,
      { html: data.value }
    ));
  }

  showCustomLinkButton() {
    return !this.sDocument.$$isRoot && this.sDocument.$$root.$$type === 'WEBPAGE2';
  }

  async customlinkButtonClicked(scope, event, data) {
    customlinkButtonClicked(
      scope.ctrl.sDocument,
      scope.ctrl.documentSections,
      data.editor,
      scope.ctrl.modalWindowService,
      scope.ctrl.$ngRedux.dispatch
    );
  }

  async footnoteButtonClicked(scope, event, data) {
    footnoteButtonClicked(
      scope.ctrl.sDocument,
      scope.ctrl.sDocument.$$root,
      data.editor,
      scope.ctrl.modalWindowService
    );
  }

  charactersCount(text) {
    text = text ? text
      .replace(/&nbsp;/g, ' ')
      .replace(/&gt;/g, '>')
      .replace(/(<([^>]+)>)/ig, '')
      .replace(/(\r\n|\n|\r)/gm, '') : '';
    return text.length;
  }
}

export default {
  template: require('./asideAttachedContent.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<'
  },
  controller: asideAttachedContent
};
