export default angular.module('redactieServices', [])
  .factory('BackendManager', require('./backend').default)
  .factory('TypeManager', require('./types').default)
  .factory('Utils', require('./utils').default)
  .factory('ConfirmationService', require('./confirmationService'))
  .factory('ModalWindowService', require('./modalWindowService'))
  .factory('Import', require('./import').default)
  .factory('ZillSelectorService', require('./zillSelectorService'))
  .factory('ProWebsiteConfigurationsService', require('./proWebsite/configurationsService'))
  .factory('RelationsUtils', require('./relationsUtils'))
  .factory('Footnote', require('./footnote'))
  .factory('LlinkidService', require('./llinkid/llinkidService'))
  .factory('Demarcation', require('./llinkid/demarcation'))
  .factory('postMessageService', require('./postMessageService'));
