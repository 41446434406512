import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideDateToSend {
  constructor($ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        dateToSend: state.document.viewModel.aside.dateToSend,
        isReadOnly: state.document.viewModel.aside.isReadOnly
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  onChange(e, dateToSend) {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchDateToSendAction(dateToSend !== '' ? new Date(dateToSend).toISOString() : undefined));
  }
}

export default {
  template: require('./asideDateToSend.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<'
  },
  controller: asideDateToSend
};
