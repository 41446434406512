import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
require('./downloadTag.scss');
import { attachmentDocumentTypes } from '../../../reduxLoop/constants/attachmentDocumentTypes';

class asideDownloadTag {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.documentTypes = attachmentDocumentTypes;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        isReadOnly: state.document.viewModel.aside.isReadOnly
      };
    })(this);

    if (this.sDocument && this.sDocument.tags && this.sDocument.tags.length > 0) {
      this.sDocument.tags.forEach((tag, $index) => {
        const currentDocType = attachmentDocumentTypes.filter(documentType => documentType.value === tag)[0];
        if (currentDocType) {
          this.currentIndex = $index;
          this.tag = currentDocType;
        }
      });
    }
  }

  $onChanges() {
    if (this.sDocument && this.tag !== this.sDocument.tags && this.sDocument.tags) {
      this.sDocument.tags.forEach((tag, $index) => {
        const currentDocType = attachmentDocumentTypes.filter(documentType => documentType.value === tag)[0];
        if (currentDocType) {
          this.currentIndex = $index;
          this.tag = currentDocType;
        }
      });
    }
  }

  $onDestroy() {
    this.unsubscribe();
  }

  tagChange() {
    this.$scope.$emit('downloadTagChange', this.tag);
    if (!this.sConfig.avoidDispatchDownloadTag) {
      let newTags = [...this.sDocument.tags];
      if (this.currentIndex && this.currentIndex !== -1) {
        newTags[this.currentIndex] = this.tag.value;
      } else {
        newTags.push(this.tag.value);
      }
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(
        this.sDocument.key,
        { tags: newTags }
      ));
    }
  }
}

export default {
  template: require('./asideDownloadTag.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<'
  },
  controller: asideDownloadTag
};
