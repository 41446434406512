module.exports = ['settings', (settings) => {
  let service = {
    listening: false
  };

  service.setMessage = function (message) {
    service.message = message;
  }

  service.addListener = function (callback, scope) {
    if (service.originWindow) {
      service.addPostMessageListener(callback, scope);
    }
  };

  service.removeListener = function () {
    window.removeEventListener('message', service.callback, false);
  };

  service.setOriginWindow = function (originWindow = window.opener) {
    if (!originWindow) {
      if (window !== window.parent) {
        originWindow = window.parent;
      }
    }
    return service.originWindow = originWindow;
  };

  service.getTrustedOrigins = function () {
    return settings.trustedOrigins.join(',');
  };

  service.addPostMessageListener = function (callback, scope) {
    service.callback = scope ? callback.bind(scope.ctrl) : callback;

    if (!service.listening) {
      window.addEventListener('message', service.callback, false);
      service.listening = true;
    }
  };

  service.function = function checkMessage(event) {
    console.log('[POST_MESSAGE]', event);
    if (!event.data) throw new Error('There is no value in data property');

    if (!(_.isObject(event.data) && Object.keys(event.data).length)) {
      throw new Error('Data must be an object and it must not be empty');
    }
  };

  service.sendErrorToOriginWindow = function (errorMsg) {
    postMessage({ state: '-1', event: 'error', error: errorMsg });
  };

  service.postMessage = function () {
    service.originWindow.postMessage({ type: 'REDACTIE_SUGGESTIONS', suggestions: service.message }, service.getTrustedOrigins());
  };

  return service;
}];
