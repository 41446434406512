import uuidv4 from 'uuid/v4';
import { Cmd, loop } from 'redux-loop';
import * as ACTION_TYPES from '../constants/actionTypes';
import { settings } from '../../config/settings';
import constants from '../constants/constants';
import { formatDate, getWebConfigurationBatch } from '../helpers/documentHelpers';
import { getVersionErrorMsg } from '../helpers/copyHelpers';
import { getAllTypes, getTypeConfig, getUiType } from '../../services/types';
import {
  publishCmd,
  readImportFileBufferCmd,
  createContentCmd,
  createContentWithDocxCmd,
  createContentWithAttachmentCmd,
  deleteDocumentsCmd,
  createNewVersionCmd,
  publishProposalCmd,
  validateCmd,
  createNewsLetterCmd,
  updateHashCmd,
  createCopyCmd,
} from '../commands/documentListCommands';
import {
  doSearchAction,
  documentsPublishedAction,
  setFileToImportData,
  contentCreated,
  resultsDeleted,
  queueSubnodeWithRelation,
  sendMailListPublishedAction,
  validationFailedAction,
  deleteSelected,
  successfullyCreatedNewVersionOfContent, // /to this.
  successfullyCreatedCopyOfContent,
  setFileToUploadAsAttachment,
  documentsPublishFailedAction,
} from '../actions/documentListActions';
import { addNotificationAction } from '../actions/notificationActions';
import { sendEmailCmd } from '../commands/documentCommands';
import { selectSelectedResults } from '@newStore/documentList/newDocumentListSelectors';
import { selectUser, selectUserHref } from '@newStore/user/userSelectors';

/**
 * This is the initial state of this reducer.
 */
const initialState = {
  publishModalOpen: false, // state of the publish modal
  createContentModalOpen: false, // state of the create content modal
  confirmationModalOpen: false,
  canBeImported: false, // new content type can be imported, depends on the selected type
  importDocx: {}, // data for the imported docx file for new content
  uploadAttachment: null, // file which should be uploaded as attachment
  newContent: { authors: { selected: [] } },
  apiPending: [], // array of batch opereation pending to be sent to the api
};

export const documentListReducer = (state = initialState, action, rootState) => {
  switch (action.type) {
    case ACTION_TYPES.DOCUMENT_LIST_PUBLISH: {
      const batch = [];
      let index = 0;
      let isPublishProposal = false;

      action.payload.resources.forEach((resource) => {
        if (resource.$$typeConfig.allowSuggestions) {
          // submit a publish proposal that needs to be accepted
          const key = action.payload.keys[index];

          const change = {
            type: 'PATCH',
            appliesTo: { href: '/content/' + resource.key },
            creator: { href: selectUserHref(rootState) },
            patch: [
              {
                op: 'add',
                path: '/issued',
                value: new Date(action.payload.publishDate).toISOString(),
              },
            ],
          };

          const proposal = {
            key: key,
            status: 'SUBMITTED_FOR_REVIEW',
            creators: [selectUserHref(rootState)],
            expandedCreators: [selectUser(rootState)],
            externalReferences: ['/content/' + resource.key],
            listOfRequestedChanges: [change],
            $$meta: { permalink: '/proposals/' + key },
          };

          batch.push({
            verb: 'PUT',
            href: '/proposals/' + proposal.key,
            body: proposal,
          });

          isPublishProposal = true;
        } else {
          const resourceWithDate = Object.assign(resource, {
            issued: new Date(action.payload.publishDate).toISOString(),
          });

          batch.push({
            verb: 'PUT',
            href: '/content/' + resourceWithDate.key,
            body: resourceWithDate,
          });
        }

        index += 1;
      });

      return loop(
        {
          ...state,
          saveAttempted: true,
          publishMessage: action.payload.message,
          publishingDocuments: action.payload.resources,
          locationUrl: action.payload.locationUrl,
        },
        Cmd.run(isPublishProposal ? publishProposalCmd : publishCmd, {
          args: [batch],
          successActionCreator: isPublishProposal
            ? sendMailListPublishedAction
            : documentsPublishedAction,
          failActionCreator: (error) => documentsPublishFailedAction(error, state),
        })
      );
    }

    case ACTION_TYPES.SEND_LIST_PUBLISHED_EMAIL: {
      const emailjobs = [];
      let count = 0;

      state.publishingDocuments.forEach((document) => {
        const emailBody = `${state.publishMessage}<p>
            <a href="${state.locationUrl.replace('/documents', `/edit/${document.key}`)}">
              (${document.$$typeConfig.information.single}) ${document.title}
            </a>
            <p><br>Gebruiker die verzond voor publicatie: 
              ${selectUser(rootState).lastName} ${selectUser(rootState).firstName}`;

        const emailjob = {
          ...settings.emails.template,
          ...settings.emails.publishDocument,
          recipients: [
            {
              emailAddress: settings.emails.tagAddressMap.get(document.tags[0]),
            },
          ],
          key: action.payload.keys[count],
          subject: `(${document.$$typeConfig.information.single}) ${document.title}`,
          replyTo: selectUser(rootState).$$email,
        };
        emailjob.recipients[0].mergeVariables = [
          {
            name: 'EMAIL',
            content: emailBody,
          },
        ];

        emailjobs.push(emailjob);
        count += 1;
      });

      return loop(
        {
          ...state,
        },
        Cmd.run(sendEmailCmd, {
          args: [emailjobs],
          successActionCreator: documentsPublishedAction,
          failActionCreator: (error) => documentsPublishFailedAction(error, state),
        })
      );
    }

    case ACTION_TYPES.DOCUMENT_LIST_PUBLISHED: {
      state.publishingDocuments.forEach((resource) => {
        resource.issued = formatDate(resource.issued);
      });

      return loop(
        {
          ...state,
          saveAttempted: false,
          publishModalOpen: false,
          publishingDocuments: [],
        },
        Cmd.action(addNotificationAction({ type: 'SUCCESS', message: 'saveSuccessMessage' }))
      );
    }

    case ACTION_TYPES.DOCUMENT_LIST_PUBLISH_FAILED: {
      return { ...state, saveAttempted: false, error: action.payload };
    }

    case ACTION_TYPES.DOCUMENT_LIST_OPENED_PUBLISH_MODAL: {
      return { ...state, publishModalOpen: true };
    }

    case ACTION_TYPES.DOCUMENT_LIST_CLOSE_PUBLISH_MODAL: {
      return { ...state, publishModalOpen: false };
    }

    case ACTION_TYPES.DOCUMENT_LIST_VALIDATE_AND_DELETE: {
      const selectedResults = selectSelectedResults(rootState);
      const validationCmds = selectedResults.reduce((list, selected) => {
        const $$uiType = getUiType(selected);
        const typeConfig = getTypeConfig($$uiType);
        if (typeConfig && typeConfig.deleteFromListValidations) {
          typeConfig.deleteFromListValidations.forEach((validation) => {
            list.push({
              cmd: validation.cmd,
              documentKey: selected.key,
              params: [selected.key, validation.failAction],
            });
          });
        }
        return list;
      }, []);

      return loop(
        {
          ...state,
          deleting: true,
        },
        validationCmds.length > 0
          ? Cmd.run(validateCmd, {
              args: [validationCmds],
              successActionCreator: deleteSelected, // resultsDeleted,
              failActionCreator: validationFailedAction,
            })
          : // : Cmd.action(resultsDeleted())
            Cmd.action(deleteSelected())
      );
    }

    case ACTION_TYPES.DOCUMENT_LIST_DELETE: {
      return loop(
        { ...state },
        Cmd.run(deleteDocumentsCmd, {
          args: [selectSelectedResults(rootState).map((s) => s.key)],
          successActionCreator: resultsDeleted,
          failActionCreator: validationFailedAction,
        })
      );
    }

    case ACTION_TYPES.DOCUMENT_LIST_RESULTS_DELETED: {
      const newState = { ...state };

      newState.deleting = false;

      return newState;
    }

    case ACTION_TYPES.DOCUMENT_LIST_DELETE_FAILED: {
      const notifications = Array.isArray(action.payload) ? action.payload : [action.payload];
      return loop(
        {
          ...state,
          deleting: false,
        },
        Cmd.list(
          notifications.map((n) =>
            Cmd.action(
              addNotificationAction({
                type: 'ERROR',
                message: n.code,
                params: n.params,
                removeAfter: 15,
              })
            )
          )
        )
      );
    }

    case ACTION_TYPES.OPENED_CREATE_CONTENT_MODAL: {
      return {
        ...state,
        createContentModalOpen: true,
        createType: action.payload,
        canBeImported: action.payload.isImportable ? action.payload.isImportable : false,
        newContent: {
          authors: { selected: [] },
          tags: action.payload.information.contentTags,
        },
      };
    }

    case ACTION_TYPES.CLOSE_CREATE_CONTENT_MODAL: {
      return {
        ...state,
        createContentModalOpen: false,
        canBeImported: false,
        importDocx: {},
        newContent: { authors: { selected: [] } },
      };
    }

    case ACTION_TYPES.READ_FILE_TO_IMPORT_DATA: {
      return loop(
        {
          ...state,
          importing: true,
        },
        Cmd.run(readImportFileBufferCmd, {
          args: [action.payload],
          successActionCreator: setFileToImportData,
          failActionCreator: (error) => console.log(error),
        })
      );
    }

    case ACTION_TYPES.SET_FILE_TO_IMPORT_DATA: {
      return {
        ...state,
        importDocx: {
          name: action.payload.name,
          arrayBuffer: action.payload.arrayBuffer,
        },
        importing: false,
      };
    }

    case ACTION_TYPES.READ_FILE_TO_UPLOAD_AS_ATTACHMENT: {
      return loop(
        {
          ...state,
          importing: true,
        },
        Cmd.run(readImportFileBufferCmd, {
          args: [action.payload],
          successActionCreator: setFileToUploadAsAttachment,
          failActionCreator: (error) => console.log(error),
        })
      );
    }

    case ACTION_TYPES.SET_FILE_TO_UPLOAD_AS_ATTACHMENT: {
      return {
        ...state,
        uploadAttachment: {
          name: action.payload.name,
          arrayBuffer: action.payload.arrayBuffer,
        },
        importing: false,
      };
    }

    case ACTION_TYPES.VALIDATE_CREATE_CONTENT: {
      const validations = action.payload.newContentParams.createValidations || [];
      const validationCmds = validations.reduce((cmds, validation) => {
        cmds.push({
          cmd: validation.cmd,
          documentKey: action.payload.newContentParams.key,
          params: [action.payload.newContentParams.title, validation.failAction],
        });
        return cmds;
      }, []);

      return loop(
        state,
        Cmd.run(validateCmd, {
          args: [validationCmds],
          successActionCreator: () => ({
            type: ACTION_TYPES.CREATE_CONTENT,
            payload: { content: action.payload.newContentParams, http: action.payload.$http },
          }),
          failActionCreator: validationFailedAction,
        })
      );
    }

    case ACTION_TYPES.CREATE_CONTENT: {
      const content = {
        $$meta: { permalink: '/content/' + action.payload.content.key },
        key: action.payload.content.key,
        type: action.payload.content.node.type,
        isNew: true,
        title: action.payload.content.title,
        readorder: 1,
        attachments: [],
        creators: [],
        importance: 'MEDIUM',
        language: 'nl',
        created: new Date().toISOString(),
        modified: new Date().toISOString(),
        ...action.payload.content.node,
        ...action.payload.content.createDefaults,
      };

      // create default webconfiguration for the root node if defined
      const webConfigurationBatch = getWebConfigurationBatch(
        action.payload.content.webconfiguration,
        content.key,
        content.title
      );

      if (action.payload.content.documentType) {
        content.tags = [...content.tags, action.payload.content.documentType.value];
      }

      if (action.payload.content.newsLetterType) {
        content.newsletterType = { href: action.payload.content.newsLetterType.$$meta.permalink };
      }

      if (action.payload.content.identifier) {
        content.identifiers = content.identifiers
          ? [...content.identifiers, action.payload.content.identifier]
          : [action.payload.content.identifier];
      }

      if (action.payload.content.themes) {
        content.themes = action.payload.content.themes;
      }

      content.creators = action.payload.content.authors.selected.map((author) => {
        return author.$$meta.permalink;
      });

      const apiPending = [
        {
          verb: 'PUT',
          href: '/content/' + content.key,
          body: content,
        },
      ];

      let subnodeBlocks = [];
      const types = getAllTypes();

      if ((!state.importDocx || !state.importDocx.name) && types[content.type]) {
        // useful to create default subnodes and add to batch when needed
        subnodeBlocks = types[content.type].buildingBlocks?.filter((block) => block.min) || [];
      }

      let cmd;
      let args;
      if (state.importDocx && state.importDocx.name) {
        cmd = createContentWithDocxCmd;
        args = [apiPending, state.importDocx, action.payload.$http, webConfigurationBatch];
      } else if (state.uploadAttachment) {
        cmd = createContentWithAttachmentCmd;
        args = [apiPending, state.uploadAttachment];
      } else if (action.payload.content.newsLetterTemplate) {
        cmd = createNewsLetterCmd;
        args = [
          apiPending,
          {
            href: content.$$meta.permalink,
            templateKey: action.payload.content.newsLetterTemplate.key,
            dateToSend: action.payload.content.dateToSend,
          },
          action.payload.$http,
        ];
      } else {
        cmd = createContentCmd;
        args = [apiPending, webConfigurationBatch];
      }

      return loop(
        {
          ...state,
          apiPending,
          saveAttempted: true,
        },
        Cmd.list(
          [
            Cmd.list(
              subnodeBlocks.map((block) => Cmd.action(queueSubnodeWithRelation(block, content.key)))
            ),
            Cmd.run(cmd, {
              args,
              successActionCreator: contentCreated,
              failActionCreator: (error) => console.log('ERROR creating content:', error),
            }),
          ],
          { sequence: true }
        ) // if we don't use sequence then the createContentCmd is executed before the subnodes are created
      );
    }

    case ACTION_TYPES.CREATE_AND_QUEUE_CONTENT_WITH_RELATION: {
      const newState = { ...state };

      const defaultChild = {
        key: action.payload.contentKey,
        type: action.payload.block.type,
        attachments: [],
        creators: [],
        importance: 'MEDIUM',
        language: 'nl',
      };

      const relation = {
        key: action.payload.relationKey,
        relationtype: 'IS_PART_OF',
        from: {
          href: '/content/' + action.payload.contentKey,
        },
        to: {
          href: '/content/' + action.payload.parentKey,
        },
      };

      if (action.payload.block.readorder) {
        relation.readorder = action.payload.block.readorder;
      }

      // include in batch the new subchild and relation to root
      newState.apiPending.push({
        verb: 'PUT',
        href: '/content/' + defaultChild.key,
        body: defaultChild,
      });
      newState.apiPending.push({
        verb: 'PUT',
        href: '/content/relations/' + relation.key,
        body: relation,
      });

      return { ...newState };
    }

    case ACTION_TYPES.SUCCESSFULLY_CREATED_CONTENT: {
      return {
        ...state,
        createContentModalOpen: false,
        canBeImported: false,
        importDocx: {},
        uploadAttachment: null,
        newContent: { authors: { selected: [] } },
        saveAttempted: false,
        apiPending: [],
        redirect: { path: 'edit', key: action.payload.key },
      };
    }

    case ACTION_TYPES.NEW_VERSION_OF_CONTENT: {
      return loop(
        {
          ...state,
          creatingNewVersion: true,
        },
        Cmd.run(createNewVersionCmd, {
          args: [action.payload],
          successActionCreator: successfullyCreatedNewVersionOfContent,
          failActionCreator: (sriClientError) => ({
            type: 'FAILED_CREATE_NEW_VERSION_OF_CONTENT',
            payload: sriClientError,
          }),
        })
      );
    }

    case ACTION_TYPES.SUCCESSFULLY_CREATED_NEW_VERSION_OF_CONTENT: {
      return loop(
        {
          ...state,
          confirmationModalOpen: false,
          creatingNewVersion: false,
        },
        Cmd.action(doSearchAction(rootState.newDocumentList.searchParams))
      );
    }

    case ACTION_TYPES.FAILED_CREATE_NEW_VERSION_OF_CONTENT: {
      const sriClientError = action.payload;
      const errors =
        sriClientError &&
        sriClientError.body &&
        sriClientError.body.errors &&
        sriClientError.body.errors.length
          ? sriClientError.body.errors
          : [{ code: 'generic' }];

      return loop(
        {
          ...state,
          confirmationModalOpen: false,
          creatingNewVersion: false,
        },
        Cmd.list(
          errors.map((error) =>
            Cmd.action(
              addNotificationAction({
                type: 'ERROR',
                removeAfter: 10,
                ...getVersionErrorMsg(error, rootState.newDocumentList.results),
              })
            )
          )
        )
      );
    }

    case ACTION_TYPES.COPY_OF_CONTENT: {
      return loop(
        {
          ...state,
          creatingCopy: true,
        },
        Cmd.run(createCopyCmd, {
          args: [action.payload],
          successActionCreator: successfullyCreatedCopyOfContent,
          failActionCreator: (sriClientError) => ({
            type: 'FAILED_CREATE_COPY_OF_CONTENT',
            payload: sriClientError,
          }),
        })
      );
    }

    case ACTION_TYPES.SUCCESSFULLY_CREATED_COPY_OF_CONTENT: {
      return loop(
        {
          ...state,
          confirmationModalOpen: false,
          creatingCopy: false,
          apiPending: [],
        },
        Cmd.action(doSearchAction(rootState.newDocumentList.searchParams))
      );
    }

    case ACTION_TYPES.FAILED_CREATE_COPY_OF_CONTENT: {
      const sriClientError = action.payload;
      const errors =
        sriClientError &&
        sriClientError.body &&
        sriClientError.body.errors &&
        sriClientError.body.errors.length
          ? sriClientError.body.errors
          : [{ code: 'generic' }];

      return loop(
        {
          ...state,
          confirmationModalOpen: false,
          creatingCopy: false,
        },
        Cmd.list(
          errors.map((error) =>
            Cmd.action(
              addNotificationAction({
                type: 'ERROR',
                removeAfter: 10,
                ...getVersionErrorMsg(error, rootState.newDocumentList.results),
              })
            )
          )
        )
      );
    }

    case ACTION_TYPES.DOCUMENT_LIST_CLEAR_REDIRECT: {
      const newState = { ...state };

      delete newState.redirect;

      return newState;
    }

    case ACTION_TYPES.UPDATE_NEWS: {
      const newsKey = constants.newsHashKey;
      const newHash = uuidv4();
      const ts = new Date();

      const hash = {
        $$meta: { permalink: '/content/hashes/' + newsKey },
        key: newsKey,
        hash: newHash,
        title: 'hash for pronewsitems and teasers on ' + ts.toLocaleString(),
      };

      return loop(
        state,
        Cmd.run(updateHashCmd, {
          args: [hash],
          successActionCreator: () =>
            addNotificationAction({ type: 'SUCCESS', message: 'updateHashSuccessMessage' }),
          failActionCreator: (error) =>
            addNotificationAction({
              type: 'ERROR',
              message: error.code,
              params: error.params,
              removeAfter: 15,
            }),
        })
      );
    }

    default: {
      return state;
    }
  }
};
