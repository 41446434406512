import { titleNotEmpty } from '../../validations/titleNotEmpty';
import { descriptionNotEmpty } from '../../validations/descriptionNotEmpty';
import { coverageNotEmpty } from '../../validations/coverageNotEmpty';
import { mainstructuresOuTypeCombinationsNotEmpty } from '../../validations/mainstructuresOuTypeCombinationsNotEmpty';
import { maxCharacters } from '../../validations/maxCharacters';
import { isLinkedContentValid } from '../../validations/linkedContent';
import { positionsNotEmpty } from '../../validations/positionsNotEmpty';
import { teasersDeleteValidationCmd } from '../../reduxLoop/commands/documentListCommands';
import { validationFailedAction } from '../../reduxLoop/actions/documentListActions';
import { settings } from '../settings';
import constants from '../../reduxLoop/constants/constants';
import { pathIsNotReserved } from '../../validations/pathIsNotReserved';
import { pathIsPermitted } from '../../validations/pathIsPermitted';
import { oldUrlIsPermitted } from '../../validations/oldUrlIsPermitted';
import { websiteUniqueWithinNode } from '../../validations/websiteUniqueWithinNode';
import { documentTags } from '../../reduxLoop/constants/documentTags';
import { urlUniqueApiWithinApi } from '../../validations/urlUniqueWithinApi';
import { urlUniqueWithinDocument } from '../../validations/urlUniqueWithinDocument';
import { mainAndSubCurriculumThemesConsistent } from '../../validations/mainAndSubCurriculumThemesConsistent';
import * as apiRoutes from '../../reduxLoop/api/apiRoutes';

export default {
  information: {
    definiteArticle: true,
    single: 'PRO.-teaser',
    plural: 'PRO.-teasers',
    ribonIcon: require('../../../img/buildingBlocks/TEASER.svg'),
    category: 'PRO',
  },
  node: {
    type: 'TEASER',
    tags: [documentTags.teaser],
  },
  webconfiguration: {
    type: 'NEWS_ITEM_AND_TEASER_FALLBACK',
    website: {
      href: `${apiRoutes.websites}/${settings.websites.pro}`,
    },
    template: {
      href: '/web/templates/d153f5d5-78b3-4de7-a231-281da633a8bb',
    },
  },
  createModal: {
    authors: {
      addUser: false,
    },
  },
  indentation: 1,
  buildingBlocks: [],
  edit: [
    {
      component: 'title',
      validations: [titleNotEmpty],
      options: {
        hideTermButton: true,
      },
    },
    {
      component: 'description',
      label: 'Teaser',
      maxAmountOfCharacters: 400,
      options: {
        type: 'description',
        hideTermButton: true,
      },
      validations: [maxCharacters, descriptionNotEmpty],
    },
    'authors',
    {
      component: 'issued',
      label: 'teaser.issued',
    },
    {
      component: 'themes',
      options: {
        referenceFrameKey: constants.dienstverleningKovKey,
        types: [
          'THEME',
          'BASIC_OPTION',
          'DOMAIN',
          'COMMONS_STUDIEGEBIED',
          'COMMONS_STUDY_PROGRAMME',
          'COMMONS_SUBJECT',
          'CURRICULUM_THEME',
          'CURRICULUM_CLUSTER',
        ],
        highlightOldCurrItems: true,
      },
      validations: [mainAndSubCurriculumThemesConsistent],
    },
    'curriculumSelector',
    {
      component: 'teaserImportance',
    },
    {
      component: 'image',
      label: 'edit.ILLUSTRATION',
      options: {
        type: 'THUMBNAIL',
        crop: true,
      },
    },
    {
      component: 'namedSet',
      validations: [mainstructuresOuTypeCombinationsNotEmpty],
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      validations: [coverageNotEmpty],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'namedSet',
      label: 'components.functions.title',
      validations: [positionsNotEmpty],
      tag: 'doelgroepen',
      property: 'positions',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'teaserLinkedContent',
      validations: [isLinkedContentValid],
    },
  ],
  isCreatable: true,
  isSearchable: true,
  isImportable: false,
  allowSuggestions: true,
  customRender: true,
  deleteFromListValidations: [
    {
      cmd: teasersDeleteValidationCmd,
      failAction: validationFailedAction,
    },
  ],
  websiteEdition: [
    {
      domain: settings.domains.www,
    },
    {
      domain: settings.domains.pro,
    },
  ],
  websiteValidations: [
    pathIsNotReserved,
    oldUrlIsPermitted,
    pathIsPermitted,
    websiteUniqueWithinNode,
    urlUniqueApiWithinApi,
    urlUniqueWithinDocument,
  ],
  confirmDeleteMessage: () => '<li>Ben je zeker dat je dit wil verwijderen?</li>',
  hideChildrenInDocument: true,
};
