import { settings } from '../settings';

export default {
  information: {
    definiteArticle: true,
    single: 'Begrip',
    plural: 'Begrippen',
    icon: require('../../../img/icons/term.svg')
  },
  node: {
    type: 'TERM',
    tags: [
      'TERM'
    ]
  },
  createModal: {
    authors: {
      addUser: true
    }
  },
  edit: [
    'title',
    'description',
    'issued',
    {
      component: 'image',
      label: 'edit.THUMBNAIL',
      options: {
        type: 'THUMBNAIL'
      }
    },
    {
      component: 'image',
      label: 'edit.ILLUSTRATION',
      options: {
        type: 'ILLUSTRATION'
      }
    }
  ],
  buildingBlocks: [
    'SECTION',
    'PARAGRAPH'
  ],
  tocTypes: [
    'SECTION'
  ],
  asDocumentView: true,
  isCreatable: true,
  isSearchable: true,
  hideInDocument: true,
  previewModes: [
    {
      type: 'ZILL',
      name: 'Zill Leerplansite',
      location: settings.apisAndUrls.zill + '/#!/bib/begrippen/{%key}?preview&version={%version}'
    }
  ]
};
