import * as DOCUMENT_ACTIONS from '../../reduxLoop/actions/documentActions';
import * as DOCUMENT_DRAG_ACTIONS from '../../reduxLoop/actions/documentDragActions';
import { config as constants } from '../../reduxLoop/constants/documentTypes';
import { getBase64 } from '../../reduxLoop/helpers/documentHelpers';

require('./dropZone.scss');

class dropZone {
  constructor($scope, $ngRedux, $element, ModalWindowService) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$element = $element;
    this.modalWindowService = ModalWindowService;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        referenceFrameThemes: state.document.viewModel.referenceFrameThemes
      };
    })(this);

    this.createDroppable();
  }

  $onDestroy() {
    this.unsubscribe();
  }

  createDroppable() {
    this.$element.droppable({
      tolerance: 'pointer',
      hoverClass: 'ui-droppable-hover',
      drop: async (event, ui) => {
        this.$element.removeClass('ui-droppable-hover');
        this.$ngRedux.dispatch(DOCUMENT_DRAG_ACTIONS.finishDraggingNodeAction());
        const isNewNode = ui.draggable.context.attributes.dragType.value === 'RIBBON';
        const level = this.sLevel;
        if (ui.draggable.context.attributes.type.value === 'WORD_IMPORT') {
          const opts = {
            component: 'documentImportModal',
            dataForModal: { parentDocument: level.levelParent }
          };

          const importResult = await this.modalWindowService.open(opts);

          console.log('Imported nodes:', importResult.resources);
          importResult.attachmentsToUpload.forEach(async upload => {
            const base64 = await getBase64(upload.file);
            upload.$$base64 = base64;
          });
          this.$ngRedux.dispatch(DOCUMENT_ACTIONS.importDocumentInSectionAction(
            importResult, level.levelParent.key
          ));
        } else if (!isNewNode) {
          this.$ngRedux.dispatch(DOCUMENT_ACTIONS.moveSelectionsToParentNodeAction(level.levelParent.key, level.position));
        } else if (Object.keys(constants.referenceFrameExternalTypes).includes(ui.draggable.context.attributes.type.value)) {
          this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openSelectExternalRelationModalAction());

          const opts = {
            component: 'selectExternalRelationModal',
            dataForModal: {
              parentDocument: level.levelParent,
              type: ui.draggable.context.attributes.type.value,
              position: level.position
            }
          };

          const selection = await this.modalWindowService.open(opts);
          console.log('Selected relation:', selection);
          if (selection) {
            this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addExternalRelationAction(
              level.levelParent.key,
              level.position,
              selection
            ));
          }
        } else if (ui.draggable.context.attributes.type.value === 'TEASER') {
          const opts = {
            component: 'teaserModal',
            dataForModal: {
              referenceFrameThemes: this.referenceFrameThemes,
              parentDocumentThemes: level.levelParent.themes
            }
          };

          const importResult = await this.modalWindowService.open(opts);
          this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addTeaser(level.levelParent.key, level.position, importResult.teaser));
        } else {
          this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addNodeToParentNodeAction(level.levelParent.key, level.position, ui.draggable.context.attributes.type.value));
        }
      },
      over: (event, ui) => {
        this.$element.addClass('ui-droppable-hover');
        console.log('OVER', this.sLevel);
        this.$element.addClass('ui-droppable-hover');
      },
      out: (event, ui) => {
        this.$element.removeClass('ui-droppable-hover');
        console.log('OUT', this.sLevel);
        this.$element.removeClass('ui-droppable-hover');
      }
    });
  }
}

export default {
  template: require('./dropZone.html'),
  controllerAs: 'ctrl',
  bindings: {
    sLevel: '<?'
  },
  controller: dropZone
};
