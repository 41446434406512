import asideIdentifier from './asideIdentifier';
import asideTitle from './asideTitle';
import asideDescription from './asideDescription';
import asideAttachedContent from './asideAttachedContent';
import asideStaticHtml from './asideStaticHtml';
import asideCheckbox from './asideCheckbox';
import asideImportance from './asideImportance';
import asideIssued from './asideIssued';
import asideDateToSend from './asideDateToSend';
import asideCurrent from './asideCurrent';
import asideColor from './asideColor';
import asideAuthors from './asideAuthors';
import asideThemes from './asideThemes';
import asideSubjects from './asideSubjects';
import asideAccessRights from './asideAccessRights';
import asideMenuItemReference from './asideMenuItemReference';
import asideContacts from './pro/asideContactPerson';
import asideSelect from './asideSelect';
import asideNamedSet from './asideNamedSet';
import asideCoverage from './asideCoverage';
import asideImage from './asideImage';
import asideVideo from './asideVideo';
import asideVideoGroup from './asideVideoGroup';
import asideMusic from './asideMusic';
import asideDownloadTag from './asideDownloadTag';
import asideLinks from './asideLinks';
import linkModal from './modals/linkModal';
import videoGroupModal from './modals/videoGroupModal';
import asideSources from './asideSources';
import sourceModal from './modals/sourceModal';
import asideReferenceGroup from './asideReferenceGroup';
import referenceSelectionModal from './modals/referenceSelectionModal';
import asideConcordanties from './asideConcordanties';
import concordantieModal from './modals/concordantieModal';
import asideAgeRange from './zill/asideAgeRange';
import asideLeerlijnReference from './zill/asideLeerlijnReference';
import asideDevelopmentPhase from './zill/asideDevelopmentPhase';
import asideGoalsByZillIllustrations from './zill/asideGoalsByZillIllustrations';
import asideZillIllustrations from './practicalExample/asideZillIllustrations';
import zillIllustrationModal from './modals/zillIllustrationModal';
import asidePracticalExampleReferences from './practicalExample/asidePracticalExampleReferences';
import documentSelectorModal from './modals/documentSelectorModal';
import asideWebFacets from './facets/metadataContainer';
import proWebsiteFacetShow from './facets/facetShow';
import proWebsiteFacetShowList from './facets/facetShowList';
import proWebsiteFacet from './facets/facet';
import proWebsiteFacets from './facets/facets';
import proWebsiteFacetModalForm from './facets/facetModalForm';
import proWebsiteReferenceFramePicker from './facets/referenceFramePicker';
import proWebsiteReferenceFramePickers from './facets/referenceFramePickers';
import proWebsiteAgeRange from './facets/ageRange';
import proWebsiteZillGoals from './facets/zillGoals';
import asideLlinkidReferences from './llinkid/references';
import llinkidReferenceModal from './llinkid/referenceModal';
import asideLlinkidReplaces from './llinkid/replaces';
import asideLlinkidRelations from './llinkid/goalRelations';
import asideLlinkidGoalType from './llinkid/goalType';
import asidePreviousVersionItems from './llinkid/previousVersionItems';
import llinkidPreviousVersionModal from './modals/llinkidPreviousVersionModal';
import llinkidGoalRelationsModal from './modals/goalRelationsModal';
import llinkidGoalOdetEndTermReferencesModal from './modals/goalOdetEndTermReferencesModal';
import asideLlinkidFoundationalSuggestions from './llinkid/foundationalSuggestions';
import asideAttachmentsGroup from './asideAttachmentsGroup';
import attachmentModal from './modals/attachmentModal';
import asideAttachmentUpload from './asideAttachmentsUpload';
import asideImageGroup from './asideImageGroup';
import imageSelectionModal from './modals/imageSelectionModal';
import asideTeaserImportance from './pro/asideTeaserImportance';
import asideTeaserLinkedContent from './pro/asideTeaserLinkedContent';
import asideDownloadDocument from './asideDownloadDocument';
import asideVersion from './asideVersion';
import asidePreviousVersion from './asidePreviousVersion';
import asideValidityPeriod from './asideValidityPeriod';
import asideRealisationPeriod from './asideRealisationPeriod/index';
import asideSecondaryEducationTypes from './asideSecondaryEducationTypes';
import asideCurriculumTheme from './asideCurriculumTheme';
import asideCurriculumSelector from './asideCurriculumSelector';
import asideExpiryDate from './asideExpiryDate';
import asideNewsletterSender from './asideNewsletterSender';

export default angular
  .module('redactieAsideComponents', [])
  .component('asideIdentifier', asideIdentifier)
  .component('asideTitle', asideTitle)
  .component('asideDescription', asideDescription)
  .component('asideAttachedContent', asideAttachedContent)
  .component('asideStaticHtml', asideStaticHtml)
  .component('asideCheckbox', asideCheckbox)
  .component('asideImportance', asideImportance)
  .component('asideAuthors', asideAuthors)
  .component('asideThemes', asideThemes)
  .component('asideSubjects', asideSubjects)
  .component('asideAccessRights', asideAccessRights)
  .component('asideMenuItemReference', asideMenuItemReference)
  .component('asideContacts', asideContacts)
  .component('asideSelect', asideSelect)
  .component('asideIssued', asideIssued)
  .component('asideDateToSend', asideDateToSend)
  .component('asideCurrent', asideCurrent)
  .component('asideColor', asideColor)
  .component('asideNamedSet', asideNamedSet)
  .component('asideCoverage', asideCoverage)
  .component('asideImage', asideImage)
  .component('asideMusic', asideMusic)
  .component('asideVideo', asideVideo)
  .component('asideVideoGroup', asideVideoGroup)
  .component('asideDownloadTag', asideDownloadTag)
  .component('asideLinks', asideLinks)
  .component('asideLinkGroup', asideLinks)
  .component('linkModal', linkModal)
  .component('videoGroupModal', videoGroupModal)
  .component('asideSources', asideSources)
  .component('sourceModal', sourceModal)
  .component('asideReferenceGroup', asideReferenceGroup)
  .component('referenceSelectionModal', referenceSelectionModal)
  .component('asideConcordanties', asideConcordanties)
  .component('concordantieModal', concordantieModal)
  .component('asideAgeRange', asideAgeRange)
  .component('asideLeerlijnReference', asideLeerlijnReference)
  .component('asideDevelopmentPhase', asideDevelopmentPhase)
  .component('asideGoalsByZillIllustration', asideGoalsByZillIllustrations)
  .component('asideZillIllustrations', asideZillIllustrations)
  .component('zillIllustrationModal', zillIllustrationModal)
  .component('asidePracticalExampleReferences', asidePracticalExampleReferences)
  .component('documentSelectorModal', documentSelectorModal)
  .component('asideWebFacets', asideWebFacets)
  .component('proWebsiteFacetShow', proWebsiteFacetShow)
  .component('proWebsiteFacetShowList', proWebsiteFacetShowList)
  .component('proWebsiteFacet', proWebsiteFacet)
  .component('proWebsiteFacets', proWebsiteFacets)
  .component('proWebsiteFacetModalForm', proWebsiteFacetModalForm)
  .component('proWebsiteReferenceFramePicker', proWebsiteReferenceFramePicker)
  .component('proWebsiteReferenceFramePickers', proWebsiteReferenceFramePickers)
  .component('proWebsiteAgeRange', proWebsiteAgeRange)
  .component('proWebsiteZillGoals', proWebsiteZillGoals)
  .component('asideLlinkidReferences', asideLlinkidReferences)
  .component('llinkidReferenceModal', llinkidReferenceModal)
  .component('asideLlinkidReplaces', asideLlinkidReplaces)
  .component('asideLlinkidRelations', asideLlinkidRelations)
  .component('asideLlinkidGoalType', asideLlinkidGoalType)
  .component('asidePreviousVersionItems', asidePreviousVersionItems)
  .component('llinkidPreviousVersionModal', llinkidPreviousVersionModal)
  .component('llinkidGoalRelationsModal', llinkidGoalRelationsModal)
  .component('llinkidGoalOdetEndTermReferencesModal', llinkidGoalOdetEndTermReferencesModal)
  .component('asideLlinkidFoundationalSuggestions', asideLlinkidFoundationalSuggestions)
  .component('asideAttachmentsGroup', asideAttachmentsGroup)
  .component('attachmentModal', attachmentModal)
  .component('asideAttachmentUpload', asideAttachmentUpload)
  .component('asideImageGroup', asideImageGroup)
  .component('imageSelectionModal', imageSelectionModal)
  .component('asideTeaserImportance', asideTeaserImportance)
  .component('asideTeaserLinkedContent', asideTeaserLinkedContent)
  .component('asideDownloadDocument', asideDownloadDocument)
  .component('asideVersion', asideVersion)
  .component('asidePreviousVersion', asidePreviousVersion)
  .component('asideValidityPeriod', asideValidityPeriod)
  .component('asideSecondaryEducationTypes', asideSecondaryEducationTypes)
  .component('asideCurriculumTheme', asideCurriculumTheme)
  .component('asideCurriculumSelector', asideCurriculumSelector)
  .component('asideRealisationPeriod', asideRealisationPeriod)
  .component('asideExpiryDate', asideExpiryDate)
  .component('asideNewsletterSender', asideNewsletterSender);
