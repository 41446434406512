import uuidv4 from 'uuid/v4';

export const customlinkButtonClicked = async (item, documentSections, editor, modal) => {
  const selectedText = editor.getSelection().getSelectedText();
  const startElement = editor.getSelection().getStartElement();
  const positionIsLink = startElement.getName() === 'a';

  const linkData = {
    currentDocument: item,
    sections: documentSections
  };

  const opts = {
    component: 'proWebsiteLinkButtonModalForm',
    dataForModal: linkData
  };

  if (positionIsLink) {
    // the href of the link where the cursor is positioned on
    opts.dataForModal = {
      ...opts.dataForModal,
      href: startElement.getAttribute('href'),
      root: startElement.getAttribute('root')
    };
    const result = await modal.open(opts);

    startElement.setAttribute('href', result.href);
    startElement.setAttribute('rel', result.rel);
    startElement.setAttribute('root', result.root);
    startElement.removeAttribute('data-cke-saved-href');
  } else if (selectedText) {
    const result = await modal.open(opts);

    // wrap selected text with a new custom element
    // eslint-disable-next-line new-cap
    const newElement = new CKEDITOR.dom.element('a');
    newElement.setAttributes({
      href: result.href,
      rel: result.rel // href.includes('/content') ? 'webpage2-link' : 'external'
    });

    if (result.root) {
      newElement.setAttributes({
        root: result.root
      });
    }

    newElement.setText(selectedText);

    editor.insertElement(newElement);
  } else {
    // TODO check why this is not showing the notification
    // $notification.error('Selecteer een stuk tekst om een begrip aan te te voegen.');
  }
};

export const termButtonClicked = async (editor, rootDocument, modalWindowService, $scope) => {
  const selectedText = editor.getSelection().getSelectedText();
  if (!selectedText) {
    return;
  }

  const opts = {
    component: 'termSelectionModal',
    dataForModal: {
      term: null,
      rootDocument: rootDocument
    }
  };

  const term = await modalWindowService.open(opts);

  // wrap selected text with a new custom element
  editor.applyStyle(new CKEDITOR.style(
    {
      element: 'inline-term',
      attributes: {
        's-href': `/content/${term.key}`
      }
    }
  ));

  $scope.$emit('term_added', { term: term });
};

export const demarcationButtonClicked = (editor) => {
  var selectedText = editor.getSelection().getSelectedText();

  // wrap selected text with a new custom element
  var newElement = new CKEDITOR.dom.element('inline-demarcation');
  newElement.setAttributes({
    's-href': '/content/' + uuidv4()
  });
  newElement.setText(selectedText);
  editor.insertElement(newElement);
};

export const footnoteButtonClicked = (currentResource, rootDocument, editor, modal) => {
  const selectedText = editor.getSelection().getSelectedText();

  const opts = {
    template: require('../screen/dialogs/footnoteDialog.html'),
    controller: require('../screen/dialogs/footnoteDialogController.js'),
    resolve: {
      data: {
        footNote: null,
        document: currentResource,
        rootDocument: rootDocument,
        skipSave: true,
        onSave: (footNote) => {
          // wrap selected text with a new custom element
          const newElement = new CKEDITOR.dom.element('inline-footnote');
          newElement.setAttributes({
            's-href': footNote.$$meta.permalink,
            title: footNote.title,
            identifier: footNote.identifier,
            rel: 'footnote'
          });

          newElement.setText('[' + footNote.identifier + ']');

          editor.insertText(selectedText + ' ');
          editor.insertElement(newElement);
        }
      }
    }
  };

  modal.open(opts);
};

export const markerButtonClicked = async (editor, modalWindowService) => {
  const selectedHtml = editor.getSelectedHtml().getHtml();
  if (!selectedHtml) {
    return;
  }
  const markExplanation = await modalWindowService.open({ component: 'selectMarkExplanationModal' });

  // wrap selected text with a custom new element
  const newElement = new CKEDITOR.dom.element('inline-mark-explanation');
  newElement.setAttribute('s-href', `/content/${markExplanation.key}`);
  newElement.setHtml(selectedHtml);
  editor.insertElement(newElement);
};
