/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import { getTypeConfig } from '../services/types';

export const urlUniqueApiWithinApi = (node, state) => {
  for (const configuration of node.websitesConfiguration) {
    const duplicate = state.possibleDuplicateWebConfigs.find(d => configuration.source.href !== d.sourceHref && (
      (configuration.website.href === d.websiteHref && configuration.path === d.path)
      || (configuration.oldLocations && configuration.oldLocations.some(l => l.website.href === d.websiteHref && l.path === d.path))));

    if (duplicate) {
      return {
        type: 'ERROR',
        message: 'components.proWebsite.configurations.error.urlNotUnique',
        params: {
          url: `https://${state.websites.find(w => w.$$meta.permalink === duplicate.websiteHref).domain}${duplicate.path}`,
          rootTitle: duplicate.rootTitle,
          rootType: getTypeConfig(duplicate.rootTag).information.single
        }
      };
    }
  }

  return true;
};
