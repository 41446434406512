module.exports = {
  information: {
    single: 'Importeren',
    plural: 'Importeren',
    ribonIcon: require('../../../../img/buildingBlocks/WORD_IMPORT.svg')
  },
  node: {
    type: 'WORD_IMPORT'
  },
  buildingBlocks: [],
  edit: [],
  allowSibling: false,
  isSearchable: false,
  ribbon: {
    importFromWord: true
  }
};
