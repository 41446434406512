const constants = require('../../../reduxLoop/constants/constants');

module.exports = {
  information: {
    single: 'Afbeelding',
    plural: 'Afbeeldingen',
    ribonIcon: require('../../../../img/buildingBlocks/IMAGE.svg')
  },
  node: {
    type: 'IMAGE'
  },
  buildingBlocks: [],
  edit: [
    {
      component: 'image',
      options: {
        type: 'ILLUSTRATION',
        fields: [
          'description',
          'alt'
        ]
      }
    },
    {
      component: 'namedSet',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    },
    {
      component: 'coverage',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    }
  ],
  allowSibling: true,
  isSearchable: false,
  customRender: true
};
