import React from 'react';
import ReactDOM from 'react-dom';

import { CurriculaSelector } from '@kathondvla/curricula-selector';

import { useSelector } from 'react-redux';
import { api, cachedApi } from '../../../../js/reduxLoop/api/apiConfig';
import { selectUserHref } from '@newStore/user/userSelectors';

let settings = require('../../../config/settings.js').settings;

const AsideCurriculumSelectorModal = ({ handleSubmit, handleClose, selectedThemes }) => {
  const userHref = useSelector(selectUserHref);
  const loginUrl = settings.oauth.logOut + '?redirect_uri=' + self.location.origin;

  const apiConfig = {
    sriClient: api,
    sriClientCached: cachedApi,
  };

  return ReactDOM.createPortal(
    <CurriculaSelector
      initialSelection={selectedThemes}
      apiConfig={apiConfig}
      userHref={userHref}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      loginUrl={loginUrl}
    />,
    document.body
  );
};

export default AsideCurriculumSelectorModal;
