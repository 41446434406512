import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

require('./asideLinks.scss');

class asideLinks {
  constructor(ModalWindowService, ConfirmationService, $ngRedux, $scope) {
    'ngInject';

    this.modalWindowService = ModalWindowService;
    this.confirmationService = ConfirmationService;

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
  }

  async addLink() {
    const opts = {
      component: 'linkModal',
      dataForModal: {
        modalTitle: this.sConfig.options.modalTitle
      }
    };

    const response = await this.modalWindowService.open(opts);

    if (response) {
      // save relation to goal item
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addLinkReferenceNodeAction(this.sDocument.key, response.title, response.url, this.sConfig.options && this.sConfig.options.type === 'group'));
    }
  }

  async delete(relation) {
    let confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeNodeAction(relation.from.$$expanded.key, true, this.sDocument.key));
    }
  }

  async edit(relation) {
    const opts = {
      component: 'linkModal',
      dataForModal: {
        title: relation.from.$$expanded.title,
        url: relation.from.$$expanded.$$url,
        modalTitle: this.sConfig.options.modalTitle
      }
    };

    const response = await this.modalWindowService.open(opts);

    if (response) {
      // save relation to goal item
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.editLinkReferenceNodeAction(
          this.sDocument.key,
          relation.from.$$expanded.key,
          response.title,
          response.url,
        )
      );
    }
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.document.viewModel.aside,
        links: state.document.viewModel.aside.editDocument.linkRelations,
        loading: false
      };
    })(this);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.expandRelationsToAction(
      this.sDocument.key
    ));
  }

  $onDestroy() {
    this.unsubscribe();
  }

  proposalLineStyle(row) {
    if (row.proposal) {
      const line = row.proposal.isSubmitted ? 'submitted' : 'not-submitted';
      const color = row.proposal.isSameUser ? 'same-user' : 'different-user';
      const isDeleted = row.proposal.isDeleted ? ' is-deleted' : '';
      const isReviewing = row.proposal.isReviewingMode ? ' is-reviewing' : '';
      return line + ' ' + color + isDeleted + isReviewing;
    }
    return '';
  }

  hasDeleteProposal(row) {
    return row.proposal && row.proposal.type === 'DELETE';
  }
}

export default {
  template: require('./asideLinks.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<'
  },
  controller: asideLinks
};
