const commonUtils = require('@kathondvla/sri-client/common-utils');
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { getSelectedAccessRights } from '../../reduxLoop/viewmodels/viewModelHelpers';

export const getNodeStateFromTypeRelations = (nodeKey, state) => {
  const stateFromTypeRelations = state.apiWithPendingChanges.contentRelations.from;
  const nodeFromTypeRelations = stateFromTypeRelations['/content/' + nodeKey];
  const versionOfTypeRelations = nodeFromTypeRelations && nodeFromTypeRelations.filter(r => r.relationtype === 'IS_VERSION_OF');

  return versionOfTypeRelations;
};

export const getConcordantiesFromDocument = (documentToGetReferencesFrom, relations = []) => {
  const documentToGetReferencesFromEvaluated = typeof documentToGetReferencesFrom !== 'undefined';

  if (documentToGetReferencesFromEvaluated && documentToGetReferencesFrom) {
    return relations.map((relation) => {
      let expandedRelation;

      const relationToRootDocument = commonUtils.getKeyFromPermalink(relation.to.href)
       === documentToGetReferencesFrom.key;
      if (relationToRootDocument) {
        // when we open the aside panel on the root element the root element relation is not
        // inside the $$fulltree array, so we get the information for the ODET document itself
        expandedRelation = documentToGetReferencesFrom;
      } else {
        // If we are searching for "children relations" we search
        // for it in the ODET $$fulltree array
        expandedRelation = documentToGetReferencesFrom.$$fullTree.find((x) => {
          return commonUtils.getKeyFromPermalink(relation.to.href) === x.key;
        });
      }

      let concordantie = { key: relation.key };

      // when we don't have `expandedRelation` it means, with high
      // probability, that the user changed the "frame document" (ODET document)
      // having some relations in place for the old "frame document"
      // HINT: This will show a message in the template telling that information
      // to the user
      if (expandedRelation) {
        concordantie = {
          ...concordantie,
          title: expandedRelation.title,
          relationIdentifier: expandedRelation.$$prefix
        };
      }
      return concordantie;
    });
  }
  return [];
};

export const getDocumentAndChildrenAccessRights = (documentSubState) => {
  // Here we get the permissions of the main node (general document),
  // the opened node and all the children
  const viewModel = documentSubState.viewModel;
  if (!viewModel) { return []; }

  const permissions = [];
  const mainNode = viewModel.document;
  const asideNode = viewModel.aside && viewModel.aside.editDocument;


  if (asideNode) {
    permissions.push({
      key: asideNode.key,
      permissions: asideNode.selectedAccessRights
    });
  }

  if (mainNode) {
    const mainNodePermissions = {
      key: mainNode.key,
      permissions: mainNode.selectedAccessRights
    };

    permissions.push(mainNodePermissions);

    mainNode.attachmentResources.forEach(x => {
      permissions.push({
        key: x.key,
        permissions: getSelectedAccessRights(x)
      });
    });
  }

  return permissions;
};

export const openGoalRelationsModal = async ({
  asideOptions,
  rootKey,
  relations,
  item,
  defaultCurriculumKey,
}) => {
  const modalWindowService = getAngularService('ModalWindowService');
  const relationOrigin = !asideOptions.revertedRelationDirection ? 'from' : 'to';
  const relationEnd = !asideOptions.revertedRelationDirection ? 'to' : 'from';

  const { modal, ...modalDataOptions } = asideOptions;

  const modalData = {
    component: modal,
    dataForModal: {
      editDocumentRootKey: rootKey,
      defaultCurriculumKey,
      selectedValues: relations.map((rel) => rel[relationEnd]?.$$expanded?.key),
      relationOrigin: relationOrigin,
      relationEnd: relationEnd,
      edit: item ? { relation: item } : {},
      ...modalDataOptions,
    },
  };

  return modalWindowService.open(modalData);
};
