export const webConfigurationRequired = (node) => {
  const nodeWebConfiguration = node.websitesConfiguration.find(wc => !wc.deleteProposal);
  if (node.$$isRoot && (node.$$type === 'WEBPAGE2' || node.$$type === 'SHARED_MINI_DATABASE_ITEM') && !nodeWebConfiguration) {
    return {
      type: 'ERROR',
      message: 'validation.webConfigRequired',
    };
  }
  if (node.$$root.$$type !== 'WEBPAGE2' || !node.$$parent || node.deleteProposal) {
    return true;
  }
  const parentWebConfiguration = node.$$parent.websitesConfiguration.find(wc => !wc.deleteProposal);
  if (!parentWebConfiguration) {
    return true;
  }
  if (
    ['THEME_HOME_FULL', 'THEME_HOME_TEXT', 'THEME_HOME_PICTURE'].includes(parentWebConfiguration.type)
    && !nodeWebConfiguration
  ) {
    return {
      type: 'ERROR',
      message: 'validation.webConfigForProTileRequired'
    };
  }
  if (
    parentWebConfiguration.type === 'MINI_DATABASE'
    && (!nodeWebConfiguration || nodeWebConfiguration.type !== 'MINI_DATABASE_ITEM')
  ) {
    return {
      type: 'ERROR',
      message: 'validation.childOfDbShouldBeDbItem'
    };
  }
  if (
    parentWebConfiguration.type === 'BLOG'
    && (!nodeWebConfiguration || nodeWebConfiguration.type !== 'BLOG_ITEM')
  ) {
    return {
      type: 'ERROR',
      message: 'validation.childOfBlogShouldBeBlogItem'
    };
  }
  return true;
};
