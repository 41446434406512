import { getBase64 } from '../../../reduxLoop/helpers/documentHelpers';

require('./rowUnstructuredDocument.scss');

import {
  replaceContentAttachmentOfGlobalDocument,
  updateAsideViewModelAction
} from '../../../reduxLoop/actions/documentActions';
import uuidv4 from 'uuid/v4';

class RowUnstructuredDocument {
  constructor(settings, $ngRedux, $state, ModalWindowService) {
    'ngInject';

    this.contentApi = settings.apisAndUrls.contentApi;
    this.proWebsite = settings.apisAndUrls.proWebsite;
    this.downloadIcon = require('../../../../img/download.svg');
    this.$ngRedux = $ngRedux;
    this.$state = $state;
    this.modalWindowService = ModalWindowService;
  }

  $onInit() {
    this.loadPages();
    this.content = this.sItem.attachments.find(attachment => attachment.type === 'CONTENT');
    this.proDownloadUrl = this.proWebsite + '/download' + this.content.href;
  }

  loadPages() {
    this.pages = this.sItem.attachments
      .filter(attachment => attachment.type === 'CONTENT_PREVIEW')
      .map(attachment => ({ ...attachment, url: this.contentApi + attachment.href }));
    this.pages.sort((a, b) => a.name < b.name ? -1 : 1);
  }

  async replaceAttachment(scope) {
    const opts = {
      component: 'browseFileModal',
      resolve: {
        modalHeaderTitle: () => 'Nieuwe versie voor ' + scope.ctrl.sItem.title,
        browseLabel: () => 'Kies een nieuwe versie',
        content: () => scope.ctrl.content
      }
    };

    const response = await scope.ctrl.modalWindowService.open(opts);

    if (response) {
      const base64 = await getBase64(response);
      const newAttachment = {
        key: scope.ctrl.attachment ? scope.ctrl.attachment.key : uuidv4(),
        type: 'CONTENT',
        name: response.name,
        size: response.size,
        $$base64: base64,
        isNew: true,
        created: new Date(),
        contentType: response.type
      };

      scope.ctrl.$ngRedux
        .dispatch(replaceContentAttachmentOfGlobalDocument(newAttachment, scope.ctrl.sItem));
    }
  }

  openAsideModal() {
    this.$state.go('edit.aside', { editKey: this.sItem.key });
    this.$ngRedux.dispatch(updateAsideViewModelAction(this.sItem.key));
  }
}

export const rowUnstructuredDocument = {
  template: require('./rowUnstructuredDocument.html'),
  controllerAs: 'ctrl',
  bindings: {
    sItem: '<'
  },
  controller: RowUnstructuredDocument
};
