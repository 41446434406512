/* eslint-disable max-len */
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { dienstverleningKovKey } from '../../../reduxLoop/constants/constants';
import { escapeRegExp } from 'lodash';
class asideCurriculumTheme {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const nodeThemes = state.document.viewModel.aside.editDocument.themes;
      const primaryReferenceFrameOptions = (
        state.document.viewModel.aside.referenceFrames[dienstverleningKovKey] || []
      ).filter((o) => o.type === 'CURRICULUM_THEME' && o.$$parent.type !== 'CURRICULUM_THEME');

      return {
        referenceFrameOptions: primaryReferenceFrameOptions,
        selectedTheme: primaryReferenceFrameOptions.find(
          (o) => nodeThemes && nodeThemes.value[0] === o.$$meta.permalink
        ),
        themesLoading: primaryReferenceFrameOptions.length === 0,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
        editKey: state.document.viewModel.aside.editDocument.key,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  change(e, item) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchThemes(this.editKey, [item.$$meta.permalink]));
  }

  filterData(data, str) {
    if (str.length === 0) return data;
    return data.filter((elm) => {
      const regExp = new RegExp(escapeRegExp(str), 'gi');
      return regExp.test(elm.title) || regExp.test(elm.name);
    });
  }
}

export default {
  template: require('./asideCurriculumTheme.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<'
  },
  controller: asideCurriculumTheme
};
