module.exports = {
  information: {
    single: 'Thema',
    plural: 'Thema\'s'
  },
  node: {
    type: 'CURRICULUM_ZILL_DEVELOPMENT_THEME',
    attachments: [
      {
        type: 'CONTENT',
        name: 'content.html',
        contentType: 'text/html'
      }
    ]
  },
  indentation: 1,
  edit: [
    {
      component: 'identifier',
      width: 2
    },
    {
      component: 'title',
      width: 10
    },
    {
      component: 'description',
      view: {
        hideIfEmpty: true
      }
    },
    {
      component: 'attachedContent',
      label: 'Lees meer',
      view: {
        hideIfEmpty: true
      }
    },
    {
      component: 'image',
      label: 'edit.COVER_IMAGE',
      options: {
        type: 'COVER_IMAGE',
        fields: []
      }
    },
    {
      component: 'image',
      label: 'edit.THUMBNAIL',
      options: {
        type: 'THUMBNAIL'
      }
    },
    {
      component: 'image',
      label: 'edit.BACKGROUND_IMAGE',
      options: {
        type: 'BACKGROUND_IMAGE',
        fields: []
      }
    },
    'contentTerms'
  ],
  allowSibling: true,
  buildingBlocks: [
    'CURRICULUM_ZILL_GENERIC_GOAL'
  ],
  documentViewIdentifier: 'pi',
  isSearchable: false,
  collapsedByDefault: true,
  disableDeleteWhenIssued: true
};
