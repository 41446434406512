import { titleNotEmpty } from '../../validations/titleNotEmpty';
import { pathIsNotReserved } from '../../validations/pathIsNotReserved';
import { oldUrlIsPermitted } from '../../validations/oldUrlIsPermitted';
import { pathIsPermitted } from '../../validations/pathIsPermitted';
import { websiteUniqueWithinNode } from '../../validations/websiteUniqueWithinNode';
import { urlUniqueApiWithinApi } from '../../validations/urlUniqueWithinApi';
import { webConfigurationRequired } from '../../validations/webConfigurationRequired';
import { settings } from '../settings';
import * as apiRoutes from '../../reduxLoop/api/apiRoutes';
import { documentTags } from '../../reduxLoop/constants/documentTags';
import constants from '../../reduxLoop/constants/constants';
import { mainstructuresOuTypeCombinationsNotEmpty } from '../../validations/mainstructuresOuTypeCombinationsNotEmpty';
import { coverageNotEmpty } from '../../validations/coverageNotEmpty';
import { mainAndSubCurriculumThemesConsistent } from '../../validations/mainAndSubCurriculumThemesConsistent';
import { isMenuItemReferenceExternalLinkValid } from '../../validations/menuReferenceExternalLink';
import { themeNotEmpty } from '../../validations/themeNotEmpty';
import { databaseTypeNotEmpty } from '../../validations/databaseTypeNotEmpty';
import { isGlobalDocumentAllowed } from '../../validations/isGlobalDocumentAllowed';
import * as ACTION_TYPES from '@store/constants/actionTypes';

export default {
  information: {
    definiteArticle: true,
    single: 'Gedeeld databankitem',
    plural: 'Gedeelde databankitems',
  },
  preloadActions: [
    {
      type: ACTION_TYPES.GET_REFERENCE_FRAME,
      payload: { key: constants.contentTypesKey },
    },
  ],
  node: {
    type: 'SHARED_MINI_DATABASE_ITEM',
    tags: [documentTags.sharedMiniDatabaseItem],
  },
  websiteEdition: [
    {
      domain: settings.domains.pro,
    },
  ],
  webconfiguration: {
    type: 'MINI_DATABASE_ITEM',
    website: {
      href: `${apiRoutes.websites}/${settings.websites.pro}`,
    },
    template: {
      href: '/web/templates/21363fa8-c06b-4ace-92ad-180e5cd0c41a',
    },
  },
  indentation: 1,
  buildingBlocks: [
    'SECTION',
    'PARAGRAPH',
    'IMAGE',
    'IMAGE_GROUP',
    'VIDEO',
    'VIDEO_GROUP',
    'ATTACHMENTS_GROUP',
    'REFERENCE_GROUP',
    'QUOTE',
    'EXAMPLE',
    'SUMMARY',
    'LEGAL',
  ],
  createModal: {
    authors: {
      addUser: false,
      queryParams: constants.allAuthorsParameters,
    },
  },
  edit: [
    {
      component: 'title',
      validations: [titleNotEmpty],
    },
    {
      component: 'description',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
    },
    {
      component: 'image',
      label: 'Afbeelding',
      options: {
        type: 'THUMBNAIL',
        crop: true,
        fields: ['alt'],
      },
    },
    {
      component: 'authors',
      label: 'Auteur(s)',
      options: {
        queryParams: constants.allAuthorsParameters,
      },
    },
    'issued',
    {
      component: 'themes',
      options: {
        referenceFrameKey: constants.dienstverleningKovKey,
        types: ['THEME', 'CURRICULUM_THEME', 'COMMONS_STUDIEGEBIED', 'COMMONS_SUBJECT'],
        highlightOldCurrItems: true,
      },
      validations: [mainAndSubCurriculumThemesConsistent, themeNotEmpty],
    },
    'curriculumSelector',
    {
      component: 'themes',
      label: 'In welke databanktype(s) moet dit item getoond worden?',
      options: {
        referenceFrameKey: constants.contentTypesKey,
        types: ['THEME'],
      },
      validations: [databaseTypeNotEmpty],
    },
    {
      component: 'webFacets',
      width: '12',
      options: {
        source: 'themesMatches',
        matchingParams: {
          themesMatchingOnRoot: { referenceFrameKey: constants.dienstverleningKovKey },
          themesOverlaps: { referenceFrameKey: constants.contentTypesKey },
          'webPage.template': '34371fa8-c06b-4ace-83ad-180e5cd0b50b', // MINI_DATABASE
          $$includeWebPages: true,
        },
      },
    },
    {
      component: 'linkGroup',
      width: '12',
      label: 'Links',
      options: { type: 'group' },
    },
    {
      component: 'attachmentsGroup',
      options: { global: true },
      validations: [isGlobalDocumentAllowed],
    },
    {
      component: 'menuItemReference',
      options: {
        filter: {
          typeIn: 'STRUCTURED_DOCUMENT,SECTION',
          rootTag: [documentTags.webPage2],
          $$expandPathToRoot: true,
          orderBy: '$$meta.modified',
          descending: true,
        },
      },
      validations: [isMenuItemReferenceExternalLinkValid],
    },
    {
      component: 'namedSet',
      validations: [mainstructuresOuTypeCombinationsNotEmpty],
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      validations: [coverageNotEmpty],
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  asDocumentView: true,
  isCreatable: true,
  isSearchable: true,
  isCloneable: true,
  allowSuggestions: true,
  isImportable: true,
  websiteValidations: [
    pathIsNotReserved,
    oldUrlIsPermitted,
    pathIsPermitted,
    websiteUniqueWithinNode,
    urlUniqueApiWithinApi,
    webConfigurationRequired,
  ],
  globalAttachmentsGroupContainer: true,
  hideChildrenInDocument: [
    {
      type: 'ATTACHMENTS_GROUP',
      tags: ['GLOBAL'],
    },
  ],
};
