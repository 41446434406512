const constants = require('../../../reduxLoop/constants/constants');

module.exports = {
  information: {
    single: 'Samenvatting',
    plural: 'Samenvatting',
    ribonIcon: require('../../../../img/buildingBlocks/SUMMARY.svg')
  },
  node: {
    type: 'SUMMARY'
  },
  indentation: 1,
  buildingBlocks: [
    'PARAGRAPH',
    'IMAGE_GROUP',
    'IMAGE',
    'VIDEO'
  ],
  edit: [
    'title',
    {
      component: 'namedSet',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    },
    {
      component: 'coverage',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    }
  ],
  asDocumentView: true,
  customRender: true
};
