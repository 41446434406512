import React from 'react';

const Button = ({ children, icon, className, disabled, onClick = () => {} }) => {
  return (
    <button type="button" className={`btn btn-default ${className ?? ''}`} disabled={disabled} onClick={onClick}>
      {icon && <span className={`glyphicon ${icon}`}></span>}
      {children && <span>{children}</span>}
    </button>
  );
};

export default Button;
