module.exports = {
  information: {
    single: 'Muziek',
    plural: 'Muziek',
    ribonIcon: require('../../../../img/buildingBlocks/MUSIC.svg')
  },
  node: {
    type: 'MUSIC'
  },
  subchilds: [],
  edit: [
    {
      component: 'music',
      options: {
        type: 'ATTACHMENT',
        fields: [
          'description',
          'rightsHolder'
        ]
      }
    }
  ],
  allowSibling: true,
  customRender: true
};
