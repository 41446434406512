import listScreen from './listScreen';
import structureScreen from './structureScreen';
import appRoot from './appRoot';
import { asideScreenController } from './asideScreen';
import { publishDateModal } from './modals/publishDate';
import { createContentComponent } from './modals/createContent';
import { submitSuggestionsModal } from './modals/submitSuggestions';
import { reviewSuggestionsModal } from './modals/reviewSuggestions';
import { notApplicableProposalsComponent } from './modals/notApplicableProposals';

export default angular.module('redactieScreenComponents', [])
  .component('appRoot', appRoot)
  .component('listScreen', listScreen)
  .component('structureScreen', structureScreen)
  .controller('asideScreenController', asideScreenController)
  .controller('publishDateModal', publishDateModal)
  .component('createContentModal', createContentComponent)
  .controller('submitSuggestionsModal', submitSuggestionsModal)
  .controller('reviewSuggestionsModal', reviewSuggestionsModal)
  .component('notApplicableProposalsModal', notApplicableProposalsComponent);
