module.exports = {
  information: {
    single: 'Inhoud',
    plural: 'Inhouden'
  },
  node: {
    type: 'CURRICULUM_ZILL_DEVELOPMENT_CONTENT'
  },
  edit: [
    'title'
  ],
  minIndentation: 4,
  buildingBlocks: [
    'CURRICULUM_ZILL_DEVELOPMENT_CONTENT',
    {
      type: 'CURRICULUM_ZILL_LEERLIJN',
      max: 1
    },
    {
      type: 'CURRICULUM_ZILL_LEERLIJN_AN',
      max: 1
    }
  ],
  rowClass: 'goal-content-row',
  hasInnerCollapser: true,
  collapsedByDefault: true,
  isSearchable: false,
  disableDeleteWhenIssued: true
};
