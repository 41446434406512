module.exports = {
  information: {
    single: 'Krachtlijn',
    plural: 'Krachtlijn'
  },
  node: {
    type: 'LLINKID_GUIDING_PRINCIPLE'
  },
  indentation: 2,
  edit: [
    {
      component: 'description',
      options: {
        showMarkerButton: true
      }
    }
  ],
  buildingBlocks: [
  ]
};
