module.exports = {
  information: {
    single: 'Bijlage',
    plural: 'Bijlagen',
    ribonIcon: require('../../../../img/buildingBlocks/ATTACHMENT.svg')
  },
  node: {
    type: 'ATTACHMENT'
  },
  isSearchable: false,
  hideInDocument: true
};
