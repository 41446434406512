module.exports = ['cachedSamenscholingApi', 'Demarcation', 'settings', function (cachedSamenscholingApi, Demarcation, settings) {
  let service = {
    data: {}
  };

  // demarctaion service wrappers
  service.summerNoteDemarcationLinkAction = function (context) {
    return Demarcation.summerNoteDemarcationLinkAction(context);
  };

  // will create a demarcation resource anmd link it with is_part_of to the goven llikid goal
  service.addDemarcation = async (llinkidGoal, selectedText, demarcationKey) => {
    return Demarcation.addDemarcation(llinkidGoal, selectedText, demarcationKey);
  };

  service.transformDemarcationReferencesIntoLinks = (description) => {
    return Demarcation.transformDemarcationReferencesIntoLinks(description);
  };

  service.transformLinksIntoDemarcation = (textWithDemarcationLinks) => {
    return Demarcation.transformLinksIntoDemarcation(textWithDemarcationLinks);
  };

  service.demarcationRelationDifferences = (text, currentDemarcations) => {
    let demarcationsAfterEdition = Demarcation.getDemarcationReferencesAfterEdition(text);
    return Demarcation.getDemarcationRelationDifferences(demarcationsAfterEdition, currentDemarcations);
  };

  service.setCurrentContent = (key) => {
    service.data.current = key;
  };

  service.loadDemarcations = (loadedContentKey) => {
    return Demarcation.loadDemarcations(loadedContentKey);
  };

  service.loadStudyProgrammes = () => {
    let samConfig = {
      baseUrl: settings.apisAndUrls.cachedSamenscholingApi + settings.samenscholing.prefix,
      headers: {}
    };
    samConfig.headers[settings.samenscholing.name] = settings.samenscholing.token;

    return cachedSamenscholingApi.getAll('/commons/studyprogrammes', {}, samConfig);
  };

  return service;
}];
