
// Dumb component: only show the source and emit an event if user confirms source deletion

class Source {
  constructor($scope, ConfirmationService) {
    'ngInject';

    this.$scope = $scope;
    this.confirmationService = ConfirmationService;
  }

  async deleteSource() {
    let confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      this.$scope.$emit('resourceDeleted', this.sSource);
    }
  }
}

module.exports = {
  template: require('./source.html'),
  controllerAs: 'ctrl',
  bindings: {
    sSource: '<'
  },
  controller: Source
};
