/* eslint-disable max-len */
import { contentApi, websitesApi } from '../api/apiConfig';
import pMap from 'p-map';
import { getResourceKey, getRoot } from '../helpers/documentHelpers';
import { fetchTreeAsLeafCmd } from './documentCommands';
import { settings } from '../../config/settings';
import * as apiRoutes from '../../reduxLoop/api/apiRoutes';

export const sendWebsitesBatchCmd = async (batch) => {
  try {
    return websitesApi.put(`${apiRoutes.webpages}/batch`, batch);
  } catch (e) {
    throw e;
  }
};

export const getWebsitesAndTemplatesCmd = async () => {
  const websites = await websitesApi.getAll(apiRoutes.websites, {}, { caching: { timeout: 5000 } });
  const webtemplates = await websitesApi.getAll('/web/templates', {}, { caching: { timeout: 5000 } });

  return {
    websites,
    webtemplates
  };
};

export const getDocumentWebpagesCmd = async (key) => {
  try {
    return websitesApi.getAll(apiRoutes.webpages, {
      externalReferencesContains: '/content/' + key
    }, { caching: { timeout: 5000 } });
  } catch (e) {
    throw e;
  }
};

// load all the webconfigurations for the given document
export const initDocumentWebsitesConfigurationCmd = async (documentKey) => {
  try {
    const configurations = await websitesApi.getAll(apiRoutes.webpages, { externalReferencesContains: ('/content/' + documentKey) });

    const websitesCache = new Map();

    await pMap(
      configurations,
      async (configuration) => {
        let website = websitesCache.get(configuration.website.href);
        if (!website) {
          website = await websitesApi.get(configuration.website.href, {}, { caching: { timeout: 5000 } });
          websitesCache.set(configuration.website.href, website);
        }
        configuration.website = {
          ...configuration.website,
          ...website
        };

        if (configuration.options && configuration.options.facets
          && configuration.options.facets.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          await pMap(
            configuration.options.facets,
            async (facet) => {
              if (facet.source) {
                try {
                  const source = await contentApi.get(facet.source.href);
                  facet.source = {
                    ...facet.source,
                    ...source
                  };
                } catch (e) {
                  console.log('ERROR retrieving source:', facet.source.href, configuration);
                }
              }
            }, { concurrency: 5 }
          );
        }
      }, { concurrency: 5 }
    );

    return {
      keys: documentKey,
      configurations: configurations.map(c => {
        return {
          ...c,
          oldLocations: c.oldLocations || []
        };
      })
    };
  } catch (e) {
    throw e;
  }
};

export const getWebpagesWithFacetContainingReferenceFrame = async (referenceFrameKey) => {
  try {
    const configurations = await websitesApi.getAll(apiRoutes.webpages, { 'options.facetsContains': `/content/${referenceFrameKey}` });
    return configurations;
  } catch (e) {
    throw e;
  }
};

/**
 * Beware! This function doesn't return the actual duplicate webconfigs but only the possible duplicates since
 * 1. The `anyPath` query doesn't take the website into account.
 * 2. From each result the path and old locations are added as separate entries.
 * The actual duplicates are determined in the validation rule `urlUniqueWithinApi`.
 * @param {Array} webpages The document's current web configurations.
 * @returns {Array} A list of possible duplicate web configurations.
 */
export const getPossibleDuplicateWebConfigs = async (webpages) => {
  const paths = webpages.reduce((accPaths, w) => {
    const oldLocations = w.oldLocations || [];
    return [
      ...accPaths,
      w.path,
      ...oldLocations.map(l => l.path)
    ];
  }, []);

  if (paths.length === 0) {
    return [];
  }

  const websites = await sendWebsitesBatchCmd([
    {
      verb: 'GET',
      href: `${apiRoutes.webpages}?limit=500&anyPath=${paths.join(',')}`
    }
  ]);

  const duplicates = websites[0].body.results
    .filter(r => !webpages.find(w => w.source.href === r.$$expanded.source.href)).map(r => r.$$expanded);

  if (duplicates.length === 0) {
    return [];
  }

  const leaves = await fetchTreeAsLeafCmd(duplicates.map(d => ({ key: getResourceKey(d.source.href) })));

  return duplicates.reduce((duplicates, d) => {
    const treeAsLeaf = leaves.find(l => `/content/${l.key}` === d.source.href).$$treeAsLeaf;
    const root = getRoot(treeAsLeaf);

    return [
      ...duplicates,
      {
        sourceHref: d.source.href,
        websiteHref: d.website.href,
        path: d.path,
        oldLocations: d.oldLocations,
        rootTitle: root.title,
        rootTag: root.tags[0]
      },
      ...(d.oldLocations || []).map(l => {
        return {
          sourceHref: d.source.href,
          websiteHref: l.website.href,
          path: l.path,
          rootTitle: root.title,
          rootTag: root.tags[0]
        };
      })
    ];
  }, []);
};

export const recacheUrlsCmd = (webpages) => {
  if (!settings.prerender.enabled) {
    return;
  }
  webpages.forEach(w => {
    const cacheUrl = `https://${w.website.domain}${w.path}`;
    const data = {
      prerenderToken: settings.prerender.token,
      url: cacheUrl
    };
    fetch(settings.prerender.url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });
  });
};
