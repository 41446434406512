import * as DOCUMENT_ACTIONS from '../../reduxLoop/actions/documentActions';
import * as NOTIFICATION_ACTIONS from '../../reduxLoop/actions/notificationActions';
import { getTitle } from '../../services/types';
require('./structureScreen.scss');
const kovsentry = require('../../app/kovSentry');

class structureScreen {
  constructor($scope, $ngRedux, $stateParams, $state, $transitions, $http, $translate, settings, $window, $timeout, ConfirmationService, ModalWindowService, postMessageService) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$transitions = $transitions;
    this.$http = $http;
    this.$translate = $translate;
    this.$window = $window;
    this.$stateParams = $stateParams;
    this.$state = $state;
    this.$timeout = $timeout;
    this.settings = settings;
    this.confirmationService = ConfirmationService;
    this.modalWindowService = ModalWindowService;
    this.postMessageService = postMessageService;
  }

  async $onInit() {
    kovsentry.startLog('Load Document', {
      'document key': this.$stateParams.key
    });
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.loadDocumentAction(this.$stateParams.key, this.$stateParams.editKey)
    );
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!state.document.viewModel.loading) {
        if (state.document.proposalsToReview.length === 0) {
          kovsentry.endLog('Accept Suggestions');
      }
        if (!state.document.isRefreshing) {
          kovsentry.endLog('Refresh Newsletter');
        }
        kovsentry.endLog('Save Document');
        kovsentry.endLog('Create Document');
        kovsentry.endLog('Load Document');
      }
      return {
        key: state.document.viewModel.document && state.document.viewModel.document.key,
        ...state.document.viewModel,
        ...state.document, // to be removed
        titleWithVersion: state.document.viewModel.document ? getTitle(state.document.viewModel.document) : null
      };
    })(async (selectedState) => {
      Object.assign(this, selectedState);
      if (this.viewModel.napModal && this.viewModel.napModal.open) {
        this.modalWindowService.open({
          component: 'notApplicableProposalsModal'
        });
      }
    });

    window.addEventListener('beforeunload', (event) => {
      if (this.itemsToSave > 0 && this.settings.environment != 'local') {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // Chrome requires returnValue to be set.
        event.returnValue = '';
      }
    });

    this.transitionUnsb = this.$transitions.onStart({
      exiting: 'edit'
    }, () => {
      if (this.itemsToSave > 0 && this.settings.environment != 'local') {
        if (!confirm(this.$translate.instant('pageDirty'))) {
          return false;
        }
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.backToDocumentsListAction());
        return true;
      }
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.backToDocumentsListAction());
    }, {
      bind: this
    });
  }

  $onDestroy() {
    this.unsubscribe();
    this.transitionUnsb();
  }

  save() {
    this.isSaving = true;
    kovsentry.startLog('Save Document', {
      document: this.document.title
    });
    this.$timeout(() => {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.saveDocumentAction(this.$http));
    }, 600);
  }

  async removeSelectedNodes() {
    let confirmed = true;
    if (this.confirmDeleteMessages.size > 0) {
      confirmed = await this.confirmationService.confirmDeletion(Array.from(this.confirmDeleteMessages).join('<br>'));
    }
    if (confirmed) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.validateAndRemoveSelectionsAction());
    }
  }

  async undoAll() {
    const confirmed = await this.confirmationService.confirm({
      confirmationMessage: 'undo.confirm',
    });
    if (confirmed) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.undoAllAction());
    }
  }

  termAdded(scope, event, data) {
    scope.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.addTermReferenceAction(data.term));
  }

  async submitSuggestions() {
    if (this.suggestions.isValidToSubmit) {
      const opts = {
        template: require('../modals/submitSuggestions/submitSuggestions.html'),
        controller: 'submitSuggestionsModal as ctrl'
      };

      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openSubmitSuggestionsModalAction());

      await this.modalWindowService.open(opts);
    } else {
      this.$ngRedux.dispatch(NOTIFICATION_ACTIONS.addNotificationAction({
        type: 'ERROR',
        message: 'proposals.error.invalidToSubmit'
      }));
    }
  }

  async openModal() {
    const opts = {
      template: require('../modals/reviewSuggestions/reviewSuggestions.html'),
      controller: 'reviewSuggestionsModal as ctrl'
    };

    await this.modalWindowService.open(opts);
  }

  async publish() {
    const opts = {
      template: require('../modals/publishDate/publishDate.html'),
      controller: 'publishDateModal as ctrl',
      resolve: {
        modalData: () => {
          return {
            type: 'DOCUMENT',
            document: this.document
          };
        }
      }
    };

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openedPublishModal());

    await this.modalWindowService.open(opts);
  }

  setLastRead() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.updateLastReadMarkAction());
  }

  refresh() {
    kovsentry.startLog('Refresh Newsletter', {
      documentName: this.document.title,
      key: this.document.key
    });
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.refreshNewsletter());
  }

  rejectSuggestions() {
    if (this.suggestions.isAllPendingSuggestionsToReview) {
      this.openModal();
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openReviewSuggestionsModalAction('REJECT'));
    } else {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.rejectSuggestionsAction());
    }
  }

  acceptSuggestions() {
    if (this.suggestions.isAllPendingSuggestionsToReview) {
      this.openModal();
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openReviewSuggestionsModalAction('ACCEPT', this.$http));
    } else {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.acceptSuggestionsAction());
    }
  }

  unsubmitSuggestions() {
    if (this.suggestions.isAllPendingSuggestionsToReview) {
      this.openModal();
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openReviewSuggestionsModalAction('CANCEL'));
    } else {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.cancelSuggestionsAction());
    }
  }

  changeSuggestMode() {
    let newMode = 'EDIT';
    if (this.suggestions.isSuggesting) {
      newMode = 'SUGGESTING';
    }
    this.suggestions.isReviewing = false;
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.changeMode(newMode));
  }

  changeReviewMode() {
    let newMode = 'EDIT';
    if (this.suggestions.isReviewing) {
      newMode = 'REVIEWING';
    }
    this.suggestions.isSuggesting = false;
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.changeMode(newMode));
  }

  async previewNewsletter() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openWindowAction(this.$window, this.key));
  }

  async openPreview(previewLocation) {
    if (previewLocation.type === 'URL') {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openPreviewAction(this.$window, this.postMessageService, previewLocation, this.key));
    }

    if (previewLocation.type === 'ZILL') {
      const opts = {
        component: 'selectCurriculumVersionModal',
        dataForModal: { selecting: false }
      };
      const versionHref = await this.modalWindowService.open(opts);

      this.$window.open(previewLocation.location.replace('{%key}', this.$stateParams.key).replace('{%version}', versionHref), '_blank');
    }
  }

  back() {
    this.$state.go('list');
  }
}

export default {
  template: require('./structureScreen.html'),
  controllerAs: 'ctrl',
  bindings: {},
  controller: structureScreen
};
