import { UserState } from './userTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: UserState = {
  personHref: null,
  securityData: null,
  isLoading: false,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    initUser(state) {
      state.isLoading = true;
    },
    userFetched(state, action: PayloadAction<{ uuid: string; username: string }>) {
      state.personHref = `/persons/${action.payload.uuid}`;
    },
    securityDataFetched(state, action: PayloadAction<Record<string, string[]>>) {
      state.isLoading = false;
      state.securityData = action.payload;
    },
    initUserFailed(state, action: PayloadAction<any>) {
      state.isLoading = false;
    },
  },
});

export const { initUser, userFetched, securityDataFetched, initUserFailed } = userSlice.actions;
export default userSlice;
