class rowAttachmentGroup {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const node = state.document.viewModel.flat.find(n => n.key === this.sItem.key);
      return {
        attachments: node
          ? node.attachmentResources.filter(attachment => (!attachment.proposal || attachment.proposal.type !== 'DELETE') && !attachment.deleteProposal)
          : []
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  $onChanges() {
    // const annotation = this.sItem.$$parent ? this.sItem.$$parent.$$annotations.filter(o => o.href === '/content/' + this.sItem.key)[0] : null;
    // this.demarcation = annotation ? annotation.text : this.sItem.$$parent.$$prefix;
    // this.$scope.$broadcast('$$rebind::demarcationChange');
  }
}

export const rowAttachmentGroupComponent = {
  template: require('./rowAttachmentGroup.html'),
  controllerAs: 'ctrl',
  bindings: {
    sItem: '<?'
  },
  controller: rowAttachmentGroup
};
