import React, { useCallback, useEffect } from 'react';
import { object, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import AsideRelationsPanel from '../asideRelationsPanel/AsideRelationsPanel';
import { openGoalRelationsModal } from '@store/helpers/documentAsideHelpers';
import { formatVersion } from '@store/helpers/documentHelpers';
import EncodedText from '@UI/encodedText/EncodedText';

const AsideGoalRelations = ({ label, foundationalLabel, options }) => {
  const [confirmationService, $translate] = getAngularService('ConfirmationService', '$translate');
  const dispatch = useDispatch();
  const allRelations = useSelector(
    (state) => state.document.apiWithPendingChanges?.contentRelations
  );
  const loading = useSelector((state) => !state.document.goalRelationsExpanded);
  const editDocument = useSelector((state) => state.document.viewModel?.aside?.editDocument);
  const isReadOnlyDocument = useSelector((state) => state.document.viewModel?.isReadOnlyDocument);
  const isReadOnly = isReadOnlyDocument || options.readOnly;
  const relationOrigin = !options.revertedRelationDirection ? 'from' : 'to';
  const relationEnd = !options.revertedRelationDirection ? 'to' : 'from';
  const isRelationTypeRelationIncluded = options.relationTypes.includes('RELATION');

  const filterFn = (relation) => {
    return (
      options.relationTypes.includes(relation.relationtype) &&
      relation[relationEnd].$$expanded &&
      (!options.toType || options.toType === relation[relationEnd].$$expanded.type)
    );
  };

  let relations = allRelations[relationOrigin]['/content/' + editDocument.key]?.filter(filterFn);

  // special case of RELATION relations: get to relations and reverse
  if (isRelationTypeRelationIncluded) {
    const toRelations = allRelations[relationEnd][`/content/${editDocument.key}`]?.filter(
      (r) => r.relationtype === 'RELATION' && r[relationOrigin].$$expanded
    );

    if (toRelations) {
      const reversedToRelations = toRelations.map((r) => ({
        ...r,
        from: r.to,
        to: r.from,
        $$reversed: true,
      }));

      relations = relations.concat(reversedToRelations.filter(filterFn));
    }
  }

  const isFoundationalDocument = editDocument?.$$root?.foundational;
  const goalRelationsParams = {
    key: editDocument.key,
    relationTypes: options.relationTypes,
    originPart: relationOrigin,
  };

  const onDelete = useCallback(async (item) => {
    if (isReadOnly) return;

    const confirmed = await confirmationService.confirmDeletion();
    if (confirmed) {
      dispatch(DOCUMENT_ACTIONS.removeRelationAction(item.key));
    }
  }, []);

  const onCreate = async () => {
    if (isReadOnly) return;

    const response = await openGoalRelationsModal({
      asideOptions: options,
      rootKey: editDocument.$$root.key,
      relations: relations,
    });

    if (response) {
      const newRelation = {
        relationtype: options.selectType ? response.realisation.value : options.relationTypes[0],
        strength: 'MEDIUM',
        [relationOrigin]: {
          href: editDocument.$$meta.permalink,
        },
        [relationEnd]: {
          href: response.goal.$$meta.permalink,
        },
      };

      dispatch(DOCUMENT_ACTIONS.addRelationAction(newRelation, true, true, goalRelationsParams));
    }
  };

  const onEdit = async (relation) => {
    if (isReadOnly) return;

    const response = await openGoalRelationsModal({
      asideOptions: options,
      rootKey: editDocument.$$root.key,
      relations: relations,
      item: relation,
    });

    dispatch(
      DOCUMENT_ACTIONS.patchRelationAction(
        relation.key,
        {
          [relation.$$reversed ? relationOrigin : relationEnd]: {
            href: response.goal.$$meta.permalink,
            $$expanded: response.goal,
          },
          relationtype: options.selectType ? response.realisation.value : options.relationTypes[0],
        },
        {
          ...goalRelationsParams,
          ...(relation.$$reversed && { originPart: relationEnd }),
        }
      )
    );
  };

  useEffect(() => {
    dispatch(
      DOCUMENT_ACTIONS.expandLlinkidGoalRelationsAction(
        editDocument.key,
        options.relationTypes,
        relationOrigin
      )
    );

    // special case of RELATION relations where we also need to fetch the from in the to relations
    if (isRelationTypeRelationIncluded) {
      dispatch(
        DOCUMENT_ACTIONS.expandLlinkidGoalRelationsAction(
          editDocument.key,
          ['RELATION'],
          relationEnd
        )
      );
    }
  }, []);

  return (
    <AsideRelationsPanel
      title={$translate.instant(
        isFoundationalDocument && foundationalLabel ? foundationalLabel : label
      )}
      items={relations}
      loading={loading}
      readOnly={isReadOnly}
      onCreate={onCreate}
      onDelete={onDelete}
      onEdit={onEdit}
    >
      {(item) => (
        <>
          <div className="col-md-2 indented">
            <EncodedText>{item[relationEnd]?.$$expanded?.completeIdentifier}</EncodedText>
          </div>
          {options.selectType && (
            <div className="col-md-2 indented">
              {$translate.instant(`relationType.${item.relationtype}`)}
            </div>
          )}
          <div className={`col-md-${options.selectType ? '7' : '9'} indented"`}>
            <span
              dangerouslySetInnerHTML={{
                __html: item[relationEnd]?.$$expanded[options.listColumn],
              }}
            ></span>
          </div>
          <div className="col-md-1 indented">
            {$translate.instant('components.llinkid.goalRelations.fromVersion', {
              version: formatVersion(item[relationEnd]?.$$expanded?.$$root?.$$version),
            })}
          </div>
        </>
      )}
    </AsideRelationsPanel>
  );
};

AsideGoalRelations.propTypes = {
  label: string,
  foundationalLabel: string,
  options: object,
};

export default AsideGoalRelations;
