import { mainstructuresOuTypeCombinationsInconsistent } from '../../../validations/mainstructuresOuTypeCombinationsInconsistent';
import { coverageInconsistent } from '../../../validations/coverageInconsistent';
import { referenceFrameDeleteValidationCmd } from '../../../reduxLoop/commands/documentListCommands';
import { validationFailedAction } from '../../../reduxLoop/actions/documentListActions';
import constants from '../../../reduxLoop/constants/constants';

export default {
  information: {
    definiteArticle: false,
    single: 'Ordeningskader',
    plural: 'Ordeningskaders',
    category: 'GENERAL'
  },
  node: {
    type: 'REFERENCE_FRAME'
  },
  createModal: {
    authors: {
      addUser: true
    }
  },
  indentation: 1,
  buildingBlocks: [
    'THEME',
    'SUBJECT',
    'DOMAIN',
    'STUDY_AREA',
    'BASIC_OPTION',
    'STUDY_DIRECTION'
  ],
  tocTypes: [
    'THEME'
  ],
  edit: [
    'title',
    'issued',
    {
      component: 'namedSet',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      validations: [
        mainstructuresOuTypeCombinationsInconsistent
      ],
      tab: constants.asideTabs.AUDIENCE
    },
    {
      component: 'coverage',
      tab: constants.asideTabs.AUDIENCE,
      validations: [
        coverageInconsistent
      ]
    }
  ],
  asDocumentView: true,
  isSearchable: true,
  isCreatable: true,
  deleteFromListValidations: [
    {
      cmd: referenceFrameDeleteValidationCmd,
      failAction: validationFailedAction
    }
  ]
};
