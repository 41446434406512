import { documentTags } from '../../reduxLoop/constants/documentTags';
import constants from '../../reduxLoop/constants/constants';
import { mainstructuresOuTypeCombinationsNotEmpty } from '../../validations/mainstructuresOuTypeCombinationsNotEmpty';
import { coverageNotEmpty } from '../../validations/coverageNotEmpty';
import { titleNotEmptyOnNewDocumentUpload } from '../../validations/titleNotEmptyOnNewDocumentUpload';
import { selectOneTypeOfDocument } from '../../validations/selectOneTypeOfDocument';
import { mainAndSubCurriculumThemesConsistent } from '../../validations/mainAndSubCurriculumThemesConsistent';

export default {
  information: {
    definiteArticle: true,
    single: 'Achtergronddocument (leerplansites so)',
    plural: 'Achtergronddocumenten (leerplansites so)',
    icon: require('../../../img/icons/shared-attachments-group.svg'),
  },
  node: {
    type: 'SHARED_ATTACHMENTS_GROUP',
    tags: [documentTags.sharedAttachmentsGroup],
  },
  indentation: 1,
  buildingBlocks: [],
  edit: [
    {
      component: 'downloadDocument',
      avoidDispatchDownloadTag: true,
      options: {
        validExtensions:
          '.pdf, .jpg, .jpeg, .png, .mp3, .mp4, .doc, .docx, .dotx, .xls, .xlsx, .xltx, .ppt, .pptx, .ppsx, .potx, .tiff',
        copyTitle: true,
      },
      validations: [titleNotEmptyOnNewDocumentUpload, selectOneTypeOfDocument],
    },
    'issued',
    {
      component: 'themes',
      label: 'edit.curriculumThemes',
      options: {
        referenceFrameKey: constants.dienstverleningKovKey,
        types: ['THEME', 'CURRICULUM_THEME', 'COMMONS_STUDIEGEBIED', 'COMMONS_SUBJECT'],
        highlightOldCurrItems: true,
      },
      validations: [mainAndSubCurriculumThemesConsistent],
    },
    'curriculumSelector',
    {
      component: 'namedSet',
      validations: [mainstructuresOuTypeCombinationsNotEmpty],
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      validations: [coverageNotEmpty],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'attachmentsGroup',
      options: { global: false },
    },
    {
      component: 'webFacets',
      width: '12',
      options: {
        source: 'themesMatches',
        matchingParams: {
          themesMatchingOnRoot: { referenceFrameKey: constants.dienstverleningKovKey },
          'webPage.template': '6a090cca-c380-4248-8722-2951431018a9', // DOWNLOAD_PAGE
          $$includeWebPages: true,
        },
      },
    },
  ],
  asDocumentView: true,
  isCreatable: true,
  isSearchable: true,
  isCloneable: true,
  allowSuggestions: true,
  hideChildrenInDocument: true,
};
