class ReferenceSelectionModal {
  async $onInit() {
    this.description = this.resolve.modalData.description;
  }

  changeType(e, type) {
    this.type = type;
    this.parameters = this.type.resourceFilter;
  }

  changeRoot(e, root) {
    this.root = root;
    this.parameters = { root: this.root.$$meta.permalink, ...this.type.resourceFilter };
  }

  changeResource(e, resource) {
    this.resourceHref = resource.$$meta.permalink;
  }

  filter(results) {
    return results.filter(r => !this.$scope.$parent.ctrl.resolve.modalData.referenceRelations.map(r => r.to.$$expanded && r.to.$$expanded.key).includes(r.key));
  }

  async submit() {
    this.modalInstance.close({ resourceHref: this.resourceHref, description: this.description });
  }

  cancel() {
    this.modalInstance.dismiss();
  }
}

export default {
  template: require('./referenceSelectionModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<'
  },
  controller: ReferenceSelectionModal
};
