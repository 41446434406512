import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ExternalDataState } from './externalDataTypes';
import { NewsletterSender } from '../../types/newsletterTypes';

const initialState: ExternalDataState = {
  persons: {},
  ous: {},
  newsletterSenders: [],
};

const externalDataState = createSlice({
  name: 'externalDataState',
  initialState,
  reducers: {
    getNewsletterSenders: () => {},
    setPersons: (state, action) => {
      const { persons } = action.payload;
      persons.forEach((person) => {
        state.persons[person.$$meta.permalink] = person;
      });
    },
    setOUs: (state, action) => {
      const { ous } = action.payload;
      ous.forEach((ou) => {
        state.ous[ou.$$meta.permalink] = ou;
      });
    },
    setAuthors: (state, action) => {
      const { authors } = action.payload;
      authors.forEach((author) => {
        if (author.$$meta.permalink.startsWith('/persons/')) {
          state.persons[author.$$meta.permalink] = author;
        } else {
          state.ous[author.$$meta.permalink] = author;
        }
      });
    },
    setNewsletterSenders: (
      state,
      action: PayloadAction<{ newsletterSenders: NewsletterSender[] }>
    ) => {
      const { newsletterSenders } = action.payload;
      state.newsletterSenders = newsletterSenders;
    },
  },
});

export const { getNewsletterSenders, setPersons, setOUs, setAuthors, setNewsletterSenders } =
  externalDataState.actions;

export default externalDataState;
