/* eslint-disable func-names */
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';
import { getGoalPathText } from '../../../../reduxLoop/helpers/documentHelpers';
import { getNow } from '@kathondvla/sri-client/date-utils';

class asideZillIllustrations {
  constructor(ZillSelectorService, ModalWindowService, ConfirmationService, $scope, $ngRedux) {
    'ngInject';

    this.modalWindowService = ModalWindowService;
    this.confirmationService = ConfirmationService;
    this.zillSelectorService = ZillSelectorService;
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      let relations = state.document.apiWithPendingChanges.contentRelations.to['/content/' + this.sDocument.key]
        ? state.document.apiWithPendingChanges.contentRelations.to['/content/' + this.sDocument.key].filter(rel => rel.relationtype === 'REFERENCES')
        : [];

      let groupedReferencesByRootMap;
      if (relations) {
        groupedReferencesByRootMap = this.groupAndFormatZillIllustrationRelations(relations, state);
      }

      return {
        groupedReferencesByRoot: groupedReferencesByRootMap
          ? [...groupedReferencesByRootMap.values()]
          : [],
        groupedReferencesByRootMap: groupedReferencesByRootMap,
        loading: state.document.loadingPracticalExampleZillIllustrations
      };
    })(this);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.loadPracticalExampleZillIllustrationsAction(
      this.sDocument.key
    ));
  }

  $onDestroy() {
    this.unsubscribe();
  }

  async openSelector() {
    const zillCurriculum = await this.modalWindowService.open({
      component: 'documentSelectorModal',
      dataForModal: {
        type: 'zillCurriculum',
        stateField: 'zillCurriculums',
        params: {
          type: 'CURRICULUM_ZILL',
          issuedBefore: getNow(),
          orderBy: 'issued',
          descending: true
        }
      }
    });

    try {
      // after selecting a zill version, we will open the zill selector where you can adapt the
      // selected goals of that version. We do not touch the goals of different zill versions.

      const groupForSelectedVersion = this.groupedReferencesByRoot
        .find(group => group.root.$$meta.permalink === zillCurriculum.$$meta.permalink);
      const currentGoalsRelatedToSelectedVersionHrefs = groupForSelectedVersion
        ? groupForSelectedVersion.illustrationRelations.map(rel => rel.goal.$$meta.permalink)
        : [];

      const selectedGoals = await this.zillSelectorService
        .openZillSelector(zillCurriculum.$$meta.permalink, currentGoalsRelatedToSelectedVersionHrefs, 'praktijkvoorbeeld');

      const deletedGoals = groupForSelectedVersion ? groupForSelectedVersion.illustrationRelations
        .filter(rel => !selectedGoals.includes(rel.goal.$$meta.permalink)) : [];

      deletedGoals.forEach(deletedIllustrationRelation => {
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeZillIllustrationRelationAction(
          deletedIllustrationRelation.illustration.key,
          deletedIllustrationRelation.illustration.relationKey
        ));
      });

      const newSelections = groupForSelectedVersion
        ? selectedGoals.filter(goalHref =>
          (!currentGoalsRelatedToSelectedVersionHrefs.some(current => current === goalHref))
        )
        : selectedGoals;

      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.expandZillGoalSelectionsAction(
        newSelections
      ));

      newSelections.forEach(async selection => {
        // open modal, ask zill illustration title,
        // create node with linked goal selections to it
        let selectedGoalHref = selection;
        let title = await this.modalWindowService.open({ component: 'zillIllustrationModal', dataForModal: { goalHref: selectedGoalHref } });

        let zillIllustration = {
          title: title
        };
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addZillIllustrationAction(
          this.sDocument.key,
          zillIllustration,
          selectedGoalHref
        ));
      });
    } catch (error) {
      if (error === 'ZILL_SELECTOR_ABORTED') {
        console.log('The user closed the zill selector manually.');
      } else {
        console.error('An unexpected error occured when communicating with the Zill selector', error);
      }
    }
  }

  groupAndFormatZillIllustrationRelations(illustrationRelations, state) {
    let groupedByRoot = new Map();

    console.log('ZILL_ILLUSTRATIONS', illustrationRelations);

    /* illustrationRelations.forEach(illustrationRelation => {
      if (illustrationRelation.from.$$expanded
        && illustrationRelation.from.$$expanded.$$relationsFrom[0]
        && illustrationRelation.from.$$expanded.$$relationsFrom[0].to) {
        let illRoot = illustrationRelation.from.$$expanded.$$relationsFrom[0]
          .to.$$expanded.$$treeAsLeaf
          .find(node => node.type === 'CURRICULUM_ZILL');

        let root = groupedByRoot.get(illRoot.$$meta.permalink);
        if (!root) {
          root = { root: illRoot, illustrationRelations: [] };
        }

        illustrationRelation.from.$$expanded.$$relationsFrom
          .forEach(relationToGoal => {
            root.illustrationRelations.push({
              text: getGoalPathText(relationToGoal.to.$$expanded.$$treeAsLeaf),
              goal: relationToGoal.to.$$expanded,
              illustration: {
                key: illustrationRelation.from.$$expanded.key,
                relationKey: illustrationRelation.key,
                title: illustrationRelation.from.$$expanded.title
              }
            });
          });

        groupedByRoot.set(illRoot.$$meta.permalink, root);
      }
    }); */

    illustrationRelations.forEach(illustrationRelation => {
      if (illustrationRelation.from.$$expanded) {
        // illustrationRelation.from.$$expanded.$$relationsFrom.forEach(relationToGoal => {
        (state.document.apiWithPendingChanges.contentRelations.from[illustrationRelation.from.href] || []).forEach(relationToGoal => {
          if (relationToGoal.to && relationToGoal.to.$$expanded) {
            let illRoot = relationToGoal.to.$$expanded.$$treeAsLeaf.find(node => node.type === 'CURRICULUM_ZILL');

            let root = groupedByRoot.get(illRoot.$$meta.permalink);
            if (!root) {
              root = { root: illRoot, illustrationRelations: [] };
            }

            root.illustrationRelations.push({
              text: getGoalPathText(relationToGoal.to.$$expanded.$$treeAsLeaf),
              goal: relationToGoal.to.$$expanded,
              illustration: {
                key: illustrationRelation.from.$$expanded.key,
                relationKey: relationToGoal.key,
                title: illustrationRelation.from.$$expanded.title
              }
            });

            groupedByRoot.set(illRoot.$$meta.permalink, root);
          }
        });
      }
    });

    return groupedByRoot;
  }

  showDeleteIllustration(row) {
    if (!row.selection) {
      return true;
    }
    return !row.header;
  }

  async deleteIllustration(row) {
    // delete illustration and relation to document
    let confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeZillIllustrationRelationAction(row.illustration.key, row.illustration.relationKey));
    }
  }
}

export default {
  template: require('./asideZillIllustrations.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<'
  },
  controller: asideZillIllustrations
};
