import { descriptionNotEmpty } from '../../../validations/descriptionNotEmpty';

export default {
  information: {
    single: 'Beginsituatie',
    plural: 'Beginsituaties',
  },
  node: {
    type: 'LLINKID_INITIAL_SITUATION',
  },
  indentation: 4,
  edit: [
    {
      component: 'description',
      validations: [descriptionNotEmpty],
      options: {
        showMarkerButton: true,
      },
    },
    {
      component: 'goalRelations',
      type: 'llinkidRelations',
      label: 'components.llinkid.goalRelations.initialSituation.title',
      options: {
        relationTypes: ['REFERENCES'],
        onlyFoundationals: false,
        modal: 'llinkidGoalRelationsModal',
        listColumn: 'description',
        modalTitle: 'components.llinkid.goalRelations.initialSituation.modalTitle',
        toType: 'LLINKID_GOAL',
      },
    },
    {
      component: 'previousVersionItems',
      type: 'previousVersionItems',
      label: 'components.llinkid.previousVersionItems.title',
      options: {
        relationTypes: ['REPLACES'],
        modal: 'llinkidPreviousVersionModal',
        listColumn: 'description',
        placeholder: 'components.llinkid.initialSituationReplaces.placeholder',
        relation: 'components.llinkid.initialSituationReplaces.relation',
        errorMessage: 'components.llinkid.initialSituationReplaces.error.invalidRelation',
        type: 'LLINKID_INITIAL_SITUATION',
      },
    },
  ],
  customRender: true,
};
