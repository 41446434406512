/* eslint-disable max-len */
import { getTypeConfig } from '../../../services/types';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class selectExternalRelationModal {
  constructor($scope, $notification, $ngRedux, cachedSamenscholingApi) {
    'ngInject';

    this.$scope = $scope;
    this.notificationService = $notification;
    this.$ngRedux = $ngRedux;
    this.loading = false;
    this.cachedSamenscholingApi = cachedSamenscholingApi;
  }

  async $onInit() {
    this.parentDocument = this.resolve.modalData.parentDocument;
    this.type = this.resolve.modalData.type;
    this.config = getTypeConfig(this.type);
    this.url = this.config.relation.filterUrl;
    this.placeholder = this.config.relation.placeholder;
    this.resourcePickerTemplate = require('../../../screen/optionTemplates/externalRelationOption.html');
    this.selectExternalRelationModalOpen = true;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!state.document.viewModel.selectExternalRelationModalOpen) {
        this.modalInstance.close(this.selected);
      }
      return {
        preLoadedOptions: state.document.referenceFrameExternalOptions[this.config.information.single] || []
      };
    })(this);

    if (this.config.relation.preLoad) {
      this.$ngRedux.dispatch(
        DOCUMENT_ACTIONS.loadReferenceFrameExternalOptionsAction(
          this.config.information.single,
          this.config.relation.filterUrls,
          this.config.relation.label
        )
      );
    }
  }

  $onDestroy() {
    this.unsubscribe();
  }

  preLoadedOptions() {
    return this.$parent.ctrl.preLoadedOptions;
  }

  filterPreLoadedOptions(options, input) {
    return options.filter(option => {
      const field = option.title || option.name || '';
      return field.toLowerCase().indexOf(input.toLowerCase()) !== -1;
    });
  }

  async submit() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.validateExternalRelationAction(
      this.selected.$$meta.permalink,
      this.selected.title,
      this.config.information.single,
      this.config.isUniqueInDienstverleningKov
    ));
  }

  cancel() {
    this.modalInstance.close();
  }

  selected2String(resource) {
    if (resource) {
      // ugly and only way to get the ctrl in this fn called from resource picker
      const ctrl = this.$parent.$parent.$parent.$parent.ctrl
        ? this.$parent.$parent.$parent.$parent.ctrl
        : this.$parent.$parent.$parent.$parent.$parent.$parent.ctrl;

      const label = ' ' + (resource.label || ctrl.config.relation.label || '');

      if (resource.name) {
        return resource.name + label;
      }

      if (resource.title) {
        return resource.title + label;
      }
    }
    return '<NONE>';
  }
}

export default {
  template: require('./selectExternalRelationModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<'
  },
  controller: selectExternalRelationModal
};
