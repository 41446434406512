const isInconsistentPositions = (node, inheritedPositions) => {
  if (inheritedPositions && node.positions) {
    if (new Set(node.positions).size > new Set(inheritedPositions).size) {
      return true;
    }

    const missingChildPositions = !node.positions.every((child) => {
      return inheritedPositions.includes(child);
    });

    return missingChildPositions;
  }
  return false;
};

const getInheritedPositions = (node) => {
  function getOwnOrInheritedPositions(n) {
    if (n.positions) {
      return n.positions;
    }
    return n.$$parent ? getOwnOrInheritedPositions(n.$$parent) : undefined;
  }
  return node.$$parent ? getOwnOrInheritedPositions(node.$$parent) : undefined;
};

export const positionsInconsistent = (n) => {
  return !isInconsistentPositions(n, getInheritedPositions(n)) ? true : {
    type: 'WARNING',
    message: 'components.positions.error.inconsistent'
  };
};
