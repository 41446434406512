module.exports = [() => {
  return {
    restrict: 'A',
    scope: false,
    link: (scope, element, attrs) => {
      let events = scope.$eval(attrs.onEventLegacy);

      if (Array.isArray(events)) {
        if (!Array.isArray(events[0])) {
          events = [events];
        }

        events.forEach((event) => {
          let eventName = event[0];
          let eventHandler = event[1];
          let enablePropagation = event[2];

          if (typeof eventHandler === 'string') {
            eventHandler = (e, data) => {
              scope[eventHandler] = data;
            };
          }

          scope.$on(eventName, (e, arg) => {
            if (!enablePropagation) { e.stopPropagation(); }

            if (typeof eventHandler === 'function') { eventHandler(scope, e, arg); } else { console.error('eventHandler is not a function for event: ' + eventName); }
          });
        });
      } else {
        console.error('You are not using onEventLegacy correctly.');
      }
    }
  };
}];
