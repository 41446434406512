/**
 * The index of the application (where it all starts) for webpack
 */

import {
  personsApi,
  contentApi,
  websitesApi,
  privateStateApi,
  cachedSamenscholingApi,
  searchApi,
  newsletterApi
} from '../reduxLoop/api/apiConfig';

import { interceptFactory } from '@kathondvla/fetch-oauth-interceptor';

// BOOTSTRAP (kind of ugly, because it expects jQuery)
window.$ = window.jQuery = require('jquery'); //eslint-disable-line
require('jquery-ui/ui/widgets/draggable');
require('jquery-ui/ui/widgets/droppable');

const settings = require('../config/settings.js').settings;

require('angular');

const LogRocket = require('logrocket');

export const app = require('./app.js').default;

app.constant('settings', settings);
app.constant('constants', require('../reduxLoop/constants/constants.js'));
app.constant('types', require('../config/types').types);

app.constant('sriClient', contentApi);
app.constant('contentApi', contentApi);
app.constant('websitesApi', websitesApi);
app.constant('privateStateApi', privateStateApi);
app.constant('cachedSamenscholingApi', cachedSamenscholingApi);
app.constant('personsApi', personsApi);
app.constant('searchApi', searchApi);
app.constant('newsletterApi', newsletterApi);

require('../reactComponents/bridges');

interceptFactory({
  urlPatterns: [
    new RegExp(settings.apisAndUrls.namedSetsApi.replace(/\//g, '\\/').replace(/\./g, '\\.'), 'g'),
    new RegExp(settings.apisAndUrls.securityApi.replace(/\//g, '\\/').replace(/\./g, '\\.'), 'g'),
    new RegExp(settings.apisAndUrls.cachedVakkenApi.replace(/\//g, '\\/').replace(/\./g, '\\.'), 'g'),
    new RegExp(settings.apisAndUrls.personsApi.replace(/\//g, '\\/').replace(/\./g, '\\.'), 'g'),
    new RegExp(settings.apisAndUrls.contentApi.replace(/\//g, '\\/').replace(/\./g, '\\.'), 'g'),
    new RegExp(settings.apisAndUrls.trainingApi.replace(/\//g, '\\/').replace(/\./g, '\\.'), 'g'),
    new RegExp(settings.apisAndUrls.websitesApi.replace(/\//g, '\\/').replace(/\./g, '\\.'), 'g'),
    new RegExp(settings.apisAndUrls.privateStateApi.replace(/\//g, '\\/').replace(/\./g, '\\.'), 'g'),
    new RegExp(settings.apisAndUrls.cachedSamenscholingApi.replace(/\//g, '\\/').replace(/\./g, '\\.'), 'g'),
    new RegExp(settings.apisAndUrls.proposalApi.replace(/\//g, '\\/').replace(/\./g, '\\.'), 'g'),
    new RegExp(settings.apisAndUrls.mailerApi.replace(/\//g, '\\/').replace(/\./g, '\\.'), 'g'),
    new RegExp(settings.apisAndUrls.newsletterApi.replace(/\//g, '\\/').replace(/\./g, '\\.'), 'g')
  ],
  oauth: settings.oauth,
  onLoggedOut: () => {
    console.log('USER IS LOGGED OUT!');
  }
});

app.config(require('./config').default);

app.config(['$sceDelegateProvider', ($sceDelegateProvider) => {
  $sceDelegateProvider.resourceUrlWhitelist([
    'self',
    'data:**',
    'https://*.katholiekonderwijs.vlaanderen/**',
    'https://vsko-content-api-*.herokuapp.com/content/**'
  ]);
}]);

app.constant('LogRocket', LogRocket);

app.run(['amMoment', 'moment', (amMoment, moment) => {
  moment.updateLocale('nl', {
    relativeTime: {
      future: 'in %s',
      past: '%s geleden',
      s: 'een paar seconden',
      ss: '%d seconden',
      m: 'een minuten',
      mm: '%d minuten',
      h: 'een uur',
      hh: '%d uur',
      d: 'een dag',
      dd: '%d dagen',
      M: 'een maanden',
      MM: '%d maanden',
      y: 'een jaar',
      yy: '%d jaar'
    }
  });
  amMoment.changeLocale('nl', {});
}]);
