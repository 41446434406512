export const waitForTimeoutNotificationCmd = async (notification) => {
  function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  if (!notification.removeAfter && notification.removeAfter !== 0) {
    notification.removeAfter = 5;
  }
  if (notification.removeAfter === 0) {
    // notification is not to be removed;
    // throw new Error('e');
  } else {
    await timeout((notification.removeAfter * 1000));
    return notification.key;
  }
};
