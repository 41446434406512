import { getNow } from '@kathondvla/sri-client/date-utils';

require('./zillGoals.scss');

class ZillGoals {
  constructor($scope, ModalWindowService, ZillSelectorService, ConfirmationService, ProWebsiteConfigurationsService, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.modalWindowService = ModalWindowService;
    this.zillSelectorService = ZillSelectorService;
    this.confirmationService = ConfirmationService;
    this.configurationsService = ProWebsiteConfigurationsService;
    this.$ngRedux = $ngRedux;
  }

  $onChanges(values) {
    if (values.sConfigurations && values.sConfigurations.currentValue) {
      this.sConfigurations = values.sConfigurations.currentValue;
    }
  }

  async delete(goalRelationKeyToBeDeleted, withoutConfirmation) {
    let confirmed;
    if (!withoutConfirmation) {
      confirmed = await this.confirmationService.confirmDeletion();
    }
    if (withoutConfirmation || confirmed) {
      this.$scope.$emit('zillGoalDeleted', goalRelationKeyToBeDeleted);
    }
  }

  async openSelector() {
    const zillCurriculum = await this.modalWindowService.open({
      component: 'documentSelectorModal',
      dataForModal: {
        type: 'zillCurriculum',
        stateField: 'zillCurriculums',
        params: {
          type: 'CURRICULUM_ZILL',
          issuedBefore: getNow(),
          orderBy: 'issued',
          descending: true
        }
      }
    });

    try {
      // after selecting a zill version, we will open the zill selector where you can adapt the
      // selected goals of that version. We do not touch the goals of different zill versions.
      const currentGoalsRelatedToSelectedVersion = this.sGoalReferenceRelations
        .filter(ref => !ref.deleteProposal && ref.to.$$expanded.$$treeAsLeaf.some(elem => elem.$$meta.permalink === zillCurriculum.$$meta.permalink));
      const currentGoalsRelatedToSelectedVersionHrefs = currentGoalsRelatedToSelectedVersion.map(ref => ref.to.href);

      const selectedGoals = Array.from(new Set(await this.zillSelectorService
        .openZillSelector(zillCurriculum.$$meta.permalink, currentGoalsRelatedToSelectedVersionHrefs, 'Pro thema')));

      // the selected goals are the goals that were confirmed after making a selection in the zill selector
      const deletedGoals = currentGoalsRelatedToSelectedVersion
        .filter(r => !selectedGoals.includes(r.to.href));
      deletedGoals.forEach(async r => {
        await this.delete(r.key, true);
      });

      this.$scope.$emit('zillGoalsSelected', selectedGoals.filter(goalHref => !currentGoalsRelatedToSelectedVersionHrefs.some(href => href === goalHref)));
    } catch (error) {
      if (error === 'ZILL_SELECTOR_ABORTED') {
        console.log('The user closed the zill selector manually.');
      } else {
        console.error('An unexpected error occured when communicating with the Zill selector', error);
      }
    }
  }

  proposalLineStyle(row) {
    if (row.proposal) {
      const line = row.proposal.isSubmitted ? 'submitted' : 'not-submitted';
      const color = row.proposal.isSameUser ? 'same-user' : 'different-user';
      const isDeleted = row.proposal.isDeleted ? ' is-deleted' : '';
      const isReviewing = row.proposal.isReviewingMode ? ' is-reviewing' : '';
      return line + ' ' + color + isDeleted + isReviewing;
    }
    return '';
  }
}

export default {
  template: require('./zillGoals.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfigurations: '<',
    sGoalReferenceRelations: '<',
    sDisabled: '<'
  },
  controller: ZillGoals
};
