import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class rowSummary {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
  }

  $onChanges() {
    this.$scope.$broadcast('$$rebind::change');
  }

  saveInline(scope, event, editorData) {
    const patch = {
      [editorData.field]: editorData.value
    };
    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(editorData.key, patch)
    );
  }
}

export const rowSummaryComponent = {
  template: require('./rowSummary.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<'
  },
  controller: rowSummary
};
