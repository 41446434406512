import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { sanitizeHTML } from '../../../reduxLoop/helpers/documentHelpers';
import { replacePathSpecialCharacters } from '../../../reduxLoop/helpers/webConfigHelpers';
import constants from '../../../reduxLoop/constants/constants';
// this is "smart" component

class Configurations {
  constructor(ProWebsiteConfigurationsService, ConfirmationService, $notification, $translate, ModalWindowService, $ngRedux, $scope) {
    'ngInject';

    this.configurationsService = ProWebsiteConfigurationsService;
    this.confirmationService = ConfirmationService;
    this.notificationService = $notification;
    this.translationService = $translate;
    this.modalWindowService = ModalWindowService;
    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
    this.loading = true;
  }

  getDefaultPath(nodeWebconfigurations, inheritedWebconfigurations) {
    let path = '';
    if (nodeWebconfigurations.length === 0 && inheritedWebconfigurations.length > 0) {
      return inheritedWebconfigurations[0].path + `/${replacePathSpecialCharacters(sanitizeHTML(this.title, 'clearAll').toLowerCase())}`;
    }
    return path;
  }

  async getConfigurationData(configuration) {
    let config = {
      isNew: true,
      path: this.getDefaultPath(this.configurations, this.inheritedConfigurations),
      source: {
        href: '/content/' + this.sDocumentKey
      },
      externalReferences: [
        '/content/' + this.rootDocument.key
      ]
    };

    if (configuration) {
      config = angular.copy(configuration);
    }

    return {
      templates: this.templates,
      configuration: config,
      websites: this.websites,
      isRootIssued: this.isRootIssued,
      isRootHomepage: this.isRootHomepage,
      showBanner: this.showBanner,
    };
  }

  setModifiedConfiguration(configuration) {
    let index = 0;
    while (index < this.configurations.length && this.configurations[index].key !== configuration.key) {
      index += 1;
    }
    this.configurations.splice(index, 1, configuration);
  }

  async loadConfigurations() {
    try {
      return await this.configurationsService.getConfigurations(this.sDocumentKey);
    } catch (e) {
      this.notificationService.error(this.translationService.instant('components.proWebsite.configurations.error.load'));
    }
  }


  async deleteConfiguration(key) {
    let confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      try {
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeWebsiteConfigurationAction(this.sDocumentKey, key));

        // this.notificationService.success(this.translationService.instant('components.proWebsite.configurations.success.deletion'));
      } catch (e) {
        this.notificationService.error(this.translationService.instant('components.proWebsite.configurations.error.delete'));
      }
    }
  }

  async editConfiguration(configuration) {
    const opts = {
      component: 'proWebsiteConfigurationModalForm',
      dataForModal: this.getConfigurationData(configuration)
    };

    const response = await this.modalWindowService.open(opts);

    try {
      const newConfiguration = this.configurationsService.clearConfiguration(response);
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.updateWebsiteConfigurationAction(newConfiguration));
    } catch (e) {
      console.error(e);
      this.notificationService.error(this.translationService.instant('components.proWebsite.configurations.error.create'));
    }
  }

  async addConfiguration() {
    const opts = {
      component: 'proWebsiteConfigurationModalForm',
      dataForModal: this.getConfigurationData()
    };

    const response = await this.modalWindowService.open(opts);

    try {
      const newConfiguration = this.configurationsService.clearConfiguration(response);
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.updateWebsiteConfigurationAction(newConfiguration));
    } catch (e) {
      this.notificationService.error(this.translationService.instant('components.proWebsite.configurations.error.create'));
    }
  }


  async $onInit() {
    // Very IMPORTANT to keep the configurations NOT LINKED to the service variable and keep the component isolated
    // Described at: https://toddmotto.com/angular-1-5-lifecycle-hooks

    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        configurations: state.document.viewModel.aside.editDocument.websitesConfiguration,
        inheritedConfigurations: state.document.viewModel.aside.editDocument.inheritedWebConfigurations,
        websites: state.document.viewModel.aside.webpages.allSites,
        loading: state.document.viewModel.loadingWebsitesConfiguration,
        templates: state.document.viewModel.aside.webpages.templates,
        rootDocument: state.document.viewModel.aside.rootDocument,
        title: state.document.viewModel.aside.editDocument.title ? state.document.viewModel.aside.editDocument.title.value : undefined,
        isRootIssued: state.document.viewModel.aside.rootDocument.issued !== undefined,
        isRootHomepage: state.document.viewModel.aside.editDocument.inheritedWebConfigurations
          .some(wc => constants.menuItemWebconfigurations.includes(wc.type)),
        showBanner: state.document.viewModel.aside.editDocument.$$type === 'WEBPAGE2',
      };
    })(this);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.initWebsiteConfigurationAction(this.sDocumentKey));
  }

  $onDestroy() {
    this.unsubscribe();
  }

  proposalLineStyle(row) {
    if (row.proposal) {
      const line = row.proposal.isSubmitted ? 'submitted' : 'not-submitted';
      const color = row.proposal.isSameUser ? 'same-user' : 'different-user';
      const isDeleted = row.proposal.isDeleted ? ' is-deleted' : '';
      const isReviewing = row.proposal.isReviewingMode ? ' is-reviewing' : '';
      return line + ' ' + color + isDeleted + isReviewing;
    }
    return '';
  }

  hasDeleteProposal(configuration) {
    return configuration.deleteProposal;
  }
}

export default {
  template: require('./configurations.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocumentKey: '@',
    sDisabled: '<'
  },
  controller: Configurations
};
