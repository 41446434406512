import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';
// this is "smart" component

class MetadataContainer {
  constructor($notification, $translate, $ngRedux, $scope) {
    'ngInject';

    this.configurations = [];
    this.notificationService = $notification;
    this.translationService = $translate;
    this.loading = true;
    this.showContainer = false;
    this.showZillGoals = false;
    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      let asideNodeVM = state.document.viewModel.aside.editDocument;

      return {
        configurations: asideNodeVM.facetSourceWebConfigs,
        loading: false,
        showContainer:
          state.document.viewModel.aside.webpages.facets.showAgeRange ||
          state.document.viewModel.aside.webpages.facets.showReferenceFrame,
        showZillGoals: state.document.viewModel.aside.webpages.facets.showZillGoals,
        referenceFrameValues: asideNodeVM.websitesReferenceFramesMap,
        zillGoalReferenceRelations: asideNodeVM.zillGoalReferenceRelations,
        isReadOnly: state.document.viewModel.aside.isReadOnly,
      };
    })(this);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.initWebFacets(this.sConfig));

    this.sAgeRangeValues = {
      startage: this.sDocument.startage,
      endage: this.sDocument.endage,
    };
  }

  $onDestroy() {
    this.unsubscribe();
  }

  updateAgeRange(scope, event, ageRange) {
    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(scope.ctrl.sDocument.key, ageRange)
    );
  }

  updateReferenceFrameSelection(scope, event, data) {
    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.updateFacetReferenceFramesAction(scope.ctrl.sDocument.key, data)
    );
  }

  updateSelectedGoals(scope, event, zillGoals) {
    console.log('UPDATE zill goals:', zillGoals);

    zillGoals.forEach(goalHref => {
      // save relation to goal item
      let newRelation = {
        relationtype: 'REFERENCES',
        strength: 'MEDIUM',
        from: {
          href: '/content/' + scope.ctrl.sDocument.key
        },
        to: {
          href: goalHref
        }
      };

      scope.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.addRelationAction(newRelation));
    });
  }

  deleteZillGoal(scope, event, zillGoalRelationKey) {
    console.log('DELETE zill goal:', zillGoalRelationKey);
    scope.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeRelationAction(zillGoalRelationKey));
  }
}

export default {
  template: require('./metadataContainer.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<'
  },
  controller: MetadataContainer
};
