// Access right are defined from broad to narrow
export const accessRights = [{
  translationKey: 'components.accessRights.dropdown.public',
  key: 'publiek',
  namedsets: [
    '/namedsets/1ab3becd-1c84-5ada-c3d7-457e7033d612'
  ]
}, {
  translationKey: 'components.accessRights.dropdown.sensitiveInformation',
  key: 'gevoelige_informatie',
  namedsets: [
    '/namedsets/2bb3becd-1c84-5ada-c3d7-457e7033d612'
  ]
}, {
  translationKey: 'components.accessRights.dropdown.reportsBoardsCommittees',
  key: 'verslagen_directiecommissies',
  namedsets: [
    '/namedsets/4db3becd-1c84-5ada-c3d7-457e7033d612'
  ]
},
{
  translationKey: 'components.accessRights.dropdown.informationDirectors',
  key: 'informatie_voor_bestuurders',
  namedsets: [
    '/namedsets/4ff3a4f1-c252-4604-aa31-7cda279a8431'
  ]
}
];

export const isInformatieVoorBestuurdersAccessRights = (currentAccessRights = []) => {
  // here we check if the permission that applies to our current node is of
  // type "Informatie voor bestuurders"

  const informatieVoorBestuurdersAccessRight = accessRights.find(x => x.key === 'informatie_voor_bestuurders');

  const arrIntersection = currentAccessRights
    .filter(x => informatieVoorBestuurdersAccessRight.namedsets.includes(x));

  return arrIntersection.length > 0;
};
