const { descriptionNotEmpty } = require('../../../validations/descriptionNotEmpty');
const { prefixNotEmpty } = require('../../../validations/prefixNotEmpty');

module.exports = {
  information: {
    single: 'Leerplandoel',
    plural: 'Leerplandoelen',
  },
  node: {
    type: 'LLINKID_GOAL',
  },
  createDefaults: {
    llinkidGoalType: 'REGULAR',
  },
  indentation: 3,
  edit: [
    {
      component: 'description',
      options: {
        showDemarcationButton: true,
        showMarkerButton: true
      },
      validations: [
        descriptionNotEmpty
      ],
    },
    {
      component: 'identifier',
      label: 'edit.prefix',
      validations: [
        prefixNotEmpty,
      ],
    },
    {
      component: 'goalReplaces',
      type: 'llinkidReplaces',
      label: 'components.llinkid.goalReplaces.title',
      options: {
        relationTypes: ['REPLACES'],
        onlyFoundationals: false,
        modal: 'llinkidGoalRelationsModal',
        modalTitle: 'components.llinkid.goalReplaces.modalTitle',
      }
    },
    {
      component: 'goalType',
      type: 'llinkidGoalType',
      label: 'components.llinkid.goalType.title',
    },
    {
      component: 'attitudinal',
      type: 'checkbox'
    },
    'demarcations',
    'goalIdentifier',
    {
      component: 'goalRelations',
      type: 'llinkidRelations',
      label: 'components.llinkid.goalRelations.title',
      options: {
        relationTypes: ['RELATION'],
        onlyFoundationals: false,
        modal: 'llinkidGoalRelationsModal',
        listColumn: 'description'
      }
    },
    {
      component: 'goalOdetEndTermReferences',
      type: 'llinkidRelations',
      label: 'components.llinkid.goalOdetEndTermReferences.title',
      options: {
        relationTypes: ['REFERENCES'],
        toType: 'CURRICULUM_ODET_DEVELOPMENT_GOAL',
        modal: 'llinkidGoalOdetEndTermReferencesModal',
        listColumn: 'title'
      }
    },
    {
      component: 'llinkidFoundationalSuggestions',
      label: 'components.llinkid.goalSuggestions.title',
      options: {
        relationTypes: ['SUGGESTED_EDUCATIONAL_ACTIVITY'],
        onlyFoundationals: false,
        listColumn: 'description'
      },
      whitelist: [
        {
          root: {
            foundational: true
          }
        }
      ]
    },
    {
      component: 'goalImplements',
      type: 'llinkidRelations',
      label: 'components.llinkid.goalImplements.title',
      foundationalLabel: 'components.llinkid.goalImplements.foundationalTitle',
      options: {
        relationTypes: ['IMPLEMENTS', 'PARTIALLY_IMPLEMENTS'],
        onlyFoundationals: false,
        selectType: true,
        modal: 'llinkidGoalRelationsModal',
        listColumn: 'description',
        revertedRelationDirection: true
      },
      whitelist: [
        {
          root: {
            foundational: true
          }
        }
      ]
    },
    {
      component: 'goalImplements',
      type: 'llinkidRelations',
      label: 'components.llinkid.goalImplements.title',
      foundationalLabel: 'components.llinkid.goalImplements.foundationalTitle',
      options: {
        relationTypes: ['IMPLEMENTS', 'PARTIALLY_IMPLEMENTS'],
        selectType: true,
        readOnly: true,
        listColumn: 'description'
      },
      whitelist: [
        {
          root: {
            type: 'LLINKID_CURRICULUM',
            foundational: false
          }
        }
      ]
    },
    {
      component: 'educationalPointers',
      type: 'llinkidReferences',
      label: 'Wegwijzers',
      options: {
        referenceFrame: 'EDUCATIONAL_POINTERS'
      }
    }
  ],
  buildingBlocks: [
    'LLINKID_GOAL_DEMARCATION',
    'LLINKID_PEDAGOGICAL_TIP',
    'LLINKID_EXTRA_GOAL_INFORMATION',
    'LLINKID_GOAL_EXPLANATION',
    'LLINKID_GOAL',
    'LLINKID_INITIAL_SITUATION'
  ],
  documentViewIdentifier: '(p)i.i',
  customRender: true,
};
