const { themeReferencesWithRootsCmd } = require('../../../reduxLoop/commands/documentCommands');

module.exports = {
  information: {
    single: 'Studierichting',
    plural: 'Studierichtingen',
  },
  node: {
    $$meta: {
      type: 'COMMONS_STUDY_PROGRAMME'
    }
  },
  indentation: 3,
  buildingBlocks: [
  ],
  edit: [
    'title'
  ],
  isSearchable: false,
  editInModal: false,
  isExternal: true,
  relation: {
    placeholder: 'Studierichting...',
    filterUrl: '/sam/commons/studyprogrammes?type=/sam/commons/studyprogrammes/types/4d74444a-1c32-47cd-b26b-d186e97981bb'
  },
  deleteValidations: [
    (node) => {
      return {
        cmd: themeReferencesWithRootsCmd,
        params: [node.$$meta.permalink],
        message: 'validation.themeReferenced'
      };
    }
  ],
  confirmDelete: true,
  isUniqueInDienstverleningKov: true,
  themesDisplaySuffix: '(studierichting)',
};
