import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideSubjects {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.document.viewModel.aside.editDocument,
        rawSubjects: state.document.viewModel.aside.editDocument.subjects
          ? state.document.viewModel.aside.editDocument.subjects.value : [],
        subjectOptions: state.document.viewModel.aside.subjectOptions,
        selectedSubjects: state.document.viewModel.aside.editDocument.selectedSubjects,
        subjectsLoading: state.document.viewModel.aside.subjectOptions.length === 0,
        editKey: state.document.viewModel.aside.editDocument.key
      };
    })(this);

    if (!this.sConfig.options || !this.sConfig.options.rawLabel) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.loadSubjectsAction());
    }
  }

  $onDestroy() {
    this.unsubscribe();
  }

  rawSubjectChanged() {
    const subject = this.rawSubject ? this.rawSubject.split(',').map(i => i.trim()) : undefined;
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(this.editKey, { subject }));
  }

  getRawSubjects(search) {
    let options = [].slice();
    if (search && options.indexOf(search) === -1) {
      options.unshift(search);
    }
    return options;
  }

  async onSelect(item) {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(
      this.editKey, { subjects: this.rawSubjects }
    ));
  }

  onRemove(item) {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(
      this.editKey, { subjects: this.rawSubjects }
    ));
  }

  change(e, items) {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(
      this.editKey, { subjects: items.map(i => i.$$meta.permalink) }
    ));
  }

  displayName(item) {
    return (item.title || item.name) + ' ' + (item.label || '');
  }

  filterPreLoadedOptions(options, input) {
    return options.filter(option => {
      const field = option.title || option.name || '';
      return field.toLowerCase().indexOf(input.toLowerCase()) !== -1;
    });
  }
}

export default {
  template: require('./asideSubjects.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<'
  },
  controller: asideSubjects
};
