const { themeReferencesWithRootsCmd } = require('../../../reduxLoop/commands/documentCommands');

module.exports = {
  information: {
    single: 'Basisoptie',
    plural: 'Basisopties'
  },
  node: {
    type: 'BASIC_OPTION'
  },
  indentation: 3,
  buildingBlocks: [
  ],
  edit: [
    'title'
  ],
  isSearchable: false,
  editInModal: false,
  isExternal: true,
  relation: {
    placeholder: 'Basisoptie...',
    filterUrls: [{
      path: '/sam/commons/studyprogrammes?type=/sam/commons/studyprogrammes/types/78ff839b-598b-4e80-ba69-2c6b7d087cbb'
    }, {
      path: '/sam/commons/studyprogrammegroups?type=/sam/commons/studyprogrammegroups/types/c75a5d42-0dd9-4781-a11a-73f525f470fd'
    }],
    preLoad: true
  },
  deleteValidations: [
    (node) => {
      return {
        cmd: themeReferencesWithRootsCmd,
        params: [node.$$meta.permalink],
        message: 'validation.themeReferenced'
      };
    }
  ],
  confirmDelete: true,
  isUniqueInDienstverleningKov: true,
  themesDisplaySuffix: '(basisoptie)',
};
