module.exports = ['$scope', '$uibModalInstance', 'settings', 'constants', 'Utils', 'data', ($scope, $uibModalInstance, settings, constants, Utils, data) => {
  var key;

  $scope.document = data.document;
  $scope.rootDocument = data.rootDocument;

  if (!data.footNote) {
    key = Utils.getUUID();
    $scope.footNote = {
      isNew: true,
      $$meta: { permalink: settings.resourcesNames.content + '/' + key },
      key: key,
      type: constants.footNoteType,
      parentKey: $scope.document.key,
      importance: 'MEDIUM',
      created: (new Date()).toISOString(),
      modified: (new Date()).toISOString(),
      attachments: []
    };
  } else {
    $scope.footNote = data.footNote;
    $scope.footNote.originalTitle = data.footNote.title;
  }

  $scope.submit = () => {
    if (data.onSave) {
      data.onSave($scope.footNote);
    }
    $uibModalInstance.close();
  };

  $scope.cancel = () => {
    $uibModalInstance.dismiss('cancel');
    $scope.footNote.title = $scope.footNote.originalTitle;
  };
}];
