class Replaces {
  constructor() {
    'ngInject';
  }
}

export default {
  template: require('./replaces.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<',
  },
  controller: Replaces,
};
