module.exports = {
  information: {
    single: 'Afbakening',
    plural: 'Afbakening'
  },
  node: {
    type: 'LLINKID_GOAL_DEMARCATION'
  },
  indentation: 4,
  buildingBlocks: [
    'LLINKID_GOAL_CONTENT',
    'SECTION',
    'PARAGRAPH',
    'IMAGE',
    'IMAGE_GROUP',
    'REFERENCE_GROUP',
    'EXAMPLE',
    'ATTACHMENTS_GROUP',
    'MUSIC',
    'VIDEO',
    'VIDEO_GROUP',
    'QUOTE'
  ],
  edit: [
    'importance'
  ],
  editInModal: true,
  createInModal: false,
  customRender: true
};
