import { titleNotEmpty } from '../../../validations/titleNotEmpty';

export default {
  information: {
    single: 'Leerplanthema',
    plural: "Leerplanthema's",
  },
  node: {
    type: 'CURRICULUM_THEME',
  },
  indentation: 3,
  buildingBlocks: ['CURRICULUM_THEME', 'THEME'],
  edit: [
    {
      component: 'title',
      validations: [titleNotEmpty],
    },
  ],
  ribbon: {
    openInLine: 'title',
  },
  confirmDelete: true,
};
