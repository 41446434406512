import { countCharacters } from '../reduxLoop/helpers/documentHelpers';

export const maxCharacters = (node) => {
  const isInvalid = node.$$typeConfig.edit.find(e => e.maxAmountOfCharacters && countCharacters(node[e.component]) >= e.maxAmountOfCharacters);
  if (isInvalid) {
    return {
      type: 'ERROR',
      message: 'edit.characterCountError'
    };
  }

  return true;
};
