/* eslint-disable func-names */
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';
import { getGoalPathText } from '../../../../reduxLoop/helpers/documentHelpers';
import { getNow } from '@kathondvla/sri-client/date-utils';

class asideGoalsByZillIllustrations {
  constructor(ZillSelectorService, ModalWindowService, ConfirmationService, $scope, $ngRedux) {
    'ngInject';

    this.modalWindowService = ModalWindowService;
    this.confirmationService = ConfirmationService;
    this.zillSelectorService = ZillSelectorService;
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      let relations = state.document.apiWithPendingChanges.contentRelations.from['/content/' + this.sDocument.key]
        ? state.document.apiWithPendingChanges.contentRelations.from['/content/' + this.sDocument.key]
          .filter(rel => rel.relationtype === 'REFERENCES' && rel.strength !== 'MEDIUM')
        : [];

      let groupedReferencesByRootMap;
      if (relations) {
        groupedReferencesByRootMap = this.groupAndFormatZillIllustrationRelations(relations, state);
      }

      return {
        groupedReferencesByRoot: groupedReferencesByRootMap
          ? [...groupedReferencesByRootMap.values()]
          : [],
        groupedReferencesByRootMap: groupedReferencesByRootMap,
        loading: state.document.loadingPracticalExampleZillIllustrations
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  async openSelector() {
    const zillCurriculum = await this.modalWindowService.open({
      component: 'documentSelectorModal',
      dataForModal: {
        type: 'zillCurriculum',
        stateField: 'zillCurriculums',
        params: {
          type: 'CURRICULUM_ZILL',
          issuedBefore: getNow(),
          orderBy: 'issued',
          descending: true
        }
      }
    });

    if (zillCurriculum) {
      let root = this.groupedReferencesByRootMap.get(zillCurriculum.$$meta.permalink);

      try {
        // after selecting a zill version, we will open the zill selector where you can adapt the
        // selected goals of that version. We do not touch the goals of different zill versions.
        const currentGoalsRelatedToSelectedVersionHrefs = root ? root.goalRelations.map(rel => rel.goal.$$meta.permalink) : [];

        const selectedGoals = await this.zillSelectorService
          .openZillSelector(zillCurriculum.$$meta.permalink, currentGoalsRelatedToSelectedVersionHrefs, 'illustratie');

        const deletedGoalRelations = root ? root.goalRelations
          .filter(rel => !selectedGoals.includes(rel.goal.$$meta.permalink)) : [];

        deletedGoalRelations.forEach(relation => {
          this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeRelationAction(relation.key));
        });

        const newSelections = root
          ? selectedGoals.filter(goalHref => !currentGoalsRelatedToSelectedVersionHrefs.some(current => current === goalHref))
          : selectedGoals;

        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.expandZillGoalSelectionsAction(
          newSelections
        ));

        newSelections.forEach(async selection => {
          this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addRelationAction({
            relationtype: 'REFERENCES',
            from: {
              href: '/content/' + this.sDocument.key
            },
            to: {
              href: selection
            },
            strength: 'LOW'
          }));
        });
      } catch (error) {
        if (error === 'ZILL_SELECTOR_ABORTED') {
          console.log('The user closed the zill selector manually.');
        } else {
          console.error('An unexpected error occured when communicating with the Zill selector', error);
        }
      }
    }
  }

  groupAndFormatZillIllustrationRelations(goalRelations) {
    let groupedByRoot = new Map();

    console.log('GOALS relations', goalRelations);

    goalRelations.forEach(relationToGoal => {
      if (relationToGoal.to.$$expanded) {
        if (relationToGoal.to && relationToGoal.to.$$expanded && relationToGoal.to.$$expanded.$$treeAsLeaf) {
          let goalRoot = relationToGoal.to.$$expanded.$$treeAsLeaf.find(node => node.type === 'CURRICULUM_ZILL');

          let root = groupedByRoot.get(goalRoot.$$meta.permalink);
          if (!root) {
            root = { root: goalRoot, goalRelations: [] };
          }

          root.goalRelations.push({
            text: getGoalPathText(relationToGoal.to.$$expanded.$$treeAsLeaf),
            goal: relationToGoal.to.$$expanded,
            key: relationToGoal.key
          });

          groupedByRoot.set(goalRoot.$$meta.permalink, root);
        }
      }
    });

    return groupedByRoot;
  }

  showDeleteIllustration(row) {
    if (!row.selection) {
      return true;
    }
    return !row.header;
  }

  async deleteIllustration(row) {
    // delete illustration and relation to document
    let confirmed = await this.confirmationService.confirmDeletion();
    if (confirmed) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeRelationAction(row.key));
    }
  }
}

export default {
  template: require('./asideGoalsByZillIllustrations.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<'
  },
  controller: asideGoalsByZillIllustrations
};
