class GoalType {
  constructor() {
    'ngInject';
  }
}

export default {
  template: require('./goalType.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<'
  },
  controller: GoalType,
};
