
module.exports = ['$rootScope', 'RelationsUtils', 'Utils', 'settings', 'contentApi', 'constants',
  function ($rootScope, RelationsUtils, Utils, settings, contentApi, constants) {
    let service = {
      data: {}
    };


    service.addFootnote = async (footnote, rootDocumentKey, batch) => {
      if (batch) {
        batch.put('/content/' + footnote.key, footnote);

        const relation = RelationsUtils.createRelation(footnote.key, rootDocumentKey, 'IS_PART_OF');
        batch.put('/content/relations/' + relation.key, relation);

        if (footnote.data) {
          footnote.data.relationHref = '/content/relations/' + relation.key;
        } else {
          footnote.relationHref = '/content/relations/' + relation.key;
        }
      }
    };

    service.deleteFootnote = async (footnote, batch) => {
      if (batch) {
        batch.delete(footnote.data ? footnote.data.relationHref : footnote.relationHref);
        batch.delete('/content/' + (footnote.data ? footnote.data.key : footnote.key));
      }
    };

    return service;
  }];
