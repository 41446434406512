import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as ACTION_TYPES from '../../reduxLoop/constants/actionTypes';
import { areAllResultsSelected } from './newDocumentListHelpers';
import { SriContentList } from '../../types/apiTypes';
import { DocumentListState, SearchParams } from './newDocumentListTypes';

/**
 * This is the initial state of this reducer.
 */
const initialState: DocumentListState = {
  loading: false,
  loadingMore: false,
  results: [],
  selected: {}, // all the selected results
  searchParams: {},
  publishModalOpen: false, // state of the publish modal
  createContentModalOpen: false, // state of the create content modal
  confirmationModalOpen: false,
  canBeImported: false, // new content type can be imported, depends on the selected type
  importDocx: {}, // data for the imported docx file for new content
  uploadAttachment: null, // file which should be uploaded as attachment
  newContent: { authors: { selected: [] } },
  apiPending: [], // array of batch opereation pending to be sent to the api
  viewModel: {},
  proposalsToReviewCount: {},
};

const newDocumentListState = createSlice({
  name: 'newDocumentListState',
  initialState,
  reducers: {
    setSearchResults: (state, action: PayloadAction<{ pageResults: SriContentList }>) => {
      const { pageResults } = action.payload;
      state.loading = false;
      state.loadingMore = false;
      state.results = [...state.results, ...pageResults];
      state.nextLink = pageResults.next;
    },
    setProposalsToReview: (
      state,
      action: PayloadAction<{ proposalsToReview: { contentHref: string; count: number }[] }>
    ) => {
      const { proposalsToReview } = action.payload;
      const proposalsToReviewCount = proposalsToReview.reduce((acc, curr) => {
        acc[curr.contentHref] = curr.count;
        return acc;
      }, {});
      state.proposalsToReviewCount = proposalsToReviewCount;
    },
    loadMore: (state, action: PayloadAction<{ href: string }>) => {
      state.loadingMore = true;
    },
    sortByColumn: (state, action: PayloadAction<{ column: string }>) => {
      const { column } = action.payload;
      const { columnSort } = state.searchParams;
      const isAscending = columnSort?.name === column ? !columnSort.isAscending : true;
      state.searchParams.columnSort = { name: column, isAscending };
      state.loading = true;
      state.results = [];
    },
    toggleSelected: (state, action: PayloadAction<{ href: string }>) => {
      const { href } = action.payload;
      if (state.selected[href]) {
        delete state.selected[href];
      } else {
        state.selected[href] = true;
      }
    },
    toggleSelectAll: (state) => {
      if (areAllResultsSelected(state.results, state.selected)) {
        state.selected = {};
      } else {
        state.results.forEach((result) => {
          state.selected[result.$$meta.permalink] = true;
        });
      }
    },
    setFilterProperty: (state, action: PayloadAction<SearchParams>) => {
      state.searchParams = { ...state.searchParams, ...action.payload };
      state.loading = true;
      state.results = [];
    },
  },
  extraReducers: {
    [ACTION_TYPES.DOCUMENT_LIST_SEARCH]: (state, action) => {
      state.loading = true;
      state.searchParams = action.payload;
      state.results = [];
      state.selected = {};
    },
    [ACTION_TYPES.DOCUMENT_LIST_RESULTS_DELETED]: (state, action) => {
      state.results = state.results.filter(
        (result) => state.selected[result.$$meta.permalink] !== true
      );
      state.selected = {};
    },
    // [ACTION_TYPES.DOCUMENT_LIST_LOAD_MORE]: (state, action) => {
    //   debugger;
    //   state.loadingMore = true;
    // },
  },
});

export const {
  setSearchResults,
  setProposalsToReview,
  loadMore,
  sortByColumn,
  toggleSelected,
  toggleSelectAll,
  setFilterProperty,
} = newDocumentListState.actions;
export default newDocumentListState;
