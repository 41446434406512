import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class selectMarkExplanationModal {
  constructor($ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    const stateField = 'markExplanationOptions';
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        markExplanations: state.document[stateField] || [],
        loadingMarkExplanations: !state.document[stateField] || !state.document[stateField].length
      };
    })(this);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.getAllOfTypeAction(
      {
        type: 'MARK_EXPLANATION',
        orderBy: 'title',
        limit: 100
      },
      { stateField }
    ));
  }

  $onDestroy() {
    this.unsubscribe();
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  submit() {
    if (this.markExplanation) {
      this.modalInstance.close(this.markExplanation);
    }
  }
}

export default {
  template: require('./selectMarkExplanationModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<'
  },
  controller: selectMarkExplanationModal
};
