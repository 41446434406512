import { titleNotEmpty } from '../../../validations/titleNotEmpty';

export default {
  information: {
    single: 'Leerplancluster',
    plural: 'Leerplanclusters',
  },
  node: {
    type: 'CURRICULUM_CLUSTER',
  },
  indentation: 3,
  buildingBlocks: ['CURRICULUM_THEME'],
  edit: [
    {
      component: 'title',
      validations: [titleNotEmpty],
    },
  ],
  ribbon: {
    openInLine: 'title',
  },
  confirmDelete: true,
  themesDisplaySuffix: '(cluster)',
};
