module.exports = {
  information: {
    single: 'Doelenlijst',
    plural: 'Doelenlijsten',
  },
  node: {
    type: 'LLINKID_GOAL_LIST',
  },
  indentation: 2,
  buildingBlocks: [
    {
      type: 'LLINKID_STATIC_PART',
      max: 1,
    },
    'LLINKID_GOAL_SECTION',
    'LLINKID_GOAL',
    {
      type: 'LLINKID_CONCORDANCE',
      max: 1,
      position: 1,
    },
  ],
  showPlaceholder: true,
  editInModal: false,
  createInModal: false,
  isDraggable: false,
};
