import rowImage from './rowImage';
import { rowLlinkidGoalComponent } from './rowLlinkidGoal';
import { rowLlinkidDemarcationComponent } from './rowLlinkidDemarcation';
import { inlineTermComponent } from './inlineTerm';
import { referenceLinkComponent } from './referenceLink';
import { rowEducationalReferencesComponent } from './rowEducationalReferences';
import { rowLlinkidPedagogicalTipComponent } from './rowLlinkidPedagogicalTip';
import { rowLlinkidExtraComponent } from './rowLlinkidExtra';
import { rowLlinkidExplanationComponent } from './rowLlinkidExplanation';
import { rowLlinkidInitialSituationComponent } from './rowLlinkidInitialSituation';
import { rowVideoGroupComponent } from './rowVideoGroup';
import { rowImageGroupComponent } from './rowImageGroup';
import { rowAttachmentComponent } from './rowAttachment';
import { rowAttachmentGroupComponent } from './rowAttachmentGroup';
import { rowMusicComponent } from './rowMusic';
import { rowQuoteComponent } from './rowQuote';
import { rowLegalComponent } from './rowLegal';
import { rowVideoComponent } from './rowVideo';
import { rowSummaryComponent } from './rowSummary';
import { rowCurriculumReferenceComponent } from './rowCurriculumReference';
import { rowLeerlijnReferenceComponent } from './rowLeerlijnReference';
import rowLeerlijnCluster from './rowLeerlijnCluster';
import { rowExternalResourceComponent } from './rowExternalResource';
import { inlineDemarcationComponent } from './inlineDemarcation';
import { inlineFootnoteComponent } from './inlineFootnote';
import { proposalIndicatorComponent } from './proposalIndicator';
import { proposalDiffComponent } from './proposalDiff';
import { hasChangesIndicatorComponent } from './hasChangesIndicator';
import renderStartEndAge from './renderStartEndAge';
import { rowTeaserComponent } from './rowTeaser';
import { rowNewsItemComponent } from './rowNewsItem';
import { rowUnstructuredDocument } from './rowUnstructuredDocument';
import { inlineMarkExplanationComponent } from './inlineMarkExplanation';
import { rowLlinkidConcordanceComponent } from './rowLlinkidConcordance';

export default angular.module('redactieRowComponents', [])
  .component('inlineTerm', inlineTermComponent)
  .component('inlineDemarcation', inlineDemarcationComponent)
  .component('inlineFootnote', inlineFootnoteComponent)
  .component('inlineMarkExplanation', inlineMarkExplanationComponent)
  .component('proposalIndicator', proposalIndicatorComponent)
  .component('proposalDiff', proposalDiffComponent)
  .component('hasChangesIndicator', hasChangesIndicatorComponent)
  .component('renderStartEndAge', renderStartEndAge)
  .component('referenceLink', referenceLinkComponent)
  .component('rowImage', rowImage)
  .component('rowLlinkidGoal', rowLlinkidGoalComponent)
  .component('rowEducationalReferences', rowEducationalReferencesComponent)
  .component('rowLlinkidPedagogicalTip', rowLlinkidPedagogicalTipComponent)
  .component('rowLlinkidExtra', rowLlinkidExtraComponent)
  .component('rowLlinkidExplanation', rowLlinkidExplanationComponent)
  .component('rowLlinkidInitialSituation', rowLlinkidInitialSituationComponent)
  .component('rowLlinkidConcordance', rowLlinkidConcordanceComponent)
  .component('rowVideoGroup', rowVideoGroupComponent)
  .component('rowImageGroup', rowImageGroupComponent)
  .component('rowAttachment', rowAttachmentComponent)
  .component('rowAttachmentGroup', rowAttachmentGroupComponent)
  .component('rowMusic', rowMusicComponent)
  .component('rowVideo', rowVideoComponent)
  .component('rowSummary', rowSummaryComponent)
  .component('rowCurriculumReference', rowCurriculumReferenceComponent)
  .component('rowLeerlijnReference', rowLeerlijnReferenceComponent)
  .component('rowLeerlijnCluster', rowLeerlijnCluster)
  .component('rowExternalResource', rowExternalResourceComponent)
  .component('rowQuote', rowQuoteComponent)
  .component('rowLegal', rowLegalComponent)
  .component('rowLlinkidDemarcation', rowLlinkidDemarcationComponent)
  .component('rowUnstructuredDocument', rowUnstructuredDocument)
  .component('rowTeaser', rowTeaserComponent)
  .component('rowNewsItem', rowNewsItemComponent);
