module.exports = angular.module('RedactieUtils', []) //eslint-disable-line
  .directive('goBack', ['$window', function ($window) {
    return function ($scope, $element) {
      $element.on('click', function () {
        $window.history.back();
      });
    };
  }])

  .directive('scrollTo', [function () {
    return function (scope, element, attrs) {
      element.bind('click', function () {
        function elmYPosition(eID) {
          var elm = document.getElementById(eID);
          var y = elm.offsetTop;
          var node = elm;
          while (node.offsetParent && node.offsetParent != document.body) {
            node = node.offsetParent;
            y += node.offsetTop;
          } return y;
        }
        document.getElementById('content').scrollTo(0, elmYPosition(attrs.scrollTo) - 135);
      });
    };
  }])

  .directive('backToTop', [function () {
    return function (scope, element) {
      element.scroll(function () {
        if ($(this).scrollTop() > 100) { //eslint-disable-line
          $('.goToTop').fadeIn(); //eslint-disable-line
        } else {
          $('.goToTop').fadeOut(); //eslint-disable-line
        }
      });
    $('.goToTop').click(function () { //eslint-disable-line
        element.animate({ scrollTop: 0 }, 100);
        return false;
      });
    };
  }])

  .directive('file', [function () {
    return {
      scope: {
        file: '='
      },
      link: function (scope, el) {
        el.bind('change', function (event) {
          var file = event.target.files[0];
          scope.file = file || null;
          scope.$apply();
        });
      }
    };
  }]);
