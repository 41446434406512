
class ReferenceFramePicker {
  constructor($scope, BackendManager, contentApi) {
    'ngInject';

    this.$scope = $scope;
    this.backend = BackendManager;
    this.contentApi = contentApi;
    this.selectedThemeGroups = [];
    this.loading = true;
  }

  async $onInit() {
    try {
      this.conceptens = await this.contentApi.getAll('/content?type=THEME&root=' + this.sReferenceFrameHref, {}, { caching: { timeout: 5000 } });
      this.selectedThemeGroups = this.sThemeGroupSelected.selected;
      this.hasProposal = this.sThemeGroupSelected.hasProposal;
    } catch (e) {
      this.conceptens = [];
    }
    this.loading = false;
    this.$scope.$apply();
  }

  themeGroupChange() {
    this.$scope.$emit('referenceFramePickerSelected', {
      referenceFrameHref: this.sReferenceFrameHref,
      values: this.selectedThemeGroups.map(s => {
        return { href: s.$$meta.permalink, $$expanded: s };
      })
    });
  }
}

module.exports = {
  template: require('./referenceFramePicker.html'),
  controllerAs: 'ctrl',
  bindings: {
    sReferenceFrameHref: '@',
    sReferenceFrameTitle: '@',
    sReferenceFrameChoices: '<',
    sThemeGroupSelected: '<',
    sDisabled: '<'
  },
  controller: ReferenceFramePicker
};
