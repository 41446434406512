import { getEmbedVideoLinkFrom } from '../../../reduxLoop/helpers/documentHelpers';

class rowVideo {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
  }

  $onChanges() {
    this.attachment = this.sItem.$$attachments.has('VIDEO') ? this.sItem.$$attachments.get('VIDEO').original : null;
  }

  videoSrc() {
    if (!this.attachment) {
      return false;
    }
    return getEmbedVideoLinkFrom(this.attachment.playbackUrl);
  }
}

export const rowVideoComponent = {
  template: require('./rowVideo.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<'
  },
  controller: rowVideo
};
