import { mainstructuresOuTypeCombinationsInconsistent } from '../../../validations/mainstructuresOuTypeCombinationsInconsistent';
import { coverageInconsistent } from '../../../validations/coverageInconsistent';
import { descriptionNotEmpty } from '../../../validations/descriptionNotEmpty';
import { shortDescriptionNotEmpty } from '../../../validations/shortDescriptionNotEmpty';
import { menuItemWithReference } from '../../../deleteConfirmations/menuItemWithReference';
import { settings } from '../../settings';
import { currentUserAsCreators } from '../../../services/currentUserAsCreators';
import constants from '../../../reduxLoop/constants/constants';
import { mainstructuresOuTypeCombinationsNotEmpty } from '../../../validations/mainstructuresOuTypeCombinationsNotEmpty';
import { coverageNotEmpty } from '../../../validations/coverageNotEmpty';
import { urlUniqueWithinDocument } from '../../../validations/urlUniqueWithinDocument';
import { positionsInconsistent } from '../../../validations/positionsInconsistent';
import { positionsNotEmpty } from '../../../validations/positionsNotEmpty';
import { pathIsNotReserved } from '../../../validations/pathIsNotReserved';
import { pathIsPermitted } from '../../../validations/pathIsPermitted';
import { oldUrlIsPermitted } from '../../../validations/oldUrlIsPermitted';
import { websiteUniqueWithinNode } from '../../../validations/websiteUniqueWithinNode';
import { accessRightsInconsistent } from '../../../validations/accessRightsInconsistent';
import { isMenuItemReferenceExternalLinkValid } from '../../../validations/menuReferenceExternalLink';
import { documentTags } from '../../../reduxLoop/constants/documentTags';
import { urlUniqueApiWithinApi } from '../../../validations/urlUniqueWithinApi';
import * as apiRoutes from '../../../reduxLoop/api/apiRoutes';
const { isGlobalDocumentAllowed } = require('../../../validations/isGlobalDocumentAllowed');
import { webConfigurationRequired } from '../../../validations/webConfigurationRequired';
import { mainAndSubCurriculumThemesConsistent } from '../../../validations/mainAndSubCurriculumThemesConsistent';
import { descriptionEmpty } from '../../../validations/descriptionEmpty';

const GLOBAL_TRAININGSDATABASE = 'GLOBAL_TRAININGSDATABASE';

export default {
  information: {
    single: 'Titel',
    plural: 'Titels',
    ribonIcon: require('../../../../img/buildingBlocks/SECTION.svg')
  },
  node: {
    type: 'SECTION'
  },
  // conditional fields to parent webconfiguration
  nodeConditional: [
    {
      field: 'creators',
      value: currentUserAsCreators,
      condition: {
        parent: ['BLOG']
      }
    },
    {
      field: 'webconfiguration',
      value: {
        type: 'BLOG_ITEM',
        website: {
          href: `${apiRoutes.websites}/${settings.websites.pro}`
        },
        template: {
          href: '/web/templates/35213fa8-b26b-7ace-92ad-180e5cd0a37c'
        }
      },
      condition: {
        parent: ['BLOG']
      }
    },
    {
      field: 'webconfiguration',
      value: {
        type: 'MINI_DATABASE_ITEM',
        website: {
          href: `${apiRoutes.websites}/${settings.websites.pro}`
        },
        template: {
          href: '/web/templates/21363fa8-c06b-4ace-92ad-180e5cd0c41a'
        }
      },
      condition: {
        parent: ['MINI_DATABASE']
      }
    },
    {
      field: 'webconfiguration',
      value: {
        type: 'THEME_PAGE',
        website: {
          href: `${apiRoutes.websites}/${settings.websites.pro}`
        },
        template: {
          href: '/web/templates/3e09d09a-11b6-4bfe-a20d-62223633b09b'
        }
      },
      condition: {
        parent: ['THEME_HOME_FULL', 'THEME_HOME_PICTURE', 'THEME_HOME_TEXT']
      }
    }
  ],
  buildingBlocks: [
    {
      type: 'SECTION',
      blacklist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } },
      ],
    },
    'PARAGRAPH',
    'IMAGE',
    {
      type: 'ATTACHMENTS_GROUP',
      blacklist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } },
      ],
    },
    {
      type: 'IMAGE_GROUP',
      blacklist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } },
      ],
    },
    {
      type: 'MUSIC',
      blacklist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } },
        { root: { $$type: 'WWW' } },
        { root: { $$type: 'SHARED_MINI_DATABASE_ITEM' } }
      ]
    },
    {
      type: 'VIDEO',
      blacklist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } }
      ]
    },
    {
      type: 'VIDEO_GROUP',
      blacklist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } }
      ]
    },
    {
      type: 'QUOTE',
      blacklist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } }
      ]
    },
    {
      type: 'WORD_IMPORT',
      whitelist: [
        {
          self: {
            hasNoChildren: true
          }
        }
      ],
      blacklist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } }
      ],
      options: {
        openImportModal: true
      }
    },
    {
      type: 'EXAMPLE',
      whitelist: [
        { root: { $$type: 'VISION_TEXT' } }
      ]
    },
    {
      type: 'EXAMPLE',
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'WWW' } }
      ]
    },
    {
      type: 'REFERENCE_GROUP',
      whitelist: [
        { root: { $$type: 'VISION_TEXT' } },
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } }
      ]
    },
    {
      type: 'REFERENCE_GROUP',
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'WWW' } }
      ]
    },
    {
      type: 'FAQ_GROUP',
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'WWW' } }
      ]
    },
    {
      type: 'SUMMARY',
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'WWW' } }
      ]
    },
    {
      type: 'LEGAL',
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'WWW' } }
      ]
    },
    {
      type: 'TEASER',
      whitelist: [
        { root: { $$type: 'PRONEWSLETTER' } }
      ]
    }
  ],
  edit: [
    {
      component: 'identifier',
      width: '2',
      label: 'edit.prefix',
      view: {
        hideIfEmpty: true,
        dd: 'te'
      },
      blacklist: [
        {
          webconfiguration: {
            inherited: ['MINI_DATABASE_ITEM', GLOBAL_TRAININGSDATABASE]
          }
        }
      ],
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'WWW' } }
      ]
    },
    {
      component: 'title',
      width: '10',
      blacklist: [
        { root: { $$type: 'PRONEWSLETTER' } },
        { root: { $$type: 'LLINKID_CURRICULUM' } }
      ]
    },
    {
      component: 'title',
      width: '10',
      whitelist: [
        { root: { $$type: 'PRONEWSLETTER' } }
      ],
      options: {
        hideTermButton: true
      }
    },
    {
      component: 'title',
      width: '10',
      whitelist: [
        { root: { $$type: 'LLINKID_CURRICULUM' } }
      ],
      options: {
        showMarkerButton: true
      }
    },
    {
      component: 'description',
      width: '12',
      label: 'Tegeltekst',
      view: { hideIfEmpty: true },
      maxAmountOfCharacters: 60,
      validations: [shortDescriptionNotEmpty],
      whitelist: [
        {
          root: { $$type: 'WEBPAGE2' },
          webconfiguration: { parent: ['THEME_HOME_FULL', 'THEME_HOME_TEXT'] },
        },
      ],
      options: { type: 'shortdescription' },
    },
    {
      component: 'description',
      width: '12',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
      view: { hideIfEmpty: true },
      validations: [descriptionNotEmpty],
      whitelist: [
        {
          root: { $$type: 'WEBPAGE2' },
          webconfiguration: { self: ['THEME_PAGE', 'MINI_DATABASE_ITEM', 'TEMPORARY_PAGE'] },
        },
      ],
    },
    {
      component: 'description',
      width: '12',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
      view: { hideIfEmpty: true },
      validations: [descriptionEmpty],
      whitelist: [
        {
          root: { $$type: 'WEBPAGE2' },
          webconfiguration: { self: ['DOWNLOAD_PAGE', 'FAQ_PAGE'] },
        },
      ],
    },
    {
      component: 'description',
      width: '12',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
      view: { hideIfEmpty: true },
      whitelist: [
        {
          root: { $$type: 'WEBPAGE2' },
          webconfiguration: {
            parent: ['THEME_HOME_TEXT', 'THEME_HOME_PICTURE', 'THEME_HOME_FULL'],
          },
        },
      ],
      blacklist: [
        {
          root: { $$type: 'WEBPAGE2' },
          webconfiguration: {
            self: [
              'THEME_PAGE',
              'MINI_DATABASE_ITEM',
              'TEMPORARY_PAGE',
              'DOWNLOAD_PAGE',
              'FAQ_PAGE',
            ],
          },
        },
      ],
    },
    {
      component: 'description',
      width: '12',
      label: 'Inleiding',
      view: { hideIfEmpty: true },
      whitelist: [{ root: { $$type: 'WWW' } }],
    },
    {
      component: 'importance',
      width: '6',
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } }
      ],
      blacklist: [
        {
          webconfiguration: {
            inherited: ['HOME_PAGE', 'FALLBACK_MENU_LEVEL_2', 'MINI_DATABASE_ITEM', GLOBAL_TRAININGSDATABASE]
          }
        }
      ]
    },
    {
      component: 'authors',
      width: '6',
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'WWW' } }
      ],
      blacklist: [
        {
          webconfiguration: {
            inherited: ['HOME_PAGE', 'FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3', 'MINI_DATABASE_ITEM', GLOBAL_TRAININGSDATABASE]
          }
        }
      ]
    },
    {
      component: 'contacts',
      width: '12',
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'WWW' } }
      ],
      blacklist: [
        {
          webconfiguration: {
            inherited: ['HOME_PAGE', 'FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3', 'MINI_DATABASE_ITEM', GLOBAL_TRAININGSDATABASE]
          }
        }
      ]
    },
    {
      component: 'image',
      width: '6',
      label: 'Omslagfoto',
      whitelist: [
        { root: { $$type: 'WWW' } }
      ],
      options: {
        type: 'COVER_IMAGE',
        crop: {
          type: 'rectangle'
        },
        fields: []
      }
    },
    {
      component: 'image',
      width: '6',
      label: 'Tegelfoto',
      whitelist: [
        {
          root: { $$type: 'WEBPAGE2' },
          webconfiguration: {
            parent: ['THEME_HOME_FULL', 'THEME_HOME_PICTURE']
          }
        },
        {
          root: { $$type: 'WEBPAGE2' },
          webconfiguration: {
            self: ['BLOG_ITEM']
          }
        },
        { root: { $$type: 'WWW' } }
      ],
      options: {
        type: 'THUMBNAIL',
        crop: true,
        fields: [
          'alt'
        ]
      }
    },
    {
      component: 'image',
      width: '12',
      label: 'Afbeelding',
      whitelist: [
        {
          root: { $$type: 'WEBPAGE2' },
          webconfiguration: {
            self: ['MINI_DATABASE_ITEM']
          }
        },
      ],
      options: {
        type: 'THUMBNAIL',
        crop: true,
        fields: [
          'alt'
        ]
      }
    },
    {
      component: 'authors',
      width: '12',
      label: 'Auteur(s)',
      whitelist: [
        {
          root: { $$type: 'WEBPAGE2' },
          webconfiguration: {
            self: ['MINI_DATABASE_ITEM']
          }
        }
      ],
    },
    {
      component: 'linkGroup',
      width: '12',
      label: 'Links',
      options: {
        type: 'group'
      },
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'WWW' } }
      ],
      blacklist: [
        {
          webconfiguration: {
            inherited: ['HOME_PAGE', 'FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3', GLOBAL_TRAININGSDATABASE]
          }
        }
      ]
    },
    {
      component: 'themes',
      options: {
        referenceFrameKey: constants.dienstverleningKovKey,
        types: [
          'THEME',
          'BASIC_OPTION',
          'DOMAIN',
          'COMMONS_STUDIEGEBIED',
          'COMMONS_STUDY_PROGRAMME',
          'COMMONS_SUBJECT',
          'CURRICULUM_THEME',
          'CURRICULUM_CLUSTER',
        ],
        highlightOldCurrItems: true,
      },
      validations: [mainAndSubCurriculumThemesConsistent],
      whitelist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } },
      ],
    },
    {
      component: 'curriculumSelector',
      options: {
        // do not send main curriculum themes
        filter: (theme) => {
          return (
            theme.type !== 'CURRICULUM_THEME' ||
            !theme.$$children.some((c) => c.type === 'CURRICULUM_THEME')
          );
        },
      },
      whitelist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } },
      ],
    },
    {
      component: 'attachmentsGroup',
      options: { global: true },
      whitelist: [{ root: { $$type: 'WEBPAGE2' } }],
      validations: [isGlobalDocumentAllowed],
      blacklist: [
        { webconfiguration: { self: ['FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3'] } },
      ],
    },
    {
      component: 'issued',
      whitelist: [
        {
          webconfiguration: { self: ['FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3'] },
        },
        {
          webconfiguration: {
            inherited: [
              'HOME_PAGE',
              'FALLBACK_MENU_LEVEL_2',
              'FALLBACK_MENU_LEVEL_3',
              GLOBAL_TRAININGSDATABASE,
            ],
          },
        },
      ],
    },
    {
      component: 'color',
      whitelist: [{ webconfiguration: { self: ['FALLBACK_MENU_LEVEL_2'] } }],
    },
    {
      component: 'image',
      label: 'edit.ICON',
      width: '6',
      options: { type: 'ICON', fields: [] },
      whitelist: [{ webconfiguration: { self: ['FALLBACK_MENU_LEVEL_2'] } }],
    },
    {
      component: 'subjects',
      options: {
        rawLabel: true
      },
      whitelist: [
        {
          webconfiguration: {
            self: [
              'FALLBACK_MENU_LEVEL_2',
              'FALLBACK_MENU_LEVEL_3',
              'BLOG',
              'MINI_DATABASE',
              'THEME_PAGE',
              'THEME_HOME_FULL',
              'THEME_HOME_PICTURE',
              'THEME_HOME_TEXT',
            ],
          },
        },
      ],
    },
    {
      component: 'themes',
      whitelist: [
        { webconfiguration: { self: ['FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3'] } },
      ],
    },
    {
      component: 'themes',
      label: 'Databanktype',
      options: {
        referenceFrameKey: constants.contentTypesKey,
        types: ['THEME'],
      },
      whitelist: [
        {
          webconfiguration: {
            self: ['MINI_DATABASE'],
          },
        },
      ],
    },
    {
      component: 'menuItemReference',
      options: {
        filter: {
          typeIn: 'STRUCTURED_DOCUMENT',
          rootTag: [documentTags.webPage2],
          orderBy: '$$meta.modified',
          descending: true
        }
      },
      whitelist: [
        {
          webconfiguration: {
            inherited: ['HOME_PAGE', 'FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3']
          }
        }
      ],
      validations: [isMenuItemReferenceExternalLinkValid],
    },
    {
      component: 'menuItemReference',
      options: {
        filter: {
          typeIn: 'STRUCTURED_DOCUMENT,SECTION',
          rootTag: [documentTags.webPage2],
          $$expandPathToRoot: true,
          orderBy: '$$meta.modified',
          descending: true
        }
      },
      whitelist: [{ root: { $$type: 'WEBPAGE2' } }],
      blacklist: [
        {
          webconfiguration: {
            inherited: ['HOME_PAGE', 'FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3', GLOBAL_TRAININGSDATABASE]
          }
        }
      ],
      validations: [isMenuItemReferenceExternalLinkValid],
    },
    {
      component: 'menuItemReference',
      options: {
        filter: {
          typeIn: 'STRUCTURED_DOCUMENT,SECTION',
          rootTag: [documentTags.webPage2],
          $$expandPathToRoot: true,
          orderBy: '$$meta.modified',
          descending: true
        },
        externalLinkHidden: true
      },
      whitelist: [{ webconfiguration: { inherited: [GLOBAL_TRAININGSDATABASE] } }],
    },
    {
      component: 'webFacets',
      width: '12',
      options: { source: 'parent' },
      whitelist: [{ root: { $$type: 'WEBPAGE2' } }],
      blacklist: [
        {
          webconfiguration: {
            inherited: ['HOME_PAGE', 'FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3', GLOBAL_TRAININGSDATABASE]
          }
        }
      ]
    },
    {
      component: 'namedSet',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
      validations: [
        mainstructuresOuTypeCombinationsNotEmpty,
        mainstructuresOuTypeCombinationsInconsistent
      ],
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } }
      ]
    },
    {
      component: 'coverage',
      tab: constants.asideTabs.AUDIENCE,
      validations: [
        coverageNotEmpty,
        coverageInconsistent
      ],
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } }
      ]
    },
    {
      component: 'namedSet',
      validations: [
        positionsNotEmpty,
        positionsInconsistent
      ],
      label: 'components.functions.title',
      tag: 'doelgroepen',
      property: 'positions',
      whitelist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } }
      ],
      tab: constants.asideTabs.AUDIENCE
    },
    {
      component: 'accessRights',
      label: 'components.accessRights.descendantsAccessRights',
      options: {
        field: 'descendantsAccessRights'
      },
      validations: [
        accessRightsInconsistent
      ],
      tab: constants.asideTabs.AUDIENCE,
      whitelist: [
        { webconfiguration: { parent: ['THEME_HOME_FULL', 'THEME_HOME_TEXT', 'THEME_HOME_PICTURE'] } }
      ]
    }
  ],
  allowSibling: true,
  allowImporting: true,
  documentViewIdentifier: 'p.i',
  isSearchable: false,
  websiteEdition: [
    {
      domain: settings.domains.pro
    },
    {
      domain: settings.domains.www
    }
  ],
  websiteValidations: [
    pathIsNotReserved,
    oldUrlIsPermitted,
    pathIsPermitted,
    websiteUniqueWithinNode,
    urlUniqueApiWithinApi,
    urlUniqueWithinDocument,
    webConfigurationRequired
  ],
  ribbon: {
    openInLine: 'title',
    customCondition: [
      { type: 'WORD_IMPORT', childrenCount: 0 }
    ]
  },
  globalAttachmentsGroupContainer: true,
  hideChildrenInDocument: [{
    type: 'ATTACHMENTS_GROUP',
    tags: ['GLOBAL']
  }],
  confirmDeleteMessage: menuItemWithReference
};
