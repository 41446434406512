import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class asideLeerlijnReference {
  constructor(Utils, $scope, $ngRedux) {
    'ngInject';

    this.Utils = Utils;
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const goals = state.document.viewModel.aside.goals;

      let relation = state.document.apiWithPendingChanges.contentRelations.from['/content/' + this.sDocument.key]
        ? state.document.apiWithPendingChanges.contentRelations.from['/content/' + this.sDocument.key].find(rel => rel.relationtype === 'REFERENCES')
        : null;

      let selectedGoal;
      if (relation) {
        selectedGoal = goals.find(goal => goal.$$meta.permalink === relation.to.href);
      }

      return {
        selectedGoal: selectedGoal,
        relation: relation,
        documentGoals: goals
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  changedGoal() {
    if (!this.selectedGoal) {
      if (this.relation) {
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeRelationAction(this.relation.key));
      }
    } else if (this.relation) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchRelationAction(
        this.relation.key,
        {
          to: { href: this.selectedGoal.$$meta.permalink }
        }
      ));
    } else {
      let newRelation = {
        relationtype: 'REFERENCES',
        strength: 'MEDIUM',
        from: {
          href: '/content/' + this.sDocument.key
        },
        to: {
          href: this.selectedGoal.$$meta.permalink
        }
      };

      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addRelationAction(
        newRelation
      ));
    }
  }

  getColor(item) {
    return {
      color: item.$$color
    };
  }
}

export default {
  template: require('./asideLeerlijnReference.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<'
  },
  controller: asideLeerlijnReference
};
