import { getAnnotations } from './helpers/documentHelpers';
import { getUiType, getTypeConfig } from '../services/types';

const nodeExtensions = {
  //
  html: (node) => {
    // get html from content type attachment
    let attachment = node.attachments ? node.attachments.find(a => a.type === 'CONTENT') : null;
    return attachment && attachment.text ? attachment.text : node.$$html;
  },
  $$annotations: (node) => {
    return getAnnotations(node);
  },
  $$type: (node) => {
    return getUiType(node);
  },
  $$typeConfig: (node) => {
    return getTypeConfig(node.$$type) || {};
  }
};

/**
 * Creates a tree from all nodes of a document.
 * @param {string} rootKey - Key of the document that you want the tree of.
 * @param {array} nodes - Node of the document
 * @param {object} relationsTo - Relations to the document
 * @param {object} state - The current state
 * @returns {object}
 */
export const createDocumentTree = (rootKey, nodes, relationsTo) => {
  function fillNodeData(node, level, rootNode, index) {
    // Set the level of the node in the tree
    node.$$level = level;
    node.$$index = index;

    // rootNode is empty for the root
    node.$$isRoot = !rootNode;
    node.$$root = rootNode || node;

    Object.keys(nodeExtensions).forEach((extension) => {
      node[extension] = nodeExtensions[extension](node);
    });

    // Add the children to the node
    if (relationsTo['/content/' + node.key]) {
      node.$$children = relationsTo['/content/' + node.key]
        .filter(o => o.from.href && ['IS_PART_OF', 'IS_INCLUDED_IN'].includes(o.relationtype) && nodes.get(o.from.href))
        .sort((a, b) => a.readorder - b.readorder)
        .map((o, i) => {
          const childDocument = nodes.get(o.from.href);
          if (childDocument) {
            childDocument.$$readOrder = o.readorder;
            childDocument.$$relation = o;
            childDocument.$$parent = node;
            o.parentKey = node.key;

            // needs a copy for the flat vm (different $$relation and $$parent in case of is_included_in nodes)
            const childCopy = { ...fillNodeData(childDocument, level + 1, node.$$root, i) };
            childCopy.$$readOrder = o.readorder;
            childCopy.$$relation = o;
            childCopy.$$allRelations = childDocument.$$allRelations ? childDocument.$$allRelations.concat(o) : [o];
            childCopy.$$parent = node;
            return childCopy;
          }
          return childDocument;
        });
    } else {
      node.$$children = [];
    }

    return node;
  }

  const root = nodes.get('/content/' + rootKey);
  if (root) {
    const tree = fillNodeData(root, 1);
    return tree;
  }
  return {};
};
