import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class asideAgeRange {
  constructor(Utils, $scope, $ngRedux) {
    'ngInject';

    this.Utils = Utils;
    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.validMonthNumbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
  }

  $onInit() {
    this.dateOption = this.getDateOptions();
  }

  checkStartAgeMonth() {
    let input = Number(this.dateOption.startageMonth);
    if (!this.validMonthNumbers.includes(input)) {
      this.dateOption.startageMonth = 0;
    } else {
      this.dateOption.startageMonth = this.Utils.removeLeftZeros(this.dateOption.startageMonth);
      this.updateDateOption();
    }
  }

  checkEndAgeMonth() {
    let input = Number(this.dateOption.endageMonth);

    if (!this.validMonthNumbers.includes(input)) {
      this.dateOption.endageMonth = 0;
    } else {
      this.dateOption.endageMonth = this.Utils.removeLeftZeros(this.dateOption.endageMonth);
      this.updateDateOption();
    }
  }

  checkStartAgeYear() {
    let isPositiveInteger = this.Utils.isValidAgeRange(this.dateOption.startageYear);

    if (!isPositiveInteger) {
      this.dateOption.startageYear = 0;
    } else {
      this.dateOption.startageYear = this.Utils.removeLeftZeros(this.dateOption.startageYear);
      this.updateDateOption();
    }
  }

  checkEndAgeYear() {
    let isPositiveInteger = this.Utils.isValidAgeRange(this.dateOption.endageYear);

    if (!isPositiveInteger) {
      this.dateOption.endageYear = 0;
    } else {
      this.dateOption.endageYear = this.Utils.removeLeftZeros(this.dateOption.endageYear);
      this.updateDateOption();
    }
  }

  updateDateOption() {
    let startage;
    let endage;
    if (Number(this.dateOption.startageMonth) === 0) {
      startage = Number(this.dateOption.startageYear);
    } else {
      let totalStartageMonth = Number(this.dateOption.startageYear) * 12
        + Number(this.dateOption.startageMonth);
      startage = this.Utils.truncator(totalStartageMonth / 12, 3);
    }

    if (Number(this.dateOption.endageMonth) === 0) {
      endage = Number(this.dateOption.endageYear);
    } else {
      let totalEndMonth = Number(this.dateOption.endageYear) * 12
        + Number(this.dateOption.endageMonth);
      endage = this.Utils.truncator(totalEndMonth / 12, 3);
    }

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(
      this.sDocument.key,
      {
        startage,
        endage
      }
    ));
  }

  getDateOptions() {
    let dateOption = {
      startageYear: 0,
      startageMonth: 0,
      endageYear: 0,
      endageMonth: 0
    };

    if (this.sDocument.startage) {
      let date = this.Utils.getYearsAndMonthFrom(this.sDocument.startage.value);
      dateOption.startageYear = date.year;
      dateOption.startageMonth = date.month;
    }

    if (this.sDocument.endage) {
      let date = this.Utils.getYearsAndMonthFrom(this.sDocument.endage.value);
      dateOption.endageYear = date.year;
      dateOption.endageMonth = date.month;
    }

    return dateOption;
  }
}

export default {
  template: require('./asideAgeRange.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<'
  },
  controller: asideAgeRange
};
