/* eslint-disable max-len */
import * as DOCUMENT_ACTIONS from '../../reduxLoop/actions/documentActions';
import constants from '../../reduxLoop/constants/constants';
require('./asideScreen.scss');

export class asideScreenController {
  constructor($ngRedux, $scope, $timeout, $state, $compile) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$state = $state;
    this.$compile = $compile;
    this.$scope = $scope;
    this.$timeout = $timeout;

    this.editOption = 'metadata';

    this.unsubscribe = this.$ngRedux.connect((state) => {
      const editSections = state.document.viewModel.aside.editDocument.$$editSections;
      if (!this.initialEditSectionsCount && editSections) {
        // when the $$editSections count channge we need to reload the components
        this.initialEditSectionsCount = editSections.length;
      }

      return {
        ...state.document.viewModel.aside,
        showAudiencesTab: this.filterSections(editSections, constants.asideTabs.AUDIENCE).length
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  close() {
    // we need this small timeout before closing the aside to give time the ckeditor fields
    // to run the blur(), if we don't have it the blur will be ignored and
    // we'll have cases where we loose the changes
    this.$timeout(() => {
      this.$state.go('edit');
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.closeAsideAction());
    }, 600);
  }

  termAdded(scope, event, data) {
    scope.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.addTermReferenceAction(data.term));
  }

  checkUserEditingNotAllowedRootNode() {
    if (this.isUserEditingNotAllowedRootNode) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.showNotAllowedRootNodeEditionWarningAction());
    }
  }

  filterSections(sections, asideTab) {
    return (sections || []).filter(section => {
      // only edit section that corresponds to the given asideTab (if tab is not defined for a section then take metadata) (#18468)
      return (!section.tab && constants.asideTabs.METADATA === asideTab) || section.tab === asideTab;
    });
  }

  getSectionWidth(sectionConfig) {
    if (sectionConfig) {
      if (typeof sectionConfig === 'string') {
        return 'col-md-12';
      }
      if (sectionConfig.width) {
        return 'col-md-' + sectionConfig.width;
      }
    }
    return 'col-md-12';
  }

  getEditSection(component, label) {
    return this.editDocument.$$editSections
      .find(c => c.component === component && (!label || c.label === label));
  }

  reloadComponents(asideTab) {
    if (this.initialEditSectionsCount !== this.editDocument.$$editSections.length) {
      this.initialEditSectionsCount = this.editDocument.$$editSections.length;
      this.loadComponents(asideTab);
    }
  }

  loadComponents(asideTab) {
    const editSections = angular.element(document.querySelector(`#${asideTab.toLowerCase()}EditSections`));
    editSections.empty();

    this.filterSections(this.editDocument.$$editSections, asideTab)
      .forEach(section => {
        let sectionComponent = section.type ? section.type : section.component;
        sectionComponent = sectionComponent.replace(/([A-Z])/g, '-$1').toLowerCase().trim();

        const componentClass = this.getSectionWidth(section);
        const label = section.label ? `, '${section.label}'` : '';
        const sectionComponentName = `aside-${sectionComponent}`;

        const componentToCompile = `<${sectionComponentName}
            class="${componentClass}"
            s-document="::ctrl.editDocument"
            s-config="::ctrl.getEditSection('${section.component}'${label})"
          ></${sectionComponentName}>`;

        editSections.append(this.$compile(componentToCompile)(this.$scope));
      });
  }
}
