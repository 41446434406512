export const initRouter = ($stateProvider, $urlRouterProvider, $locationProvider) => {
  // Redirects

  $locationProvider.html5Mode(true);
  $urlRouterProvider.when('', '/documents');
  $urlRouterProvider.when('/', '/documents');

  // 404 if state not found
  $urlRouterProvider.otherwise(($injector) => {
    $injector.get('$state').go('notFound', null, { location: false });
  });

  // Default pages
  $stateProvider
    .state('notFound', {
      url: '/404',
      template: require('../screen/notFound/notFound.html')
    });

  // Edit

  $stateProvider
    .state('list', {
      url: '/documents',
      component: 'listScreen'
    })
    .state('edit', {
      url: '/edit/:key',
      component: 'structureScreen'
    })
    .state('edit.aside', {
      url: '/:editKey',
      template: '',
      controller: ['$scope', '$aside', ($scope, $aside) => {
        const asideInstance = $aside.open({
          template: require('../screen/asideScreen/asideScreen.html'),
          controller: 'asideScreenController as ctrl',
          windowClass: 'modal fade in',
          backdrop: 'static',
          placement: 'right',
          size: 'lg'
        });
        $scope.$on('$destroy', () => {
          console.log('destroy');
          asideInstance.close();
        });
      }]
    });
};
