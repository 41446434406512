/* eslint-disable max-len */
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { regions } from '../../../reduxLoop/helpers/dioceses';
require('./asideCoverage.scss');

class asideCoverage {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.regions = regions;
    this.option = null;
    this.disabled = false;
    this.mandatory = false;
    this.showInconsistent = false;
  }

  disableComponentIfRootHasNoCoverage() {
    if (this.sDocument.$$isRoot) {
      this.mandatory = true;
    } else if (!this.sDocument.$$root.coverage
      || !this.sDocument.$$root.coverage.length) {
      this.disabled = true;
    }
  }

  isInconsistent(childCoverage, parentDocument) {
    if (parentDocument.coverage === undefined || (parentDocument.coverage && parentDocument.coverage.length === 0 && childCoverage.length > 0)) {
      let parentDocumentOfTheParent = parentDocument.$$parent;
      // Goes up until root if necessary
      return this.isInconsistent(childCoverage, parentDocumentOfTheParent);
    }

    if (childCoverage.length > parentDocument.coverage.length) {
      return true;
    }

    // check that all children coverage are present in parentDocument.coverage
    return !childCoverage.every((child) => {
      return parentDocument.coverage.includes(child);
    });
  }

  showInconsistentMessage(coverages) {
    if (!this.sDocument.$$isRoot) {
      let parentDocument = this.sDocument.$$parent;
      this.showInconsistent = this.isInconsistent(coverages, parentDocument);
    }
  }

  isEmpty() {
    return !this.isInherited && (!this.editDocument.coverage || this.editDocument.coverage.value.length === 0);
  }

  async $onInit() {
    this.disableComponentIfRootHasNoCoverage();

    this.unsubscribe = this.$ngRedux.connect((state) => {
      const isRoot = state.document.viewModel.aside.rootDocument.key === this.sDocument.key;
      return {
        ...state.document.viewModel.aside,
        coverage: {
          ...(state.document.viewModel.aside.editDocument.coverage
          || state.document.viewModel.aside.editDocument.inheritedCoverage)
        },
        isInherited: !isRoot && !state.document.viewModel.aside.editDocument.coverage,
        isRoot,
        isTeaser: state.document.viewModel.aside.editDocument.type === 'TEASER'
      };
    })(this);

    const coverage = this.editDocument.coverage
      ? this.editDocument.coverage.value : this.editDocument.inheritedCoverage;
    if (!this.disabled && coverage) {
      this.selectedRegions = this.regions.filter((region) => {
        return coverage.includes(region.$$meta.permalink);
      });

      this.showInconsistentMessage(coverage);

      this.setOption();
    }
  }

  async $onDestroy() {
    this.unsubscribe();
  }

  backToInherited() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(this.editDocument.key, {
      coverage: undefined
    }));

    this.selectedRegions = this.regions.filter((region) => {
      return this.editDocument.inheritedCoverage.includes(region.$$meta.permalink);
    });

    this.showInconsistentMessage(this.editDocument.inheritedCoverage);

    this.setOption();
  }

  setOption() {
    if (this.selectedRegions.length === this.regions.length) {
      this.option = 'all';
    } else {
      this.option = 'specific';
    }
  }

  regionsChanged() {
    this.setOption();
    let coverages = this.selectedRegions.map(selectedRegion => selectedRegion.$$meta.permalink);
    this.showInconsistentMessage(coverages);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(this.editDocument.key, {
      coverage: this.selectedRegions.map(r => r.$$meta.permalink)
    }));
  }

  clearAll() {
    this.selectedRegions = [];
    this.regionsChanged();
  }

  selectAll() {
    this.selectedRegions = this.regions;
    this.regionsChanged();
  }
}

export default {
  template: require('./asideCoverage.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<'
  },
  controller: asideCoverage
};
