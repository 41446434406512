import { getReferenceFramesFacet } from '../../../../services/proWebsite/configurationsFacets';

class ReferenceFramePickers {
  constructor($scope, ProWebsiteConfigurationsService, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.configurationsService = ProWebsiteConfigurationsService;
    this.$ngRedux = $ngRedux;
  }

  $onChanges(values) {
    if (values.sConfigurations && values.sConfigurations.currentValue
      && values.sConfigurations.currentValue !== values.sConfigurations.previousValue) {
      this.sConfigurations = values.sConfigurations.currentValue;
      this.referenceFrames = getReferenceFramesFacet(this.sConfigurations, this.sModel.type, this.sValues);
    }
  }

  async $onInit() {
    if (this.sConfigurations) {
      this.referenceFrames = getReferenceFramesFacet(this.sConfigurations, this.sModel.type, this.sValues);
    }
  }
}

export default {
  template: require('./referenceFramePickers.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfigurations: '<',
    sModel: '<',
    sValues: '<',
    sDisabled: '<'
  },
  controller: ReferenceFramePickers
};
