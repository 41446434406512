/* eslint-disable max-len */
/* eslint-disable no-useless-catch */
import pMap from 'p-map';
import uuidv4 from 'uuid/v4';
import CircularJSON from 'circular-json';
import {
  contentApi,
  cachedVakkenApi,
  trainingApi,
  privateStateApi,
  cachedSamenscholingApi,
  proposalApi,
  mailerApi,
  personsApi,
  newsletterApi,
  namedSetsApi,
  endpoints,
  getApiFromUrl
} from '../api/apiConfig';
import { settings } from '../../config/settings';
import { config as typesConstants } from '../constants/documentTypes';
import { findExternalRelationType, getResourceKey } from '../helpers/documentHelpers';
import { sendWebsitesBatchCmd } from './websitesCommands';
import { sendNewsletterSettingsBatchCmd } from './documentListCommands';
import * as studyProgrammeGroupTypes from '../../reduxLoop/constants/studyProgrammeGroupTypes';
import * as newsletterTypes from '../../reduxLoop/constants/newsletterTypes';

const commonUtils = require('@kathondvla/sri-client/common-utils');

export const openWindowCmd = async (window, url, name) => {
  return window.open(url, name);
};

export const openPreviewCmd = async (window, postMessageService, url, suggestions) => {
  const originWindow = await openWindowCmd(window, url, 'pro');
  postMessageService.setOriginWindow(originWindow);
  console.log('REDACTIE sent', suggestions);
  postMessageService.setMessage(CircularJSON.stringify(suggestions));
  const { postMessage } = postMessageService;
  postMessageService.addListener((event) => {
    if (event.data.source === 'PRO') {
      postMessage();
    }
  });
};

const scaleImage = async (image, width, height, mimeType) => new Promise((async (resolve) => {
  const img = new Image();
  img.onload = scale;
  img.src = image;

  function scale() {
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(this, 0, 0, width, height);
    const result = canvas.toDataURL(mimeType, 0.6);
    resolve(result);
  }
}));

/**
 * Load an array of hrefs from the general api base url. They could belong to different api's
 */
export const loadHrefsCmd = async (hrefs, key) => {
  try {
    let results = [];
    if (hrefs) {
      const baseHrefs = new Set(hrefs.map(h => commonUtils.getPathFromPermalink(h)));
      const data = await Promise.all([...baseHrefs].map(href => {
        // special case: if base is /content we do batch because possibly we need to load a lot of resources (url might be too long)
        // note: this can be extended to other bases but not all (eg. /vakken, /persons don't support batch)
        const endpoint = endpoints.get(href) || {
          client: getApiFromUrl(href),
          supportsBatch: false
        };
        const batchHref = endpoint.supportsBatch
          ? `${href}/batch`
          : null;
        return endpoint.client.getAllHrefs(hrefs.filter(h => h.startsWith(href)), batchHref, {}, { caching: { timeout: 3000 } });
      }));
      results = data.reduce((accumulator, result) => {
        result = result.map(r => r.$$expanded || r);
        return [...accumulator, ...result];
      }, []);
    }
    return { results, key, hrefs };
  } catch (e) {
    throw e;
  }
};

export const loadDocumentCmd = async (key) => {
  try {
    const contents = await contentApi.getAll('/content', { root: `/content/${key}`, limit: 6000 });
    const externalResources = await loadExternalResources(contents);

    return [...contents, ...externalResources];
  } catch (e) {
    throw e;
  }
};

const loadExternalResources = async (contents) => {
  // expand those external resources that are part of the document tree aswell
  // (eg. /sam subjects, domain, etc)
  const externalResourcesHrefs = contents.reduce((list, content) => {
    const hrefs = content.$$relationsTo.reduce((extHrefs, relation) => {
      relation = relation.$$expanded;
      if (relation.from.href.startsWith('/') && !relation.from.href.startsWith('/content')) {
        extHrefs.push(relation.from.href);
      }
      return extHrefs;
    }, []);
    list = [...list, ...hrefs];
    return list;
  }, []);
  const externalResources = await loadHrefsCmd(externalResourcesHrefs);
  return externalResources.results.map((r) => {
    // fill with default fields of a content-api resource alike node
    r.$$relationsFrom = [];
    r.$$relationsTo = [];
    r.attachments = [];
    if (r.$$meta.type === 'VAK') {
      // special case for /vakken external resources
      r.$$meta.type = 'COMMONS_SUBJECT';
      r.label = '(oude structuur)';
    }
    r.type = r.type && r.type.href ? findExternalRelationType(r.type.href) : r.$$meta.type;
    return r;
  });
};

const loadReferences = async (contents) => {
  const referenceHrefs = contents.reduce((resourceHrefs, content) => {
    const hrefs = content.$$relationsTo.reduce((hrefs, relation) => {
      hrefs.push(relation.$$expanded.from.href);
      return hrefs;
    }, []);
    return [...resourceHrefs, ...hrefs];
  }, []);
  const resources = await loadHrefsCmd(referenceHrefs);
  return resources.results;
};

async function getNamedSetChildren() {
  const namedSets = await namedSetsApi.getRaw('/namedsets/query/subsets', { tags: 'mainstructure_outype' }, { caching: { timeout: 43200 } });

  const namedSetsMap = new Map();
  namedSets.results.forEach(item => {
    if (item.subsets && item.subsets.length) {
      namedSetsMap.set(item.namedset.href, item.subsets.map(s => s.href));
    }
  });

  return namedSetsMap;
}

const getInherited = (node, property) => {
  return node[property] ? node[property] : getInherited(node.$$parent, property);
};

const getMainstructuresOuTypeCombinations = (node, namedSetsMap) => {
  const mainstructuresOuTypeCombinations = getInherited(node, 'mainstructuresOuTypeCombinations');
  return mainstructuresOuTypeCombinations.reduce((mainstructuresOuTypeCombinations, mainstructuresOuTypeCombination) => {
    return [...mainstructuresOuTypeCombinations, mainstructuresOuTypeCombination, ...(namedSetsMap.get(mainstructuresOuTypeCombination) || [])];
  }, []);
};

const getBatchParams = (node, themes, mainstructuresOuTypeCombinations) => {
  let themeHrefs;

  if (node.themes && node.themes.length) {
    themeHrefs = [...node.themes.reduce((themesMap, theme) => {
      const recursiveChildren = themes.get(theme);
      if (recursiveChildren) {
        recursiveChildren.forEach(t => themesMap.add(t.href));
      } else {
        console.warn('Theme ' + theme + ' not found in referenceframe');
      }
      themesMap.add(theme); // add the theme itself too.

      return themesMap;
    }, new Set())];
  }

  const coverage = getInherited(node, 'coverage');
  const positions = getInherited(node, 'positions');

  const batchParams = {
    mainstructuresOuTypeCombinationsOverlaps: mainstructuresOuTypeCombinations ? mainstructuresOuTypeCombinations.join(',') : '',
    coverageOverlaps: coverage ? coverage.join(',') : '',
    positionsOverlaps: positions ? positions.join(',') : ''
  };

  if (themeHrefs) {
    batchParams.themesOverlaps = themeHrefs.join(',');
  } else {
    batchParams.themesIsNullOrEmpty = true;
  }

  return batchParams;
};

const sendEventsBatch = async (referenceGroups, themes, dateToSend, newsletterType) => {
  // at least one week in the future
  const startDateAfter = new Date(dateToSend);
  startDateAfter.setDate(startDateAfter.getDate() + 7);

  // no longer than 1 (general) or 3 (subject) months in the future
  const startDateBefore = new Date(dateToSend);
  const monthsInFuture = newsletterType.href === newsletterTypes.subjectSpecific.href
    ? 3
    : 1;
  startDateBefore.setMonth(startDateBefore.getMonth() + monthsInFuture);

  const params = {
    type: 'TRAINING',
    status: 'CONFIRMED',
    startDateAfter: startDateAfter.toISOString(),
    startDateBefore: startDateBefore.toISOString(),
    'issued.startDateBefore': dateToSend,
    publicAndNotFull: true,
    firstOfSeries: true,
    limit: 5000,
  };

  const namedSetsMap = await getNamedSetChildren();
  const batch = trainingApi.createBatch();
  referenceGroups.forEach((rg) => {
    const mainstructuresOuTypeCombinations = getMainstructuresOuTypeCombinations(rg, namedSetsMap);
    const batchParams = { ...params, ...getBatchParams(rg, themes, mainstructuresOuTypeCombinations) };
    // we save this into $$batchGetUrl as to know which batch result is for which section. (order is messed up)
    rg.$$batchGetUrl = commonUtils.parametersToString('/events', batchParams);
    batch.get(rg.$$batchGetUrl);
  });

  return batch.send('/events/batch');
};

export const loadEventsCmd = async (referenceGroups, themes, dateToSend, newsletterType, removedItems) => {
  try {
    const results = await sendEventsBatch(referenceGroups, themes, dateToSend, newsletterType);
    const removedItemSet = removedItems ? new Set(removedItems.map(e => e.href)) : new Set();
    return {
      referenceGroups: results.map(r => {
        return {
          ...referenceGroups.find(g => g.$$batchGetUrl === r.href),
          $$events: r.body.results
            .map((e) => e.$$expanded)
            .filter(
              (e) =>
                !removedItemSet.has(e.$$meta.permalink) &&
                (!e.issued?.endDate || e.issued?.endDate >= dateToSend)
            ),
        };
      }, [])
    };
  } catch (e) {
    throw e;
  }
};

const sendTeaserBatch = async (sections, themes, dateToSend, newsletterType) => {
  const twoMonthsBack = new Date(dateToSend);
  twoMonthsBack.setMonth(twoMonthsBack.getMonth() - 2);

  const params = {
    type: 'TEASER',
    issuedAfter: twoMonthsBack.toISOString(),
    issuedBefore: dateToSend,
    importanceIn: 'HIGH,MEDIUM',
    notIncludedInWithRootNewsletterType: newsletterType.href,
    limit: 6000
  };

  // a teaser with a theme is added to all sections with that theme
  const sectionsWithTeaser = sections.filter(s => s.themes && s.themes.length);
  // a teaser without a theme is only added to the first section without a theme
  const sectionsWithoutTheme = sections.filter(s => !s.themes || !s.themes.length);
  if (sectionsWithoutTheme.length) {
    sectionsWithTeaser.push(sectionsWithoutTheme.reduce((previous, current) => {
      return previous.$$index < current.$$index
        ? previous
        : current;
    }));
  }

  const batch = contentApi.createBatch();
  sectionsWithTeaser.forEach((section) => {
    const mainstructuresOuTypeCombinations = getInherited(section, 'mainstructuresOuTypeCombinations');
    const batchParams = { ...params, ...getBatchParams(section, themes, mainstructuresOuTypeCombinations) };
    // we save this into $$batchGetUrl as to know which batch result is for which section. (order is messed up)
    section.$$batchGetUrl = commonUtils.parametersToString('/content', batchParams);
    batch.get(section.$$batchGetUrl);
  });

  return batch.send('/content/batch');
};

export const loadTeasersCmd = async (sections, themes, dateToSend, newsletterType, removedItems) => {
  try {
    const teasersPerSection = await sendTeaserBatch(sections, themes, dateToSend, newsletterType);
    const teasersWithSection = teasersPerSection.reduce((teasers, result) => {
      const section = sections.find(s => s.$$batchGetUrl === result.href);
      delete section.$$batchGetUrl;
      return [...teasers, ...result.body.results.map(r => {
        return {
          ...r.$$expanded,
          $$section: section
        };
      })];
    }, []);

    const removedItemSet = removedItems ? new Set(removedItems.map(e => e.href)) : new Set();
    const teasers = teasersWithSection.filter(e => !removedItemSet.has(e.$$meta.permalink)); // filter out the deleted teasers from settings.
    const references = await loadReferences(teasers);

    return { teasers, references };
  } catch (e) {
    throw e;
  }
};

export const sendBatchCmd = async (batch) => {
  if (!batch || !batch.length) {
    return [];
  }

  try {
    return contentApi.put('/content/batch', batch);
  } catch (e) {
    throw e;
  }
};

export const fetchDocumentPrivateStateCmd = async (documentKey, userKey) => {
  try {
    const privateStates = await privateStateApi.getAll('/privatestates', {
      owner: `/persons/${userKey}`,
      context: settings.privateState.component,
      'state.root': `/content/${documentKey}`
    });
    return privateStates.length > 0 ? privateStates[0] : {
      key: uuidv4(),
      isNew: true,
      context: settings.privateState.component,
      state: {
        root: `/content/${documentKey}`,
        collapsedNodes: [],
        lastRead: new Date().toISOString()
      },
      owner: {
        href: `/persons/${userKey}`
      }
    };
  } catch (e) {
    throw e;
  }
};

export const putPrivateStateCmd = async (privateState) => {
  try {
    return privateStateApi.put(`/privateStates/${privateState.key}`, privateState);
  } catch (e) {
    throw e;
  }
};

export const fetchDocumentProposalsCmd = async (key) => {
  try {
    return proposalApi.getAll('/proposals', {
      statusIn: ['IN_PROGRESS,SUBMITTED_FOR_REVIEW'].join(','),
      externalReferencesContains: `/content/${key}`
    });
  } catch (e) {
    throw e;
  }
};

export const fetchProposalsCreatorsCmd = async (creatorHrefs) => {
  try {
    if (creatorHrefs && creatorHrefs.length > 0) {
      return personsApi.getAll('/persons', {
        hrefs: creatorHrefs
      });
    }
  } catch (e) {
    throw e;
  }
};

// expand those external resources that are part of the document tree aswell but only in proposals
export const fetchProposalsExternalContentCmd = async (proposals) => {
  const externalResourcesHrefs = proposals.reduce((list, proposal) => {
    const hrefs = proposal.listOfRequestedChanges.reduce((extHrefs, change) => {
      const resource = change.resource;
      if (resource && resource.relationtype === 'IS_INCLUDED_IN') {
        extHrefs.push(resource.from.href);
      }
      if (change.patch && change.patch.filter(p => p.path === '/relationtype' && p.value === 'IS_INCLUDED_IN').length > 0) {
        const fromPatch = change.patch.find(p => p.path === '/from');
        if (fromPatch) {
          extHrefs.push(fromPatch.value.href);
        }
      }
      return extHrefs;
    }, []);
    list = [...list, ...hrefs];
    return list;
  }, []);
  const externalResources = await loadHrefsCmd(externalResourcesHrefs);
  return externalResources.results.map(r => {
    // fill with default fields of a content-api resource alike node
    r.$$relationsFrom = [];
    r.$$relationsTo = [];
    r.type = !r.type || r.type.href ? r.$$meta.type : r.type;
    return r;
  });
};

/**
 * Upload file attachments as multipart
 * @param {*} config object
 * . $http (required)
 * . baseUrl (optional) default /content
 */
export const putAttachmentsCmd = async (contentHref, attachments, config = {}) => {
  try {
    attachments = [].concat(attachments);

    const body = [];
    const fd = new FormData();

    await pMap(
      attachments,
      async (attachment) => {
        if (!attachment.$$url && attachment.width && attachment.height && !attachment.skipScale) {
          attachment.$$url = await scaleImage(
            attachment.$$base64,
            attachment.width,
            attachment.height,
            attachment.$$mimeType
          );
        }
        // set $$url for proposal uploads
        if (!attachment.$$url && attachment.$$base64) {
          attachment.$$url = attachment.$$base64;
        }

        if (config.external || !attachment.file) {
          // we need to first get the attachment from an external source
          let domain = '';
          if (attachment.$$url.startsWith('/content')) {
            domain = settings.apisAndUrls.contentApi;
          } else if (attachment.$$url.startsWith('/proposals')) {
            domain = settings.apisAndUrls.proposalApi;
          }

          const url = domain + attachment.$$url;
          const resource = await config.$http.get(url, {
            responseType: 'blob',
          });
          fd.append('data', resource.data, attachment.name);

          attachment.file = { name: attachment.name };
        } else if (attachment.file) {
          fd.append('data', attachment.file, attachment.file.name);
        }

        const attachmentField = {
          key: attachment.key,
          type: attachment.type,
          name: attachment.file.name,
          width: attachment.width,
          height: attachment.height,
          resized: attachment.resized
        };

        if (attachment.description) {
          attachmentField.description = attachment.description;
        }
        if (attachment.alt) {
          attachmentField.alt = attachment.alt;
        }
        if (attachment.rightsHolder) {
          attachmentField.rightsHolder = JSON.stringify(attachment.rightsHolder);
        }

        if (attachment.size) {
          attachmentField.size = attachment.size;
        }

        body.push({
          file: attachment.file.name,
          attachment: attachmentField,
          resource: { href: contentHref },
        });
      },
      { concurrency: 5 }
    );

    fd.append('body', JSON.stringify(body));

    return fetch(`${config.baseUrl ?? `${settings.apisAndUrls.contentApi}/content`}/attachments`, {
      method: 'POST',
      body: fd,
    });
  } catch (e) {
    throw e;
  }
};

const uploadFiles = async (fileUploads, $http) => {
  const hrefs = new Set(fileUploads.map((f) => f.body.relatedTo.href));
  await pMap(
    hrefs,
    async (href) => {
      await putAttachmentsCmd(
        href,
        fileUploads.filter((f) => f.body.relatedTo.href === href).map((f) => f.body),
        { $http }
      );
    }, { concurrency: 5 }
  );
};

/**
 * Send a batch to the proposal-api
 * In case the contentBatch is filled it means it's applying a proposal that should
 * be saved in content-api
 * @param {*} batch
 * @param {*} contentBatch
 * @param {*} $http
 */
export const sendProposalsBatchCmd = async (
  batch,
  contentBatch,
  webpagesBatch,
  proposedFileUploads,
  fileUploads,
  $http
) => {
  try {
    if (contentBatch && contentBatch.length > 0) {
      await contentApi.put('/content/batch', contentBatch);
    }

    if (webpagesBatch && webpagesBatch.length > 0) {
      await sendWebsitesBatchCmd(webpagesBatch);
    }

    await proposalApi.put('/proposals/batch', batch);

    if ($http) {
      if (proposedFileUploads.length > 0) {
        // upload files to proposal-api
        await pMap(
          proposedFileUploads,
          async (u) => {
            await putAttachmentsCmd(u.proposalHref, [u.attachment], {
              $http,
              baseUrl: `${settings.apisAndUrls.proposalApi}/proposals`,
            });
          },
          { concurrency: 5 }
        );
      } else if (fileUploads.length > 0) {
        // upload files directly to content-api
        await uploadFiles(fileUploads, $http);
      }
    }

    return;
  } catch (e) {
    throw e;
  }
};

export const saveDocumentCmd = async (
  contentBatch,
  webpagesBatch,
  newsletterSettingsBatch,
  fileUploads,
  $http
) => {
  try {
    if (contentBatch && contentBatch.length > 0) {
      await sendBatchCmd(contentBatch);
    }

    if (webpagesBatch && webpagesBatch.length > 0) {
      await sendWebsitesBatchCmd(webpagesBatch);
    }

    if (newsletterSettingsBatch && newsletterSettingsBatch.length > 0) {
      await sendNewsletterSettingsBatchCmd(newsletterSettingsBatch);
    }

    await uploadFiles(fileUploads, $http);
  } catch (e) {
    throw e;
  }
};

export const loadNamedSetsCmd = async (tag) => {
  try {
    return await namedSetsApi.getAll('/namedsets', { tags: tag });
  } catch (e) {
    throw e;
  }
};

export const fetchStudyProgrammesCmd = async (field) => {
  try {
    const studyProgrammes = await cachedSamenscholingApi.getAll('/sam/commons/studyprogrammes', {}, { caching: { timeout: 5000 } });
    // add grade number to study programme
    const gradesUrl = `/sam/commons/studyprogrammegroups?type=${studyProgrammeGroupTypes.grade.href}`;
    const grades = await cachedSamenscholingApi.getAll(gradesUrl, {}, { caching: { timeout: 5000 } });
    studyProgrammes.forEach(sp => {
      const grade = grades.find(g => {
        return sp.studyProgrammeGroups.some(spg => spg.studyProgrammeGroup.href === g.$$meta.permalink);
      });
      sp.$$grade = grade.title;
    });
    return { field, results: studyProgrammes };
  } catch (e) {
    throw e;
  }
};

export const fetchLlinkidThemeReferencesCmd = async (field) => {
  try {
    const referenceFrame = typesConstants.llinkidReferenceFrames.find(r => r.field === field);
    const params = {
      root: referenceFrame.key,
      type: 'THEME',
      orderBy: 'title'
    };

    const results = await contentApi.getAll('/content', params, { caching: { timeout: 5000 } });

    return { field, results };
  } catch (e) {
    throw e;
  }
};

export const fetchEducationalActivityTypesCmd = async () => {
  try {
    return cachedSamenscholingApi.getAll('/sam/commons/educationalactivitytypes', {}, { caching: { timeout: 5000 } });
  } catch (e) {
    throw e;
  }
};

export const fetchTreeAsLeafCmd = async (resources, rootKey) => {
  try {
    const batch = [];
    resources.forEach((resource) => {
      batch.push({
        verb: 'GET',
        href: `/content?limit=500&leaf=${resource.body ? resource.body.key : resource.key}`
      });
    });

    const results = await sendBatchCmd(batch);

    resources.forEach((resource) => {
      const batchResult = results.find(
        r => r.href.indexOf(resource.body ? resource.body.key : resource.key) !== -1
      );
      resource.$$treeAsLeaf = batchResult.body.results.map(r => r.$$expanded);
    });

    return rootKey ? { key: rootKey, results: resources } : resources;
  } catch (e) {
    throw e;
  }
};

export const fetchisIncludedInProThemeCmd = async (key) => {
  const results = await contentApi.getList('/content', {
    leaf: key,
    tagsIn: 'WEBPAGE2',
    limit: 1,
    expand: 'NONE'
  });

  return results.length === 1;
};

export const fetchAllCmd = async (params, options = {}) => {
  try {
    let results = await contentApi.getAll('/content', params, { caching: { timeout: 5000 } });

    results.options = options;

    // need data to generate complete identifiers for each result
    if (options.fetchTreeAsLeaf) {
      results = await fetchTreeAsLeafCmd(results);
    }

    return results;
  } catch (e) {
    throw e;
  }
};

// load all content nodes + relations + active proposals for the given document
export const fetchExternalDocumentCmd = async (documentKey) => {
  const caching = { caching: { timeout: 5000 } };
  try {
    const [content, proposals] = await Promise.all([
      contentApi.getAll('/content', {
        root: documentKey
      }, caching),
      proposalApi.getAll('/proposals', {
        statusIn: 'IN_PROGRESS,SUBMITTED_FOR_REVIEW',
        externalReferencesContains: `/content/${documentKey}`
      }, caching)
    ]);

    return {
      content,
      proposals,
      documentKey
    };
  } catch (e) {
    throw e;
  }
};

// nodesWithRequiresRelations param format: object { property=nodeHref, value=relations}
export const fetchThemeReferenceFramesMapCmd = async (nodesWithRequiresRelations) => {
  try {
    const caching = { timeout: 5000 };

    const [themeStructuredDocuments] = await Promise.all([
      contentApi.getAll('/content', {
        typeIn: 'REFERENCE_FRAME',
        expand: 'NONE'
      }, caching)
    ]);

    let requiredThemeHrefs = Object.keys(nodesWithRequiresRelations).reduce((list, key) => {
      const requires = nodesWithRequiresRelations[key]
        .filter(rel => rel.relationtype === 'REQUIRES')
        .map(rel => rel.from.href);
      return list.concat(requires);
    }, []);
    requiredThemeHrefs = Array.from(new Set(requiredThemeHrefs));

    const leafTrees = await fetchTreeAsLeafCmd(requiredThemeHrefs.map(href => ({ key: getResourceKey(href) })));

    return Object.keys(nodesWithRequiresRelations).reduce((result, href) => {
      const referenceFrameMap = new Map();
      themeStructuredDocuments.forEach((themeStructuredDocument) => {
        referenceFrameMap.set(themeStructuredDocument.href, []);
      });

      nodesWithRequiresRelations[href].forEach(relation => {
        const themeResult = leafTrees.find(tr => tr.key === getResourceKey(relation.from.href));
        if (themeResult) {
          const referenceFrame = themeResult.$$treeAsLeaf.find(n => n.type === 'REFERENCE_FRAME');
          if (referenceFrame) {
            const referenceFrameHref = referenceFrame.$$meta.permalink;
            const theme = themeResult.$$treeAsLeaf.find(n => n.key === themeResult.key && n.type === 'THEME');
            if (theme && referenceFrameMap.has(referenceFrameHref)) {
              const value = referenceFrameMap.get(referenceFrameHref);
              value.push(theme);
            }
          }
        }
      });

      result[href] = referenceFrameMap;
      return result;
    }, {});
  } catch (e) {
    throw e;
  }
};

export const fetchReferenceFramesExternalOptionsCmd = async (type, filterUrls, label) => {
  try {
    const options = [];
    await pMap(
      filterUrls,
      async (url) => {
        const externalReferenceApi = getApiFromUrl(url.path);
        const urlOptions = await externalReferenceApi.getAll(url.path);
        urlOptions.forEach(option => {
          option.label = url.label ? url.label : label;
          if (url.type) {
            option.$$meta.type = url.type;
          }
          if (option.type && option.type.href) {
            option.type = findExternalRelationType(option.type.href);
          }
          options.push(option);
        });
      }, { concurrency: 5 }
    );

    return {
      type,
      options: options
    };
  } catch (e) {
    throw e;
  }
};

// TODO: deprecated, remove
export const expandLlinkidGoalRelationsToPartCmd = async (key, relations) => {
  try {
    const batch = [];
    relations.forEach((rel) => {
      batch.push({
        verb: 'GET',
        href: rel.to.href
      });
    });

    let results = await sendBatchCmd(batch);

    // need data to generate complete identifiers for each result
    results = await fetchTreeAsLeafCmd(results);

    return { key, results };
  } catch (e) {
    throw e;
  }
};

export const fetchRelationsWithExpandedPartCmd = async (
  key,
  relations,
  partToExpand,
  fetchTreeAsLeaf = true) => {
  try {
    const filteredRelations = relations.filter(rel => commonUtils.getPathFromPermalink(rel[partToExpand].href) === '/content');

    const batch = [];
    filteredRelations.forEach((rel) => {
      batch.push({
        verb: 'GET',
        href: rel[partToExpand].href
      });
    });

    let results = await sendBatchCmd(batch);

    // need data to generate complete identifiers for each result
    if (fetchTreeAsLeaf) {
      // WARNING: The following method is using the previous results and
      // iterating them adding some new information it gets from the API,
      // so the previous call IS RELEVANT
      results = await fetchTreeAsLeafCmd(results);
    }

    return {
      key,
      relationsToExpand: partToExpand === 'from' ? 'to' : 'from',
      relations,
      results
    };
  } catch (e) {
    throw e;
  }
};

export const loadPracticalExampleZillIllustrationsCmd = async (key) => {
  try {
    // const caching = { timeout: 5000 };

    // await fetchRelationsPartCmd(key, relations, 'from', false);

    const relations = await contentApi.getAll('/content/relations', {
      to: `/content/${key}`,
      relationtype: 'REFERENCES'
    }, {
      expand: ['from']
    });

    // TODO add to relations those only present in the paramenter relationsTo list

    await pMap(
      relations,
      async (illustrationToPracticalExampleRelation) => {
        const illustrationFromRelations = await contentApi.getAll('/content/relations', {
          from: `/content/${illustrationToPracticalExampleRelation.from.$$expanded.key}`
        }, {
          expand: ['to']
        });

        // eslint-disable-next-line max-len
        illustrationToPracticalExampleRelation.from.$$expanded.$$relationsFrom = illustrationFromRelations
          .filter(relation => relation.to.$$expanded.type.indexOf('CURRICULUM_ZILL_') !== -1);

        await fetchTreeAsLeafCmd(
          illustrationFromRelations.map(rel => rel.to.$$expanded)
        );
      }, { concurrency: 5 }
    );


    return {
      key,
      relations
    };
  } catch (e) {
    throw e;
  }
};

export const sendEmailCmd = async (emailjobs) => {
  try {
    return Promise.all(emailjobs.map(emailjob => mailerApi.put(`/mailer/emailjobs/${emailjob.key}`, emailjob)));
  } catch (e) {
    throw e;
  }
};

export const fetchNewsletterSettingsCmd = async (key) => {
  try {
    return await newsletterApi.getAll('/newsletter/settings', { 'newsletter.href': `/content/${key}` });
  } catch (e) {
    throw e;
  }
};

export const patchNewsletterSettingsApprovalDateCmd = async (newsletterSettings) => {
  try {
    return newsletterApi.patch(`/newsletter/settings/${newsletterSettings.key}`, [{ op: 'replace', path: '/approvalDate', value: newsletterSettings.approvalDate }], { strip$$Properties: false });
  } catch (e) {
    throw e;
  }
};

export const loadSubjectsCmd = async () => {
  try {
    let samSubjects = cachedSamenscholingApi.getAll('/sam/commons/subjects', { limit: 1000 });
    let vakkenSubjects = cachedVakkenApi.getAll('/vakken', { limit: 1000 });

    const results = await Promise.all(
      [samSubjects, vakkenSubjects]
    );

    samSubjects = results[0];
    vakkenSubjects = results[1].map(vak => {
      vak.name += ' (oude structuur)';
      return vak;
    });

    return samSubjects.concat(vakkenSubjects);
  } catch (e) {
    throw e;
  }
};

export const themeReferencesWithRootsCmd = async (nodeHref) => {
  const batch = [];
  batch.push({
    verb: 'GET',
    href: `/content?limit=500&themesContains=${nodeHref}`
  });
  batch.push({
    verb: 'GET',
    href: `/content/relations?limit=500&from=${nodeHref}&relationtype=REQUIRES`
  });

  const results = await sendBatchCmd(batch);

  const referencesInThemesKeys = results[0].body.results.map(r => r.$$expanded.key);
  const requiresReferencesKeys = results[1].body.results.map(r => getResourceKey(r.$$expanded.to.href));

  const referencesKeys = [...referencesInThemesKeys, ...requiresReferencesKeys];
  const leafs = await fetchTreeAsLeafCmd([...referencesKeys].map(k => ({ key: k })));

  const referencedNodeRoots = new Set(leafs.map(leaf => {
    const tree = leaf.$$treeAsLeaf;
    const rootTitle = tree[0].title;
    // eslint-disable-next-line no-nested-ternary
    const referenceNodeTitle = tree.find(n => n.key === leaf.key).title;
    return '. ' + rootTitle + (rootTitle !== referenceNodeTitle
      ? ' > ' + referenceNodeTitle
      : '');
  }));

  const referencesRootNodes = Array.from(new Set(leafs.map(leaf => {
    const tree = leaf.$$treeAsLeaf;
    return tree[0];
  })));

  return new Promise(async (resolve, reject) => {
    try {
      if (referencesKeys.length > 0) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          code: 'edit.referenceFrame.error.deleteThemeWithReferences',
          params: {
            references: '<br>' + [...referencedNodeRoots].join('<br>'),
            referencesRootNodes
          }
        });
      } else {
        resolve();
      }
    } catch (e) {
      throw reject(e);
    }
  });
};

export const fetchSecondaryEducationTypesCmd = async () => {
  try {
    return cachedSamenscholingApi.getAll('/sam/commons/secondaryeducationtypes', { orderBy: 'name' }, { caching: { timeout: 5000 } });
  } catch (e) {
    throw e;
  }
};

export const getFacetSourceWebConfigsCmd = async (key, options, state) => {
  if (!options || !options.source) {
    throw new Error('Configuration webFacets component: missing parameter "options.source"');
  }

  const node = state.apiWithPendingChanges.content.get(`/content/${key}`);

  if (options.source === 'parent') {
    return node.$$parent ? node.$$parent.websitesConfiguration : [];
  }

  if (options.source === 'themesMatches') {
    // Get mini-databases / download pages
    if (!node.themes || !node.themes.length) {
      return [];
    }

    let isThemeFieldEmpty = false;

    const params = Object.fromEntries(
      Object.entries(options.matchingParams).map(([k, v]) => {
        if (!v.referenceFrameKey) {
          return [k, v];
        }

        const referenceFrame = state.viewModel.aside.referenceFrames[v.referenceFrameKey];
        const filteredThemes = node.themes.filter((href) =>
          referenceFrame.some((rf) => rf.$$meta.permalink === href)
        );

        if (!filteredThemes.length) {
          isThemeFieldEmpty = true;
        }

        return [k, filteredThemes];
      })
    );

    // If one of the theme fields is empty, there are no matches
    // Sending eg themesMatchingOnRoot=/content/...&themesOverlaps=<empty> would result in incorrect matching
    if (isThemeFieldEmpty) {
      return [];
    }

    const miniDatabases = await contentApi.getAll('/content', params);
    const webPages = miniDatabases
      .map((mdb) => mdb.$$webPages)
      .flat()
      .map((wp) => wp.$$expanded);

    // Get facet reference frames
    const facets = webPages.map((wp) => (wp.options && wp.options.facets) || []).flat();
    const referenceFrameHrefs = facets
      .filter((f) => f.component === 'SELECT_FROM_REFERENCE_FRAME')
      .map((f) => f.source.href);

    if (referenceFrameHrefs.length) {
      const referenceFrames = (await loadHrefsCmd([...new Set(referenceFrameHrefs)])).results;
      webPages.forEach((wp) => {
        if (wp.options && wp.options.facets) {
          wp.options.facets.forEach((f) => {
            f.source = f.source
              ? referenceFrames.find((rf) => rf.$$meta.permalink === f.source.href)
              : f.source;
          });
        }
      });
    }

    return webPages.map((wp) => ({
      ...wp,
      template: state.webtemplates.find((t) => t.$$meta.permalink === wp.template.href),
    }));
  }

  throw new Error('Configuration webFacets component: invalid parameter "options.source"');
};
