import * as DOCUMENT_ACTIONS from '../../reduxLoop/actions/documentActions';

class markExplanations {
  constructor($ngRedux) {
    'ngInject';

    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        markExplanationReferences: state.document.viewModel.markExplanationReferences
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  showMarkExplanations() {
    return this.markExplanationReferences
      && this.markExplanationReferences.length;
  }
}

export default {
  template: require('./markExplanations.html'),
  controllerAs: 'ctrl',
  controller: markExplanations
};
