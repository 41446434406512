
class zillIllustrationModal {
  constructor($notification, $translate, $ngRedux, $scope) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
  }

  hasError() {
    return false;
  }

  async submit() {
    if (!this.hasError()) {
      this.modalInstance.close(this.title);
    }
  }

  change(scope, e, data) {
    scope.ctrl.title = data.value;
    scope.ctrl.characters = scope.ctrl.charactersCount(data.value);
    scope.$apply();
  }

  blur(scope, e, data) {
    scope.ctrl.title = data.value;
  }

  cancel() {
    this.modalInstance.dismiss();
  }
  
  charactersCount(text) {
    text = text ? text
      .replace(/&nbsp;/g, ' ')
      .replace(/&gt;/g, '>')
      .replace(/(<([^>]+)>)/ig, '')
      .replace(/(\r\n|\n|\r)/gm, '') : '';
    return text.length;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      let goal = state.document.selectedZillGoals
        .find(g => g.$$meta.permalink === this.resolve.modalData.goalHref);

      return {
        goal: goal,
        loading: !goal
      };
    })(this);

    this.title = '';
    this.characters = this.charactersCount(this.title);
  }

  $onDestroy() {
    this.unsubscribe();
  }
}


export default {
  template: require('./zillIllustrationModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<'
  },
  controller: zillIllustrationModal
};
