module.exports = ['BackendManager', 'settings', function(BackendManager, settings) {//eslint-disable-line
  return {
    restrict: 'E',
    scope: {
      documentKey: '=',
      document: '='
    },
    template: require('./hasNewVersionLabel.html'),
    replace: true,
    link: (scope) => {
      scope.$watch('documentKey', () => {
        scope.hasNewVersion = false;
        if (scope.document && scope.document.$$relationsTo) {
          if (scope.document.$$relationsTo.filter(o => o.$$expanded.relationtype === 'REPLACES').length > 0) {
            scope.hasNewVersion = true;
          }
        } else {
          BackendManager.getResources(settings.resourcesNames.relations, {
            to: scope.documentKey,
            relationtype: 'REPLACES',
            expand: 'results.to,results.from'
          }).then((relations) => {
            relations.results.forEach((relation) => {
              if (relation.from.$$expanded.type === relation.to.$$expanded.type) {
                scope.hasNewVersion = true;
              }
            });
          });
        }
      });
    }
  };
}];
