import * as apiRoutes from '../../../reduxLoop/api/apiRoutes';
const constants = require('../../../reduxLoop/constants/constants');
require('./configurationModalForm.scss');
const {
  common: { urlUtils },
} = require('@kathondvla/shared-logic');

class ConfigurationModalForm {
  constructor($notification, $translate, Utils,
    ConfirmationService, ProWebsiteConfigurationsService) {
    'ngInject';

    this.notificationService = $notification;
    this.translationService = $translate;
    this.utils = Utils;
    this.confirmationService = ConfirmationService;
    this.configurationsService = ProWebsiteConfigurationsService;
  }

  $onInit() {
    this.configuration = this.resolve.modalData.configuration;
    this.templates = this.resolve.modalData.templates
      ? this.resolve.modalData.templates
        .filter(template => {
          // if root is pro.homepage only 'FALLBACK_MENU_LEVEL_2' and 'FALLBACK_MENU_LEVEL_3'
          // are included, if not all the rest
          return (!this.resolve.modalData.isRootHomepage
            && !constants.homepageOnlyWebconfigurations.includes(template.code))
            || (this.resolve.modalData.isRootHomepage
              && constants.homepageOnlyWebconfigurations.includes(template.code));
        })
        .map(template => {
          return this.addCodeTranslation(template);
        })
        .sort((t1, t2) => {
          if (t1.codeTranslation < t2.codeTranslation) {
            return -1;
          }
          if (t1.codeTranslation > t2.codeTranslation) {
            return 1;
          }
          return 0;
        })
      : [];

    this.websites = this.resolve.modalData.websites;

    if (!this.configuration.options) {
      this.configuration.options = {
        facets: [],
      };
    }

    this.addCodeTranslation(this.configuration.template);
    this.isRootIssued = this.resolve.modalData.isRootIssued;
    this.isNewConfiguration = this.configuration.isNew;
    this.initialPath = this.configuration.path;

    this.showBanner = this.resolve.modalData.showBanner;
    this.configuration.options.banner = this.configuration.options.banner || {};
  }

  async isValidConfiguration() {
    if (!this.configuration.website) {
      this.notificationService.error(this.translationService.instant('components.proWebsite.configurationModalForm.error.invalidWebsite'));
      return false;
    }

    if (!this.configuration.path || this.configuration.path.trim() === '') {
      this.notificationService.error(this.translationService.instant('components.proWebsite.configurationModalForm.error.invalidPath'));
      return false;
    }

    if (this.configuration.path && !this.configuration.path.startsWith('/')) {
      this.notificationService.error(this.translationService.instant('components.proWebsite.configurationModalForm.error.invalidPathStart'));
      return false;
    }

    if (!this.configuration.template) {
      this.notificationService.error(this.translationService.instant('components.proWebsite.configurationModalForm.error.invalidType'));
      return false;
    }

    // All banner options are required if highlight is checked
    const banner = this.configuration.options.banner;
    if (banner.highlight) {
      if (!banner.title) {
        this.notificationService.error(
          this.translationService.instant(
            'components.proWebsite.configurationModalForm.error.banner.title'
          )
        );
        return false;
      }

      if (!banner.subtitle) {
        this.notificationService.error(
          this.translationService.instant(
            'components.proWebsite.configurationModalForm.error.banner.subtitle'
          )
        );
        return false;
      }

      if (!banner.cta) {
        this.notificationService.error(
          this.translationService.instant(
            'components.proWebsite.configurationModalForm.error.banner.cta'
          )
        );
        return false;
      }

      if (!urlUtils.isValidHttpUrl(banner.url)) {
        this.notificationService.error(
          this.translationService.instant(
            'components.proWebsite.configurationModalForm.error.banner.url'
          )
        );
        return false;
      }

      if (!banner.from) {
        this.notificationService.error(
          this.translationService.instant(
            'components.proWebsite.configurationModalForm.error.banner.from'
          )
        );
        return false;
      }

      if (!banner.to) {
        this.notificationService.error(
          this.translationService.instant(
            'components.proWebsite.configurationModalForm.error.banner.to'
          )
        );
        return false;
      }

      if (banner.from > banner.to) {
        this.notificationService.error(
          this.translationService.instant(
            'components.proWebsite.configurationModalForm.error.banner.fromBeforeTo'
          )
        );
        return false;
      }
    }

    if (this.configuration.isNew) {
      this.configuration.key = this.utils.getUUID();
      this.configuration.$$meta = {
        permalink: `${apiRoutes.webpages}/${this.configuration.key}`
      };
      delete this.configuration.isNew;
    }

    return true;
  }

  async requiresNoFacetConfirmation() {
    if (this.configurationsService.typeHasFacets(this.configuration.template)
      && this.configuration.options.facets.length === 0) {
      let confirmationMessageToBeTranslated = this.configurationsService.getNoFacetMessageFor(
        this.configuration.template.code
      );
      const response = await this.confirmationService.confirm({
        confirmationMessage: confirmationMessageToBeTranslated,
      });
      return response;
    }
    return true;
  }

  async requiresPathToOldUrlsConfirmation() {
    if (this.isRootIssued
      && this.configuration.path !== this.initialPath
      && !this.isNewConfiguration) {
      const response = await this.confirmationService.confirm({
        confirmationMessage: 'components.proWebsite.configurationModalForm.addToOldUrls',
      });
      return response;
    }
    return false;
  }

  async submit() {
    const isValid = await this.isValidConfiguration();
    if (!isValid) {
      return;
    }

    const noFacetConfirmation = await this.requiresNoFacetConfirmation();
    if (!noFacetConfirmation) {
      return;
    }

    const pathToOldUrlsConfirmation = await this.requiresPathToOldUrlsConfirmation();
    if (pathToOldUrlsConfirmation) {
      this.configuration.oldUrlsVm = this.configuration.oldUrlsVm || [];
      const newOldUrl = `https://${this.configuration.website.domain}${this.initialPath}`;
      if (!this.configuration.oldUrlsVm.includes(newOldUrl)) {
        this.configuration.oldUrlsVm.push(newOldUrl);
      }
    }

    this.modalInstance.close(this.configuration);
  }

  showFacets() {
    return this.configurationsService.typeHasFacets(this.configuration.template);
  }

  showMultipleAlternativeMenus() {
    return this.configurationsService.typeHasMultipleAlternativeMenus(this.configuration.template);
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  facetsChanged(facets) {
    // it could involve a facet addition, deletion or modification
    // we don't care about it we receive and copy the "whole" updated structure
    this.configuration.options.facets = angular.copy(facets);
  }

  getItems(search) {
    let options = [].slice();
    if (search && options.indexOf(search) === -1) {
      options.unshift(search);
    }
    return options;
  }

  validateOldUrl(oldUrl) {
    if (!urlUtils.isValidHttpUrl(oldUrl)) {
      return {
        code: 'components.proWebsite.configurationModalForm.error.notValidUrl'
      };
    }
    if (!this.websites.some(w => oldUrl.startsWith(`https://${w.domain}`))) {
      return {
        code: 'components.proWebsite.configurationModalForm.error.oldUrlsInvalidWebsite',
        params: {
          validWebsites: this.websites.map(x => `https://${x.domain}`).join(', ')
        }
      };
    }
    return undefined;
  }

  async onSelect(item) {
    const error = this.validateOldUrl(item);
    if (error) {
      this.configuration.oldUrlsVm = this.configuration.oldUrlsVm.filter(s => s !== item);
      this.notificationService.error(error.code, error.params);
    }
  }

  async onRemove(item) {
    let confirmed = await this.confirmationService.confirmDeletion();
    if (!confirmed) {
      this.configuration.oldUrlsVm.push(item);
    }
  }

  truncate(str, n = 78) {
    return (str.length > n) ? str.substr(0, n - 1) + '&hellip;' : str;
  }

  addCodeTranslation(template) {
    if (template) {
      template.codeTranslation = this.translationService.instant(`webconfigurationTemplates.${template.code}`, template.code);
      // default is not supported by $translate we need to manually set it back
      template.codeTranslation = template.codeTranslation.indexOf('webconfigurationTemplates') === -1
        ? template.codeTranslation : template.code;
    }
    return template;
  }

  updateFrom(event, date) {
    this.configuration.options.banner.from = date ? new Date(date).toJSON() : date;
  }

  updateTo(event, date) {
    this.configuration.options.banner.to = date ? new Date(date).toJSON() : date;
  }
}

export default {
  template: require('./configurationModalForm.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<'
  },
  controller: ConfigurationModalForm
};
