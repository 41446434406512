import * as NOTIFICATION_ACTIONS from '../../reduxLoop/actions/notificationActions';

class ImageSelector {
  constructor($scope, $ngRedux, $translate, ModalWindowService) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.modalWindowService = ModalWindowService;
    this.translationService = $translate;
  }

  $onInit() {
    this.hasImage = (this.sImage.$$url && this.sImage.$$url.indexOf('undefined') === -1) || this.sImage.file;
    this.isLocalImage = this.hasImage && this.sImage.file && this.sImage.$$base64;
    if (this.isLocalImage) {
      this.imagePreview = this.sImage.$$base64;
    }
    this.$scope.$on('onInvalidTypeNotification', () => {
      this.$ngRedux.dispatch(NOTIFICATION_ACTIONS.addNotificationAction({ type: 'ERROR', message: 'imageSelector.onInvalidTypeNotification' }));
    });
    this.$scope.$on('onInvalidSizeNotification', (event, payload) => {
      this.$ngRedux.dispatch(NOTIFICATION_ACTIONS.addNotificationAction({ type: 'ERROR', message: this.translationService.instant('imageSelector.onInvalidSizeNotification', payload) }));
    });
    this.$scope.$on('onImageDelete', () => {
      this.$ngRedux.dispatch(deleteProfilePicture(this.personHref));
    });
  }

  async editProfilePicture() {
    const opts = {
      animation: true,
      component: 'kovImageEditor',
      size: 'md',
      backdrop: 'static',
      scope: this.$scope,
      resolve: {
        currentProfilePicture: () => this.sImage.$$url,
        aspectRatio: () => !this.crop.type || this.crop.type !== 'rectangle' ? 1.3333333333333333 : undefined, // square (default) type will take the aspectRatio 1.33
        requiredWidth: () => 710,
        requiredHeight: () => 533,
        title: () => 'Image'
      }
    };

    const response = await this.modalWindowService.open(opts);

    this.hasImage = true;
    this.isLocalImage = true;
    this.imagePreview = response.original;

    this.$scope.$emit('changed_cropped_image', { ...response, type: this.sType });
  }

  uploadFile(scope, event) {
    const file = event.target.files[0];
    // only allow png, jpg and jpeg as file extensions
    // except for type ICON where svg is also permitted
    if (/\.(jpe?g|png)$/i.test(file.name)
      || (scope.ctrl.sType === 'ICON' && file.name.endsWith('.svg'))) {
      scope.ctrl.hasImage = true;
      scope.ctrl.isLocalImage = true;
      scope.ctrl.imageSourceLocal(file);
      scope.$emit('changed_image', { type: scope.ctrl.sType, file: file });
    } else {
      scope.$emit('onInvalidTypeNotification');
    }
  }

  imageSourceLocal(image) {
    let component = this;
    let $scope = this.$scope;

    var reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = function () {
      component.imagePreview = reader.result;
      $scope.$apply();
    };
    reader.onerror = function (error) {
      console.error('Error: ', error);
    };
  }

  removeImage() {
    this.hasImage = false;
    this.$scope.$emit('removed_image', { type: this.sType });
  }
}

export default {
  template: require('./imageSelector.html'),
  controllerAs: 'ctrl',
  bindings: {
    sType: '<',
    sImage: '<',
    maxSizeInBytes: '<',
    sDisabled: '<',
    crop: '<'
  },
  controller: ImageSelector
};
