const constants = require('../../../reduxLoop/constants/constants');

module.exports = {
  information: {
    single: 'Faq-groep',
    plural: 'Faq-groepen',
    ribonIcon: require('../../../../img/buildingBlocks/FAQ_GROUP.svg')
  },
  node: {
    type: 'FAQ_GROUP'
  },
  indentation: 1,
  buildingBlocks: ['FAQ'],
  edit: [
    'title',
    {
      component: 'themes',
      label: 'Categorie FAQ-groep',
      options: {
        referenceFrameKey: constants.faqCategoriesKey,
        types: ['THEME'],
      },
    },
    {
      component: 'namedSet',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    },
    {
      component: 'coverage',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    }
  ],
  asDocumentView: true
};
