import { call, put, takeLatest } from 'redux-saga/effects';

import * as externalDataApi from './externalDataDataAccess';
import { getNewsletterSenders, setNewsletterSenders } from './externalDataState';
import { addNotificationAction } from '@store/actions/notificationActions';

function* getNewsletterSendersSaga() {
  try {
    const newsletterSenders = yield call(externalDataApi.getNewsletterSenders);
    yield put(setNewsletterSenders({ newsletterSenders }));
  } catch (error) {
    console.error(error);
    yield put(
      addNotificationAction({
        type: 'ERROR',
        message: 'loadingError.newsletterSenders',
        removeAfter: 0,
      })
    );
  }
}

export function* watchExternalDataSaga() {
  yield takeLatest(getNewsletterSenders, getNewsletterSendersSaga);
}
