import { config as CKconfig } from '../../reduxLoop/constants/ckeditor';
import santitizeHTML from 'sanitize-html';
import { sanitizeHTML } from '../../reduxLoop/helpers/documentHelpers';
import { termButtonClicked, demarcationButtonClicked, markerButtonClicked } from '../../services/editorCustomButtonsService';
// this is "dummy" component, only emits events on edition done

class Editor {
  constructor($rootScope, $scope, $sce, $element, $attrs,
    $timeout, $compile, ModalWindowService) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$sce = $sce;
    this.$element = $element;
    this.$attrs = $attrs;
    this.$timeout = $timeout;
    this.modalWindowService = ModalWindowService;
    this.$compile = $compile;
  }

  async $onInit() {
    const config = CKconfig[this.sField];
    const component = this;
    const extraConfig = config.extraConfig ? config.extraConfig : {};

    let editor = CKEDITOR.replace(this.$element[0], {
      toolbarGroups: this.getToolbarGroups(config),
      removePlugins: this.getToolbar(config),
      removeButtons: this.getRemovedButtons(config),
      extraPlugins: 'justify', // needed to show paragraph justify buttons
      extraAllowedContent: 'a inline-footnote inline-demarcation inline-term inline-mark-explanation[*]',
      contentsCss: '/ckeditor.css',
      title: false,
      height: config.height ? config.height : null,
      font_defaultLabel: 'arial',
      fontSize_defaultLabel: '15px',
      readOnly: component.sDisabled,
      ...extraConfig,
      on: {
        instanceReady: () => {
          editor.setData(this.getValue());
          if (component.sDisabled) {
            editor.document.getBody().getParent().setStyle('cursor', 'not-allowed');
            editor.document.getBody().setStyle('cursor', 'not-allowed');
          }
        },

        // at this point the data has been converted to html
        toDataFormat: () => {
          if (!editor.snap) {
            editor.snap = editor.getSnapshot();
          }
        },

        change: () => {
          // emit event notifying model update
          let html = editor.getData();
          component.sModel = html;
          if (component.sTextChangedEvent) {
            this.$scope.$emit(
              component.sTextChangedEvent,
              { field: component.sField, value: html, original: editor.snap }
            );
          }
          this.$scope.$emit('editor_text_changed', { field: component.sField, value: html, original: editor.snap });
        },
        blur: () => {
          let html = editor.getData();
          if (sanitizeHTML(editor.snap, this.sField) !== sanitizeHTML(html, this.sField)) {
            this.$scope.$emit('edit_done', {
              key: this.sKey, field: this.sField, value: html, original: editor.snap, editor: editor
            });
            editor.snap = html;
          } else {
            this.$scope.$emit('edit_without_changes_done', {
              key: this.sKey, field: this.sField, value: html, original: editor.snap, editor: editor
            });
          }
        }
      }
    });

    this.addCustomButtons(editor, config);
    this.addCustomCommands(editor, component);
    if (config.height) {
      editor.height = config.height;
    }

    editor.on('doubleclick', (evt) => {
      var element = evt.data.element;
      if (element.is('a')) {
        evt.stop(); // don't do the other listeners
        // optionally put your code
        component.$scope.$emit('customlink_button_clicked', { key: component.sKey, field: component.sField, editor: editor });
      }
    }, null, null, 1); // 10 is default, so put something lower for higher priority

    editor.on('doubleclick', (evt) => {
      var element = evt.data.element;
      if (element.is('inline-term')) {
        evt.stop(); // don't do the other listeners
        // optionally put your code
        let sel = editor.getSelection();
        sel.selectElement(sel.getStartElement());
      }
    }, null, null, 1); // 10 is default, so put something lower for higher priority

    editor.on('selectionChange', () => {
      var sel = editor.getSelection();
      var element = sel.getStartElement();
      if (!['P', 'A', 'B', 'STRONG', 'I'].includes(element.$.tagName)) {
        sel.selectElement(element);
      }
    }, null, null, 1); // 10 is default, so put something lower for higher priority
  }

  $onChanges() {
    this.$scope.$broadcast('$$rebind::change');
  }

  getValue() {
    let value = santitizeHTML(this.sInitial, {
      allowedTags: false,
      allowedAttributes: false,
      transformTags: {
        a: (tagName, attribs) => {
          if (attribs.href && attribs.href.startsWith('/content/')) {
            switch (attribs.rel) {
              case 'term':
                return {
                  tagName: 'inline-term',
                  attribs: {
                    's-href': attribs.href
                  }
                };
              case 'demarcation':
                return {
                  tagName: 'inline-demarcation',
                  attribs: {
                    's-href': attribs.href
                  }
                };
              case 'footnote':
                return {
                  tagName: 'inline-footnote',
                  attribs: {
                    ...attribs,
                    's-href': attribs.href
                  }
                };
              default:
                break;
            }
          }
          return {
            tagName,
            attribs
          };
        },
        span: (tagName, attribs) => {
          const href = attribs['data-href'];
          if (href && href.startsWith('/content/')) {
            return {
              tagName: 'inline-mark-explanation',
              attribs: {
                's-href': href
              }
            };
          }
          return {
            tagName,
            attribs
          };
        }
      }
    });
    return value;
  }

  addCustomCommands(editor, component) {
    editor.addCommand('insertTermCmd', {
      exec: () => {
        termButtonClicked(editor, this.sRootDocument, this.modalWindowService, this.$scope);
      }
    });
    editor.addCommand('insertDemarcationCmd', {
      exec: () => {
        demarcationButtonClicked(editor);
      }
    });
    editor.addCommand('insertFootnoteCmd', {
      exec: () => {
        component.$scope.$emit('footnote_button_clicked', { key: component.sKey, field: component.sField, editor: editor });
      }
    });
    editor.addCommand('insertCustomLinkCmd', {
      exec: () => {
        component.$scope.$emit('customlink_button_clicked', { key: component.sKey, field: component.sField, editor: editor });
      }
    });
    editor.addCommand('insertMarkExplanationCmd', {
      exec: () => {
        markerButtonClicked(editor, this.modalWindowService);
      }
    });
  }

  getToolbarGroups(config) {
    let toolbarGroups = [];

    if (config && config.reducedToolbar) {
      toolbarGroups = [
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'custom' }
      ];
    } else {
      toolbarGroups = [
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'clipboard', groups: ['undo', 'clipboard'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks'] },
        { name: 'insert', groups: ['insert'] },
        { name: 'links', groups: ['links'] },
        { name: 'custom' }
      ];
    }
    return toolbarGroups;
  }

  getToolbar(config) {
    return config.hideToolbar ? 'toolbar,pastefromword, tableselection, uploadwidget, clipboard, pastetext, widget, uploadimage' : '';
  }

  getRemovedButtons(config) {
    let extra = config && this.sWebpage2Link ? ',Link' : '';

    return 'Source,Save,Templates,NewPage,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,SelectAll,Scayt,Find,Replace,Form,Checkbox,TextField,Radio,Textarea,Select,Button,ImageButton,HiddenField,Underline,Strike,CopyFormatting,Outdent,Indent,Blockquote,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Flash,Image,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Styles,TextColor,Maximize,About,ShowBlocks,BGColor,Format,Font,FontSize' + extra;
  }

  addCustomButtons(editor, config) {
    if (config && !config.customButtons) {
      config.customButtons = [];
    }

    if (config && config.customButtons.indexOf('term') !== -1 && !this.hideTermButton) {
      editor.ui.addButton('InsertTerm', {
        label: 'Begrip',
        command: 'insertTermCmd',
        toolbar: 'custom',
        icon: require('../../../img/termIcon.svg')
      });
    }

    if (config && config.customButtons.indexOf('demarcation') !== -1 && this.sDemarcationButton) {
      editor.ui.addButton('InsertDemarcation', {
        label: 'Afbakening',
        command: 'insertDemarcationCmd',
        toolbar: 'custom',
        icon: require('../../../img/afbakening.png')
      });
    }

    if (config && config.customButtons.indexOf('footnote') !== -1 && !this.sWebpage2Link) {
      editor.ui.addButton('InsertFootnote', {
        label: 'Voetnoot',
        command: 'insertFootnoteCmd',
        toolbar: 'custom',
        icon: require('../../../img/footnote1.png')
      });
    }

    if (config && config.customButtons.indexOf('customLink') !== -1 && this.sWebpage2Link) {
      editor.ui.addButton('InsertCustomLink', {
        label: 'Link',
        command: 'insertCustomLinkCmd',
        toolbar: 'links',
        icon: 'Link'
      });
    }

    if (config && config.customButtons.indexOf('marker') !== -1 && this.sMarkerButton) {
      editor.ui.addButton('InsertMarkExplanation', {
        label: 'Markeerverklaring',
        command: 'insertMarkExplanationCmd',
        toolbar: 'custom',
        icon: require('../../../img/icons/mark.svg')
      });
    }
  }
}

export default {
  controllerAs: 'ctrl',
  bindings: {
    sModel: '<?',
    sInitial: '@?',
    sField: '@?',
    sKey: '@?',
    sFocusOnInit: '<',
    hideTermButton: '<',
    sDemarcationButton: '<',
    sMarkerButton: '<',
    sWebpage2Link: '<',
    sDisabled: '<',
    sRootDocument: '<'
  },
  template: '<textarea id="{{ ::ctrl.sField + \'-\' + ctrl.sKey }}"></textarea>',
  controller: Editor
};
