module.exports = {
  information: {
    single: 'Cluster',
    plural: 'Clusters'
  },
  node: {
    type: 'CURRICULUM_ZILL_CLUSTER'
  },
  indentation: 1,
  edit: [
    {
      component: 'identifier',
      width: 2
    },
    {
      component: 'title',
      width: 10
    },
    'description'
  ],
  buildingBlocks: [
    'CURRICULUM_ZILL_DEVELOPMENT_FIELD'
  ],
  isSearchable: false,
  documentViewIdentifier: 'i',
  disableDeleteWhenIssued: true
};
