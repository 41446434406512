function roundUpTo1stSept(date) {
  const d = new Date(date);
  const start = new Date(Date.UTC(d.getFullYear(), 8)); // first september 20xx
  if (d > start) {
    // it was issued after 1st sept. round up to the next 1 sept.
    return date.setFullYear(date.getFullYear() + 1);
  }
  return start;
}

// TODO refactor: return all errors at once
export const expiryDateValidation = (node) => {
  let expiryDate;

  if ((node.expiryDate?.value || node.expiryDate) !== undefined) {
    expiryDate = new Date(node.expiryDate?.value || node.expiryDate);
  }

  if (expiryDate && node.type === 'LLINKID_CURRICULUM') {
    if (expiryDate && !(expiryDate.getMonth() === 7 && expiryDate.getDate() === 31)) {
      return {
        type: 'ERROR',
        message: 'edit.expiryDate.dateFormatError',
      };
    }

    const incomingValidityStartDate = roundUpTo1stSept(node.issued?.value || node.issued);

    const newDate = new Date(
      incomingValidityStartDate.getFullYear(),
      incomingValidityStartDate.getMonth() +
        (node.realisationPeriod?.value || node.realisationPeriod),
      incomingValidityStartDate.getDate() - 1
    );

    if (newDate > expiryDate) {
      return {
        type: 'ERROR',
        message: 'edit.expiryDate.realisationPeriodError',
      };
    }

    const hasBeenReplaced = node.$$relationsTo.some(
      (z) => z.$$expanded.relationtype === 'REPLACES'
    );

    if (hasBeenReplaced) {
      return {
        type: 'ERROR',
        message: 'edit.expiryDate.versionError',
      };
    }
  }

  return true;
};
