import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class OdetEndTermReferencesModal {
  constructor($translate, $ngRedux) {
    'ngInject';

    this.translationService = $translate;

    this.$ngRedux = $ngRedux;
  }

  async submit() {
    if (!this.selectedGoal) {
      this.notificationService.error(this.translationService.instant('components.llinkid.goalOdetEndTermReferences.error.invalidReference'));
    } else {
      this.modalInstance.close({
        goal: this.selectedGoal
      });
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }


  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      // filter already selected goals
      const goals =
        state.document.llinkidOdetCurriculum.$$goals?.filter((g) => {
          return !this.resolve.modalData.selectedValues.includes(g.key);
        }) ?? [];

      if (!this.selectedGoal && this.resolve.modalData.edit.relation) {
        this.selectedGoal = this.resolve.modalData.edit.relation.to.$$expanded;
      }

      return {
        odetCurricumlum: state.document.llinkidOdetCurriculum,
        curriculumGoals: goals,
        loading: state.document.llinkidOdetCurriculum.$$loading,
        allIsAlreadySelected: state.document.llinkidOdetCurriculum.$$goals
          && this.resolve.modalData.selectedValues.length
            === state.document.llinkidOdetCurriculum.$$goals.length
      };
    })(this);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.loadLlinkidOdetGoalsAction());
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

export default {
  template: require('./goalOdetEndTermReferencesModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<'
  },
  controller: OdetEndTermReferencesModal
};
