module.exports = {
  information: {
    single: 'Concordantie (S)MD/BK',
    plural: 'Concordanties (S)MD/BK',
  },
  node: {
    type: 'LLINKID_CONCORDANCE',
    title: 'Minimumdoelen, specifieke minimumdoelen of doelen die leiden naar BK',
  },
  buildingBlocks: [],
  edit: [
    'title',
    {
      component: 'description',
      label: 'Concordaties',
    },
  ],
  allowSibling: false,
  isSearchable: false,
  customRender: true,
};
