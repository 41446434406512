/* eslint-disable max-len */
import * as DOCUMENT_ACTIONS from '../../reduxLoop/actions/documentActions';
import { isEmpty } from '../../reduxLoop/helpers/documentHelpers';

require('./tocRow.scss');

class tocRow {
  constructor($scope, $state, $ngRedux, $element, $translate) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$ngRedux = $ngRedux;
    this.$element = $element;
    this.previousItem = null;
    this.$translate = $translate;
    this.isTogglingCollapse = false;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const node = state.document.viewModel.flat.find(n => n.key === this.sItem.key);
      this.onChanges(node);
      return {
        rootDocument: state.document.viewModel.document,
        allReadButton: state.document.viewModel.allReadButton
      };
    })(this);

    if (this.sItem.$$new) {
      this.openInline = this.sItem.$$typeConfig.ribbon && this.sItem.$$typeConfig.ribbon.openInLine
        ? this.sItem.$$typeConfig.ribbon.openInLine
        : false;
      if (!this.openInline) {
        this.openEdit();
      }
      // this.$ngRedux.dispatch(patchNodeAction(this.sItem.key, { $$new: false }, false));
    }
  }

  onChanges(newItem) {
    if (this.sItem && newItem) {
      // if (this.isCollapseble(newItem) !== this.isCollapseble(this.sItem)) {
      if (this.sItem.$$isCollapsed !== newItem.$$isCollapsed) {
        // console.log('onChanges toc collapse:', newItem.key);
        this.isTogglingCollapse = false;
        this.$scope.$broadcast('$$rebind::collapse');
      }
      if (newItem.$$hoverOnCollapse.self !== this.sItem.$$hoverOnCollapse.self
        || newItem.$$hoverOnCollapse.parent !== this.sItem.$$hoverOnCollapse.parent) {
        // console.log('onChanges hover:', newItem.key, newItem.$$hoverOnCollapse.self, newItem.$$hoverOnCollapse.parent, '/', this.sItem.$$hoverOnCollapse.self, this.sItem.$$hoverOnCollapse.parent, this.random);
        this.$scope.$broadcast('$$rebind::hover');
      }
      if (newItem.modificationAfterLastRead !== this.sItem.modificationAfterLastRead) {
        this.$scope.$broadcast('$$rebind::hasChanges');
      }
      if ((newItem.proposal && newItem.proposal.hash && !this.sItem.proposal)
        || (!newItem.proposal && this.sItem.proposal && this.sItem.proposal.hash)
        || (newItem.proposal && this.sItem.proposal && newItem.proposal.hash !== this.sItem.proposal.hash)
      ) {
        this.$scope.$broadcast('$$rebind::proposal');
      }
      if (newItem.$$validations.length !== this.sItem.$$validations.length) {
        this.$scope.$broadcast('$$rebind::validations');
      }
    }
    this.sItem = { ...newItem };
  }

  // @deprecated since binding of sItem is one time binded now and is not refreshed anymore (replaced by onChanges)
  $onChanges() {
    // this.$scope.$broadcast('$$rebind::change');
    if (this.previousItem) {
      if (this.sItem.$$isCollapsed !== this.previousItem.$$isCollapsed) {
        this.$scope.$broadcast('$$rebind::collapse');
      }
      if (this.sItem.$$hoverOnCollapse.self !== this.previousItem.$$hoverOnCollapse.self
        || this.sItem.$$hoverOnCollapse.parent !== this.previousItem.$$hoverOnCollapse.parent) {
        this.$scope.$broadcast('$$rebind::hover');
      }
    }
    this.previousItem = { ...this.sItem };
  }

  $onDestroy() {
    this.unsubscribe();
  }

  openEdit() {
    this.$state.go('edit.aside', { editKey: this.sItem.key });
  }

  saveInline(scope, event, editorData) {
    const patch = {
      [editorData.field]: editorData.value
    };
    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(editorData.key, patch)
    );
  }

  getPrefixToShow() {
    if (this.sItem.$$prefix && this.sItem.identifiers && this.sItem.identifiers.length > 0) {
      return this.sItem.$$prefix.slice(0, this.sItem.$$prefix.length - this.sItem.identifiers[0].length);
    }
    return '';
  }

  getTypeName() {
    return this.sItem.$$typeConfig.information ? this.sItem.$$typeConfig.information.single : this.sItem.$$type;
  }

  validationPopoverMessage() {
    let html = '<div>';
    this.sItem.$$validations.forEach((validation => {
      if (validation.error.type === 'WARNING') {
        html += `<div>
        <span class="label label-warning popover-label-validation"> </span>
        <span>${this.$translate.instant(validation.error.message, validation.error.params)}</span>
        </div>`;
      } else if (validation.error.type === 'ERROR') {
        html += `<div>
        <span class="label label-danger popover-label-validation"> </span>
        <span>${this.$translate.instant(validation.error.message, validation.error.params)}</span>
        </div>`;
      } else {
        html += `<div>
        <span class="label label-default popover-label-validation"> </span>
        <span>${this.$translate.instant(validation.error.message, validation.error.params)}</span>
        </div>`;
      }
    }));
    return html + '</div>';
  }

  validationColorOfRow() {
    if (this.sItem.$$validations.filter(o => o.error.type === 'ERROR').length > 0) {
      return 'red';
    }
    if (this.sItem.$$validations.filter(o => o.error.type === 'WARNING').length > 0) {
      return 'orange';
    }
    return 'gray';
  }

  getIllustration() {
    return this.sItem.$$attachments.has('ILLUSTRATION') ? this.sItem.$$attachments.get('ILLUSTRATION').original : null;
  }

  hasEditSection(name, value) {
    const component = this.sItem.$$editSections.filter(o => o.component === name);
    if (component.length > 0) {
      if (component[0].showPlaceholder) {
        return false;
      }
      if (component[0].view && component[0].view.hideIfEmpty && isEmpty(value)) {
        return false;
      }
      return true;
    }
    return false;
  }

  hasDeleteProposal() {
    if (!this.sItem.proposal) {
      return false;
    }
    return this.sItem.proposal.type === 'DELETE';
  }

  proposalLineStyle() {
    if (this.sItem.proposal) {
      const line = this.sItem.proposal.isSubmitted ? 'submitted' : 'not-submitted';
      const color = this.sItem.proposal.isSameUser ? 'same-user' : 'different-user';
      const isDeleted = this.sItem.proposal.isDeleted ? ' is-deleted' : '';
      const isReviewing = this.sItem.proposal.isReviewingMode ? ' is-reviewing' : '';
      return line + ' ' + color + isDeleted + isReviewing;
    }
    return '';
  }

  // == Collapse ==
  isCollapseble() {
    if (!this.sItem.$$parent || this.sItem.$$isCollapsed) {
      return false;
    }
    return this.sItem.$$children.filter((n) => {
      return n && n.isInTOC;
    }).length > 0;
  }

  isExpandable() {
    if (!this.sItem.$$parent || !this.sItem.$$isCollapsed) {
      return false;
    }
    return this.sItem.$$children.filter((n) => {
      return n && n.isInTOC;
    }).length > 0;
  }

  toggleCollapse() {
    this.isTogglingCollapse = true;
    this.$scope.$broadcast('$$rebind::collapse');

    // this.$ngRedux.dispatch(DOCUMENT_ACTIONS.clearHoverOnCollapseAction());
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.toggleCollapseAction(this.sItem.key));
  }

  hoverOnCollapse() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.hoverOnCollapseAction(this.sItem.key));
  }

  stopHoverOnCollapse() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.clearHoverOnCollapseAction());
  }


  // == SELECTION OF ROW ==
  hideSelect() {
    return this.sItem.$$isRoot || this.sItem.$$selection.parent;
  }

  selectionLineLevelStyle() {
    return 'level' + this.sItem.$$selection.parent;
  }

  select() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.toggleSelectionAction(this.sItem.key));
  }

  // == DRAG OF ROW ==
  hideDraggable() {
    const hideDraggable = () => {
      return this.sItem.$$typeConfig.isDraggable === undefined
        ? false
        : !this.sItem.$$typeConfig.isDraggable;
    };

    return this.hideSelect() || hideDraggable();
  }
}

export default {
  template: require('./tocRow.html'),
  controllerAs: 'ctrl',
  bindings: {
    sItem: '<?',
    sHash: '<?'
  },
  controller: tocRow
};
