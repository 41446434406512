import { titleNotEmpty } from '../../validations/titleNotEmpty';
import { coverageNotEmpty } from '../../validations/coverageNotEmpty';
import { mainstructuresOuTypeCombinationsNotEmpty } from '../../validations/mainstructuresOuTypeCombinationsNotEmpty';
import { validationFailedAction } from '../../reduxLoop/actions/documentListActions';
import { globalDocumentDeleteValidationCmd } from '../../reduxLoop/commands/documentListCommands';
import constants from '../../reduxLoop/constants/constants';
import { accessRights } from '../../reduxLoop/helpers/accessRights';
import * as ACTION_TYPES from '@store/constants/actionTypes';

export default {
  information: {
    definiteArticle: true,
    single: 'Gedeeld document',
    plural: 'Gedeelde documenten',
  },
  preloadActions: [
    {
      type: ACTION_TYPES.GET_IS_INCLUDED_IN_PRO_THEME,
    },
  ],
  node: {
    type: 'UNSTRUCTURED_DOCUMENT',
    tags: ['PRO_UNSTRUCTURED_DOCUMENT'],
  },
  createDefaults: { accessRights: accessRights[0].namedsets },
  indentation: 1,
  buildingBlocks: [],
  tocTypes: [],
  createModal: {
    identifier: {},
    attachmentDocumentType: { required: true },
    authors: { addUser: true },
    uploadAttachment: { required: true },
  },
  edit: [
    {
      component: 'title',
      width: 10,
      validations: [titleNotEmpty],
    },
    {
      component: 'identifier',
      width: '2',
      label: 'Referentienummer',
    },
    {
      component: 'description',
      label: 'Inleiding / korte inhoud',
    },
    {
      component: 'downloadTag',
      label: 'Documenttype',
      width: 4,
    },
    {
      component: 'authors',
      label: 'Auteurs',
    },
    {
      component: 'namedSet',
      validations: [mainstructuresOuTypeCombinationsNotEmpty],
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      validations: [coverageNotEmpty],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'accessRights',
      label: 'components.accessRights.descendantsAccessRights',
      options: { field: 'accessRights', readOnly: false },
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  isCreatable: true,
  isSearchable: true,
  isImportable: false,
  customRender: true,
  deleteFromListValidations: [
    {
      cmd: globalDocumentDeleteValidationCmd,
      failAction: validationFailedAction,
    },
  ],
};
