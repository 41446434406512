import { createSelector } from '@reduxjs/toolkit';
import { orderBy } from 'lodash';

import { RootState } from '../../../../types/rootStateTypes';

export const selectIsNewsletterSenderComponentLoading = (state: RootState) =>
  !state.document.viewModel.aside.editDocument.key ||
  state.externalData.newsletterSenders.length === 0;

export const selectNewsletterSenderOptions = createSelector(
  [(state: RootState) => state.externalData.newsletterSenders],
  (newsletterSenders) => {
    return orderBy(newsletterSenders, 'department').map((sender) => ({
      key: sender.key,
      href: sender.$$meta.permalink,
      display: `${sender.department} - ${sender.name} <${sender.email}>`,
    }));
  }
);

export const selectSelectedNewsletterSender = createSelector(
  [
    (state: RootState) => state.document.viewModel.aside.editDocument.newsletterSender?.value.href,
    selectNewsletterSenderOptions,
  ],
  (selectedSenderHref, senderOptions) => senderOptions.find((o) => o.href === selectedSenderHref)
);
