import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../types/rootStateTypes';

export const selectIsAsideReadonly = (state: RootState) =>
  state.document.viewModel.aside.isReadOnly;

export const selectAsideContentKey = (state: RootState) =>
  state.document.viewModel.aside.editDocument.key;

export const GoalRelationsSelector = createSelector(
  [
    (state) => state.document.apiWithPendingChanges,
    (state) => state.document.viewModel?.aside?.editDocument?.key,
  ],
  (api, documentKey) => {
    const relations = api?.contentRelations?.from['/content/' + documentKey] ?? [];
    return relations;
  }
);
