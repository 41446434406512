import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideImportance {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.importances = ['VERY_LOW', 'LOW', 'MEDIUM', 'HIGH', 'VERY_HIGH'];

    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.document.viewModel.aside
      };
    })(this);
  }

  $onInit() {
  }

  change() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(
      this.editDocument.key, { importance: this.editDocument.importance.value }
    ));
  }
}

export default {
  template: require('./asideImportance.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<'
  },
  controller: asideImportance
};
