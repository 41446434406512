import { getUiType } from '../../services/types';
import constants from '../constants/constants';

export const getVersionErrorMsg = (error, items) => {
  const errorsToFormat = ['llinkid.curriculum.not.published', 'new.version.already.exists'];
  if (errorsToFormat.includes(error.code)) {
    const item = items.find((curr) => curr.key === error.key);
    return { message: `versioning.error.${error.code}`, params: { title: item.$$titleAsHtml } };
  }

  return { message: 'versioning.error.generic' };
};

export const isValidSelection = (selectedItems, allowedTypes) => {
  if (!selectedItems) {
    return false;
  }

  return (
    selectedItems.length &&
    selectedItems.length <= constants.maxOperationsAllowed &&
    selectedItems.every((item) => allowedTypes.includes(getUiType(item)))
  );
};

export const allowVersionItemSelected = (selectedItems) => {
  return isValidSelection(selectedItems, [constants.curriculumZill, constants.llinkidCurriculum]);
};

export const isCloneableItemSelected = (selectedItems, allowedTypes) => {
  return isValidSelection(
    selectedItems,
    allowedTypes.map((o) => o.type)
  );
};
