import * as constants from '@store/constants/constants';
import * as DOCUMENT_LIST_ACTIONS from '../../../reduxLoop/actions/documentListActions';
import { attachmentDocumentTypes } from '../../../reduxLoop/constants/attachmentDocumentTypes';
import * as newsletterTypes from '../../../reduxLoop/constants/newsletterTypes';
import { selectUser } from '@newStore/user/userSelectors';

require('./createContent.scss');
const kovsentry = require('../../../app/kovSentry');

export class createContentModal {
  constructor($scope, $ngRedux, $http, $translate, searchApi, contentApi, newsletterApi) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$http = $http;
    this.searchApi = searchApi;
    this.contentApi = contentApi;
    this.newsletterApi = newsletterApi;
    this.attachmentDocumentTypes = attachmentDocumentTypes;
    this.$translate = $translate;

    this.$scope.resourcePickerAuthorTemplate = require('../../../screen/optionTemplates/authorOption.html');
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!state.documentList.createContentModalOpen) {
        this.close();
      }
      if (!state.documentList.importing) {
        kovsentry.endLog('Import Document');
      }

      return {
        loggedUser: selectUser(state),
        createType: state.documentList.createType,
        createConfig: state.documentList.createType.createModal,
        saveAttempted: state.documentList.saveAttempted,
        canBeImported: state.documentList.canBeImported,
        createContentModalOpen: state.documentList.createContentModalOpen,
        importDocx: state.documentList.importDocx,
        uploadAttachment: state.documentList.uploadAttachment,
        newContent: state.documentList.newContent,
        queryAuthorsParameters: state.documentList.createType.createModal?.authors?.queryParams
          ? state.documentList.createType.createModal.authors.queryParams
          : constants.defaultAuthorsParameters,
      };
    })(this);

    if (!this.createConfig || !this.createConfig.authors || this.createConfig.authors.addUser) {
      this.newContent.authors.selected = [this.loggedUser];
    }

    if (this.createConfig && this.createConfig.theme && this.createConfig.theme.filter) {
      this.themesParameters = this.createConfig.theme.filter;
    }

    angular
      .element(document.querySelector('#docx'))
      .on('change', this.handleImportFileSelect.bind(this));
    angular
      .element(document.querySelector('#uploadFile'))
      .on('change', this.handleUploadFileSelect.bind(this));
  }

  clickBrowseForUpload() {
    console.warn('browse clicked');
    angular.element(document.querySelector('#uploadFile')).click();
  }

  clickBrowseForImport() {
    angular.element(document.querySelector('#docx')).click();
  }

  $onDestroy() {
    this.unsubscribe();
  }

  handleImportFileSelect(event) {
    kovsentry.startLog('Import Document', {
      filename: event.currentTarget.files[0],
    });
    this.$ngRedux.dispatch(DOCUMENT_LIST_ACTIONS.readFileToImport(event));
  }

  handleUploadFileSelect(event) {
    kovsentry.startLog('Import Document', {
      filename: event.currentTarget.files[0],
    });
    console.warn('upload trigered', event);
    this.uploadAttachmentError = null;
    this.$ngRedux.dispatch(DOCUMENT_LIST_ACTIONS.readFileToUploadAsAttachment(event));
  }

  titleChanged() {
    if (this.newContent.title) {
      this.titleError = null;
    } else {
      this.titleError = 'De titel is verplicht';
    }
  }

  documentTypeSelected() {
    this.attachmentDocumentTypeError = null;
  }

  changeNewsLetterTemplate(e, item) {
    this.newsLetterTemplateError = null;
    this.newContent.newsLetterTemplate = item;
  }

  changeNewsLetterType(e, item) {
    this.newsLetterTypeError = null;
    this.newContent.newsLetterType = item;
  }

  changeDateToSend(e, item) {
    this.dateToSendError = null;
    this.newContent.dateToSend = new Date(item).toISOString();
  }

  themesSelected(e, model) {
    this.newContent.themes = model.map((t) => t.$$meta.permalink);
  }

  validate() {
    this.titleError = null;
    this.authorsError = null;
    this.attachmentDocumentTypeError = null;
    this.uploadAttachmentError = null;
    this.newsLetterTypeError = null;
    this.dateToSendError = null;

    // the title is always required
    if (!this.newContent.title) {
      this.titleError = 'De titel is verplicht.';
    }

    if (
      this.createConfig &&
      this.createConfig.authors &&
      this.createConfig.authors.required &&
      this.newContent.authors.selected.length === 0
    ) {
      this.authorsError = this.$translate.instant(
        this.createConfig.authors.errorMessageKey || 'createContentModal.authors'
      );
    }
    if (
      this.createConfig &&
      this.createConfig.attachmentDocumentType &&
      this.createConfig.attachmentDocumentType.required &&
      !this.newContent.documentType
    ) {
      this.attachmentDocumentTypeError = 'Kies een document type.';
    }
    if (
      this.createConfig &&
      this.createConfig.uploadAttachment &&
      this.createConfig.uploadAttachment.required &&
      !this.uploadAttachment
    ) {
      this.uploadAttachmentError = 'Kies een bestand om up te loaden.';
    }
    if (
      this.createConfig &&
      this.createConfig.newsLetterTemplate &&
      this.createConfig.newsLetterTemplate.required &&
      !this.newContent.newsLetterTemplate
    ) {
      this.newsLetterTemplateError = 'Kies een niewsbericht template.';
    }
    if (
      this.createConfig &&
      this.createConfig.newsLetterType &&
      this.createConfig.newsLetterType.required &&
      !this.newContent.newsLetterType
    ) {
      this.newsLetterTypeError = 'Kies een niewsbericht type.';
    }
    if (
      this.createConfig &&
      this.createConfig.dateToSend &&
      this.createConfig.dateToSend.required &&
      !this.newContent.dateToSend
    ) {
      this.dateToSendError = 'Kies een verwachte verzenddatum.';
    }
  }

  submit() {
    kovsentry.startLog('Create Document', {
      ...this.newContent,
      type: this.createType.node.type,
    });
    this.validate();
    if (
      this.titleError ||
      this.authorsError ||
      this.attachmentDocumentTypeError ||
      this.uploadAttachmentError ||
      this.newsLetterTypeError ||
      this.dateToSendError
    ) {
      return;
    }
    console.warn('The new content we will create', this.newContent);
    this.$ngRedux.dispatch(
      DOCUMENT_LIST_ACTIONS.validateCreateContentAction(
        {
          ...this.newContent,
          node: this.createType.node,
          webconfiguration: this.createType.webconfiguration,
          createValidations: this.createType.createValidations,
          createDefaults: this.createType.createDefaults,
        },
        this.$http
      )
    );
  }

  cancel() {
    this.$ngRedux.dispatch(DOCUMENT_LIST_ACTIONS.closeCreateContentModal());
  }

  placeholder() {
    let article = 'nieuw';

    if (!this.createType.information.definiteArticle) {
      article = 'nieuwe';
    }

    let text =
      'Voeg een ' + article + ' ' + this.createType.information.single.toLowerCase() + ' toe...';

    return text.replace(' zill ', ' ZILL ');
  }

  author2String(resource) {
    if (resource) {
      if (resource.firstName) {
        return resource.firstName + ' ' + resource.lastName;
      }

      if (resource.$$name) {
        return resource.$$name;
      }

      if (resource.name) {
        return resource.name;
      }
    }

    return '<NONE>';
  }

  theme2String(resource) {
    return resource.title;
  }

  displayNewsletterType(item) {
    if (!item) {
      return '';
    }
    let translationKey = 'create.newsletterType.';
    switch (item.key) {
      case newsletterTypes.subjectSpecific.key:
        translationKey += 'subjectSpecific';
        break;
      case newsletterTypes.thematic.key:
        translationKey += 'thematic';
        break;
      default:
        return '';
    }
    return this.$scope.$parent.$parent.ctrl.$translate.instant(translationKey);
  }
}

export const createContentComponent = {
  template: require('./createContent.html'),
  controllerAs: 'ctrl',
  controller: createContentModal,
  bindings: {
    resolve: '<',
    close: '&',
    dismiss: '&',
  },
};
