module.exports = {
  information: {
    definiteArticle: false,
    single: 'Visietekst',
    plural: 'Visieteksten'
  },
  node: {
    type: 'STRUCTURED_DOCUMENT',
    tags: [
      'VISION_TEXT'
    ]
  },
  createModal: {
    authors: {
      addUser: true
    }
  },
  indentation: 1,
  buildingBlocks: [
    'SECTION',
    'PARAGRAPH'
  ],
  tocTypes: [
    'SECTION'
  ],
  edit: [
    'title',
    'description',
    'issued',
    'authors',
    'contributors',
    {
      component: 'image',
      options: {
        type: 'COVER_IMAGE',
        fields: []
      }
    },
    'themes'
  ],
  asDocumentView: true,
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
};
