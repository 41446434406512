export const coverageNotEmpty = (node, state) => {
  const isRoot = node.key === state.tree.key;
  let invalid;
  if (isRoot) {
    invalid = !node.coverage || node.coverage.length === 0;
  } else {
    invalid = node.coverage && node.coverage.length === 0;
  }

  return !invalid ? true : {
    type: isRoot ? 'ERROR' : 'WARNING',
    message: 'components.coverage.error.noRegionSelected'
  };
};
