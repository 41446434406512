module.exports = {
  information: {
    single: 'Soort',
    plural: 'Soorten'
  },
  node: {
    type: 'CURRICULUM_ODET_LEVEL'
  },
  indentation: 1,
  buildingBlocks: [
    'CURRICULUM_ODET_LEERGEBIED'
  ],
  edit: [
    {
      component: 'identifier',
      width: 2
    },
    {
      component: 'title',
      width: 10
    },
    {
      component: 'description',
      view: {
        hideIfEmpty: true
      }
    }
  ],
  isSearchable: false
};
