import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideSelect {
  constructor($scope, $ngRedux, $translate) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.translationService = $translate;
  }

  $onInit() {
    let component = this;
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const editDocument = state.document.viewModel.aside.editDocument;

      let selected;
      let choices = editDocument['$$' + component.sConfig.component + 'Choices']
        || state.document.selectChoices[component.sConfig.component] || [];

      if (editDocument[component.sConfig.component]) {
        selected = component.sConfig.options.subfield
          ? editDocument[component.sConfig.component].value[component.sConfig.options.subfield]
          : editDocument[component.sConfig.component].value;

        if (choices.length > 0) {
          selected = choices
            .filter(choice => selected.map(s => s.href).includes(choice.$$meta.permalink));
        }
      }

      return {
        clearAllAllowed: component.sConfig.options
          ? component.sConfig.options.clearAllAllowed
          : false,
        selectAllAllowed: component.sConfig.options
          ? component.sConfig.options.selectAllAllowed
          : false,
        choices: choices,
        selected: selected || [],
        readOnly: state.document.viewModel.isReadOnlyDocument,
      };
    })(this);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.initFieldChoicesAction(
      this.sDocument.key,
      this.sConfig.component
    ));
  }

  $onDestroy() {
    this.unsubscribe();
  }

  changed() {
    let patchSelection = this.selected.map(s => ({ href: s.$$meta.permalink }));

    let patch = {};
    let subfield = {};
    if (this.sConfig.options.subfield) {
      subfield[this.sConfig.options.subfield] = patchSelection;
      patch[this.sConfig.component] = subfield;
    } else {
      patch[this.sConfig.component] = patchSelection;
    }

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(this.sDocument.key, patch));
  }

  clearAll() {
    this.selected = [];
    this.changed();
  }

  selectAll() {
    this.selected = this.choices;
    this.changed();
  }

  display(item) {
    if (!item) {
      return '';
    }
    return item.$$grade
      ? `${item.title} (${this.translationService.instant('asideSelect.grade')} ${item.$$grade})`
      : item.title;
  }
}

export default {
  template: require('./asideSelect.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<'
  },
  controller: asideSelect
};
