import { selectUserVmForDocumentList } from '@newStore/user/userSelectors';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideIssued {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const securityVM = selectUserVmForDocumentList(state);
      const isUserAllowedToEdit =
        state.document.mode === 'SUGGESTING' ||
        securityVM.publishedEditables.some(
          (e) =>
            e.node.type === state.document.viewModel.aside.editDocument.$$type ||
            e.node.tags?.includes(state.document.viewModel.aside.editDocument.$$type)
        );

      return {
        issued: {
          ...state.document.viewModel.aside.editDocument.issued,
          key: 'issued',
        },
        editKey: state.document.viewModel.aside.editDocument.key,
        isReadOnly: state.document.viewModel.aside.isReadOnly || !isUserAllowedToEdit,
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  updateIssued(scope, event, newDate) {
    if (newDate) {
      newDate = new Date(newDate).toISOString();
    }

    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(scope.ctrl.editKey, {
        issued: newDate !== '' ? newDate : undefined,
      })
    );
  }
}

export default {
  template: require('./asideIssued.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
  },
  controller: asideIssued,
};
