export const mainstructuresOuTypeCombinationsNotEmpty = (node, state) => {
  const isRoot = node.key === state.tree.key;
  let invalid;
  if (isRoot) {
    invalid = !node.mainstructuresOuTypeCombinations || node.mainstructuresOuTypeCombinations.length === 0;
  } else {
    invalid = node.mainstructuresOuTypeCombinations && node.mainstructuresOuTypeCombinations.length === 0;
  }

  return !invalid ? true : {
    type: isRoot ? 'ERROR' : 'WARNING',
    message: 'components.mainstructuresOuTypeCombinations.error.noValueSelected'
  };
};
