const constants = require('../../../reduxLoop/constants/constants');

module.exports = {
  information: {
    single: 'Videogroep',
    plural: 'Videogroepen',
    ribonIcon: require('../../../../img/buildingBlocks/VIDEO_GROUP.svg')
  },
  node: {
    type: 'VIDEO_GROUP'
  },
  buildingBlocks: [],
  edit: [
    'videoGroup',
    {
      component: 'namedSet',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    },
    {
      component: 'coverage',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    }
  ],
  allowSibling: true,
  isSearchable: false,
  customRender: true,
  hideChildrenInDocument: true
};
