module.exports = {
  information: {
    single: 'Rubriek',
    plural: 'Rubriek',
  },
  node: {
    type: 'LLINKID_GOAL_SECTION',
  },
  edit: [
    {
      component: 'identifier',
      width: 2,
    },
    {
      component: 'title',
      width: 10,
      options: {
        showMarkerButton: true,
      },
    },
    'importance',
    'educationalPointers',
  ],
  indentation: 2,
  buildingBlocks: [
    'LLINKID_GOAL',
    'LLINKID_GOAL_SECTION',
    'PARAGRAPH',
    {
      type: 'LLINKID_STATIC_PART',
      max: 1,
      position: 1,
    },
    {
      type: 'LLINKID_CONCORDANCE',
      max: 1,
      position: 1,
    },
  ],
};
