module.exports = {
  information: {
    single: 'Verwijs Stap (voorgaand)',
    plural: 'Verwijs Stapen (voorgaand)'
  },
  node: {
    type: 'CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE'
  },
  minIndentation: 4,
  edit: [
    {
      component: 'developmentPhase',
      whitelist: [
        {
          parent: {
            specificForAN: true
          }
        }
      ]
    },
    'leerlijnReference'
  ],
  customRender: true,
  rowClass: 'goal-timeline-row',
  collapsedByDefault: true,
  isSearchable: false,
  disableDeleteWhenIssued: true
};
