import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { getResourceKey } from '../../../reduxLoop/helpers/documentHelpers';
import constants from '../../../reduxLoop/constants/constants';

require('./asideReferenceGroup.scss');

const defaultReferenceGroupTypes = [
  constants.referenceGroupTypes.WEBPAGE2,
  constants.referenceGroupTypes.VISION_TEXT,
  constants.referenceGroupTypes.CURRICULUM_ZILL,
  constants.referenceGroupTypes.ZILL_ILLUSTRATION
];

class asideReferenceGroup {
  constructor(ModalWindowService, ConfirmationService, $ngRedux) {
    'ngInject';

    this.modalWindowService = ModalWindowService;
    this.confirmationService = ConfirmationService;
    this.$ngRedux = $ngRedux;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.document.viewModel.aside,
        referenceRelations: state.document.viewModel.aside.editDocument.referenceRelations,
        isReadOnly: state.document.viewModel.aside.isReadOnly
          || state.document.viewModel.aside.editDocument.referenceRelationsTo.some(ref => {
            return ref.proposal && ref.proposal.isSubmitted
              && !state.document.viewModel.suggestions.isReviewing;
          }),
        description: state.document.viewModel.aside.editDocument.description
          && state.document.viewModel.aside.editDocument.description.value,
        referenceGroupTypes: state.document.viewModel.document.$$typeConfig.referenceGroupTypes
          || defaultReferenceGroupTypes
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  async addReference() {
    const opts = {
      component: 'referenceSelectionModal',
      dataForModal: {
        referenceRelations: this.referenceRelations,
        types: this.referenceGroupTypes
      }
    };

    const response = await this.modalWindowService.open(opts);

    if (response) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addReferenceFromNodeAction(
        this.sDocument.key, response.resourceHref, response.description
      ));
    }
  }

  async removeReference(reference) {
    const confirmed = await this.confirmationService.confirmDeletion();
    if (!confirmed) {
      return;
    }
    const refsInNode = this.editDocument.referenceRelations
      .filter(r => r.from.href === reference.from.href);
    if (refsInNode.length === 1) {
      // remove REFERENCE node since this is the last REFERENCES relation
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeNodeAction(
        getResourceKey(reference.from.href), true, this.sDocument.key
      ));
    } else {
      // only remove REFERENCES relation
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeRelationAction(reference.key));
    }
  }

  async removeReferences() {
    const confirmed = await this.confirmationService.confirmDeletion();
    if (!confirmed) {
      return;
    }
    // create map of reference nodes with relations to remove
    const relationsToRemoveMap = this.editDocument.referenceRelations
      .filter(r => r.selected)
      .reduce((relationsMapAcc, relation) => {
        const relations = relationsMapAcc.get(relation.from.href);
        if (relations) {
          relations.push(relation);
        } else {
          relationsMapAcc.set(relation.from.href, [relation]);
        }
        return relationsMapAcc;
      }, new Map());

    relationsToRemoveMap.forEach((relationsToRemove, refNodeHref) => {
      const refsInNode = this.editDocument.referenceRelations
        .filter(r => r.from.href === refNodeHref);
      if (refsInNode.length === relationsToRemove.length) {
        // remove REFERENCE node since all REFERENCES relation will be removed
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeNodeAction(
          getResourceKey(refNodeHref), true, this.sDocument.key
        ));
      } else {
        // only remove REFERENCES relations
        relationsToRemove.forEach((relation) => {
          this.$ngRedux.dispatch(DOCUMENT_ACTIONS.removeRelationAction(relation.key));
        });
      }
    });
  }

  proposalLineStyle(reference) {
    if (reference.proposal) {
      const line = reference.proposal.isSubmitted ? 'submitted' : 'not-submitted';
      const color = reference.proposal.isSameUser ? 'same-user' : 'different-user';
      const isDeleted = reference.proposal.isDeleted ? ' is-deleted' : '';
      const isReviewing = reference.proposal.isReviewingMode ? ' is-reviewing' : '';
      return line + ' ' + color + isDeleted + isReviewing;
    }
    return '';
  }

  hasDeleteProposal(row) {
    return row.proposal && row.proposal.type === 'DELETE';
  }

  changeDescription() {
    if ((this.description && !this.editDocument.description)
      || this.description !== this.editDocument.description.value) {
      this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(this.sDocument.key, {
        description: this.description
      }, true, this.editDocument.$$parent ? this.editDocument.$$parent.key : undefined));
    }
  }

  display(item) {
    if (!item.to.$$expanded) {
      return '';
    }
    const reference = item.to.$$expanded;
    let display = angular.isFunction(item.type.display)
      ? item.type.display(reference)
      : reference[item.type.display];

    // add root title in parentheses
    if (reference.$$root && reference.$$root.title) {
      display += ` (${reference.$$root.title.trim()})`;
    }
    return display;
  }
}

export default {
  template: require('./asideReferenceGroup.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<'
  },
  controller: asideReferenceGroup
};
