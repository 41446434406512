const { themeReferencesWithRootsCmd } = require('../../../reduxLoop/commands/documentCommands');

module.exports = {
  information: {
    single: 'Domein',
    plural: 'Domein\'s'
  },
  node: {
    type: 'DOMAIN'
  },
  indentation: 3,
  buildingBlocks: [
  ],
  edit: [
    'title'
  ],
  isSearchable: false,
  editInModal: false,
  isExternal: true,
  relation: {
    placeholder: 'Domein...',
    filterUrls: [{
      path: '/sam/commons/studyprogrammegroups?type=/sam/commons/studyprogrammegroups/types/f7883b87-ed95-4b13-bc7b-48b0508f1d12'
    }],
    preLoad: true
  },
  deleteValidations: [
    (node) => {
      return {
        cmd: themeReferencesWithRootsCmd,
        params: [node.$$meta.permalink],
        message: 'validation.themeReferenced'
      };
    }
  ],
  confirmDelete: true,
  isUniqueInDienstverleningKov: true,
  themesDisplaySuffix: '(domein)',
};
