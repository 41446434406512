import { reactToAngularComponent } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import StoreProvider from '@store/StoreProvider';
import app from '../app/app';
import AsideGoalReplaces from '@UI/aside/asideGoalReplaces/AsideGoalReplaces';
import AsideGoalRelations from '@UI/aside/asideGoalRelations/AsideGoalRelations';
import AsidePreviousVersionItems from '@UI/aside/asidePreviousVersionItems/AsidePreviousVersionItems';
import EncodedText from '@UI/encodedText/EncodedText';
import AsideCurriculumSelector from '@components/curriculumSelector/AsideCurriculumSelector';
import AsideGoalType from '@UI/aside/asideGoalType/AsideGoalType';
import AsideNewsletterSender from '@UI/aside/asideNewsletterSender/AsideNewsletterSender';

app.component('reactAsideGoalReplaces', reactToAngularComponent(AsideGoalReplaces, StoreProvider));
app.component(
  'reactAsideGoalRelations',
  reactToAngularComponent(AsideGoalRelations, StoreProvider)
);
app.component(
  'reactAsidePreviousVersionItems',
  reactToAngularComponent(AsidePreviousVersionItems, StoreProvider)
);
app.component('reactEncodedText', reactToAngularComponent(EncodedText, StoreProvider));

app.component(
  'reactAsideCurriculumSelector',
  reactToAngularComponent(AsideCurriculumSelector, StoreProvider)
);

app.component('reactAsideGoalType', reactToAngularComponent(AsideGoalType, StoreProvider));

app.component(
  'reactAsideNewsletterSender',
  reactToAngularComponent(AsideNewsletterSender, StoreProvider)
);
