import { Cmd, loop } from 'redux-loop';
import * as ACTION_TYPES from '../constants/actionTypes';
import * as DOCUMENT_ACTIONS from '../actions/documentActions';

/**
 * This is the initial state of this reducer.
 */
const initialState = {
  draggingTypes: []
};

export const documentDragReducer = (state = initialState, action, rootscope) => {
  switch (action.type) {
    case ACTION_TYPES.START_DRAG_NODE: {
      return loop({
        ...state,
        draggingTypes: rootscope.document.viewModel.flat
          .filter(o => rootscope.document.selections.includes(o.key)).map(o => o.$$type)
      },
      Cmd.list([
        Cmd.action(DOCUMENT_ACTIONS.setSelectionAction(action.payload.relationKey, true))
      ]));
    }

    case ACTION_TYPES.STOP_DRAG_NODE: {
      return Object.assign({}, state, { dragging: null });
    }

    case ACTION_TYPES.FINISH_DRAG_NODE: {
      return loop(
        state,
        Cmd.action(DOCUMENT_ACTIONS.clearSelectionsAction())
      );
    }


    default: {
      return state;
    }
  }
};
