import React, { useCallback, useEffect } from 'react';
import { object, string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { GoalRelationsSelector } from '@store/selectors/asideSelectors';
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';
import { formatVersion, getReplacesRelationsHref } from '@store/helpers/documentHelpers';
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import AsideRelationsPanel from '../asideRelationsPanel/AsideRelationsPanel';
import { openGoalRelationsModal } from '@store/helpers/documentAsideHelpers';
import EncodedText from '../../encodedText/EncodedText';

const AsideGoalReplaces = ({ label, options }) => {
  const [$translate, confirmationService] = getAngularService('$translate', 'ConfirmationService');
  const dispatch = useDispatch();
  const relations = useSelector(GoalRelationsSelector);
  const loading = useSelector((state) => !state.document.goalRelationsExpanded);
  const curriculum = useSelector((state) => state.document.viewModel.document);
  const editDocument = useSelector((state) => state.document.viewModel?.aside?.editDocument);
  const previousCurriculumVersion = useSelector(
    (state) => state.document.viewModel?.aside?.previousVersion
  );
  const readOnly = useSelector((state) => {
    return (
      state.document.viewModel?.isReadOnlyDocument ||
      state.document.viewModel?.document?.version?.major === 1
    );
  });

  const replaces = relations.filter((relation) =>
    options.relationTypes.includes(relation.relationtype)
  );

  const onDelete = useCallback(async (item) => {
    const confirmed = await confirmationService.confirmDeletion();
    if (confirmed) {
      dispatch(DOCUMENT_ACTIONS.removeRelationAction(item.key));
    }
  }, []);

  const onCreate = async () => {
    const response = await openGoalRelationsModal({
      asideOptions: options,
      rootKey: editDocument.$$root.key,
      defaultCurriculumKey: previousCurriculumVersion.key,
      relations: replaces,
    });

    if (response) {
      const newRelation = {
        relationtype: options.relationTypes[0],
        from: {
          href: editDocument.$$meta.permalink,
        },
        to: {
          href: response.goal.$$meta.permalink,
        },
      };

      const goalRelationsParams = {
        key: editDocument.key,
        relationTypes: options.relationTypes,
      };

      dispatch(DOCUMENT_ACTIONS.addRelationAction(newRelation, true, true, goalRelationsParams));
    }
  };

  useEffect(() => {
    dispatch(
      DOCUMENT_ACTIONS.expandLlinkidGoalRelationsAction(editDocument.key, options.relationTypes)
    );

    const replacesRelations = getReplacesRelationsHref(curriculum);
    dispatch(DOCUMENT_ACTIONS.loadPreviousVersionAction(curriculum.key, replacesRelations));
  }, []);

  return (
    <AsideRelationsPanel
      title={$translate.instant(label)}
      items={replaces}
      loading={loading}
      readOnly={readOnly}
      onCreate={onCreate}
      onDelete={onDelete}
    >
      {(item) => (
        <>
          <div className="col-md-1 indented">
            <EncodedText>{item.to?.$$expanded?.completeIdentifier}</EncodedText>
          </div>
          <div className="col-md-10 indented">
            <span
              dangerouslySetInnerHTML={{
                __html: item.to?.$$expanded?.description,
              }}
            ></span>
          </div>
          <div className="col-md-1 indented">
            {$translate.instant('components.llinkid.goalRelations.fromVersion', {
              version: formatVersion(item.to?.$$expanded?.$$root?.$$version),
            })}
          </div>
        </>
      )}
    </AsideRelationsPanel>
  );
};

AsideGoalReplaces.propTypes = {
  label: string,
  options: object,
};

export default AsideGoalReplaces;
