import { settings } from '../../config/settings';
import sriClient from '@kathondvla/sri-client/fetch-sri-client';

// NOTE this is not needed, I was testing DI and stub export function
export function initApis(client) {
  return {
    api: client || sriClient({
      baseUrl: settings.apisAndUrls.api
    })
  };
}

export const api = sriClient({
  baseUrl: settings.apisAndUrls.api
});

export const cachedApi = sriClient({
  baseUrl: settings.apisAndUrls.cachedApi
});

export const contentApi = sriClient({
  baseUrl: settings.apisAndUrls.contentApi
});

export const trainingApi = sriClient({
  baseUrl: settings.apisAndUrls.trainingApi
});

export const personsApi = sriClient({
  baseUrl: settings.apisAndUrls.personsApi
});

export const namedSetsApi = sriClient({
  baseUrl: settings.apisAndUrls.namedSetsApi
});

export const securityApi = sriClient({
  baseUrl: settings.apisAndUrls.securityApi
});

export const oldSecurityApi = sriClient({
  baseUrl: settings.apisAndUrls.oldSecurityApi
});

export const cachedVakkenApi = sriClient({
  baseUrl: settings.apisAndUrls.cachedVakkenApi
});

export const websitesApi = sriClient({
  baseUrl: settings.apisAndUrls.websitesApi
});

export const privateStateApi = sriClient({
  baseUrl: settings.apisAndUrls.privateStateApi
});

export const cachedSamenscholingApi = sriClient({
  baseUrl: settings.apisAndUrls.cachedSamenscholingApi
});

export const searchApi = sriClient({
  baseUrl: settings.apisAndUrls.searchApi
});

export const proposalApi = sriClient({
  baseUrl: settings.apisAndUrls.proposalApi
});

export const mailerApi = sriClient({
  baseUrl: settings.apisAndUrls.mailerApi
});

export const newsletterApi = sriClient({
  baseUrl: settings.apisAndUrls.newsletterApi
});

export const endpoints = new Map([
  ['/content', {
    client: contentApi,
    supportsBatch: true
  }],
  ['/events', {
    client: trainingApi,
    supportsBatch: true
  }]
]);

export const getApiFromUrl = (url) => {
  if (url.startsWith('/sam')) {
    return cachedSamenscholingApi;
  }
  if (url.startsWith('/vakken')) {
    return cachedVakkenApi;
  }

  return api;
};
