import { settings } from '../../settings';
import * as ACTION_TYPES from '@store/constants/actionTypes';

export default {
  information: {
    definiteArticle: true,
    single: 'Leerplan basis',
    plural: 'Leerplannen basis',
    icon: require('../../../../img/icons/zill.svg'),
    category: 'LEERPLAN'
  },
  preloadActions: [
    {
      type: ACTION_TYPES.INIT_ZILL_ODET_CURRICULUM_DOCUMENT,
    },
  ],
  node: {
    type: 'CURRICULUM_ZILL',
    tags: [
      'CURRICULUM'
    ]
  },
  createModal: {
    authors: {
      addUser: true
    }
  },
  indentation: 1,
  edit: [
    'title',
    'description',
    'authors',
    'contributors',
    'issued',
    {
      component: 'concordanties',
      options: {
        isRoot: true
      }
    },
    {
      component: 'image',
      label: 'edit.COVER_IMAGE',
      options: {
        type: 'COVER_IMAGE',
        fields: []
      }
    }, {
      component: 'image',
      label: 'edit.THUMBNAIL',
      options: {
        type: 'THUMBNAIL',
        fields: [
          'description',
          'rightsHolder',
          'alt'
        ]
      }
    },
    {
      component: 'image',
      label: 'edit.BACKGROUND_IMAGE',
      options: {
        type: 'BACKGROUND_IMAGE',
        fields: []
      }
    },
    'contentTerms'],
  tocTypes: [
    'CURRICULUM_ZILL_DEVELOPMENT_FIELD',
    'CURRICULUM_ZILL_DEVELOPMENT_THEME'
  ],
  buildingBlocks: [
    'CURRICULUM_ZILL_CLUSTER'
  ],
  asDocumentView: true,
  isCreatable: true,
  isSearchable: true,
  previewModes: [
    {
      type: 'URL',
      name: 'Zill Leerplansite',
      location: settings.apisAndUrls.zill + '/#!/leerinhoud?preview&version=/content/{%key}'
    },
    {
      type: 'URL',
      name: 'Zill Selectietool',
      location: settings.apisAndUrls.zillSelector + '/#/?preview&version=/content/{%key}'
    }
  ]
};
