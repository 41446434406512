
class FacetModalForm {
  constructor($notification, $translate, contentApi) {
    'ngInject';

    this.notificationService = $notification;
    this.translationService = $translate;
    this.contentApi = contentApi;
    this.resourcePickerTemplate = require('../../../../screen/optionTemplates/themeOption.html');
  }

  isValidFacet() {
    if (!this.facet.label || this.facet.label.trim() === '') {
      this.notificationService.error(this.translationService.instant('components.proWebsite.facetModalForm.error.invalidLabel'));
      return false;
    }

    if (!this.facet.component) {
      this.notificationService.error(this.translationService.instant('components.proWebsite.facetModalForm.error.invalidComponent'));
      return false;
    }

    if (this.facet.component === 'SELECT_FROM_REFERENCE_FRAME' && !this.facet.source) {
      this.notificationService.error(this.translationService.instant('components.proWebsite.facetModalForm.error.invalidSource'));
      return false;
    }

    return true;
  }

  theme2String(t) {
    return t ? t.title : '<NONE>';
  }

  submit() {
    if (this.isValidFacet()) {
      this.modalInstance.close(this.facet);
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  async $onInit() {
    this.facet = this.resolve.modalData.facet;
    this.componentTypes = this.resolve.modalData.componentTypes;
  }
}

module.exports = {
  template: require('./facetModalForm.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<'
  },
  controller: FacetModalForm
};
