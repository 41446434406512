module.exports = {
  information: {
    single: 'Eindterm',
    plural: 'Eindtermen'
  },
  node: {
    type: 'CURRICULUM_ODET_ENDTERM'
  },
  indentation: 4,
  rowClass: 'goal-row',
  edit: [
    {
      component: 'identifier',
      width: 2
    },
    {
      component: 'title',
      width: 10
    },
    {
      component: 'description',
      view: {
        hideIfEmpty: true
      }
    },
    'goalIdentifier'
  ],
  isSearchable: false,
  documentViewIdentifier: 'o(p*)(.)i'
};
