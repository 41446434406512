class NewsletterSender {
  constructor() {
    'ngInject';
  }
}

export default {
  template: require('./newsletterSender.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
  },
  controller: NewsletterSender,
};
