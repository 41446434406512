export const selectOneTypeOfDocument = (node) => {
  return node.$$children.length > 0 &&
    node.$$children.some(children =>
      children.$$type === 'GLOBAL_DOCUMENT' ||
      children.$$type === 'ATTACHMENT'
    )
    ? true
    : {
      type: 'ERROR',
      message: 'labels.download.selectOneTypeOfDocument'
    }
};
