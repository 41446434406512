class rowLeerlijnCluster {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
  }

  $onChanges() {
  }

  $onDestroy() {
  }
}

export default {
  template: require('./rowLeerlijnCluster.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<'
  },
  controller: rowLeerlijnCluster
};
