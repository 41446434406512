module.exports = {
  information: {
    definiteArticle: true,
    single: 'Decretal leerplan',
    plural: 'Decretale leerplannen',
    icon: require('../../../../img/icons/odet.svg'),
    category: 'LEERPLAN'
  },
  node: {
    type: 'CURRICULUM_ODET',
    tags: [
      'CURRICULUM'
    ]
  },
  indentation: 1,
  buildingBlocks: [
    'CURRICULUM_ODET_LEVEL'
  ],
  tocTypes: [
    'CURRICULUM_ODET_LEVEL',
    'CURRICULUM_ODET_LEERGEBIED'
  ],
  edit: [
    {
      component: 'identifier',
      width: 2
    },
    {
      component: 'title',
      width: 10
    },
    'description',
    'issued'
  ],
  asDocumentView: true,
  isSearchable: true,
};
