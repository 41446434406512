/* eslint-disable no-restricted-syntax */
import { securityApi, contentApi } from '../api/apiConfig';

export const loadAllowedEditNodeCmd = async (nodeKey, loggedUserKey) => {
  const allowedResources = await securityApi.get('/security/query/resources/raw', {
    person: `/persons/${loggedUserKey}`,
    component: '/security/components/content-api',
    ability: 'update',
  });

  const isPartOfResources = await contentApi.post('/content/isPartOf', {
    a: { href: `/content/${nodeKey}` },
    b: {
      hrefs: allowedResources.filter((r) => /^\/content(\?.+)?$/.test(r)),
    },
  });

  return isPartOfResources.length > 0;
};
