import { titleNotEmpty } from '../../validations/titleNotEmpty';
import { createNewsLetterTemplateValidationCmd } from '../../reduxLoop/commands/documentListCommands';
import { validationFailedAction as validationFailedAction } from '../../reduxLoop/actions/documentListActions';
import { settings } from '../settings';
import constants from '../../reduxLoop/constants/constants';
import { coverageNotEmpty } from '../../validations/coverageNotEmpty';
import { mainstructuresOuTypeCombinationsNotEmpty } from '../../validations/mainstructuresOuTypeCombinationsNotEmpty';
import { positionsNotEmpty } from '../../validations/positionsNotEmpty';
import { pathIsNotReserved } from '../../validations/pathIsNotReserved';
import { websiteUniqueWithinNode } from '../../validations/websiteUniqueWithinNode';
import { urlUniqueApiWithinApi } from '../../validations/urlUniqueWithinApi';
import { urlUniqueWithinDocument } from '../../validations/urlUniqueWithinDocument';
import { newsletterSenderNotEmpty } from '../../validations/newsletterSenderNotEmpty';
import { getNewsletterSenders } from '@newStore/externalData/externalDataState';

export default {
  information: {
    definiteArticle: true,
    single: 'PRO.-nieuwsbriefsjabloon',
    plural: 'PRO.-nieuwsbriefsjablonen',
    icon: require('../../../img/icons/document.svg'),
    category: 'PRO',
  },
  node: {
    type: 'STRUCTURED_DOCUMENT',
    tags: ['PRONEWSLETTERTEMPLATE'],
  },
  createDefaults: { newsletterSender: { href: constants.dienstCommunicatieSender } },
  createModal: {
    authors: { addUser: true },
    newsLetterType: { required: true },
  },
  preloadActions: [getNewsletterSenders()],
  indentation: 1,
  buildingBlocks: ['SECTION', 'PARAGRAPH', 'IMAGE', 'REFERENCE_GROUP'],
  referenceGroupTypes: [constants.referenceGroupTypes.TRAINING],
  edit: [
    {
      component: 'title',
      validations: [titleNotEmpty],
    },
    {
      component: 'newsletterSender',
      label: 'components.newsletterSender.label',
      validations: [newsletterSenderNotEmpty],
    },
    {
      component: 'tableOfContents',
      type: 'checkbox',
    },
    {
      component: 'namedSet',
      validations: [mainstructuresOuTypeCombinationsNotEmpty],
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      tab: constants.asideTabs.AUDIENCE,
      validations: [coverageNotEmpty],
    },
    {
      component: 'namedSet',
      label: 'components.functions.title',
      validations: [positionsNotEmpty],
      tag: 'doelgroepen',
      property: 'positions',
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  isCreatable: true,
  isSearchable: true,
  isImportable: false,
  allowSuggestions: false,
  customRender: false,
  createValidations: [
    {
      cmd: createNewsLetterTemplateValidationCmd,
      failAction: validationFailedAction,
    },
  ],
  websiteEdition: [{ domain: settings.domains.www }, { domain: settings.domains.pro }],
  websiteValidations: [
    pathIsNotReserved,
    websiteUniqueWithinNode,
    urlUniqueApiWithinApi,
    urlUniqueWithinDocument,
  ],
};
