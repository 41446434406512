module.exports = {
  information: {
    single: 'Veld',
    plural: 'Velden'
  },
  node: {
    type: 'CURRICULUM_ZILL_DEVELOPMENT_FIELD',
    attachments: [
      {
        type: 'CONTENT',
        name: 'content.html',
        contentType: 'text/html'
      }
    ]
  },
  indentation: 1,
  edit: [
    {
      component: 'identifier',
      width: 2
    },
    {
      component: 'title',
      width: 10
    },
    'description',
    {
      component: 'attachedContent',
      label: 'Lees meer',
      view: {
        hideIfEmpty: true
      }
    },
    'authors',
    'contributors',
    {
      component: 'image',
      label: 'edit.ICON',
      options: {
        type: 'ICON',
        fields: []
      }
    },
    'color',
    {
      component: 'image',
      label: 'edit.COVER_IMAGE',
      options: {
        type: 'COVER_IMAGE',
        fields: []
      }
    },
    {
      component: 'image',
      label: 'edit.THUMBNAIL',
      options: {
        type: 'THUMBNAIL'
      }
    },
    {
      component: 'image',
      label: 'edit.BACKGROUND_IMAGE',
      options: {
        type: 'BACKGROUND_IMAGE',
        fields: []
      }
    },
    'contentTerms',
    'video'
  ],
  allowSibling: true,
  buildingBlocks: [
    'CURRICULUM_ZILL_DEVELOPMENT_THEME'
  ],
  documentViewIdentifier: 'i',
  isSearchable: false,
  disableDeleteWhenIssued: true
};
