import { contentApi } from '../../../reduxLoop/api/apiConfig';
import { getKeyFromContentHref } from '../../../reduxLoop/helpers/documentHelpers';

class rowEducationalReferences {
  constructor(settings, constants, $ngRedux) {
    'ngInject';

    this.settings = settings;
    this.constants = constants;
    this.$ngRedux = $ngRedux;

    this.loading = true;

    this.strengthSizes = {
      LOW: '25',
      MEDIUM: '30',
      HIGH: '38'
    };
  }

  imageSource(reference) {
    if (reference.attachments.length > 0) {
      return this.settings.apisAndUrls.contentApi + reference.attachments[0].href;
    }

    return '';
  }

  size(reference) {
    if (this.sReferenceFrame === 'EDUCATIONAL_COMPONENTS' && reference.strength) {
      return this.strengthSizes[reference.strength];
    }
    return '30';
  }

  $onChanges() {
    this.setReferences();
  }


  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const relationsTo = { ...state.document.apiWithPendingChanges.contentRelations.to };
      let referencesRelations = relationsTo['/content/' + this.sItem.key]
        ? relationsTo['/content/' + this.sItem.key].filter(r => r.relationtype === 'REQUIRES')
        : [];

      return {
        referencesRelations: referencesRelations
      };
    })(this);

    return (async () => {
      // load references of loaded llinkid document
      const referenceConfig = this.constants.llinkidReferenceFrames
        .find(rf => rf.type === this.sReferenceFrame);

      this.referenceFrameNodes = await contentApi.getAll('/content', {
        root: '/content/' + referenceConfig.key,
        type: 'THEME'
      });

      this.referenceFrameNodes = this.referenceFrameNodes.reduce((nodes, node) => {
        nodes[node.key] = node;
        return nodes;
      }, []);

      this.referencesTitle = referenceConfig.title;
      this.setReferences();
      this.loading = false;
    })();
  }

  setReferences() {
    if (this.referencesRelations && this.referenceFrameNodes) {
      this.references = this.referencesRelations
        .filter(relation => {
          const fromKey = getKeyFromContentHref(relation.from.href);
          return this.referenceFrameNodes[fromKey];
        }).map(relation => {
          const fromKey = getKeyFromContentHref(relation.from.href);
          const reference = this.referenceFrameNodes[fromKey];
          reference.strength = relation.strength;
          return reference;
        });
    }
  }
}

export const rowEducationalReferencesComponent = {
  template: require('./rowEducationalReferences.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<',
    sReferenceFrame: '@'
  },
  controller: rowEducationalReferences
};
