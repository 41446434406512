const moment = require('moment');
const apiConfig = require('../api/apiConfig');
const organisationalUnits = require('./organisationalUnits');

const dienstverleningKovKey = 'dfa63c53-a63c-4f61-90bd-6ab13644c538';

module.exports = { //eslint-disable-line
  curriculumRootTypes: ['CURRICULUM_ZILL', 'CURRICULUM_ODET'],
  curriculumZill: 'CURRICULUM_ZILL',
  structuredType: 'STRUCTURED_DOCUMENT',
  visionText: 'VISION_TEXT',
  begripType: 'TERM',
  blocksection: 'SECTION',
  practicalExampleType: 'PRACTICAL_EXAMPLE',
  webpageType: 'WEBPAGE',
  proWebsiteTag: 'WEBPAGE2',
  musicType: 'MUSIC',
  attachmentsGroupType: 'ATTACHMENTS_GROUP',
  attachmentType: 'ATTACHMENT',
  imageType: 'IMAGE',
  illustrationType: 'ILLUSTRATION',
  videoType: 'VIDEO',
  quoteType: 'QUOTE',
  videoGroupType: 'VIDEO_GROUP',
  imagesGroupType: 'IMAGE_GROUP',
  llinkidTypes: [
    'LLINKID_BASIC_MATERIAL_REQUIREMENTS',
    'LLINKID_CONCORDANCE',
    'LLINKID_CONTEXT',
    'LLINKID_CORRELATION_ENDTERMS',
    'LLINKID_CURRICULUM',
    'LLINKID_GOAL',
    'LLINKID_GOAL_CONTENT',
    'LLINKID_GOAL_DEMARCATION',
    'LLINKID_GOAL_LIST',
    'LLINKID_GOAL_SECTION',
    'LLINKID_GUIDING_PRINCIPLE',
    'LLINKID_GUIDING_PRINCIPLE_GROUP',
    'LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT',
    'LLINKID_PEDAGOGICAL_TIP',
    'LLINKID_EXTRA_GOAL_INFORMATION',
    'LLINKID_GOAL_EXPLANATION',
    'LLINKID_INITIAL_SITUATION',
    'LLINKID_STATIC_PART',
    'PARAGRAPH',
    'TERM',
  ],
  curriculumTypes: ['CURRICULUM_ZILL', 'CURRICULUM_ZILL_CLUSTER', 'CURRICULUM_ZILL_DEVELOPMENT_FIELD',
    'CURRICULUM_ZILL_DEVELOPMENT_THEME', 'CURRICULUM_ZILL_GENERIC_GOAL',
    'CURRICULUM_ZILL_CONTENT_GROUP', 'CURRICULUM_ZILL_DEVELOPMENT_CONTENT',
    'CURRICULUM_ZILL_LEERLIJN', 'CURRICULUM_ZILL_LEERLIJN_BUITEWOON', 'CURRICULUM_ZILL_LEERLIJN_CLUSTER'],
  curriculumHiddenTypes: ['CURRICULUM_ZILL_CONTENT_GROUP', 'CURRICULUM_ZILL_DEVELOPMENT_CONTENT',
    'CURRICULUM_ZILL_LEERLIJN', 'CURRICULUM_ZILL_LEERLIJN_BUITEWOON', 'CURRICULUM_ZILL_LEERLIJN_CLUSTER',
    'CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE', 'CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE'],
  odetCurriculumTypes: ['CURRICULUM_ODET', 'CURRICULUM_ODET_LEVEL', 'CURRCICULUM_ODET_LEERGEBIED',
    'CURRICULUM_ODET_SUBSECTION', 'CURRICULUM_ODET_DEVELOPMENT_GOAL', 'CURRICULUM_ODET_ENDTERM',
    'CURRICULUM_ODET_CONTENT_GROUP', 'CURRICULUM_ODET_CONTENT'],
  odetCurriculumRootType: 'CURRICULUM_ODET',
  odetConcordantiesTypes: ['CURRICULUM_ODET_ENDTERM', 'CURRICULUM_ODET_DEVELOPMENT_GOAL'],
  goalType: 'CURRICULUM_ZILL_GENERIC_GOAL',
  goalChildrenTypes: ['CURRICULUM_ZILL_DEVELOPMENT_CONTENT', 'CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE',
    'CURRICULUM_ZILL_LEERLIJN_CLUSTER', 'CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE', 'CURRICULUM_ZILL_LEERLIJN'],
  goalContentGroupType: 'CURRICULUM_ZILL_CONTENT_GROUP',
  goalContentType: 'CURRICULUM_ZILL_DEVELOPMENT_CONTENT',
  timelineGroupType: 'CURRICULUM_ZILL_LEERLIJN',
  timelineClusterType: 'CURRICULUM_ZILL_LEERLIJN_CLUSTER',
  timelineTypes: ['CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE', 'CURRICULUM_ZILL_LEERLIJN_CLUSTER',
    'CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE'],
  collapsableTypes: ['CURRICULUM_ZILL_DEVELOPMENT_CONTENT', 'CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE',
    'CURRICULUM_ZILL_LEERLIJN_CLUSTER', 'CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE'],
  footNoteType: 'SOURCE',
  exampleType: 'EXAMPLE',
  relationTypes: ['IS_PART_OF', 'IS_VERSION_OF', 'REFERENCES', 'REPLACES', 'REQUIRES'],
  attachmentTypes: ['COVER_IMAGE', 'THUMBNAIL', 'ICON', 'ATTACHMENT'],
  typesToSearch: ['STRUCTURED_DOCUMENT', 'WEBPAGE', 'TERM', 'CURRICULUM_ZILL',
    'CURRICULUM_ODET', 'REFERENCE_FRAME'],
  tagsToSearch: ['WEBPAGE', 'VISION_TEXT', 'PRACTICAL_EXAMPLE'],
  buitengewoon: 'buitengewoon',
  gewoon: 'gewoon',
  educationSortGewoonKey: '0c739f2c-2acf-11e5-937b-00ff001a7a08',
  educationSortBuitegewoonKey: '0c739f38-2acf-11e5-937b-00ff001a7a08',
  excludedTypesToSearch: ['CURRCICULUM_ODET_LEERGEBIED', 'CURRICULUM_ODET_DEVELOPMENT_GOAL', 'CURRICULUM_ODET_ENDTERM',
    'CURRICULUM_ODET_LEERGEBIED', 'CURRICULUM_ODET_LEVEL', 'CURRICULUM_ODET_SUBSECTION', 'CURRICULUM_ZILL_CLUSTER',
    'CURRICULUM_ZILL_CONTENT_GROUP', 'CURRICULUM_ZILL_DEVELOPMENT_CONTENT', 'CURRICULUM_ZILL_DEVELOPMENT_FIELD',
    'CURRICULUM_ZILL_DEVELOPMENT_THEME', 'CURRICULUM_ZILL_GENERIC_GOAL', 'CURRICULUM_ZILL_LEERLIJN',
    'CURRICULUM_ZILL_LEERLIJN_BUITEWOON', 'CURRICULUM_ZILL_LEERLIJN_CLUSTER', 'CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE',
    'CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE', 'EXAMPLE', 'HORIZONTAL_RULE', 'IMAGE', 'PARAGRAPH',
    'QUESTION', 'REFERENCE', 'REFERENCE_GROUP', 'SECTION', 'THEME', 'VISION_TEXT',
    'VISION_TEXT_PARAGRAPH', 'VISION_TEXT_SECTION'],
  llinkidReferenceFrames: [
    { type: 'EDUCATIONAL_COMPONENTS', key: '8669afe3-aa83-437b-b624-742e93e8de5a', title: 'Vormingscomponenten' },
    { type: 'EDUCATIONAL_POINTERS', key: '8f39910a-5db7-4306-9443-74a318aba0da', title: 'Wegwijzers' }
  ],
  llinkidCurriculum: 'LLINKID_CURRICULUM',
  llinkidGoalType: 'LLINKID_GOAL',
  llinkidGoalTypes: ['REGULAR', 'DEEPENING', 'EXTRA', 'CHOICE'],
  curriculumOdetDevelopmentGoalType: 'CURRICULUM_ODET_DEVELOPMENT_GOAL',
  llinkidGoalDemarcationType: 'LLINKID_GOAL_DEMARCATION',
  dropZoneType: 'DROP_ZONE',
  sharedMiniDatabaseItem: 'SHARED_MINI_DATABASE_ITEM',
  downloadTags: {
    MODEL: 'model',
    PRESENTATION: 'presentatie',
    REPORT: 'verslag'
  },
  teaserImportances: ['LOW', 'MEDIUM', 'HIGH'],
  teaserLinkOptions: {
    NOT_LINKED: { name: 'NOT_LINKED', translation: 'notLinked' },
    WEBPAGE2: {
      name: 'WEBPAGE2', translation: 'webPage', sriClient: apiConfig.contentApi, resource: '/content', resourceFilter: { typeIn: 'STRUCTURED_DOCUMENT', tagsOverlaps: 'WEBPAGE2' }
    },
    SHARED_MINI_DATABASE_ITEM: {
      name: 'SHARED_MINI_DATABASE_ITEM',
      translation: 'sharedMiniDatabaseItem',
      sriClient: apiConfig.contentApi,
      resource: '/content',
      resourceFilter: {
        typeIn: 'SHARED_MINI_DATABASE_ITEM',
        tagsOverlaps: 'SHARED_MINI_DATABASE_ITEM',
        issuedBefore: new Date().toISOString(),
        orderBy: '$$meta.created',
        descending: true,
      },
    },
    JOB_OFFER: {
      name: 'JOB_OFFER', translation: 'jobOpening', sriClient: apiConfig.contentApi, resource: '/content', resourceFilter: { typeIn: 'STRUCTURED_DOCUMENT', tagsOverlaps: 'JOB_OFFER' }
    },
    EVENT: {
      name: 'EVENT',
      translation: 'event',
      sriClient: apiConfig.trainingApi,
      resource: '/training/modules',
      resourceFilter: {
        'enrollmentPeriod.endDateAfter': (new Date()).toISOString(), sourceModuleIsNull: true, private: 'false', orderBy: '$$meta.created', descending: true
      }
    },
    PRONEWSITEM: {
      name: 'PRONEWSITEM', translation: 'newsItem', sriClient: apiConfig.contentApi, resource: '/content', resourceFilter: { typeIn: 'STRUCTURED_DOCUMENT', tagsOverlaps: 'PRONEWSITEM' }
    },
    EXTERNAL_URL: { name: 'EXTERNAL_URL', translation: 'externalUrl' }
  },
  needRootResource: ['SECTION'],
  menuItemWebconfigurations: ['HOME_PAGE', 'FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3'],
  homepageOnlyWebconfigurations: ['FALLBACK_MENU_LEVEL_2', 'FALLBACK_MENU_LEVEL_3'],
  updateChildWebconfigurations: [
    { code: 'BLOG', childType: 'BLOG_ITEM', childTemplate: '/web/templates/35213fa8-b26b-7ace-92ad-180e5cd0a37c' },
    { code: 'MINI_DATABASE', childType: 'MINI_DATABASE_ITEM', childTemplate: '/web/templates/21363fa8-c06b-4ace-92ad-180e5cd0c41a' }
  ],
  referenceGroupTypes: {
    WEBPAGE2: {
      title: 'Een webpagina/tegel/deel van een pagina', icon: require('../../../img/icons/webpage.svg'), sriClient: apiConfig.contentApi, resource: '/content', masterResourceFilter: { typeIn: 'STRUCTURED_DOCUMENT', tagsOverlaps: 'WEBPAGE2' }, resourceFilter: { typeIn: 'SECTION' }, display: 'title'
    },
    VISION_TEXT: {
      title: 'Visietekst', icon: require('../../../img/icons/webpage.svg'), sriClient: apiConfig.contentApi, resource: '/content', masterResourceFilter: { typeIn: 'STRUCTURED_DOCUMENT', tagsOverlaps: 'VISION_TEXT' }, resourceFilter: { typeIn: 'SECTION' }, display: 'title'
    },
    CURRICULUM_ZILL: {
      title: 'Leerplandoel Zill', icon: require('../../../img/icons/zill.svg'), sriClient: apiConfig.contentApi, resource: '/content', masterResourceFilter: { typeIn: 'CURRICULUM_ZILL' }, resourceFilter: { typeIn: 'CURRICULUM_ZILL_DEVELOPMENT_FIELD, CURRICULUM_ZILL_DEVELOPMENT_THEME, CURRICULUM_ZILL_GENERIC_GOAL' }, display: 'title'
    },
    ZILL_ILLUSTRATION: {
      title: 'Praktijkvoorbeeld Zill', icon: require('../../../img/icons/practicalExample.svg'), sriClient: apiConfig.contentApi, resource: '/content', resourceFilter: { typeIn: 'STRUCTURED_DOCUMENT', tagsOverlaps: 'PRACTICAL_EXAMPLE' }, display: 'title'
    },
    LLINKID_CURRICULUM: {
      title: 'Leerplan LLINKID', icon: require('../../../img/icons/llinkid.svg'), sriClient: apiConfig.contentApi, resource: '/content', resourceFilter: { typeIn: 'LLINKID_CURRICULUM' }, display: 'title'
    },
    LLINKID_GOAL: {
      title: 'Leerplandoel LLINKID', icon: require('../../../img/icons/llinkid.svg'), sriClient: apiConfig.contentApi, resource: '/content', masterResourceFilter: { typeIn: 'LLINKID_CURRICULUM' }, resourceFilter: { typeIn: 'LLINKID_GOAL' }, display: 'description'
    },
    FAQ: {
      title: 'Veelgestelde vraag (FAQ)', icon: require('../../../img/buildingBlocks/FAQ.svg'), sriClient: apiConfig.contentApi, resource: '/content', resourceFilter: { typeIn: 'FAQ' }, display: 'title'
    },
    EXTERNAL_URL: { title: 'Een externe URL', icon: require('../../../img/icons/webpage.svg') },
    TEASER: {
      title: 'Teaser', icon: require('../../../img/buildingBlocks/TEASER.svg'), sriClient: apiConfig.contentApi, resource: '/content', resourceFilter: { typeIn: 'TEASER' }, display: 'title'
    },
    PERSON: {
      title: 'Persoon', icon: require('../../../img/icons/webpage.svg'), sriClient: apiConfig.personsApi, resource: '/persons', display: 'username'
    },
    TRAINING: {
      title: 'Training',
      icon: require('../../../img/icons/webpage.svg'),
      sriClient: apiConfig.trainingApi,
      resource: '/events',
      display: (item) => item && `${item.summary} - ${moment(item.startDate).format('DD/MM/YYYY HH:mm')}`,
      resourceFilter: {
        type: 'TRAINING', publicAndNotFull: true, firstOfSeries: true, startDateAfter: new Date().toISOString()
      }
    },
    WWW_NEWS_ITEM: {
      title: 'Nieuws', icon: require('../../../img/icons/webpage.svg'), sriClient: apiConfig.contentApi, resource: '/content', resourceFilter: { typeIn: 'STRUCTURED_DOCUMENT', tagsOverlaps: 'NEWS_ITEM' }, display: 'title'
    },
    WWW_CURRENT_THEME: {
      title: 'Actuele thema', icon: require('../../../img/icons/webpage.svg'), sriClient: apiConfig.contentApi, resource: '/content', resourceFilter: { typeIn: 'STRUCTURED_DOCUMENT', tagsOverlaps: 'WWW_CONTENT' }, display: 'title'
    }
  },
  asideTabs: {
    METADATA: 'METADATA',
    AUDIENCE: 'AUDIENCE'
  },
  privateStateVersions: {
    current: 1,
    collapseByDefault: 1
  },
  collapseByDefaultChildsUnderTypes: ['BLOG', 'MINI_DATABASE'],
  reservedSuffixs: ['/zoeken', '/nieuwsoverzicht', '/professionalisering', '/localnews', '/newsDetail'],
  reservedPaths: ['/view-options'],
  reservedCharacters: /[<>%{}|\\^`:?#[\]@!$&()*+,;="]/,
  defaultAuthorsParameters: { // for the authors autocomplete search query
    expand: 'FULL',
    types: 'person,organisational_unit',
    organisationalUnitTypes: 'KATHONDVLA_DEPARTMENT,VERGADERGROEP,LEERPLANCOMMISSIE,BELEIDSORGAAN,PROJECTGROEP',
    limit: 30,
    highlight: 'false'
  },
  // All persons and organisational units
  allAuthorsParameters: {
    expand: 'FULL',
    types: 'person,organisational_unit',
    organisationalUnitTypes: 'KATHONDVLA_DEPARTMENT,VERGADERGROEP,LEERPLANCOMMISSIE,BELEIDSORGAAN,PROJECTGROEP,SCHOOLENTITY,LERARENNETWERK',
    limit: 30,
    highlight: 'false',
  },
  jobofferAuthorsParameters: { // for the joboffer authors autocomplete search query
    expand: 'FULL',
    types: 'organisational_unit',
    searchTags: 'KATHONDVLA_DEPARTMENT,PRIMARY_SCHOOLENTITY,SECONDARY_SCHOOLENTITY,CVO,GRADUATE_SCHOOL,UNIVERSITY,GOVERNINGINSTITUTION,ANDERE,CLB,BOARDING',
    limit: 30,
    highlight: 'false',
    onderwijsKoepel: organisationalUnits.kathOndVla.href
  },
  dienstverleningKovKey,
  queryThemesParameters: {
    // for the themes autocomplete search query
    root: dienstverleningKovKey,
    typeIn: [
      'THEME',
      'BASIC_OPTION',
      'DOMAIN',
      'COMMONS_STUDIEGEBIED',
      'COMMONS_STUDY_PROGRAMME',
      'COMMONS_SUBJECT',
      'CURRICULUM_THEME',
    ],
  },
  newsHashKey: '6c48604b-1549-410c-9c81-5467f239800d',
  maxOperationsAllowed: 5,
  contentTypesKey: 'ac1047fe-8a92-4cd0-9975-b40f2bae4ed1',
  faqCategoriesKey: '89532e00-4227-48b3-8133-1a9a67b4cade',
  sectionCurrVakkenEnLeerplannen: '2b254a9a-9774-4869-81fc-133d95cb8568',
  sectionCurrVakkenEnLeerplannenNieuw: 'e7c8b4bc-e77e-48f4-b1e8-11c26965fe9f',
  dienstCommunicatieSender: '/newsletter/senders/43e326eb-f945-4337-8b6a-2cf9ff843cb1',
};
