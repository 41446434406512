export const config = {
  curriculumZill: 'CURRICULUM_ZILL',
  llinkidCurriculum: 'LLINKID_CURRICULUM',
  llinkidReferenceFrames: [
    {
      field: 'educationalComponents', type: 'EDUCATIONAL_COMPONENTS', key: '8669afe3-aa83-437b-b624-742e93e8de5a', title: 'Vormingscomponenten'
    },
    {
      field: 'educationalPointers', type: 'EDUCATIONAL_POINTERS', key: '8f39910a-5db7-4306-9443-74a318aba0da', title: 'Wegwijzers'
    }
  ],
  llinkidOdetCurriculumKey: '4552c2bd-4ff3-4ec5-8457-3d7b3d3106ad',
  prothema: 'WEBPAGE2',
  globalDocument: 'GLOBAL_DOCUMENT',
  downloadWebconfigurationTypes: ['DOWNLOAD_PAGE', 'TEMPORARY_PAGE'],
  globalAttachmentsGroupWebconfigurationSelfTypes: [
    'THEME_PAGE',
    'CURRICULUM_PAGE',
    'MINI_DATABASE_ITEM',
  ],
  globalAttachmentsGroupWebconfigurationParentTypes: ['BLOG', 'MINI_DATABASE'],
  referenceFrameExternalTypes: {
    DOMAIN: ['/sam/commons/studyprogrammegroups/types/f7883b87-ed95-4b13-bc7b-48b0508f1d12'],
    SUBJECT: [],
    STUDY_AREA: [],
    STUDY_DIRECTION: [],
    BASIC_OPTION: ['/sam/commons/studyprogrammegroups/types/c75a5d42-0dd9-4781-a11a-73f525f470fd', '/sam/commons/studyprogrammes/types/78ff839b-598b-4e80-ba69-2c6b7d087cbb']
  }
};
