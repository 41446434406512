export default {
  information: {
    definiteArticle: false,
    single: 'Markeerverklaring',
    plural: 'Markeerverklaringen',
    icon: require('../../../img/icons/mark-explanation.svg')
  },
  node: {
    type: 'MARK_EXPLANATION',
    tags: [
      'MARK_EXPLANATION'
    ]
  },
  createModal: {
    authors: {
      addUser: true
    }
  },
  buildingBlocks: [],
  edit: [
    'title',
    'description',
    'issued'
  ],
  asDocumentView: true,
  isCreatable: true,
  isSearchable: true,
};
