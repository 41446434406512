module.exports = {
  information: {
    single: 'Leerlijn Gewoon',
    plural: 'Leerlijn Gewoon'
  },
  node: {
    type: 'CURRICULUM_ZILL_LEERLIJN',
    educationsorts: [
      '/onderwijssoorten/0c739f2c-2acf-11e5-937b-00ff001a7a08'
    ],
    specificForAN: false
  },
  buildingBlocks: [
    'CURRICULUM_ZILL_LEERLIJN_CLUSTER',
    'CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE',
    'CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE'
  ],
  showPlaceholder: true,
  indentation: 1,
  collapsedByDefault: true,
  isSearchable: false,
  disableDeleteWhenIssued: true
};
