import { isExternalLinkHttp, isResourceSupported } from '../reduxLoop/helpers/documentHelpers';

export const isMenuItemReferenceExternalLinkValid = (node, state) => {
  const relations = state.apiWithPendingChanges.contentRelations.from[`/content/${node.key}`];
  if (relations) {
    const relation = relations.find(r => r.relationtype === 'REFERENCES');

    if (!relation) return true;

    if (!isResourceSupported(relation.to.href) && !isExternalLinkHttp(relation.to.href)) {
      return {
        type: 'ERROR',
        message: 'validation.externalLinkNotHttp'
      };
    }
  }

  return true;
};
