module.exports = {
  information: {
    single: 'Verwijzing',
    plural: 'Verwijzingen'
  },
  node: {
    type: 'REFERENCE'
  },
  edit: [
    {
      component: 'identifier',
      width: 2
    },
    {
      component: 'title',
      width: 10
    }
  ],
  hideInDocument: true,
  isSearchable: false
};
