import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
const kovsentry = require('../../../app/kovSentry');

export class reviewSuggestionsModal {
  constructor($scope, $ngRedux, $uibModalInstance, $location) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$uibModalInstance = $uibModalInstance;
    this.$location = $location;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!state.document.viewModel.reviewSuggestionsModalOpen) {
        this.$uibModalInstance.close();
      }

      return {
        saveAttempted: state.document.viewModel.reviewingSuggestions,
        reviewAction: state.document.viewModel.reviewAction,
        countSuggestionsToReview: state.document.viewModel.suggestions.countSuggestionsToReviewSelected,
        hasProposalForIssuedDate: state.document.viewModel.suggestions.hasProposalForIssuedDate,
        message: state.document.viewModel.reviewSuggestionsMessage
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  submit() {
    switch (this.reviewAction) {
      case 'ACCEPT': {
        kovsentry.startLog('Accept Suggestions', {
          message: this.message
        });
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.acceptSuggestionsAction());
        break;
      }
      case 'REJECT': {
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.rejectSuggestionsAction());
        break;
      }
      case 'CANCEL': {
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.cancelSuggestionsAction());
        break;
      }
      default: {
        break;
      }
    }
  }

  cancel() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.closeReviewSuggestionsModalAction());
  }

  change(scope, event, data) {
    // key pressed
    scope.ctrl.message = data.value;
  }

  blur(scope, event, data) {
    scope.ctrl.message = data.value;
  }
}
