module.exports = {
  information: {
    definiteArticle: false,
    single: 'Zill-illustratie',
    plural: 'Zill-illustraties',
    icon: require('../../../img/icons/zillIllustration.svg'),
    category: 'ZILL'
  },
  node: {
    type: 'ZILL_ILLUSTRATION',
    tags: [
      'ZILL_ILLUSTRATION'
    ]
  },
  createModal: {
    authors: {
      addUser: true
    }
  },
  edit: [
    'title',
    'authors',
    'issued',
    'practicalExampleReferences',
    'goalsByZillIllustration'
  ],
  buildingBlocks: [],
  tocTypes: [],
  asDocumentView: true,
  isCreatable: true,
  isSearchable: true,
};
