import { titleNotEmpty } from '../../validations/titleNotEmpty';
import { settings } from '../settings';
import { pathIsNotReserved } from '../../validations/pathIsNotReserved';
import { pathIsPermitted } from '../../validations/pathIsPermitted';
import { oldUrlIsPermitted } from '../../validations/oldUrlIsPermitted';
import { websiteUniqueWithinNode } from '../../validations/websiteUniqueWithinNode';
import { documentTags } from '../../reduxLoop/constants/documentTags';
import { urlUniqueApiWithinApi } from '../../validations/urlUniqueWithinApi';
import { urlUniqueWithinDocument } from '../../validations/urlUniqueWithinDocument';
import * as apiRoutes from '../../reduxLoop/api/apiRoutes';

export default {
  information: {
    definiteArticle: true,
    single: 'Pers',
    plural: 'Pers',
    icon: require('../../../img/icons/document.svg')
  },
  node: {
    type: 'STRUCTURED_DOCUMENT',
    tags: [
      documentTags.pressRelease
    ]
  },
  webconfiguration: {
    type: 'WWW_NEWS_DETAIL',
    website: {
      href: `${apiRoutes.websites}/${settings.websites.www}`
    },
    template: {
      href: '/web/templates/514777b4-4657-4590-835e-7ff9d6e99b22'
    }
  },
  createModal: {
    authors: {
      addUser: false
    }
  },
  indentation: 1,
  buildingBlocks: [
    'SECTION',
    'PARAGRAPH',
    'IMAGE',
    'IMAGE_GROUP',
    'ATTACHMENTS_GROUP',
    'VIDEO',
    'VIDEO_GROUP',
    'QUOTE',
    'SUMMARY',
    'LEGAL',
    'REFERENCE_GROUP',
    'EXAMPLE'
  ],
  edit: [
    {
      component: 'title',
      validations: [
        titleNotEmpty
      ]
    },
    {
      component: 'description',
      label: 'Inleiding'
    },
    {
      component: 'image',
      width: '6',
      label: 'Omslagfoto',
      options: {
        type: 'COVER_IMAGE',
        fields: []
      }
    },
    {
      component: 'image',
      width: '6',
      label: 'Tegelfoto',
      options: {
        type: 'THUMBNAIL',
        fields: [
          'alt'
        ]
      }
    },
    {
      component: 'authors',
      label: 'Auteur(s)'
    },
    'issued',
    'importance',
    {
      component: 'themes',
      options: { referenceFrameKey: '085bc39d-c7a6-4209-bc7d-22962d23724b', types: ['THEME'] },
    },
  ],
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  allowSuggestions: true,
  websiteEdition: [
    {
      domain: settings.domains.www
    },
    {
      domain: settings.domains.pro
    }
  ],
  websiteValidations: [
    pathIsNotReserved,
    oldUrlIsPermitted,
    pathIsPermitted,
    websiteUniqueWithinNode,
    urlUniqueApiWithinApi,
    urlUniqueWithinDocument
  ]
};
