import { getOldUrl } from '../reduxLoop/helpers/webConfigHelpers';

/* eslint-disable max-len */
export const urlUniqueWithinDocument = (n, state) => {
  const duplicatesPerNode = n.websitesConfiguration.reduce((duplicatesPerNode, c) => {
    const duplicatesPerConfiguration = [...state.apiWithPendingChanges.webpages.values()]
      .reduce((duplicatesPerConfiguration, webpage) => {
        if (webpage.key === c.key) {
          return duplicatesPerConfiguration;
        }
        // compare configuration url / old urls with webpage url
        if ((c.path === webpage.path && c.website.key === webpage.website.key)
        || (c.oldUrlsVm && c.oldUrlsVm.includes(`https://${webpage.website.domain}${webpage.path}`))) {
          duplicatesPerConfiguration.push({
            configuration: webpage,
            path: webpage.path
          });
        }
        // compare configuration url / old urls with webpage old urls
        const duplicateOldUrls = !webpage.oldLocations
          ? []
          : webpage.oldLocations.reduce((oldDuplicates, oldLocation) => {
            const oldUrl = getOldUrl(oldLocation, state.websites);
            if ((c.path === oldLocation.path && c.website.href === oldLocation.website.href)
            || (c.oldUrlsVm && c.oldUrlsVm.includes(oldUrl))) {
              oldDuplicates.push(oldUrl);
            }
            return oldDuplicates;
          }, []);

        if (duplicateOldUrls.length) {
          duplicatesPerConfiguration.push({
            configuration: webpage,
            path: duplicateOldUrls
          });
        }
        return duplicatesPerConfiguration;
      }, []);

    return [...duplicatesPerNode, ...duplicatesPerConfiguration];
  }, []);


  return !duplicatesPerNode.length ? true : {
    type: 'ERROR',
    message: 'components.proWebsite.configurations.error.notUniqueWithinDocument',
    params: {
      path: duplicatesPerNode.map(d => Array.isArray(d.path) ? d.path.join('- ') : d.path).join(', '),
      node: duplicatesPerNode.map(d => {
        const node = state.apiWithPendingChanges.content.get(d.configuration.source.href);
        const pathToNode = state.tree.title
          + (node.key !== state.tree.key ? ` > .. > ${node.title}` : '');
        return pathToNode;
      }).join(', ')
    }
  };
};
