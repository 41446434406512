import { titleNotEmpty } from '../../validations/titleNotEmpty';
import { newsItemDeleteValidationCmd } from '../../reduxLoop/commands/documentListCommands';
import { validationFailedAction } from '../../reduxLoop/actions/documentListActions';
import { settings } from '../settings';
import constants from '../../reduxLoop/constants/constants';
import { documentTags } from '../../reduxLoop/constants/documentTags';
import { pathIsNotReserved } from '../../validations/pathIsNotReserved';
import { pathIsPermitted } from '../../validations/pathIsPermitted';
import { oldUrlIsPermitted } from '../../validations/oldUrlIsPermitted';
import { websiteUniqueWithinNode } from '../../validations/websiteUniqueWithinNode';
import { urlUniqueApiWithinApi } from '../../validations/urlUniqueWithinApi';
import { urlUniqueWithinDocument } from '../../validations/urlUniqueWithinDocument';
import * as apiRoutes from '../../reduxLoop/api/apiRoutes';
import { accessRights } from '../../reduxLoop/helpers/accessRights';

export default {
  information: {
    definiteArticle: true,
    single: 'PRO.-nieuwsbericht',
    plural: 'PRO.-nieuwsberichten',
    icon: require('../../../img/icons/document.svg'),
    category: 'PRO',
  },
  node: {
    type: 'STRUCTURED_DOCUMENT',
    tags: [documentTags.proNewsItem],
  },
  createDefaults: {
    accessRights: accessRights[0].namedsets,
    descendantsAccessRights: accessRights[0].namedsets,
  },
  webconfiguration: {
    type: 'NEWS_ITEM_AND_TEASER_FALLBACK',
    website: {
      href: `${apiRoutes.websites}/${settings.websites.pro}`,
    },
    template: {
      href: '/web/templates/d153f5d5-78b3-4de7-a231-281da633a8bb',
    },
  },
  createModal: {
    authors: {
      addUser: false,
    },
  },
  indentation: 1,
  buildingBlocks: [
    'SECTION',
    'PARAGRAPH',
    'IMAGE',
    'IMAGE_GROUP',
    'ATTACHMENTS_GROUP',
    'VIDEO',
    'VIDEO_GROUP',
    'QUOTE',
    'SUMMARY',
    'LEGAL',
    'REFERENCE_GROUP',
    'EXAMPLE',
  ],
  edit: [
    {
      component: 'title',
      validations: [titleNotEmpty],
    },
    {
      component: 'image',
      label: 'edit.ILLUSTRATION',
      options: {
        type: 'THUMBNAIL',
      },
    },
    {
      component: 'authors',
      label: 'Auteur(s)',
    },
    'issued',
    {
      component: 'accessRights',
      label: 'components.accessRights.descendantsAccessRights',
      options: {
        field: 'descendantsAccessRights',
      },
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  allowSuggestions: true,
  customRender: true,
  deleteFromListValidations: [
    {
      cmd: newsItemDeleteValidationCmd,
      failAction: validationFailedAction,
    },
  ],
  websiteEdition: [
    {
      domain: settings.domains.www,
    },
    {
      domain: settings.domains.pro,
    },
  ],
  websiteValidations: [
    pathIsNotReserved,
    oldUrlIsPermitted,
    pathIsPermitted,
    websiteUniqueWithinNode,
    urlUniqueApiWithinApi,
    urlUniqueWithinDocument,
  ],
};
