import constants from '../reduxLoop/constants/constants';

/* eslint-disable max-len */
export const pathIsNotReserved = (node) => {
  const isReservedPath = node.websitesConfiguration.find(c => constants.reservedPaths.some(path => path === c.path) || constants.reservedSuffixs.some(suffix => c.path.endsWith(suffix)));

  return !isReservedPath ? true : {
    type: 'ERROR',
    message: 'components.proWebsite.configurations.error.pathIsReserved',
    params: {}
  };
};
