import { titleNotEmpty } from '../../validations/titleNotEmpty';
import { settings } from '../settings';
import constants from '../../reduxLoop/constants/constants';
import { coverageNotEmpty } from '../../validations/coverageNotEmpty';
import { mainstructuresOuTypeCombinationsNotEmpty } from '../../validations/mainstructuresOuTypeCombinationsNotEmpty';
import { positionsNotEmpty } from '../../validations/positionsNotEmpty';
import { pathIsNotReserved } from '../../validations/pathIsNotReserved';
import { pathIsPermitted } from '../../validations/pathIsPermitted';
import { oldUrlIsPermitted } from '../../validations/oldUrlIsPermitted';
import { websiteUniqueWithinNode } from '../../validations/websiteUniqueWithinNode';
import { urlUniqueApiWithinApi } from '../../validations/urlUniqueWithinApi';
import { urlUniqueWithinDocument } from '../../validations/urlUniqueWithinDocument';
import { newsletterSenderNotEmpty } from '../../validations/newsletterSenderNotEmpty';
import * as ACTION_TYPES from '@store/constants/actionTypes';
import { getNewsletterSenders } from '@newStore/externalData/externalDataState';

export default {
  information: {
    definiteArticle: true,
    single: 'PRO.-nieuwsbrief',
    plural: 'PRO.-nieuwsbrieven',
    icon: require('../../../img/icons/document.svg'),
    category: 'PRO',
  },
  node: {
    type: 'STRUCTURED_DOCUMENT',
    tags: ['PRONEWSLETTER'],
  },
  createDefaults: { newsletterSender: { href: constants.dienstCommunicatieSender } },
  createModal: {
    authors: { addUser: true },
    newsLetterTemplate: { required: true },
    dateToSend: { required: true },
  },
  preloadActions: [{ type: ACTION_TYPES.GET_NEWSLETTER_SETTINGS }, getNewsletterSenders()],
  indentation: 1,
  buildingBlocks: ['SECTION', 'PARAGRAPH', 'IMAGE', 'REFERENCE_GROUP'],
  tocTypes: ['SECTION'],
  referenceGroupTypes: [constants.referenceGroupTypes.TRAINING],
  edit: [
    {
      component: 'title',
      validations: [titleNotEmpty],
      options: { hideTermButton: true },
    },
    'dateToSend',
    {
      component: 'newsletterSender',
      label: 'components.newsletterSender.label',
      validations: [newsletterSenderNotEmpty],
    },
    {
      component: 'tableOfContents',
      type: 'checkbox',
    },
    {
      component: 'namedSet',
      validations: [mainstructuresOuTypeCombinationsNotEmpty],
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      validations: [coverageNotEmpty],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'namedSet',
      label: 'components.functions.title',
      validations: [positionsNotEmpty],
      tag: 'doelgroepen',
      property: 'positions',
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  isCreatable: true,
  isSearchable: true,
  isImportable: false,
  allowSuggestions: false,
  customRender: false,
  websiteEdition: [{ domain: settings.domains.www }, { domain: settings.domains.pro }],
  websiteValidations: [
    pathIsNotReserved,
    oldUrlIsPermitted,
    pathIsPermitted,
    websiteUniqueWithinNode,
    urlUniqueApiWithinApi,
    urlUniqueWithinDocument,
  ],
  hideTermButton: true,
  expandByDefault: true,
};
