import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideColor {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.colorpickerSettings = {
      position: 'bottom'
    };
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.document.viewModel.aside.editDocument
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  change() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(this.sDocument.key, {
      color: this.color.value
    }));
  }
}

export default {
  template: require('./asideColor.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
    sDocument: '<'
  },
  controller: asideColor
};
