import constants from '../../reduxLoop/constants/constants';
import { coverageNotEmpty } from '../../validations/coverageNotEmpty';
import { documentTags } from '../../reduxLoop/constants/documentTags';
import { mainstructuresOuTypeCombinationsNotEmpty } from '../../validations/mainstructuresOuTypeCombinationsNotEmpty';
import { titleNotEmpty } from '../../validations/titleNotEmpty';
import { mainAndSubCurriculumThemesConsistent } from '../../validations/mainAndSubCurriculumThemesConsistent';

export default {
  information: {
    definiteArticle: false,
    single: 'Gedeelde FAQ (leerplansites so)',
    plural: 'Gedeelde FAQ (leerplansites so)',
  },
  node: {
    type: 'SHARED_FAQ',
    tags: [documentTags.sharedFaq],
  },
  indentation: 1,
  buildingBlocks: [
    'PARAGRAPH',
    'IMAGE_GROUP',
    'IMAGE',
    'VIDEO',
    'ATTACHMENTS_GROUP',
    'QUOTE',
    'LEGAL',
  ],
  edit: [
    {
      component: 'title',
      label: 'Vraag',
      validations: [titleNotEmpty],
    },
    {
      component: 'authors',
      label: 'Auteur(s)',
      options: {
        queryParams: constants.allAuthorsParameters,
      },
    },
    'issued',
    {
      component: 'themes',
      label: 'edit.curriculumThemes',
      options: {
        referenceFrameKey: constants.dienstverleningKovKey,
        types: ['THEME', 'CURRICULUM_THEME', 'COMMONS_STUDIEGEBIED', 'COMMONS_SUBJECT'],
        highlightOldCurrItems: true,
      },
      validations: [mainAndSubCurriculumThemesConsistent],
    },
    'curriculumSelector',
    {
      component: 'themes',
      label: 'Categorie FAQ-groep',
      options: {
        referenceFrameKey: constants.faqCategoriesKey,
        types: ['THEME'],
      },
    },
    {
      component: 'namedSet',
      validations: [mainstructuresOuTypeCombinationsNotEmpty],
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      validations: [coverageNotEmpty],
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  createModal: {
    title: {
      label: 'Vraag',
    },
    authors: {
      addUser: false,
      queryParams: constants.allAuthorsParameters,
    },
  },
  asDocumentView: true,
  isCreatable: true,
  isCloneable: true,
  isSearchable: true,
  allowSuggestions: true,
};
