const constants = require('../../../reduxLoop/constants/constants');
const { accessRightsInconsistent } = require('../../../validations/accessRightsInconsistent');
const { isGlobalDocumentAllowed } = require('../../../validations/isGlobalDocumentAllowed');

module.exports = {
  information: {
    single: 'Bijlagegroep',
    plural: 'Bijlagegroep',
    conditionalTitle: (node) => (node.isDownloadAttachmentsGroup ? 'Download' : 'Bijlagegroep'),
    ribbonTitle: 'Bijlagegroep/Download',
    ribonIcon: require('../../../../img/buildingBlocks/ATTACHMENT.svg'),
  },
  node: {
    type: 'ATTACHMENTS_GROUP',
    tags: ['INLINE'],
  },
  edit: [
    {
      component: 'downloadDocument',
      whitelist: [{ root: { $$type: 'WEBPAGE2' } }],
      avoidDispatchDownloadTag: true,
      options: {
        validExtensions:
          '.pdf, .jpg, .jpeg, .png, .mp3, .mp4, .doc, .docx, .dotx, .xls, .xlsx, .xltx, .ppt, .pptx, .ppsx, .potx, .tiff',
      },
    },
    'attachmentsGroup',
    {
      component: 'namedSet',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      validations: [isGlobalDocumentAllowed],
      whitelist: [{ root: { $$type: 'WEBPAGE2' } }],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      whitelist: [{ root: { $$type: 'WEBPAGE2' } }],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'accessRights',
      label: 'components.accessRights.descendantsAccessRights',
      options: {
        field: 'descendantsAccessRights'
      },
      whitelist: [{ root: { $$type: 'WEBPAGE2' } }, { root: { $$type: 'PRONEWSITEM' } }],
      validations: [accessRightsInconsistent],
      tab: constants.asideTabs.AUDIENCE
    },
    {
      component: 'webFacets',
      width: '12',
      options: { source: 'parent' },
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ]
    }
  ],
  createInModal: true,
  isSearchable: false,
  hideChildrenInDocument: true,
  confirmDelete: true
};
