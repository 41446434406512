import { mainstructuresOuTypeCombinationsInconsistent } from '../../../validations/mainstructuresOuTypeCombinationsInconsistent';
import { coverageInconsistent } from '../../../validations/coverageInconsistent';
import { mainstructuresOuTypeCombinationsNotEmpty } from '../../../validations/mainstructuresOuTypeCombinationsNotEmpty';
import { coverageNotEmpty } from '../../../validations/coverageNotEmpty';
import { positionsInconsistent } from '../../../validations/positionsInconsistent';
import { positionsNotEmpty } from '../../../validations/positionsNotEmpty';
import { mainAndSubCurriculumThemesConsistent } from '../../../validations/mainAndSubCurriculumThemesConsistent';
import constants from '../../../reduxLoop/constants/constants';

export default {
  information: {
    single: 'Verwijsgroep',
    plural: 'Verwijsgroepen',
    ribonIcon: require('../../../../img/buildingBlocks/REFERENCE_GROUP.svg'),
  },
  node: { type: 'REFERENCE_GROUP' },
  edit: [
    'referenceGroup',
    {
      component: 'themes',
      whitelist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } },
      ],
      options: {
        referenceFrameKey: constants.dienstverleningKovKey,
        types: [
          'THEME',
          'BASIC_OPTION',
          'DOMAIN',
          'COMMONS_STUDIEGEBIED',
          'COMMONS_STUDY_PROGRAMME',
          'COMMONS_SUBJECT',
          'CURRICULUM_THEME',
          'CURRICULUM_CLUSTER',
        ],
        highlightOldCurrItems: true,
      },
      validations: [mainAndSubCurriculumThemesConsistent],
    },
    {
      component: 'curriculumSelector',
      whitelist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } },
      ],
      options: {
        // do not send main curriculum themes
        filter: (theme) => {
          return (
            theme.type !== 'CURRICULUM_THEME' ||
            !theme.$$children.some((c) => c.type === 'CURRICULUM_THEME')
          );
        },
      },
    },
    {
      component: 'namedSet',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      validations: [
        mainstructuresOuTypeCombinationsNotEmpty,
        mainstructuresOuTypeCombinationsInconsistent,
      ],
      whitelist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      validations: [coverageNotEmpty, coverageInconsistent],
      whitelist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'namedSet',
      validations: [positionsNotEmpty, positionsInconsistent],
      label: 'components.functions.title',
      tag: 'doelgroepen',
      property: 'positions',
      whitelist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } },
      ],
      tab: constants.asideTabs.AUDIENCE,
    },
  ],
  isSearchable: false,
  customRender: true,
  hideChildrenInDocument: true,
};
