import { selectUser } from '@newStore/user/userSelectors';
import { removeNotificationAction } from '../../reduxLoop/actions/notificationActions';
import './appRoot.scss';

export class appRoot {
  constructor($ngRedux, LogRocket, settings, $scope, $window, $location, $translate) {
    'ngInject';

    this.debug = settings.debug;
    this.user = {};

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
    this.$window = $window;
    this.settings = settings;
    this.$location = $location;
    this.$translate = $translate;

    this.unsubscribe = $ngRedux.subscribe(() => {
      const state = $ngRedux.getState();
      const person = selectUser(state);
      // Give identity to other services + initialize LogRocket
      if (person && person.key !== this.user.key) {
        if (
          this.settings.logging.logrocket.enabled &&
          this.settings.logging.logrocket.users.includes(person.username)
        ) {
          LogRocket.init(this.settings.logging.logrocket.key, {
            console: {
              isEnabled: {
                log: false,
                debug: false,
              },
            },
          });
          LogRocket.identify(person.key, {
            username: person.username,
            name: `${person.firstName} ${person.lastName}`,
            email: person.$$email,
          });
        }
        this.user = person;
      }

      // If oauth token is set, hide loading screen.
      const userKey = person && person.key;
      if (userKey !== this.userKey) {
        $('body').addClass('loaded');
      }
      this.userKey = userKey;
    });

    $ngRedux.connect((state) => {
      return {
        notificationsList: state.notifications.map((o) => {
          o.message = this.$translate.instant(o.message, o.params);
          return o;
        }),
      };
    })(this);
  }

  $onInit() {
    const self = this;
    this.$scope.$on('onDropdownClicked', function (e, type) {
      if (type === 'settings') {
        self.$window.open(self.settings.apisAndUrls.myVsko);
      } else if (type === 'logout') {
        self.$window.location.href =
          self.settings.oauth.logOut + '?redirect_uri=' + self.$location.absUrl();
      }
    });
  }

  $onDestroy() {
    this.unsubscribe();
  }

  removeNotification(scope, event, key) {
    scope.ctrl.$ngRedux.dispatch(removeNotificationAction(key));
  }

  onDigestLengthUpdate(length) {
    // console.log('DIGEST', length + 'ms');
  }
}

export default {
  template: require('./appRoot.html'),
  controllerAs: 'ctrl',
  bindings: {},
  controller: appRoot,
};
