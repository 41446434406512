/* eslint-disable func-names */
import he from 'he';

export default [ '$window', '$q', 'constants', 'settings', 'types','$notification', 'contentApi', function ($window, $q, constants, settings, types, $notification, contentApi) { //eslint-disable-line
  var that = {};
  var contentTypeConversionMap = [];
  var appendZero = function (number) {
    return number > 9 ? number : 0 + '' + number;
  };
  that.appendZero = appendZero;

  contentTypeConversionMap['application/vnd.openxmlformats-officedocument.wordprocessingml.document'] = {
    name: 'Ms Word-document',
    icon: require('../../img/icons/doc.svg')
  };
  contentTypeConversionMap['application/vnd.openxmlformats-officedocument.wordprocessingml.template'] = {
    name: 'Ms Word-document',
    icon: require('../../img/icons/doc.svg')
  };
  contentTypeConversionMap['application/msword'] = {
    name: 'Ms Word-document',
    icon: require('../../img/icons/doc.svg')
  };
  contentTypeConversionMap['application/pdf'] = {
    name: 'Adobe Acrobat Document',
    icon: require('../../img/icons/pdf.svg')
  };
  contentTypeConversionMap['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'] = {
    name: 'Ms Excel-document',
    icon: require('../../img/icons/xls.svg')
  };
  contentTypeConversionMap['application/vnd.openxmlformats-officedocument.spreadsheetml.template'] = {
    name: 'Ms Excel-document',
    icon: require('../../img/icons/xls.svg')
  };
  contentTypeConversionMap['application/vnd.ms-excel'] = {
    name: 'Ms Excel-document',
    icon: require('../../img/icons/xls.svg')
  };
  contentTypeConversionMap['video/mp4'] = {
    name: 'Video MP4',
    icon: require('../../img/icons/mp4.svg')
  };
  contentTypeConversionMap['audio/mp3'] = {
    name: 'Audio MP3',
    icon: require('../../img/icons/mp3.svg')
  };
  contentTypeConversionMap['audio/mpeg'] = {
    name: 'Audio MP3',
    icon: require('../../img/icons/mp3.svg')
  };
  contentTypeConversionMap['image/jpeg'] = {
    name: 'Image JPEG',
    icon: require('../../img/icons/jpg.svg')
  };
  contentTypeConversionMap['image/jpg'] = {
    name: 'Image JPG',
    icon: require('../../img/icons/jpg.svg')
  };
  contentTypeConversionMap['image/png'] = {
    name: 'Image PNG',
    icon: require('../../img/icons/jpg.svg')
  };
  contentTypeConversionMap['image/tiff'] = {
    name: 'Image TIFF',
    icon: require('../../img/icons/jpg.svg')
  };
  contentTypeConversionMap['application/vnd.ms-powerpoint'] = {
    name: 'Ms PowerPoint-document',
    icon: require('../../img/icons/ppt.svg')
  };
  contentTypeConversionMap['application/vnd.openxmlformats-officedocument.presentationml.presentation'] = {
    name: 'Ms PowerPoint-document',
    icon: require('../../img/icons/ppt.svg')
  };
  contentTypeConversionMap['application/vnd.openxmlformats-officedocument.presentationml.template'] = {
    name: 'Ms PowerPoint-document',
    icon: require('../../img/icons/ppt.svg')
  };
  contentTypeConversionMap['application/vnd.openxmlformats-officedocument.presentationml.slideshow'] = {
    name: 'Ms PowerPoint-document',
    icon: require('../../img/icons/ppt.svg')
  };

  that.getLabelFor = function (contentType) {
    if (contentType in contentTypeConversionMap) {
      return contentTypeConversionMap[contentType].name;
    }
    return '';
  };

  that.getIconFor = function (contentType) {
    if (contentType in contentTypeConversionMap) {
      return contentTypeConversionMap[contentType].icon;
    }
    return '';
  };

  function excludeFromStart(str, tokens) {
    tokens.map(function (token) {
      if (str.startsWith(token)) {
        str = str.slice(token.length);
        str = excludeFromStart(str, tokens);
      }
    });
    return str;
  }
  function excludeFromEnd(str, tokens) {
    tokens.map(function (token) {
      if (str.endsWith(token)) {
        str = str.slice(0, -token.length);
        str = excludeFromEnd(str, tokens);
      }
    });
    return str;
  }

  function getFootNotesLinks(textWithFootNoteLinks) {
    var domParser = new DOMParser();
    var docElement = domParser.parseFromString(textWithFootNoteLinks, 'text/html').documentElement;
    var allLinksElements = docElement.getElementsByTagName('a');

    let links = Array.from(allLinksElements);

    return links.filter((link) =>{
      let rel = link.getAttribute('rel');
      return rel === 'footnote';
    });
  }

  function findFootNoteLinkInFootnotes(link, footnotes) {
    let linkHref = link.getAttribute('href');

    let matchingFootNotes = footnotes.filter((footnote) => {
      let footnoteHref = footnote.data ? footnote.data.href : footnote.$$meta.permalink;
      return footnoteHref === linkHref;
    });

    if (matchingFootNotes.length > 0) {
      return matchingFootNotes[0];
    }
  }

  that.dateToString = function (date) {
    if (date) {
      return date.getFullYear() + '-' + appendZero(date.getMonth() + 1) + '-' + appendZero(date.getDate()); //eslint-disable-line
    }
    return undefined;
  };

  that.timeToString = function (date) {
    if (date) {
      date = new Date(date);
      return date.getFullYear() + '-' + appendZero(date.getMonth() + 1) + '-' + appendZero(date.getDate()) + ' ' + //eslint-disable-line
        date.getHours() + ':' + date.getMinutes();
    }
    return undefined;
  };

  that.formatDate = function (isoDate) {
    var moment = require('moment');
    if (isoDate) {
      return moment(isoDate).format('DD/MM/YYYY');
    }
    return '';
  };

  that.inputToDateString = function (input) {
    var date;
    if (typeof input === 'string') {
      date = input.split('/').reverse().join('-');
    } else {
      date = that.dateToString(input);
    }
    return date;
  };

  that.printDate = function (dateString) {
    return dateString.split('-').reverse().join('/');
  };

  that.getUUID = function () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8); //eslint-disable-line
      return v.toString(16);
    });
  };

  that.charactersCount = function (text) {
    text = text ? text
      .replace(/&nbsp;/g, ' ')
      .replace(/&gt;/g, '>')
      .replace(/(<([^>]+)>)/ig, '') : '';
    return text.length;
  };

  that.replaceSpecialCharacters = function (string) {
    return string
      .replace(/[\u00C0-\u00C5\u00E0-\u00E5]/g, 'a')
      .replace(/[\u00C6\u00E6]/g, 'ae')
      .replace(/[\u00C7\u00E7]/g, 'c')
      .replace(/[\u00C8-\u00CB\u00E8-\u00EB]/g, 'e')
      .replace(/[\u00CC-\u00CF\u00EC-\u00EF]/g, 'i')
      .replace(/[\u00D1\u00F1]/g, 'n')
      .replace(/[\u00D2-\u00D6\u00D8\u00F2-\u00F6\u00F8]/g, 'o')
      .replace(/[\u00D9-\u00DC\u00F9-\u00FC]/g, 'u')
      .replace(/[\u00DD\u00FD\u00FF]/g, 'y')
      .replace(/[\u00DF]/g, 'ss')
      .replace(/[ł]/g, 'l')
      .replace(/[^a-zA-Z0-9\-]/g, '');
  };

  that.sanitizeHtml = function (string, options) {
    var sanitize = require('sanitize-html');
    if (options) {
      string = sanitize(string, options);
    }
    string = excludeFromStart(string, ['<br />', '<br>']);
    string = excludeFromEnd(string, ['<br />', '<br>']);
    string = string.replace(/<br\s*[\/]?>/gi, '<br>'); // replcae <br /> with <br>
    return string;
  };

  that.containsDateOfBirth = function (number, dateOfBirth) {
    if (!number || !dateOfBirth) {
      return false;
    }
    var reverse = dateOfBirth.split('-');
    reverse[0] = reverse[0].substring(2);
    var value = number.replace(/\s/g, '');
    return value.match('^[0-9]*' + reverse.join('') + '[0-9]*$');
  };

  //* * documents and structure utils **//

  that.identifiers = function (document) {
    return document.identifiers ? document.identifiers.join(' ') : null;
  };

  that.generateGoalIdentifier = function (parentNode) {
    var upperIdentifiers = [];

    function readUpperIdentifier(parent) {
      if (parent.data.identifiers) {
        upperIdentifiers.push(parent.data.identifiers);
      }

      if (parent.$$parent && (parent.$$parent.$$level > 2 || parent.$$parent.$$type.indexOf('LLINKID') != -1)) {
        return readUpperIdentifier(parent.$$parent);
      }
      return upperIdentifiers;
    }

    return readUpperIdentifier(parentNode).reverse();
  };


  that.generateZillGoalIdentifier = function (goalIdentifier, parentNode) {
    var upperGoalIdentifier = that.generateGoalIdentifier(parentNode);
    return upperGoalIdentifier.join('') + (goalIdentifier || '');
  };

  that.generateOdetGoalIdentifier = function (goalIdentifier, parentNode) {
    var upperGoalIdentifier = that.generateGoalIdentifier(parentNode);

    var firstNumber = upperGoalIdentifier[1] ? upperGoalIdentifier[1] : '';
    var lastNumber = goalIdentifier || '';

    if (firstNumber != '' && lastNumber != '') {
      firstNumber += '.';
    }

    return upperGoalIdentifier[0] + ' ' + firstNumber + lastNumber;
  };

  that.generateLlinkidGoalIdentifier = function (goalIdentifier, parentNode) {
    let upperGoalIdentifier = that.generateGoalIdentifier(parentNode);

    if (!upperGoalIdentifier || upperGoalIdentifier.length === 0) {
      return goalIdentifier;
    }

    let text = upperGoalIdentifier.reduce((flatText, identifier) => {
      return flatText + '.' + identifier;
    });

    let lastNumber = goalIdentifier || '';

    return text + lastNumber;
  };

  that.generateGoalIdentifierWithContent = function (goal, parentDocuments) {
    var upperIdentifiers = [];

    function getParentDocument(content, list) {
      var parents = list.filter(function (document) {
        return document.$$relationsTo && document.$$relationsTo.filter(function (r) {
          var fromKey = r.$$expanded.from.href.split('/').slice(-1)[0];
          return fromKey === content.key && r.$$expanded.relationtype === 'IS_PART_OF';
        }).length > 0;
      });
      if (parents.length > 0) {
        return parents[0];
      }
      return list.length - 2 >= 0 ? list[list.length - 2] : null;
    }

    function readUpperIdentifier(content) {
      var parent = getParentDocument(content, parentDocuments);

      if (content.identifiers) {
        upperIdentifiers.push(content.identifiers);
      }

      if (parent && upperIdentifiers.length < 2) {
        return readUpperIdentifier(parent);
      }
      return upperIdentifiers;
    }

    var identifier = readUpperIdentifier(getParentDocument(goal, parentDocuments)).reverse().join('') + (goal.identifiers ? goal.identifiers.join('') : ''); //eslint-disable-line
    return identifier;
  };

  that.structureIdentifiers = function (document, level, parentNode, excludePrefix) {
    var identifier = that.identifiers(document);

    var upperIdentifiers = [];
    function readUpperIdentifier(parent) {
      if (parent.data.identifiers) {
        upperIdentifiers.push(parent.data.identifiers);
      }
      if (parent.$$parent) {
        return readUpperIdentifier(parent.$$parent);
      }
      return upperIdentifiers;
    }

    if (!identifier && document.type !== 'CURRICULUM_ODET_DEVELOPMENT_GOAL') {
      return;
    }

    if (!types[document.type] || !that.hasEditField(document.type, 'identifier')) {
      identifier = '';
    } else if (that.hasEditField(document.type, 'goalIdentifier')) {
      if (document.type.indexOf('CURRICULUM_ODET') > -1) {
        identifier = (!excludePrefix ? '&bull; ' : '') + that.generateOdetGoalIdentifier(identifier, parentNode);
      } else if (document.type.indexOf('CURRICULUM_ZILL') > -1) {
        identifier = (!excludePrefix ? '&bull; ' : '') + that.generateZillGoalIdentifier(identifier, parentNode);
      } else if (document.type.indexOf('LLINKID') > -1) {
        identifier = that.generateLlinkidGoalIdentifier(identifier, parentNode);
      }
    } else if (!types[document.type].documentViewIdentifier
             || types[document.type].documentViewIdentifier === 'simple') {
      identifier += '';
    } else if (types[document.type] && types[document.type].documentViewIdentifier) {
      if (types[document.type].documentViewIdentifier === 'parent-1' && parentNode) {
        identifier = parentNode.data.structureIdentifiers + identifier + '';
      } else if (types[document.type].documentViewIdentifier === 'parent-n') {
        upperIdentifiers = readUpperIdentifier(parentNode).reverse();
        identifier = (upperIdentifiers.length > 0 ? upperIdentifiers.join('') + '' : '') + identifier + '.';
      }
    }

    return identifier; //eslint-disable-line
  };

  that.treeOdetIdentifiers = function (document, level, parentNode) {
    var identifier = that.structureIdentifiers(document, level, parentNode, true);

    if (!identifier) {
      return;
    }

    return identifier;
  };

  that.odetIdentifiers = function (document, level, parentNode) {
    var identifier = that.structureIdentifiers(document, level, parentNode, true);

    if (!identifier) {
      return;
    }

    var upperTitles = [];

    function readUpperTitle(node) {
      if (!node) {
        return upperTitles;
      }

      if (node.title) {
        upperTitles.push(node.title);
      }

      if (node.$$parent && node.$$parent.$$level > 2) {
        return readUpperTitle(node.$$parent);
      }
      return upperTitles;
    }

    upperTitles = readUpperTitle(parentNode);
    return identifier + (upperTitles.length > 0 ? ' (' + upperTitles.reverse().join(' - ') + ')' : '');  //eslint-disable-line
  };

  that.hasEditField = function (type, field) {
    return type && types[type] && types[type].edit && (types[type].edit.indexOf(field) !== -1 || types[type].edit.filter(section => section.component === field).length > 0);
  };

  that.structureDescription = function (document) {
    return document.type !== constants.goalContentType ? document.description : null;
  };

  that.imageHref = function (document, type) {
    var attachs;
    if (document.attachments) {
      attachs = document.attachments.filter(function (a) {
        return a.type === type;
      });
      return attachs.length > 0 ? attachs[0].href : null;
    }
    return null;
  };

  that.videoAttachment = function (document, type) {
    var attachs;

    if (document.attachments) {
      attachs = document.attachments.filter(function (a) {
        return a.type === type;
      });
      return attachs.length > 0 ? attachs[0] : null;
    }
    return null;
  };

  that.attachmentData = function (document, type) {
    var attachs;
    if (document.attachments) {
      attachs = document.attachments.filter(function (a) {
        return a.type === type;
      });

      if (attachs.length > 0) {
        var attachmentData = attachs[0];

        if (!attachmentData.created) {
          attachmentData.created = new Date(document.$$meta.created);
        }

        return attachmentData;
      }
    }
    return null;
  };

  that.compact = function (title) {
    if (!title) {
      return '';
    }
    return title.length < 130 ? title : title.substring(0, 130) + '...';
  };

  that.isPositiveIntegerNumber = function (age) {
    if (typeof age === 'string' || age instanceof String) {
      age = age.trim();

      if (age == '') {
        return false;
      }

      age = Number(age);
    }

    return Number.isInteger(age) && age >= 0;
  };

  that.addCSSClassToHTMLTables = function (html) {
    return html.split('<table>').join('<table class="table table-bordered">');
  };

  that.nameWithOutExtension = function (name) {
    if (name) {
      let n = name.lastIndexOf('.');
      return n > -1 ? name.substr(0, n) : name;
    }
    return '';
  };

  that.isValidUrl = function (url) {
    if (url !== undefined && url !== '') {
      return /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.(vlaanderen|[a-z]{2,6})\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/i.test(url);
    }
    return false;
  };

  that.replaceAll = function (str, search, replacement) {
    return str.split(search).join(replacement);
  };

  that.transformFootNoteReferencesIntoLinks = function (textWithFootNotes) {
    let domParser = new DOMParser();
    let docElement = domParser.parseFromString(textWithFootNotes, 'text/html').documentElement;
    let footNotes = docElement.getElementsByTagName('footnote');

    // getElementsByTagName return a nodeList, it still doesn't support for each
    Array.prototype.slice.call(footNotes).forEach((footNote) => {
      let href = footNote.getAttribute('href');
      let linkRepresentation = '<a href="' + href + '" rel="footnote"></a>';

      // replace <footnote ....> with <a href='/content/source_uuid'>
      let text = footNote.outerHTML;
      text = that.replaceAll(text, '&nbsp;', ' ');

      textWithFootNotes = that.replaceAll(textWithFootNotes, text, linkRepresentation);
    });

    return textWithFootNotes;
  };

  that.getDocumentFootNotesFromLinks = function (textWithFootNoteLinks, footnotes) {
    let documentFootNotes = [];
    let footnotesLinks = getFootNotesLinks(textWithFootNoteLinks);

    footnotesLinks.forEach((link) => {
      let matchingFootNote = findFootNoteLinkInFootnotes(link, footnotes);

      if (matchingFootNote) {
        documentFootNotes.push(matchingFootNote);
      }
    });

    return documentFootNotes;
  };

  that.getDocumentFootNotesFromTags = function (textWithFootNoteTags, footnotes) {
    let documentFootNotes = [];
    let footnotesHrefs = that.getFootnotesHrefsFromTags(textWithFootNoteTags);

    footnotesHrefs.forEach((href) => {
      let matchingFootNote = footnotes.find(f => f.data ? f.data.href === href : f.$$meta.permalink === href);

      if (matchingFootNote) {
        documentFootNotes.push(matchingFootNote);
      }
    });

    return documentFootNotes;
  };

  /**
 * Get a list of footnote hrefs using the given text with <footnote> tags
 */
  that.getFootnotesHrefsFromTags = function (text) {
    let afterEdition = [];

    var domParser = new DOMParser();

    var docElement = domParser.parseFromString(text, 'text/html').documentElement;
    var footnoteReferencesInContent = docElement.getElementsByTagName('footnote');
    for (var i = 0; i < footnoteReferencesInContent.length; i++) {
      if (footnoteReferencesInContent[i].innerText.trim() != '') {
        afterEdition.push(footnoteReferencesInContent[i].getAttribute('href'));
      }
    }

    return afterEdition;
  };

  that.transformFootNoteLinksIntoFootNotes = function (textWithFootNoteLinks, footnotes) {
    let footnotesLinks = getFootNotesLinks(textWithFootNoteLinks);

    footnotesLinks.forEach((link) => {
      let matchingFootNote = findFootNoteLinkInFootnotes(link, footnotes);

      if (matchingFootNote) {
        let linkToBeReplaced = link.outerHTML;
        let linkHref = matchingFootNote.data ? matchingFootNote.data.href : matchingFootNote.$$meta.permalink;
        let footNoteIdentifier = matchingFootNote.data ? matchingFootNote.data.identifiers : matchingFootNote.identifier;
        let footNoteRepresentation = '<footnote contenteditable="false" title ="' + matchingFootNote.title + '" class="footNoteInText" href="' + linkHref + '"><span>[' + footNoteIdentifier + ']</span></footnote>';

        textWithFootNoteLinks = textWithFootNoteLinks.replace(linkToBeReplaced, footNoteRepresentation);
      }
    });

    return textWithFootNoteLinks;
  };

  that.transformTermReferencesIntoLinks = function (textWithTermReferences) {
    var domParser = new DOMParser();
    var docElement = domParser.parseFromString(textWithTermReferences, 'text/html').documentElement;
    var allTermReferencesElements = docElement.getElementsByTagName('term');
    if (allTermReferencesElements.length > 0) {
      textWithTermReferences = docElement.innerHTML;

      for (var j = 0; j < allTermReferencesElements.length; j++) {
        var text = allTermReferencesElements[j].getAttribute('data-title');
        var href = allTermReferencesElements[j].getAttribute('value');

        var linkRepresentation = '<a href="' + href + '" rel="term">' + text + '</a>';
        // replace <term ....> with <a href='/content/uuid'>
        var text = allTermReferencesElements[j].outerHTML;
        textWithTermReferences = that.replaceAll(textWithTermReferences, text, linkRepresentation);
      }
    }

    return textWithTermReferences;
  };

  // TODO move to term utils
  that.transformTermLinksIntoTerm = function (textWithTermLinks, terms) {
    async function loadTerms(rootKey) {
      // Get terms referenced by the document
      let params = {
        'referencedBy.root': rootKey,
        typeIn: types.TERM.information.contentType,
        limit: 500
      };
      let termsResponse = await contentApi.getAll(settings.resourcesNames.content, params);
      let terms = termsResponse.map((term) => {
        term.data = { key: term.key, type: term.type };
        return term;
      });
      that.sortTerms(terms);
      return terms;
    }

    if (!terms) {
      // terms = await loadTerms('bb2a3f9e-05ea-45e0-a86f-b543c82e1bca');
      // return textWithTermLinks;
    }

    var domParser = new DOMParser();
    var docElement = domParser.parseFromString(textWithTermLinks, 'text/html').documentElement;
    var allLinksElements = docElement.getElementsByTagName('a');
    textWithTermLinks = docElement.innerHTML;

    for (var j = 0; j < allLinksElements.length; j++) {
      var rel = allLinksElements[j].getAttribute('rel');
      var linkHref = allLinksElements[j].getAttribute('href');
      var innerText = allLinksElements[j].innerText;

      var foundIndex = -1;

      // we look if this link points to a TERM href
      if (terms) {
        for (var i = 0; i < terms.length; i++) {
          if (terms[i].$$meta.permalink === linkHref) {
            foundIndex = i;
            break;
          }
        }
      }

      if (foundIndex !== -1 || rel === 'term') {
        var description = '';

        if (terms && terms[foundIndex] && terms[foundIndex].description) {
          description = that.sanitizeHtml(terms[foundIndex].description, { allowedTags: [] });
          description = description.replace(/\s+/g, ' ').trim();

          if (description.length > 80) {
            description = description.substring(0, 80);
            description += '...';
          }
        }

        var linkToBeReplaced = allLinksElements[j].outerHTML;

        let termTitle = '?'; // should never reach this
        var termRepresentation = '<term contenteditable="false" title="' + description + '" class="termInText" value="' + linkHref + '" data-title="' + innerText + '">[' + innerText + ' => ' + (foundIndex >= 0 ? terms[foundIndex].title : termTitle) + ']</term>';

        // replace <a> with <term ...bla >
        textWithTermLinks = that.replaceAll(textWithTermLinks, linkToBeReplaced, termRepresentation);
      }
    }
    return textWithTermLinks;
  };

  that.addTermReferencesAfterEdition = function (termReferences, field) {
    var domParser = new DOMParser();

    var docElement = domParser.parseFromString(field, 'text/html').documentElement;
    var termReferencesInContent = docElement.getElementsByTagName('term');
    for (var i = 0; i < termReferencesInContent.length; i++) {
      if (termReferencesInContent[i].innerText.trim() != '') {
        termReferences.push(termReferencesInContent[i].getAttribute('value'));
      }
    }
  };

  that.getTermRelationDifferences = function (termReferencesAfterEdition, termReferencesBeforeEdition) {
    Array.prototype.diff = function (a) {
      return this.filter(function (i) { return a.indexOf(i) < 0; });
    };

    // now we need to compare $scope.termReferences vs termReferencesAfterEdition
    var newTermReferenceRelations = termReferencesAfterEdition.diff(termReferencesBeforeEdition);
    var termReferenceRelationsToBeDeleted = termReferencesBeforeEdition.diff(termReferencesAfterEdition);


    console.log('new term references: ' + newTermReferenceRelations);
    console.log('old term references to be deleted: ' + termReferenceRelationsToBeDeleted);

    return {
      relationsToAdd: newTermReferenceRelations,
      relationsToDelete: termReferenceRelationsToBeDeleted
    };
  };

  that.chunk = function (array, groupsize) {
    var sets = [];
    var chunks = array.length / groupsize;

    for (var i = 0, j = 0; i < chunks; i++, j += groupsize) {
      sets[i] = array.slice(j, j + groupsize);
    }

    return sets;
  };

  that.intersect = function (a, b) {
    var d = {};
    var results = [];
    for (var i = 0; i < b.length; i++) {
      d[b[i]] = true;
    }
    for (var j = 0; j < a.length; j++) {
      if (d[a[j]]) results.push(a[j]);
    }
    return results;
  };

  that.readOrderIsCorrect = function (items) {
    return items.every((item, index) => {
      return Number(item.data.readorder) === Number(index + 1);
    });
  };

  that.fixReadOrder = function (items) {
    items.map((item, index) => {
      item.data.readorder = Number(index + 1);
      let relationKey = item.relationKey ? item.relationKey : item.data.relationKey;
      item.data.relationHref = settings.resourcesNames.relations + '/' + relationKey;
    });
  };


  function groupByParentNode(rows) {
    let parentKeys = new Set();
    let groupedRows = [];

    rows.forEach((row) => {
      parentKeys.add(row.$$parent.data.key);
    });

    for (let key of parentKeys) {
      let sameParentRows = rows.filter((row) => {
        return row.$$parent.data.key === key;
      });

      groupedRows.push(sameParentRows);
    }

    return groupedRows;
  }

  that.updateReadOrderInTocRows = function (tocRows) {
    let levels = [2, 3, 4];

    levels.forEach((level) => {
      let levelTocRows = tocRows.filter((row) => {
        return row.$$level === level;
      });

      let rowGroupsToBeOrdered = groupByParentNode(levelTocRows);

      rowGroupsToBeOrdered.forEach((rowGroup) => {
        that.updateHideUpAndHideDownOrder(rowGroup);
      });
    });
  };

  that.updateHideUpAndHideDownOrder = function (items) {
    if (items.length === 1) {
      items[0].data.hideUpOrder = true;
      items[0].data.hideDownOrder = true;
    } else {
      items.map((item, index) => {
        if (index === 0) {
          item.data.hideUpOrder = true;
          item.data.hideDownOrder = false;
        } else if (index === items.length - 1) {
          item.data.hideUpOrder = false;
          item.data.hideDownOrder = true;
        } else {
          item.data.hideUpOrder = false;
          item.data.hideDownOrder = false;
        }
      });
    }
  };

  that.getBuildingBlocksFor = function (document, rootDocument) {
    if (rootDocument && rootDocument.issued && new Date(rootDocument.issued) < new Date()) {
      if (constants.curriculumTypes.includes(document.type)) {
        return [];
      }

      if (rootDocument.type === constants.llinkidCurriculum && constants.llinkidTypes.includes(document.type)) {
        return [];
      }
    }

    if (types[document.type] && types[document.type].buildingBlocks) {
      let buildingBlocks = types[document.type].buildingBlocks.map(bb => {
        // transform to new way of defining building blocks (as objects) if it's not using it yet
        if (typeof bb !== 'object') {
          bb = {
            type: bb
          };
        }
        return bb;
      });

      if (types[document.type].excludeFromBuildingBlocks && rootDocument && rootDocument.type) {
        types[document.type].excludeFromBuildingBlocks.forEach((itemToExclude) => {
          buildingBlocks = buildingBlocks.filter((item) => {
            return !(item.type === itemToExclude.type && itemToExclude.rootParent === rootDocument.type);
          });
        });
      }

      if (types[document.type].includeInBuildingBlocks && rootDocument && rootDocument.type) {
        let moreBuildingBlock = types[document.type].includeInBuildingBlocks.filter((itemToInclude) => {
          return itemToInclude.rootParent === rootDocument.type;
        });

        buildingBlocks = buildingBlocks.concat(moreBuildingBlock);
      }

      return buildingBlocks;
    }
    return [];
  };

  that.getTitleForTimeLineGroup = function (row) {
    if (row.educationsorts && row.educationsorts.length > 0
        || row.data && row.data.educationsorts && row.data.educationsorts.length > 0) {
      let educationSort = row.educationsorts ? row.educationsorts[0] : row.data.educationsorts[0];
      let buitengewoonHref = '/onderwijssoorten/' + constants.educationSortBuitegewoonKey;

      return educationSort === buitengewoonHref ? 'Leerlijn Buitengewoon' : 'Leerlijn Gewoon';
    }

    return '';
  };

  that.removeLastEntersFrom = function (text, enterPattern) {
    if (text.length >= enterPattern.length) {
      let res = text.substr(text.length - enterPattern.length, enterPattern.length);
      if (res === enterPattern) {
        let newText = text.substr(0, text.length - enterPattern.length);
        return that.removeLastEntersFrom(newText, enterPattern);
      }
      return text;
    }
    return text;
  };

  that.removeIntermediateEntersFrom = function (text, enterPattern, maxEnterCount) {
    if (text.length >= enterPattern.length) {
      let fullEnterPattern = enterPattern.repeat(maxEnterCount + 1);
      let foundInIndex = text.indexOf(fullEnterPattern);
      if (foundInIndex >= 0) {
        let controlIndex = foundInIndex + enterPattern.repeat(maxEnterCount).length;
        let res = text.substr(controlIndex, enterPattern.length);
        if (res === enterPattern) {
          let newText = text.substr(0, controlIndex) + text.substr(controlIndex + enterPattern.length, text.length);
          return that.removeIntermediateEntersFrom(newText, enterPattern, maxEnterCount);
        }
        return text;
      }
      return text;
    }
    return text;
  };

  that.addResourceIfNotInList = function (list, resource) {
    if (list) {
      let found = list.find((item) => {
        return item.key === resource.key;
      });
      if (!found) {
        list.push(resource);
      }
    }
  };

  that.isRootWebpage2 = function (rootDocument) {
    return rootDocument.type === constants.structuredType
      && rootDocument.tags
      && rootDocument.tags.includes(constants.proWebsiteTag);
  };

  that.hasRealChildren = function (node) {
    let nodes = node.$$children.filter((child) => {
      return child && child.$$type !== constants.dropZoneType;
    });
    return nodes.length > 0;
  };

  that.ckeditor = {
    config: {
      identifiers: {
        hideToolbar: true
      },
      title: {
        customButtons: ['term'],
        reducedToolbar: true,
        blockedKeystrokes: [13, CKEDITOR.SHIFT + 13]
      },
      description: {
        customButtons: ['term', 'demarcation', 'customLink'],
        height: 75
      },
      shortdescription: {
        customButtons: ['term', 'customLink'],
        reducedToolbar: true,
        height: 60
      },
      text: {
        customButtons: ['term', 'footnote', 'customLink'],
        height: 150
      }
    }
  };

  that.getKeyFrom = function (url) {
    let res = url.split('/');
    return res[res.length - 1];
  };

  that.getIntegerPartOf = function (decimalNumber) {
    return decimalNumber.toString().split('.')[0];
  };

  that.getDecimalPartOf = function (decimalNumber) {
    let parts = decimalNumber.toString().split('.');
    return Number('0.' + parts[1]);
  };

  that.truncator = function (numToTruncate, intDecimalPlaces) {
    var numPower = Math.pow(10, intDecimalPlaces); // "numPowerConverter" might be better
    return ~~(numToTruncate * numPower) / numPower;
  };

  that.isValidAgeRange = function (value) {
    return Number.isInteger(Number(value)) && Number(value) >= 0 && Number(value) <= 99;
  };

  that.removeLeftZeros = function (value) {
    return value.replace(/^0+/, '');
  };

  that.getYearsAndMonthFrom = function (age) {
    if (that.isValidAgeRange(age)) {
      return {
        year: age,
        month: 0
      };
    }
    return {
      year: that.getIntegerPartOf(age),
      month: Math.ceil(that.getDecimalPartOf(age) * 12)
    };
  };

  that.formatCurrDisplayTitle = (curr) => {
    let displayTitle = `${curr.title}`;
    if (curr.identifiers) displayTitle += ` ${curr.identifiers[0]}  `;
    if (curr.$$version) displayTitle += ` (v${curr.$$version.replace('.0.0', '')})`;
    if (curr.issued) displayTitle += ` (${curr.issued})`;
    
    return he.decode(displayTitle);
  }

  return that;
}];
