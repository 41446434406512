import * as ACTION_TYPES from '../constants/actionTypes';
import uuidv4 from 'uuid/v4';

export const addNotificationAction = (notification) => ({
  type: ACTION_TYPES.ADD_NOTIFICATION,
  payload: { key: uuidv4(), ...notification }
});

export const removeNotificationAction = (key) => ({
  type: ACTION_TYPES.REMOVE_NOTIFICATION,
  payload: key
});
