import './inlineDemarcation.scss';

class inlineDemarcation {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
  }

  $onChanges() {
  }
}

export const inlineDemarcationComponent = {
  transclude: true,
  template: require('./inlineDemarcation.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHref: '@'
  },
  controller: inlineDemarcation
};
