import { types } from '../config/types';
import constants from '../reduxLoop/constants/constants';
import { searchGroups } from '../reduxLoop/helpers/searchGroups';
const he = require('he');

const isMatch = (object, source) => {
  const isMatchWith = require('lodash/isMatchWith');
  Object.keys(source).forEach((key) => {
    if (object && object[key] === undefined && source && source[key] === false) {
      object[key] = undefined;
    }
  });
  return isMatchWith(object, source, (objValue, srcValue) => {
    if (srcValue === false && typeof objValue === 'undefined') {
      return true;
    }
    return undefined;
  });
};

export const getAllTypes = () => {
  return types;
};

export const getTitle = (node, { addIdentifier } = {}) => {
  let title = node.title || '';
  if (node.type !== constants.llinkidCurriculum && node.type !== constants.curriculumZill) {
    return title;
  }

  const version = node.$$version ? `(v${node.$$version.replace('.0.0', '')})` : '';
  const identifier = (node.identifiers && node.identifiers[0]) || '';

  const completeTitle = addIdentifier ? `${title} ${identifier} ${version}` : `${title} ${version}`;

  return he.decode(completeTitle);
};

export const getTypeConfig = (type) => {
  const config = types[type];
  if (config) {
    config.edit = config.edit ? config.edit.map(section => {
      if (typeof section === 'string') {
        section = {
          component: section
        };
      }
      return section;
    }) : [];

    config.buildingBlocks = config.buildingBlocks ? config.buildingBlocks.map(block => {
      if (typeof block === 'string') {
        block = {
          type: block
        };
      }
      return block;
    }) : [];

    return config;
  }
  console.log('Type not defined:', type);
  return undefined;
};

export const getUiType = (node) => {
  const matchingTypes = Object.keys(types)
    .map(o => ({
      type: o,
      value: types[o]
    }))
    .filter(o => {
      if (o.value.node) {
        return isMatch({ ...node }, o.value.node);
      }
      return false;
    });
  if (matchingTypes.length > 0) {
    return matchingTypes[0].type;
  }
  return node.type;
};

export const getIcon = (uiType) => {
  if (types[uiType] && types[uiType].information && types[uiType].information.icon) {
    return types[uiType].information.icon;
  }
  return types.GENERIC.information.icon;
};

export const sortFunction = (a, b) => {
  var nameA = a.information.single.toUpperCase(); // ignore upper and lowercase
  var nameB = b.information.single.toUpperCase(); // ignore upper and lowercase
  if (nameA < nameB) {
    return -1;
  }
  if (nameA > nameB) {
    return 1;
  }

  // names must be equal
  return 0;
};

export const groupSearchables = (searchables) => {
  const groupedSearchables = [...searchGroups];

  searchables.forEach((searchable) => {
    searchable.information.category = searchable.information.category || 'GENERAL';

    const group = groupedSearchables.find((g) => g.category === searchable.information.category);
    if (group) {
      group.searchables = [...(group.searchables || []), ...[searchable]];
    } else {
      groupedSearchables.push({
        category: searchable.information.category,
        searchables: [searchable],
      });
    }
  });

  return groupedSearchables;
};

export const getEditSections = (document, parentDocument, rootDocument) => {
  function filterLogic(item) {
    if (item.root && !isMatch(rootDocument || document.rootNode, item.root)) {
      return false;
    }

    if (item.parent && !isMatch(parentDocument || document.parentNode, item.parent)) {
      return false;
    }

    if (item.self && !isMatch(document, item.self)) {
      return false;
    }

    return true;
  }

  const config = types[document.$$type];
  if (config) {
    let editSections = config.edit;
    if (editSections && editSections.length > 0) {
      return editSections.filter(section => {
        if (section.whitelist && section.whitelist.length > 0) {
          if (section.whitelist.filter(filterLogic).length < 1) {
            return false;
          }
        }

        if (section.blacklist && section.blacklist.length > 0) {
          if (section.blacklist.filter(filterLogic).length > 0) {
            return false;
          }
        }

        return true;
      });
    }
  }

  return [];
};

/**
 * To be called from editController on init.
 * Each type that should do something extra can do it here.
 */
export const onEditLoad = (type) => {
  if (type === constants.llinkidGoalType) {
    // no needed anymore
    // Demarcation.loadDemarcations(loadedContentKey)
  }
};

/**
 * Perform a validation in the given document according to specific rules for each type.
 * return a list of error codes if valdidation fails.
 */
export const validate = (document) => {
  if (types[document.$$type] && types[document.$$type].validate) {
    return types[document.$$type].validate(document, types[document.$$type]);
  }
  return [];
};

const module = ['Demarcation', 'constants', '$injector', function(Demarcation, constants, $injector) { //eslint-disable-line

  let that = {};
  that.types = types;
  that.getTypeConfig = getTypeConfig;
  that.getUiType = getUiType;
  that.getIcon = getIcon;
  that.sortFunction = sortFunction;

  that.getEditSections = getEditSections;
  that.onEditLoad = onEditLoad;
  /**
   * Called when the document view finished to load, all the structures are created.
   * Each type that should do something extra can do it here.
   */
  // TODO: PLACE THIS CODE SOMEWARE ELSE;
  that.onDocumentViewLoad = (rows, data = {}) => {
    if (rows[0].$$type === constants.llinkidCurriculum) {
      // LLINKID_GOAL_DEMARCATION: set goal part reference
      rows.filter(row => row.$$type === constants.llinkidGoalDemarcationType)
        .map(row => {
          const goalDescription = row.$$parent.data.description;

          if (!goalDescription) {
            return row;
          }

          var domParser = new DOMParser();
          var docElement = domParser.parseFromString(goalDescription, 'text/html').documentElement;
          var allLinksElements = docElement.getElementsByTagName('a');

          Array.prototype.forEach.call(allLinksElements, linkElement => {
            var linkHref = linkElement.getAttribute('href');
            var innerText = linkElement.innerText;

            if (row.data.href === linkHref) {
              row.data.goalPart = innerText;
            }
          });
        });

      // LLINKID_GOAL: transform description demarcation links into elements
      rows.filter(row => row.$$type === constants.llinkidGoalType)
        .map(goal => {
          // goal.data.description = Utils.transformTermLinksIntoTerm(goal.data.description, data.TERMS);
          goal.data.description = Demarcation.transformLinksIntoDemarcation(goal.data.description);
        });
    }
  };

  that.validate = validate;

  return that;
}];

export default module;
