import { install } from 'redux-loop';
import { rootReducer } from './reducers';
import * as ACTION_TYPES from './constants/actionTypes';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import initSagas from '../store/saga';
import { settings } from '../config/settings';
import { initUser } from '@newStore/user/userState';

export const initState = (store) => {
  store.dispatch({ type: ACTION_TYPES.INIT });
  store.dispatch(initUser());
};

/**
 * This function will initiate the store of the application.
 */
const setupStore = (preloadedState?: any) => {
  const sagaMiddleware = createSagaMiddleware();

  if (process.env.NODE_ENV === 'development' && settings.enableMswInBrowser) {
    const { worker } = require('../mocks/browser');
    const workerConfig = {
      // turn off MSW warnings for specific routes
      onUnhandledRequest(req, print) {
        if (req.url.pathname.startsWith('/fonts/')) {
          return;
        }

        const excludedRoutes = [
          '/favicon-32x32.png',
          '/sockjs-node/info',
          '/resource/errors_nl.json',
        ];

        const isExcluded = excludedRoutes.some((route) => req.url.pathname.includes(route));
        if (isExcluded) {
          return;
        }

        print.warning();
      },
    };

    worker.start(workerConfig);
  }

  /**
   * callback function for the configureStore, to make sure that redux-loop is installed before the middleware.
   * otherwise, redux saga will not be able to listen to loop actions.
   * @param {Array} enhancers - An array of store enhancers.
   * @returns {Array} - An array of store enhancers with redux-loop installed before the middleware.
   */
  const enhancersInOrder = (enhancers) => {
    return [install(), ...enhancers];
  };

  const store = configureStore({
    reducer: rootReducer,
    middleware: [
      ...getDefaultMiddleware({
        thunk: false,
        // immutableCheck: false, // TODO: enable this once we stop crashing on it, or configure it to ignore some paths.
        immutableCheck: {
          ignoredPaths: [
            'documentList',
            'document',
            'security',
            'documentDrag',
            'user',
            'security',
            'notifications',
          ],
        },
        serializableCheck: {
          ignoredPaths: [],
          ignoredActions: [],
        },
      }),
      sagaMiddleware,
    ],
    enhancers: enhancersInOrder,
    preloadedState,
    devTools: {
      serialize: {
        replacer: (key, value) => {
          if (value instanceof Map) {
            return {
              dataType: 'Map',
              value: [...value.entries()],
            };
          }

          const keysToOverwrite = [
            '$$root',
            '$$parent',
            'sriClient',
            '$$children',
            '$$dropZones',
            '$$relationsTo',
            '$$relationsFrom',
          ];

          if (value && keysToOverwrite.includes(key)) {
            if (['$$root', '$$parent'].includes(key)) {
              // @ts-ignore
              return { key: value.key };
            }
            if (key === '$$children') {
              // @ts-ignore
              return value.map((x) => ({ key: x.key }));
            }
            if (['$$relationsFrom', '$$relationsTo'].includes(key)) {
              // @ts-ignore
              return value.map((x) => ({
                href: x.href,
                $$expanded: {
                  from: x.$$expanded && x.$$expanded.from && x.$$expanded.from.href,
                  to: x.$$expanded && x.$$expanded.to && x.$$expanded.to.href,
                },
              }));
            }
            return `<<${key}-HIDDEN>>`;
          }
          return value;
        },
      },
    },
  });

  initSagas(sagaMiddleware);

  if (process?.env?.JEST_WORKER_ID === undefined) {
    // TODO: test without this IF
    // temporarily disable these inits for jest tests

    // Start with an INIT action
    initState(store);
  }

  return store;
};

export default setupStore;
