import { settings } from '../../settings';
import { applicabilityNotEmpty } from '../../../validations/applicabilityNotEmpty';
import { curriculumThemeNotEmpty } from '../../../validations/curriculumThemeNotEmpty';
import { secondaryEducationTypesNotEmpty } from '../../../validations/secondaryEducationTypesNotEmpty';
import { expiryDateValidation } from '../../../validations/expiryDateValidation';
import * as ACTION_TYPES from '@store/constants/actionTypes';

export default {
  information: {
    definiteArticle: true,
    single: 'Leerplan secundair',
    plural: 'Leerplannen secundair',
    icon: require('../../../../img/icons/llinkid.svg'),
    category: 'LEERPLAN',
  },
  preloadActions: [
    {
      type: ACTION_TYPES.GET_SECONDARY_EDUCATION_TYPES,
    },
  ],
  node: { type: 'LLINKID_CURRICULUM' },
  createDefaults: {
    realisationPeriod: 24,
    version: {
      major: 1,
      minor: 0,
      patch: 0,
    },
  },
  createModal: {
    authors: {
      addUser: true,
    },
  },
  indentation: 1,
  edit: [
    {
      component: 'foundational',
      type: 'checkbox',
    },
    {
      component: 'identifier',
      width: 2,
    },
    {
      component: 'title',
      width: 8,
      options: {
        showMarkerButton: true,
      },
    },
    {
      component: 'version',
      width: 2,
      readonly: true,
    },
    {
      component: 'previousVersion',
      readonly: true,
    },
    'authors',
    {
      component: 'issued',
      width: 4,
    },
    {
      component: 'realisationPeriod',
      width: 4,
      options: {
        schoolyears: 2,
      },
    },
    {
      component: 'expiryDate',
      width: 4,
      validations: [expiryDateValidation],
    },
    {
      component: 'validityPeriod',
      width: 12,
      readonly: true,
    },
    {
      component: 'applicability',
      type: 'select',
      label: 'components.llinkid.applicability.title',
      options: {
        clearAllAllowed: true,
        selectAllAllowed: false,
        placeholder: 'Study programmes...',
        subfield: 'studyProgrammes',
      },
      validations: [applicabilityNotEmpty],
    },
    {
      component: 'curriculumTheme',
      validations: [curriculumThemeNotEmpty],
    },
    {
      component: 'secondaryEducationTypes',
      validations: [secondaryEducationTypesNotEmpty],
    },
    {
      component: 'educationalPointers',
      type: 'llinkidReferences',
      label: 'Wegwijzers',
      options: {
        referenceFrame: 'EDUCATIONAL_POINTERS',
      },
    },
    {
      component: 'educationalComponents',
      type: 'llinkidReferences',
      label: 'Vormingscomponenten',
      options: {
        referenceFrame: 'EDUCATIONAL_COMPONENTS',
      },
    },
    {
      component: 'image',
      label: 'Omslagfoto',
      options: {
        type: 'COVER_IMAGE',
        fields: [],
      },
    },
  ],
  tocTypes: [
    'LLINKID_GUIDING_PRINCIPLE_GROUP',
    'LLINKID_GOAL_SECTION',
    'LLINKID_GOAL_LIST',
    'LLINKID_CORRELATION_ENDTERMS',
    'LLINKID_CONTEXT',
    'LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT',
    'LLINKID_BASIC_MATERIAL_REQUIREMENTS',
    'SECTION',
  ],
  buildingBlocks: [
    {
      type: 'LLINKID_GUIDING_PRINCIPLE_GROUP',
      min: 1,
      max: 1,
      readorder: 1,
    },
    {
      type: 'LLINKID_CONTEXT',
      min: 1,
      max: 1,
      readorder: 2,
    },
    {
      type: 'LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT',
      min: 1,
      max: 1,
      readorder: 3,
    },
    {
      type: 'LLINKID_GOAL_LIST',
      min: 1,
      max: 1,
      readorder: 4,
    },
    {
      type: 'LLINKID_BASIC_MATERIAL_REQUIREMENTS',
      min: 1,
      max: 1,
      readorder: 5,
    },
    {
      type: 'LLINKID_CORRELATION_ENDTERMS',
      min: 1,
      max: 1,
      readorder: 6,
    },
    {
      type: 'TERM',
      disableDropInDocument: true,
    },
  ],
  asDocumentView: true,
  isCreatable: true,
  isSearchable: true,
  isCloneable: true,
  previewModes: [
    {
      type: 'URL',
      name: 'LLinkID',
      location: settings.apisAndUrls.llinkid + '/#!/leerplan/{%key}/doelenlijst?preview=true',
    },
  ],
  disableDeleteWhenIssued: true,
};
