/* eslint-disable max-len */
import { config as sanitizeHTMLConfig } from '../constants/sanitizeHTML';
import { config as documentTypes } from '../constants/documentTypes';
import uuidv4 from 'uuid/v4';
import { types, documentTypesAllowingSuggestions } from '../../config/types';
import { createContentCmd, createContentWithThumbnailCmd } from '../commands/documentListCommands';
import { hasDeletionProposal, getProposalType } from '../viewmodels/proposalViewModel';
import { getRelatedContentHref, updateApiWithPendingChanges } from './documentStateHelpers';
import { createDocumentTree } from '../createDocumentTree';
import { treeToFlatVM } from '../viewmodels/createDocumentViewModel';
import { relationTypes } from '../../reduxLoop/constants/relationTypes';
import * as apiRoutes from '../../reduxLoop/api/apiRoutes';
import { replacePathSpecialCharacters } from '../helpers/webConfigHelpers';
import * as constants from '../constants/constants';
import * as newsletterTypes from '../constants/newsletterTypes';
const TreeModel = require('tree-model');

export const isAttachmentsGroupOnNodeLevelAllowed = (node) => {
  const couldNodeContainAttachmentsGroup = node.$$typeConfig.globalAttachmentsGroupContainer;
  if (!couldNodeContainAttachmentsGroup) {
    return false;
  }

  const isNodeWebsiteConfigurationSatisfied = node.websitesConfiguration.some((c) =>
    documentTypes.globalAttachmentsGroupWebconfigurationSelfTypes.includes(c.type)
  );
  const isNodeParentWebsiteConfigurationSatisfied =
    node.$$parent &&
    node.$$parent.websitesConfiguration.some((c) =>
      documentTypes.globalAttachmentsGroupWebconfigurationParentTypes.includes(c.type)
    );

  return isNodeWebsiteConfigurationSatisfied || isNodeParentWebsiteConfigurationSatisfied;
};

export const createMessageForPro = (apiWithPendingChanges) => {
  const items = [...apiWithPendingChanges.content.values()]
    .filter(content => {
      // filter out nodes suggested to be deleted
      const proposal = apiWithPendingChanges.proposals.get('/content/' + content.key);
      return !proposal || getProposalType(proposal) !== 'DELETE';
    }).map(content => {
      return {
        ...content,
        attachments: content.attachments.map(attachment => {
          return {
            ...attachment,
            href: attachment.href && attachment.href.indexOf('undefined') === -1 && !attachment.$$base64 && attachment.href.indexOf('/proposals') === -1
              ? attachment.href : (attachment.$$url || '')
          };
        }),
        $$html: content.html
      };
    });
  const relations = apiWithPendingChanges.contentRelations;
  translateItemsintoContentStyle(items, relations);

  return {
    items,
    webpages: [...apiWithPendingChanges.webpages.values()]
  };
};


function appendExpanded(relation) {
  if (!relation.$$expanded) {
    return {
      href: `/content/relations/${relation.key}`,
      $$expanded: relation
    };
  }
  return relation;
}

function translateItemsintoContentStyle(items, relations) {
  items.forEach((item) => {
    if (item.html) {
      item.$$html = item.html;
    }

    if (!item.$$meta) {
      item.$$meta = {
        permalink: `/content/${item.key}`
      };
    }

    item.$$relationsFrom = [];
    const fromRelations = relations.from[`/content/${item.key}`];
    if (fromRelations) {
      item.$$relationsFrom = fromRelations.map(relation => appendExpanded(relation));
    }
    item.$$relationsTo = [];
    const toRelations = relations.to[`/content/${item.key}`];
    if (toRelations) {
      item.$$relationsTo = toRelations.map(relation => appendExpanded(relation));
    }
  });
}

export const groupAttachments = (fileUploads) => {
  return fileUploads.reduce((result, u) => {
    const exists = result.find(r => r.contentHref === u.body.relatedTo.href);
    if (exists) {
      exists.attachments.push(u.body);
    } else {
      result.push({
        contentHref: u.body.relatedTo.href,
        attachments: [u.body]
      });
    }
    return result;
  }, []);
};

export const flatTree = (flat, original) => {
  const tree = new TreeModel({ childrenPropertyName: '$$children' });
  const node = tree.parse(angular.copy(original));
  node.walk(n => {
    const children = [];
    n.children.forEach(c => {
      c.walk(cn => {
        children.push({ href: cn.model.$$meta.permalink, title: cn.model.title });
      });
    });
    flat.set(n.model.$$meta.permalink, children);
  });

  return flat;
};

// For the themes matching, the subjects newsletter only looks at the two sections Curriculum: vakken en leerplannen in the reference frame.
// The thematic newsletter looks at all the rest.
export const getRefFrameItemsMap = (newsletterTypeHref, tree) => {
  const treeModel = new TreeModel({ childrenPropertyName: '$$children' });
  const node = treeModel.parse(angular.copy(tree));
  const oldCurrBranch = node
    .first((n) => n.model.key === constants.sectionCurrVakkenEnLeerplannen)
    .drop();
  const newCurrBranch = node
    .first((n) => n.model.key === constants.sectionCurrVakkenEnLeerplannenNieuw)
    .drop();

  if (newsletterTypeHref === newsletterTypes.thematic.href) {
    return flatTree(new Map(), node.model);
  }

  if (newsletterTypeHref === newsletterTypes.subjectSpecific.href) {
    return new Map([
      ...flatTree(new Map(), oldCurrBranch.model),
      ...flatTree(new Map(), newCurrBranch.model),
    ]);
  }

  return undefined;
};

export const isTeaserUsedInCurrentNewsletter = (teaser, content, relations) => {
  const sections = [...content.values()].filter(c => c.type === 'SECTION').map(s => s.$$meta.permalink);
  return [...relations.values()].find(r => sections.find(s => r.from.href === teaser.$$meta.permalink && r.to.href === s));
};

export const linkTeaserToNewsItem = (key, teaserKey, newsItemKey, title, authors, attachments, typeConfig, http) => {
  const document = createDocument({
    key,
    authors,
    title,
    node: typeConfig.node,
    createDefaults: typeConfig.createDefaults
  });
  const resources = createReferenceResources(
    {
      key: teaserKey
    },
    types.REFERENCE.node,
    'Lees meer',
    '/content/' + newsItemKey,
    1
  );
  const resourcesBatch = resources.map(r => {
    return {
      verb: 'PUT',
      href: r.href,
      body: {
        ...r.body,
        $$new: r.body.$$new || false
      }
    };
  });

  const documentBatch = convertToBatch(document);
  const batch = [documentBatch, ...resourcesBatch];
  const attachment = attachments.length > 0 ? attachments[0] : undefined;
  const webpagesBatch = getWebConfigurationBatch(typeConfig.webconfiguration, key, title);

  const name = attachment ? createContentWithThumbnailCmd : createContentCmd;
  const args = attachment ? [batch, webpagesBatch, attachment, http] : [batch, webpagesBatch];

  return {
    name,
    args
  };
};

export const addEditLinkReferenceNode = (state, parentKey, referenceKey, label, referencedResourceHref) => {
  return referenceKey
    ? editLinkReferenceNode(state, parentKey, referenceKey, label, referencedResourceHref)
    : addLinkReferenceNode(state, parentKey, label, referencedResourceHref);
};

export const addLinkReferenceNode = (state, parentKey, label, referencedResourceHref, isUnderGroup) => {
  const parent = state.apiWithPendingChanges.content.get(`/content/${parentKey}`);
  const resources = createReferenceResources(
    parent,
    types.REFERENCE.node,
    label,
    referencedResourceHref,
    parent.$$children.length + 1,
    isUnderGroup
  );

  const pendingActions = [...state.pendingActions];
  pendingActions.push({
    type: 'CREATE',
    resources
  });

  return pendingActions;
};

export const getRelationPatch = (relation, patch) => {
  return {
    href: `/content/relations/${relation.key}`,
    relatedTo: { href: relation.from.href },
    patch
  };
};

export const editLinkReferenceNode = (state, parentKey, referenceKey, label, resourceHref) => {
  const reference = state.apiWithPendingChanges.content.get('/content/' + referenceKey);
  const relation = state.apiWithPendingChanges.contentRelations.from['/content/' + referenceKey]
    .find(r => r.relationtype === 'REFERENCES');

  const pendingActions = [...state.pendingActions];
  pendingActions.push({
    type: 'PATCH',
    resources: [{
      href: `/content/${referenceKey}`,
      parentHref: parentKey ? `/content/${parentKey}` : undefined,
      patch: [{ op: reference && reference.title ? 'replace' : 'add', path: '/title', value: label }]
    },
    getRelationPatch(relation, [{ op: relation && relation.to ? 'replace' : 'add', path: '/to', value: { href: resourceHref } }])]
  });

  return pendingActions;
};

export const createReferenceResources = (parent, node, label, toHref, readorder, isUnderGroup) => {
  const newKey = uuidv4();
  const newRelationKey = uuidv4();
  const newReferencesRelationKey = uuidv4();
  const newGroupKey = uuidv4();
  const newGroupRelationKey = uuidv4();

  const newLinkReference = {
    key: newKey,
    type: 'REFERENCE',
    tags: ['LINK'],
    title: label,
    $$new: 'true',
    node
  };
  if (!newLinkReference.attachments) { newLinkReference.attachments = []; }
  if (!newLinkReference.importance) { newLinkReference.importance = 'MEDIUM'; }

  let toKey = parent.key;
  let newLinkGroup;
  let newLinkGroupRelation;

  if (isUnderGroup) {
    const linkGroup = parent.$$children.find(c => c.$$type === 'LINK_GROUP');

    if (!linkGroup) {
      // we need to create the link group and relation to parent also
      newLinkGroup = {
        key: newGroupKey,
        type: 'LINK_GROUP',
        $$new: 'true',
        attachments: [],
        importance: 'MEDIUM'
      };

      newLinkGroupRelation = {
        key: newGroupRelationKey,
        relationtype: 'IS_PART_OF',
        readorder,
        from: {
          href: '/content/' + newGroupKey
        },
        to: {
          href: '/content/' + parent.key
        }
      };
    }

    toKey = linkGroup ? linkGroup.key : newLinkGroup.key;
  }

  let newRelation = {
    key: newRelationKey,
    relationtype: 'IS_PART_OF',
    readorder,
    from: {
      href: '/content/' + newKey
    },
    to: {
      href: '/content/' + toKey
    }
  };

  let newExternalSourceRelation = {
    key: newReferencesRelationKey,
    relationtype: 'REFERENCES',
    strength: 'MEDIUM',
    from: {
      href: '/content/' + newLinkReference.key
    },
    to: {
      href: toHref
    }
  };

  let resources = [
    {
      href: '/content/' + newKey,
      body: newLinkReference,
      parentHref: '/content/' + parent.key
    },
    {
      href: '/content/relations/' + newRelationKey,
      relatedTo: { href: '/content/' + newKey },
      body: newRelation
    },
    {
      href: '/content/relations/' + newReferencesRelationKey,
      relatedTo: { href: '/content/' + newKey },
      body: newExternalSourceRelation
    }
  ];

  if (newLinkGroup) {
    resources = [
      ...resources,
      {
        href: '/content/' + newLinkGroup.key,
        body: newLinkGroup
      },
      {
        href: '/content/relations/' + newLinkGroupRelation.key,
        relatedTo: { href: '/content/' + newLinkGroup.key },
        body: newLinkGroupRelation
      }
    ];
  }

  return resources;
};

export const convertToBatch = (document) => {
  return {
    verb: 'PUT',
    href: '/content/' + document.key,
    body: document
  };
};

export const createDocument = (newContentParams) => {
  let newDocument = {
    $$meta: { permalink: '/content/' + newContentParams.key },
    key: newContentParams.key,
    type: newContentParams.node.type,
    isNew: true,
    title: newContentParams.title,
    readorder: 1,
    attachments: [],
    creators: [],
    importance: 'MEDIUM',
    language: 'nl',
    created: (new Date()).toISOString(),
    modified: (new Date()).toISOString(),
    ...newContentParams.node,
    ...newContentParams.createDefaults,
    $$children: []
  };
  if (newContentParams.documentType) {
    newDocument.tags = [...newDocument.tags, newContentParams.documentType.value];
  }
  if (newContentParams.identifier) {
    newDocument.identifiers = newDocument.identifiers
      ? [...newDocument.identifiers, newContentParams.identifier]
      : [newContentParams.identifier];
  }
  newDocument.creators = newContentParams.authors;

  return newDocument;
};

export const isResourceSupported = (url) => {
  const supportedResources = ['/content/', '/events/', '/persons/', '/training/modules/', '/web/sites/', '/websites/'];
  return supportedResources.some(resource => url.startsWith(resource));
};

export const isResourceToBeExpanded = (href, resourcesToExpand, expandedResources, notFoundResourcesSet) => {
  return !resourcesToExpand.some(r => r.href === href)
    && !expandedResources[href]
    && isResourceSupported(href)
    && !notFoundResourcesSet.has(href);
};

/**
 * From the relations we currently have in the state get all the resources that need to be expanded.
 * Resources that have already been expanded (found or not) are ignored.
 * These relation types don't need to be expanded:
 *   IS_PART_OF: loaded in the root call.
 *   IS_VERSION_OF: loaded in a separate action INIT_ZILL_ODET_CURRICULUM_DOCUMENT.
 *   REPLACES: this is a reference to the original and is not needed in the document.
 *   IS_INCLUDED_IN: loaded in the root call.
 * @param {Array} relations
 * @param {Object} expandedResources
 * @param {Set} notFoundResourcesSet
 * @returns {Array} The resources to be expanded.
 */
export const getResourcesToExpand = (content, expandedResources, notFoundResourcesSet) => {
  const relations = content.filter(c => c.$$relationsFrom).map(c => c.$$relationsFrom).flat();
  const excludedRelationTypes = ['IS_PART_OF', 'IS_VERSION_OF', 'REPLACES', 'IS_INCLUDED_IN'];
  return relations.reduce((resourcesToExpand, relation) => {
    if (!excludedRelationTypes.includes(relation.$$expanded.relationtype)
      && isResourceToBeExpanded(relation.$$expanded.to.href, resourcesToExpand, expandedResources, notFoundResourcesSet)) {
      resourcesToExpand.push({ href: relation.$$expanded.to.href });
    }
    return resourcesToExpand;
  }, []);
};

/**
 * This function will give you a list of all keys that fall under this tree item.
 * @param tree - Tree that you want to know all children keys of.
 * @returns {array}
 */
export const keysUnderNode = (tree) => {
  if (tree) {
    if (tree.$$children && tree.$$children.length > 0) {
      return [
        tree.key,
        ...tree.$$children.reduce((rows, child) => [...rows, ...keysUnderNode(child)], []),
      ];
    }
    return [tree.key];
  }
  return [];
};

/**
 * This function will give you a list of all hrefs that fall under this node.
 * The node itself is not included by default.
 * @param node - Node that you want to know all children hrefs of.
 * @param includeNode - Set to true if you also want to include the node itself.
 * @returns {Array} A list of child hrefs.
 */
export const hrefsUnderNode = (node, includeNode = false) => {
  if (!node) {
    return [];
  }

  const hrefs = includeNode ? [node.$$meta.permalink] : [];

  if (!node.$$children || !node.$$children.length) {
    return hrefs;
  }

  return [
    ...hrefs,
    ...node.$$children.reduce(
      (childHrefs, child) => [...childHrefs, ...hrefsUnderNode(child, true)],
      []
    ),
  ];
};

export const findContent = (key, contentMap) => {
  return [...contentMap.values()].find(c => c.key === key);
};

export const getContentPermalink = (key, contentMap) => {
  const content = findContent(key, contentMap);
  if (content && content.$$meta) {
    return content.$$meta.permalink;
  }
  return '/content/' + key;
};

export const trustAsHtml = ['$sce', ($sce) => {
  // TODO not working
  return (value) => {
    return $sce.trustAsHtml(value);
  };
}];

export const formatDate = (isoDate, includeHour = true) => {
  const moment = require('moment');
  if (isoDate) {
    return moment(isoDate).format('DD/MM/YYYY' + (includeHour ? ' HH:mm' : ''));
  }
  return '';
};

function excludeFromStart(str, tokens) {
  // whitespace is always ignored
  str = str.trimStart();
  tokens.map((token) => {
    if (str.startsWith(token)) {
      str = str.slice(token.length);
      str = excludeFromStart(str, tokens);
    }
    return null;
  });
  return str;
}

function excludeFromEnd(str, tokens) {
  // whitespace is always ignored
  str = str.trimEnd();
  tokens.map((token) => {
    if (str.endsWith(token)) {
      str = str.slice(0, -token.length);
      str = excludeFromEnd(str, tokens);
    }
    return null;
  });
  return str;
}

export const sanitizeHTML = (html, type, trim) => {
  const sanitize = require('sanitize-html');
  if (html && typeof html === 'string') {
    const options = sanitizeHTMLConfig[type];

    html = html.trim();
    html = sanitize(html, {
      allowedTags: false,
      allowedAttributes: false,
      transformTags: {
        'inline-term': (tagName, attribs) => {
          return {
            tagName: 'a',
            attribs: {
              href: attribs['s-href'],
              rel: 'term'
            }
          };
        },
        'inline-demarcation': (tagName, attribs) => {
          return {
            tagName: 'a',
            attribs: {
              href: attribs['s-href'],
              rel: 'demarcation'
            }
          };
        },
        'inline-footnote': (tagName, attribs) => {
          return {
            tagName: 'a',
            attribs: {
              ...attribs,
              href: attribs['s-href'],
              rel: 'footnote'
            }
          };
        },
        'inline-mark-explanation': (tagName, attribs) => {
          return {
            tagName: 'span',
            attribs: {
              'data-href': attribs['s-href'],
              'data-rel': 'mark-explanation'
            }
          };
        }
      },
      textFilter: (text) => {
        return trim ? text.trimRight() : text;
      }
    });
    if (options) {
      html = sanitize(html, options);
    }

    html = excludeFromStart(html, ['<br />', '<br>']);
    html = excludeFromEnd(html, ['<br />', '<br>']);
    html = html.replace(/<br\s*[/]?>/gi, '<br>'); // replace <br /> with <br>
    html = html.replace(/(\r\n|\n|\r)/gm, '');
    html = html.replace(/<\/ul><br>/gi, '</ul>');
  }
  return html;
};

export const isMatch = (object, source) => {
  const isMatchWith = require('lodash/isMatchWith');
  Object.keys(source).forEach((key) => {
    if (object && object[key] === undefined && source && source[key] === false) {
      object[key] = undefined;
    }
  });
  const match = isMatchWith(object, source, (objValue, srcValue) => {
    if (srcValue === false && typeof objValue === 'undefined') {
      return true;
    }
    if (Array.isArray(srcValue) && Array.isArray(objValue)
      && srcValue.length !== objValue.filter(c => !c.$$isHidden).length) {
      return false;
    }
    return undefined;
  });
  return match;
};

export const replaceAll = (str, search, replacement) => {
  return str.split(search).join(replacement);
};

export const filterConfigBasedOnNode = (config, node, state) => {
  function filterLogic(item) {
    if (item.root && !isMatch(node.$$root, item.root)) {
      return false;
    }

    if (item.parent && !isMatch(node.$$parent, item.parent)) {
      return false;
    }

    // Return false if the node has children.
    // Delete proposals are already taken into account.
    if (
      item.self && item.self.hasNoChildren
      && (
        (node.proposal && node.proposal.isDeleted)
        || node.$$children.some((child) => !hasDeletionProposal(child, state))
      )
    ) {
      return false;
    }

    if (item.webconfiguration) {
      if (item.webconfiguration.self
        && !(
        item.webconfiguration.self.some(wc => node.websitesConfiguration.map(c => c.type).includes(wc))
        || (item.webconfiguration.selfEmpty && node.websitesConfiguration.filter(wc => !wc.deleteProposal).length === 0)
        )
      ) {
        return false;
      }

      if (item.webconfiguration.parent
        && !item.webconfiguration.parent.some(wc => node.$$parent && node.$$parent.websitesConfiguration.map(c => c.type).includes(wc))) {
        return false;
      }

      if (item.webconfiguration.root
        && !(
        item.webconfiguration.root.some(wc => node.$$root && node.$$root.websitesConfiguration && node.$$root.websitesConfiguration.map(c => c.type).includes(wc))
        || (item.webconfiguration.rootEmpty && node.$$root.websitesConfiguration && node.$$root.websitesConfiguration.length === 0)
        )
      ) {
        return false;
      }

      if (item.webconfiguration.inherited
        && !item.webconfiguration.inherited.some(wc => node.inheritedWebConfigurations.map(c => c.type).includes(wc))
        && !item.webconfiguration.inherited.some(wc => node.websitesConfiguration.map(c => c.type).includes(wc))) {
        return false;
      }
    }

    return true;
  }

  if (config && config.length > 0) {
    return config.filter(c => {
      if (c.whitelist && c.whitelist.length > 0) {
        if (c.whitelist.filter(filterLogic).length < 1) {
          return false;
        }
      }

      if (c.blacklist && c.blacklist.length > 0) {
        if (c.blacklist.filter(filterLogic).length > 0) {
          return false;
        }
      }

      return true;
    });
  }

  return [];
};

export const countCharacters = (text) => {
  const replaced = text ? text
    .replace(/&nbsp;/g, ' ')
    .replace(/&gt;/g, '>')
    .replace(/(<([^>]+)>)/ig, '')
    .replace(/(\r\n|\n|\r)/gm, '') : '';
  return replaced.length;
};

export const getAnnotations = (document) => {
  let annotations = [];
  const sanitize = require('sanitize-html');

  let fields = ['title', 'description', 'html'];
  fields.forEach(field => {
    sanitize(sanitizeHTML(document[field], field), {
      allowedTags: false,
      allowedAttributes: false,
      exclusiveFilter: (frame) => {
        const href = frame.attribs.href || frame.attribs['data-href'];
        if (href && href.startsWith('/content/')) {
          annotations.push({
            text: frame.text.trim(),
            type: frame.attribs.rel || frame.attribs['data-rel'] || 'term',
            href,
            field: field,
            $$attribs: frame.attribs
          });
        }
        return true;
      }
    });
  });
  return annotations;
};

export const deleteChildrenRecursiveFrom = (document, allChildren, batch) => {
  let documentChildrenHrefs = document.$$relationsTo.filter((relation) => {
    return relation.$$expanded.relationtype === 'IS_PART_OF';
  }).map((relation) => {
    return relation.$$expanded.from.href;
  });

  let documentChildrenRelationsHref = [
    ...document.$$relationsTo.map((relation) => {
      return relation.href;
    }),
    ...document.$$relationsFrom.map((relation) => {
      return relation.href;
    })
  ];

  let documentChildren = allChildren.filter((child) => {
    return documentChildrenHrefs.includes(child.$$meta.permalink);
  });

  documentChildrenHrefs.forEach((documentHref) => {
    batch.push({
      verb: 'DELETE',
      href: documentHref
    });
  });

  documentChildrenRelationsHref.forEach((relationHref) => {
    batch.push({
      verb: 'DELETE',
      href: relationHref
    });
  });

  documentChildren.forEach((doc) => {
    deleteChildrenRecursiveFrom(doc, allChildren, batch);
  });
};

export const getReferenceFrameRelationDifferences = (selectedBefore, selectedAfter) => {
  let referenceFrameBeforeEditionHrefs = selectedBefore.map((reference) => {
    return reference.$$expanded
      ? reference.$$expanded.$$meta.permalink
      : reference.$$meta.permalink;
  });
  let referenceFrameAfterEditionHrefs = selectedAfter.map((reference) => {
    return reference.$$expanded
      ? reference.$$expanded.$$meta.permalink
      : reference.$$meta.permalink;
  });

  // now we need to compare referenceFrameBeforeEdition vs referenceFrameAfterEdition
  let newReferenceFrameRelations = referenceFrameAfterEditionHrefs
    .diff(referenceFrameBeforeEditionHrefs);
  let referenceFrameRelationsToBeDeleted = referenceFrameBeforeEditionHrefs
    .diff(referenceFrameAfterEditionHrefs);

  return {
    relationsToAdd: newReferenceFrameRelations,
    relationsToDelete: referenceFrameRelationsToBeDeleted
  };
};

export const getKeyFromContentHref = (href) => {
  return href.replace('/content/', '');
};

export const isEmpty = (value) => {
  if (typeof (value) === 'number' || typeof (value) === 'boolean') {
    return false;
  }
  if (typeof (value) === 'undefined' || value === null) {
    return true;
  }
  if (typeof (value.length) !== 'undefined') {
    return value.length === 0;
  }
  let count = 0;
  for (let i in value) {
    if (value.hasOwnProperty(i)) {
      count++;
    }
  }
  return count == 0;
};

export const clearDemarcationLinks = (description) => {
  const domParser = new DOMParser();
  const docElement = domParser.parseFromString(description, 'text/html').documentElement;
  const allLinksElements = docElement.getElementsByTagName('a');

  for (let j = 0; j < allLinksElements.length; j += 1) {
    let innerText = allLinksElements[j].innerText;

    let linkToBeReplaced = allLinksElements[j].outerHTML;

    let representation = innerText;
    // replace <a> with ''
    description = replaceAll(description, linkToBeReplaced, representation);
  }
  return description;
};

// fill $$expand part of the relations in result
export const fillExpandedPartOfRelations = (relationsInStateToExpand, relationPartToExpand, state, results) => {
  relationsInStateToExpand.forEach((rel) => {
    // note: results is a batch opration results list
    const expanded = results
      .find(result => result.href === rel[relationPartToExpand].href);

    if (expanded) {
      const relationsTo = {};
      const nodesMap = new Map();
      if (expanded.$$treeAsLeaf) {
        expanded.$$treeAsLeaf.forEach((n) => {
          relationsTo[`/content/${n.key}`] = n.$$relationsTo.map(r => r.$$expanded);
          nodesMap.set(`/content/${n.key}`, n);
        });

        // create a tree of the referenced document and flat to sort it
        let flat = [];
        const root = getRoot(expanded.$$treeAsLeaf);
        if (root) {
          const tree = createDocumentTree(root.key, nodesMap, relationsTo);
          flat = treeToFlatVM(tree, state, true);
        }

        expanded.body = flat.find(n => n.key === expanded.body.key);
      }

      expanded.body.$$identifier = getGoalIdentifier({
        goal: expanded.body,
        relations: expanded.$$treeAsLeaf,
        isOdet: true,
      });
      expanded.body.completeIdentifier = expanded.body.$$identifier;
      expanded.body.$$treeAsLeaf = expanded.$$treeAsLeaf;
      rel[relationPartToExpand].$$expanded = expanded.body;
    }
  });
};

export const getGoalIdentifier = ({ goal, relations, isOdet }) => {
  let parent = goal;
  let completeIdentifier = '';

  if (!relations) return '';

  while (parent) {
    const nextParent = findParent(parent, relations);
    const currentIdentifier = parent.identifiers?.join('') || '';
    if (currentIdentifier !== '') { 
      const isSubGoal = nextParent?.type === constants.llinkidGoalType;
      const isLlinkidRoot = nextParent?.type === constants.llinkidCurriculum;
      let separator = '';

      if ((isSubGoal || isOdet) && nextParent) separator = '.';
      else if (isLlinkidRoot) separator = ' ';

      completeIdentifier = `${separator}${currentIdentifier}${completeIdentifier}`;
    }
    parent = nextParent;
  }

  return completeIdentifier = completeIdentifier.indexOf('.') === 0 ? completeIdentifier.substring(1) : completeIdentifier;
};

const findParent = (item, relations) => {
  const isPartOfRelation = item.$$relationsFrom?.find(
    (r) => r.$$expanded.relationtype === 'IS_PART_OF'
  );
  if (!isPartOfRelation) return null;

  const parent = relations?.find(
    (r) => r.$$meta.permalink === isPartOfRelation?.$$expanded?.to.href
  );

  return parent;
};

export const getBase64 = async (file) => {
  return new Promise((resolve, reject) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
  });
};

export const getGoalPathText = (treeAsLeaf) => {
  let text = treeAsLeaf
    .filter(node => node.type !== 'CURRICULUM_ZILL')
    .map(node => node.title ? sanitizeHTML(node.title, 'clearAll') : sanitizeHTML(node.description, 'clearAll'));
  return text.join(' > ');
};

export const getEmbedVideoLinkFrom = (url) => {
  if (url !== undefined && url !== '') {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return 'https://youtube.com/embed/' + match[2];
    }
    if (url.indexOf('vimeo') !== -1) {
      const id = url.split('/')[url.split('/').length - 1];
      return `https://player.vimeo.com/video/${id}`;
    }
    if (url.indexOf('microsoft') !== -1) {
      const id = url.split('/')[url.split('/').length - 1];
      return `https://web.microsoftstream.com/embed/video/${id}?autoplay=false&amp;showinfo=true`;
    }
  }
  return false;
};

/**
 * @from https://stackoverflow.com/questions/28735459/how-to-validate-youtube-url-in-client-side-in-text-box
 */
export const isValidYouTubeUrl = (url) => {
  if (url !== undefined && url !== '') {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=|\?v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    if (match && match[2].length === 11) {
      return true;
    }
  }
  return false;
};

export const isValidVimeoUrl = (url) => {
  if (url !== undefined && url !== '') {
    return /(https:\/\/)?vimeo.com\/\d{4,20}(?=\b|\/)/.test(url);
  }
  return false;
};

export const isValidMicrosoftstreamUrl = (url) => {
  if (url !== undefined && url !== '') {
    return /(https:\/\/)?web.microsoftstream.com\/video\/[-a-zA-Z0-9]{36}(?=\b|\/)/.test(url);
  }
  return false;
};

export const compactTitle = (title) => {
  if (!title) {
    return '';
  }
  return title.length < 130 ? title : title.substring(0, 130) + '...';
};

export const getMaxReadOrder = (relations = []) => {
  return relations.reduce((max, rel) => {
    return rel.readorder > max ? rel.readorder : max;
  }, 0);
};

export const getNewReadOrder = (position, children, selectionsCount) => {
  let previousSiblingRO = position - 2 >= 0 && children[position - 2]
    ? children[position - 2].$$readOrder : 0;
  let nextSiblingRO = position - 1 >= 0 && children[position - 1]
    ? children[position - 1].$$readOrder : 0;

  let gapBetweenRO = nextSiblingRO - previousSiblingRO;
  let gapForNewRO = gapBetweenRO / (selectionsCount + 1);

  return {
    previousReadOrder: previousSiblingRO,
    incrementGap: nextSiblingRO > 0 ? Math.abs(gapForNewRO) : 1
  };
};

export const getResourceType = (href) => {
  if (href.includes('/relations')) {
    return 'relations';
  }
  if (href.includes('/attachments')) {
    return 'fileUploads';
  }
  if (href.includes('/sam') || href.includes('/vakken')) {
    return 'externalContent';
  }
  if (href.includes('/newsletter')) {
    return 'newsletterSettings';
  }
  if (href.startsWith(apiRoutes.webpages) || href.startsWith(apiRoutes.websites)) {
    const hrefItems = href.split('/');
    return hrefItems[1].concat(hrefItems[2]);
  }
  return href.split('/')[1];
};

export const getResourceKey = (href) => {
  const split = href.split('/');
  return split[split.length - 1];
};

export const getContentResourceFromRelatedHref = (relatedHref, relations) => {
  if (relatedHref.indexOf('/relations') !== -1) {
    const relation = relations.get(relatedHref);
    return relation ? relation.to.href : relatedHref;
  }
  return relatedHref;
};

export const clearImageData = (attachment) => {
  delete attachment.file;
  delete attachment.name;
  delete attachment.size;
  delete attachment.href;
  delete attachment.$$url;
  delete attachment.$$base64;
};

export const clear$$Fields = (resource) => {
  const newResource = { ...resource };
  // eslint-disable-next-line no-restricted-syntax
  for (let [key] of Object.entries(newResource)) {
    if (key.indexOf('$$') !== -1 || key === 'file') {
      delete newResource[key];
    }
  }
  return newResource;
};

export const removeProposalsCircularDependencies = (proposals) => {
  const result = proposals.map(proposal => {
    return {
      ...proposal,
      listOfRequestedChanges: proposal.listOfRequestedChanges.map(change => {
        return {
          ...change,
          resource: clear$$Fields(change.resource)
        };
      })
    };
  });
  return result;
};

export const removePendingActionsCircularDependencies = (pendingActions) => {
  const result = pendingActions.map(p => {
    return {
      ...p,
      resources: p.resources.map(r => ({
        ...r,
        body: clear$$Fields(r.body),
        node: clear$$Fields(r.node)
      }))
    };
  });
  return result;
};

export const findExternalRelationType = (typeHref) => {
  // eslint-disable-next-line no-restricted-syntax
  for (let [key, value] of Object.entries(documentTypes.referenceFrameExternalTypes)) {
    if (value.indexOf(typeHref) !== -1) {
      return key;
    }
  }
  return undefined;
};

// related resources to a proposal could be the resource where is applied to,
// the resource who is related or the parent resource where the proposal is created under
export const getProposalRelatedResourcesHrefs = (proposal) => {
  return proposal.listOfRequestedChanges.reduce((list, change) => {
    if (change.relatedTo) {
      list.push(change.relatedTo.href);
    }
    if (change.appliesTo) {
      list.push(change.appliesTo.href);
    }
    if (change.resource && change.resource.to) {
      // related to a parent node
      list.push(change.resource.to.href);
    }
    return list;
  }, []);
};

export const getProposedFileUploads = (proposalsBatch) => {
  // seek proposal attachments to be upload
  return proposalsBatch.reduce((result, value) => {
    const proposal = value.body;
    if (proposal.status !== 'ACCEPTED') {
      const uploadChanges = proposal.listOfRequestedChanges
        .filter(
          (change) => change.type === 'UPLOAD' && (change.attachment.file || change.attachment.name)
        )
        .map((upload) => {
          // for upload a copy with the file data
          const uploadCopy = {
            ...upload,
            attachment: { ...upload.attachment },
            proposalHref: '/proposals/' + proposal.key
          };

          // In suggest mode it only makes sense to point to /proposals
          upload.attachment.href = `/proposals/${proposal.key}/attachments/${upload.attachment.file.name}`;

          if (!upload.attachment.name) {
            upload.attachment.name = upload.attachment.file ? upload.attachment.file.name : '';
          }

          // for proposals batch a light version of attachment
          delete upload.attachment.file;
          delete upload.attachment.$$base64;

          return uploadCopy;
        });
      result = result.concat(uploadChanges);
    }
    return result;
  }, []);
};

export const getResourcesToRemove = (nodesToRemove, content, contentRelations, state, removedResources = []) => {
  return nodesToRemove.reduce((resources, key) => {
    const nodeHref = getContentPermalink(key, content);
    const relationsToRemove = [];

    const relations = contentRelations.from[nodeHref];
    if (relations) {
      relations
        // when it's a is_included_in relation be sure to include it only if the parent node is also being removed
        // this is to avoid removing other relations of the same node to different parts of the document (eg. attachments group with global docs)
        .filter(relation => relation.relationtype !== 'IS_INCLUDED_IN' || removedResources.includes(relation.to.href))
        .forEach(relation => {
          relationsToRemove.push(relation.key);
        });
    }

    const isIncludedIn = relations.find(r => r.relationtype === 'IS_INCLUDED_IN');
    if (!isIncludedIn && contentRelations.to[nodeHref]) {
      contentRelations.to[nodeHref].forEach(relation => {
        relationsToRemove.push(relation.key);
      });
    }

    relationsToRemove.forEach(relationKey => {
      resources = resources.filter(r => r.href !== '/content/relations/' + relationKey);
      resources.push({
        href: '/content/relations/' + relationKey,
        relatedTo: { href: nodeHref }
      });
    });

    // remove those webconfigurations the deleted node may have
    [...state.apiWithPendingChanges.webpages.values()]
      .filter(wp => wp.source.href === `/content/${key}`)
      .forEach(wp => resources.push({
        href: wp.$$meta ? wp.$$meta.permalink : `${apiRoutes.webpages}/${wp.key}`,
        relatedTo: { href: wp.source.href }
      }));

    if (!isIncludedIn) {
      resources.push({
        href: nodeHref
      });

      const childNodesToRemove = (contentRelations.to[nodeHref] || [])
        .filter((r) => r.relationtype === 'IS_PART_OF')
        .map((childRelation) => childRelation.from.href.split('/').pop());

      return [...resources, ...getResourcesToRemove(childNodesToRemove, content, contentRelations, state, resources)];
    }

    return resources;
  }, []);
};

// for the moment we only support conditions on the parent node but could be extended to self for exmaple
function fulfillConditionWebconfigurations(condition, parent, state) {
  if (condition.parent) {
    const parentWebConfigurations = [...state.apiWithPendingChanges.webpages.values()]
      .filter(wc => getResourceKey(wc.source.href) === parent.key);
    // TODO we should support cases when only the template is set and not the type anymore
    return parentWebConfigurations.filter(pwc => condition.parent.includes(pwc.type));
  }
  return [];
}

export const addNewNodeConditionalFields = (node, conditionalFields, parent, state, rootState) => {
  if (conditionalFields) {
    conditionalFields.forEach(conditionalField => {
      if (conditionalField.field !== 'webconfiguration') {
        // note: webconfiguration is a special case handled differently in another function
        const fulfilledConditionWebconfigs = fulfillConditionWebconfigurations(conditionalField.condition, parent, state);

        if (fulfilledConditionWebconfigs.length > 0) {
          if (angular.isFunction(conditionalField.value)) {
            node[conditionalField.field] = conditionalField.value(rootState);
          } else {
            node[conditionalField.field] = conditionalField.value;
          }
        }
      }
    });
  }
  return node;
};

export const getNewNodeConditionalWebconfigurations = (node, conditionalFields, parent, state) => {
  const webconfigurations = [];

  if (conditionalFields) {
    conditionalFields
      .filter(conditionalField => conditionalField.field === 'webconfiguration')
      .forEach(conditionalField => {
        const fulfilledConditionWebconfigs = fulfillConditionWebconfigurations(conditionalField.condition, parent, state);

        if (fulfilledConditionWebconfigs.length > 0) {
          webconfigurations.push({
            ...conditionalField.value,
            key: uuidv4(),
            path: `${fulfilledConditionWebconfigs[0].path}/${node.title || ''}`, // TODO should be /<parent webconfig path>/<title>
            $$incompletePath: node.title === undefined,
            source: {
              href: `/content/${node.key}`
            },
            externalReferences: [
              `/content/${state.key}`
            ],
            oldLocations: []
          });
        }
      });
  }
  return webconfigurations;
};

// if node title was updated we may need to patch the default webconfiguration path
// here we create the webconfiguration patch that will be used in that case
export const getWebconfigurationPatch = (node, nodePatch, state) => {
  let webconfigurationPatch;

  const titlePatch = nodePatch.find(p => p.path === '/title');
  if (titlePatch) {
    const nodeWebconfigurationToCompletePath = [...state.apiWithPendingChanges.webpages.values()]
      .find(wc => wc.source.href === `/content/${node.key}` && wc.$$incompletePath);

    if (nodeWebconfigurationToCompletePath && nodeWebconfigurationToCompletePath.path.endsWith('/')) {
      webconfigurationPatch = {
        key: nodeWebconfigurationToCompletePath.key,
        patch: [{
          op: 'replace',
          path: '/path',
          value: `${nodeWebconfigurationToCompletePath.path}${replacePathSpecialCharacters(sanitizeHTML(titlePatch.value, 'title').toLowerCase())}`
        }]
      };
    }
  }

  return webconfigurationPatch;
};

// in some cases when a webconfiguration template is updated then the webconfiguration of the chidren nodes
// should be patched with a new template corresponding to the updated parent.
// eg. blog change to mini_database => all children nodes with webconfiguration get mini_database_item (#18298)
export const getChildWebconfigurationsToUpdate = (nodeKey, webconfiguration, state) => {
  if (constants.updateChildWebconfigurations.map(c => c.code).includes(webconfiguration.template.code)) {
    const keysUnderWcNode = findContent(nodeKey, state.apiWithPendingChanges.content).$$children.map(c => c.key);

    const update = constants.updateChildWebconfigurations.find(c => c.code === webconfiguration.template.code);

    return keysUnderWcNode.reduce((list, childKey) => {
      const childWebconfiguration = [...state.apiWithPendingChanges.webpages.values()]
        .find(wc => wc.source.href === `/content/${childKey}`);

      if (childWebconfiguration && childWebconfiguration.template.href !== update.childTemplate) {
        const childWebtemplate = state.webtemplates.find(wt => wt.code === update.childType);
        const patch = [{
          op: 'replace',
          path: '/type',
          value: update.childType
        }, {
          op: 'replace',
          path: '/template',
          value: {
            ...childWebtemplate,
            href: childWebtemplate.$$meta.permalink
          }
        }];

        const childWebconfigurationPatch = {
          type: 'PATCH',
          href: `${apiRoutes.webpages}/${childWebconfiguration.key}`,
          patch: patch,
          relatedTo: { href: `/content/${childKey}` }
        };
        list.push(childWebconfigurationPatch);
      }

      return list;
    }, []);
  }
  return [];
};

function relationsRequiringRemovedFacetReferenceFrame(removedFacets, state) {
  return removedFacets.reduce((list, facet) => {
    Object.keys(state.websitesReferenceFramesMap).forEach(nodeHref => {
      const refFrameThemes = state.websitesReferenceFramesMap[nodeHref].get(facet.source.href);
      if (refFrameThemes && refFrameThemes.length) {
        // find relations to the node and from one of the refFrameThemes
        list = list.concat(state.apiWithPendingChanges.contentRelations.to[nodeHref]
          .filter(relation => refFrameThemes.some(theme => relation.from.href === (theme.$$meta ? theme.$$meta.permalink : theme.href))));
      }
    });
    return list;
  }, []);
}

export const getRelationsToRemovedFacetReferenceFrame = (newWebconfiguration, state) => {
  // check if a reference frame facet was removed -> remove references to it in children (#17564)
  const currentWebconfiguration = state.apiWithPendingChanges.webpages.get(`${apiRoutes.webpages}/${newWebconfiguration.key}`);
  const newFacets = newWebconfiguration.options ? newWebconfiguration.options.facets : undefined;

  if (currentWebconfiguration && currentWebconfiguration.options && newFacets
    && newFacets.length < currentWebconfiguration.options.facets.length) {
    // from the removed we need only those that are REFERENCE_FRAME
    const removedFacets = currentWebconfiguration.options.facets
      .filter(oldFacet => oldFacet.component === 'SELECT_FROM_REFERENCE_FRAME' && !newFacets.find(nf => nf.source && nf.source.href === oldFacet.source.href));
    if (removedFacets.length) {
      return relationsRequiringRemovedFacetReferenceFrame(removedFacets, state);
    }
  }

  return [];
};

// check all submitted proposal are valid:
// . parent node of the suggested node should already exists or is submitted or it's being submitted
export const isValidProposalsSubmit = (proposalsToSubmit, state) => {
  const submittedNodes = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const [resourceHref, proposal] of state.apiWithPendingChanges.proposals) {
    if (proposalsToSubmit.some(p => p.key === proposal.key)) {
      const node = state.apiWithPendingChanges.content.get(resourceHref);
      if (node) {
        submittedNodes.push(node);
      }
    }
  }

  const invalidNodes = submittedNodes.filter(node => {
    let parentNodeInApi;
    let parentIsSubmitted = false;
    let parentIsBeingSubmitted = false;
    const isRoot = !node.$$parent;

    if (node && node.$$parent) {
      parentNodeInApi = state.api.content.get(`/content/${node.$$parent.key}`);
      parentIsBeingSubmitted = submittedNodes.some(n => node.$$parent && n.key === node.$$parent.key);
      const parentProposal = state.api.proposals.get(`/content/${node.$$parent.key}`);
      parentIsSubmitted = parentProposal && parentProposal.status === 'SUBMITTED_FOR_REVIEW';
    }

    return !(parentNodeInApi || parentIsSubmitted || parentIsBeingSubmitted || isRoot);
  });

  return invalidNodes.length === 0;
};

// apply rules in (#18160) to detect which should be the default collapsed nodes
export const getNodesToCollapseByDefault = (state) => {
  if (state.tree.$$typeConfig.expandByDefault) {
    return [];
  }

  let collapsedByDefault = [];

  // first level sections if there are at least 10
  const firstLevelSections = state.tree.$$children.filter(n => n.$$type === 'SECTION' && n.$$children.length);
  if (firstLevelSections.length >= 10) {
    collapsedByDefault = firstLevelSections.map(n => n.$$meta.permalink);
  }

  // all blog and mini database items
  const blogAndMiniDbWebconfigurations = [...state.apiWithPendingChanges.webpages.values()]
    .filter(wc => constants.collapseByDefaultChildsUnderTypes.includes(wc.type));

  collapsedByDefault = blogAndMiniDbWebconfigurations.reduce((list, wc) => {
    const parentNode = state.apiWithPendingChanges.content.get(wc.source.href);
    if (parentNode) {
      list = list.concat(parentNode.$$children.filter(n => n.$$type === 'SECTION').map(n => `/content/${n.key}`));
    }
    return list;
  }, collapsedByDefault);

  return collapsedByDefault;
};

export const getImage = (attachments, type, width) => {
  if (!attachments || !attachments.has(type)) {
    return undefined;
  }
  const image = attachments.get(type);
  if (width && image.resized && image.resized.length) {
    const resizedImage = image.resized.find(r => r.width === width);
    return resizedImage || image.resized[0];
  }
  return image.original;
};

export const getRelationTree = (relationKey, content, relations) => {
  const node = getNodeByRelationKey(relationKey, content, relations);
  if (!node || !node.$$children || node.$$children.length === 0) return [relationKey];
  return [
    relationKey,
    ...node.$$children.reduce((relationKeys, node) => [...relationKeys, ...getRelationTree(node.$$relation.key, content, relations)], [])
  ];
};

export const getNodeByRelationKey = (relationKey, content, relations) => {
  const relation = relations.get(`/content/relations/${relationKey}`);
  const node = content.get(relation.from.href);
  return {
    ...node,
    $$relation: relation
  };
};

export const getNodesByRelationKeys = (relationKeys, content, relations) => {
  return relationKeys.map(relationKey => {
    return getNodeByRelationKey(relationKey, content, relations);
  });
};

export const getNodeTree = (relationKeys, content, relations) => {
  const nodes = getNodesByRelationKeys(relationKeys, content, relations);
  return nodes.reduce((nodeKeys, node) => {
    return [...nodeKeys, ...keysUnderNode(node)];
  }, []);
};

const getRemovedItemsPatch = (permalink, removedItems) => {
  return {
    type: 'PATCH',
    resources: [{
      href: permalink,
      patch: [{
        op: 'add',
        path: '/removedItems',
        value: removedItems.map(r => { return { href: r }; })
      }]
    }]
  };
};

export const getTeaserPatchAction = (nodeKeysToRemove, newsletterSetting, content) => {
  const nodes = nodeKeysToRemove.map(key => {
    return content.get(`/content/${key}`);
  });

  const teasers = nodes.filter(n => n.$$type === 'TEASER');
  const original = newsletterSetting.removedItems || [];
  const removedItems = [...new Set([...original.map(r => r.href) || [], ...teasers.map(t => t.$$meta.permalink)])];

  return getRemovedItemsPatch(newsletterSetting.$$meta.permalink, removedItems);
};

export const getEventPatchAction = (nodeKeyToRemove, newsletterSetting, content) => {
  const node = content.get(`/content/${nodeKeyToRemove}`);
  const original = newsletterSetting.removedItems || [];
  const removedItems = [...new Set([...original.map(r => r.href) || [], node.$$url])];

  return getRemovedItemsPatch(newsletterSetting.$$meta.permalink, removedItems);
};

export const getTeaserDeleteAction = (relationKeys, content, relations) => {
  return relationKeys.reduce((resources, relationKey) => {
    const childRelationKeys = getRelationTree(relationKey, content, relations);
    const childResources = childRelationKeys.reduce((childResources, childRelationKey) => {
      const childRelation = relations.get(`/content/relations/${childRelationKey}`);
      const childNode = content.get(childRelation.from.href);
      return childNode.$$type === 'TEASER' ? [...childResources, { href: `/content/relations/${childRelation.key}`, relatedTo: { href: childNode.$$meta.permalink } }] : childResources;
    }, []);
    return [...resources, ...childResources];
  }, []);
};

export const isUserEditingNotAllowedRootNode = (nodeKey, state) => {
  return state.mode === 'EDIT' && nodeKey === state.tree.key && !state.allowedToEditRootNode;
};

export const deleteUploadsForDeletedNodes = (resourceHrefs, state) => {
  const content = state.apiWithPendingChanges.content;
  const deleteUploadResources = [];

  resourceHrefs.forEach((resourceHref) => {
    const node = content.get(resourceHref);
    if (node) {
      const proposal = state.apiWithPendingChanges.proposals.get(resourceHref);
      proposal?.listOfRequestedChanges?.forEach((change) => {
        if (change.type === 'UPLOAD') {
          deleteUploadResources.push({
            href: change.appliesTo.href,
            type: 'DELETE_UPLOAD',
            relatedTo: { href: resourceHref },
          });
        }
      });
    }
  });

  return deleteUploadResources;
};

export const getNamedSetsPatch = (property, namedSetsOptions) => {
  const selectedNamedSets = namedSetsOptions.filter(l => l.selected);
  const namedSets = selectedNamedSets.map(c => c.$$meta.permalink);
  const patch = { [property]: namedSets };

  if (property === 'mainstructuresOuTypeCombinations') {
    return {
      ...patch,
      ...getMainstructuresOuTypesPatch(selectedNamedSets)
    };
  }

  return patch;
};

export const getMainstructuresOuTypesPatch = (selectedNamedSets) => {
  const mainstructures = selectedNamedSets.reduce((m, l) => {
    return [...m, ...l.selectors.find(s => s).value.filter(v => v.mainstructure).map(v => v.mainstructure)];
  }, []);
  const outypes = selectedNamedSets.reduce((m, l) => {
    return [...new Set([...m, ...l.selectors.find(s => s).value.filter(v => v.ouType).map(v => v.ouType)])];
  }, []);

  return {
    mainstructures,
    outypes
  };
};

export const fillApiContentAndRelationsMap = (apiContentList) => {
  const contents = apiContentList.reduce((map, node) => {
    map.set(node.$$meta.permalink, node);
    return map;
  }, new Map());
  const allRelations = new Map();

  apiContentList.forEach((node) => {
    node.$$relationsTo.forEach((rel) => {
      allRelations.set(rel.href, rel.$$expanded);
    });
    node.$$relationsFrom.forEach((rel) => {
      allRelations.set(rel.href, rel.$$expanded);
    });
  });

  return {
    content: contents,
    relations: allRelations
  };
};

/**
 * On init the api.proposals Map should be filled with
 * key: content resource href
 * value: the last proposal related to that content resource
 */
export const fillApiProposalsMap = (proposals) => {
  const proposalsMap = proposals
    .filter(proposal => ['IN_PROGRESS', 'SUBMITTED_FOR_REVIEW', 'REVIEWING'].includes(proposal.status))
    .reduce((map, proposal) => {
      proposal.listOfRequestedChanges.forEach(change => {
        const contentHref = getRelatedContentHref(change);
        let currentContentProposal = map.get(contentHref);

        if (!currentContentProposal
          || proposal.$$meta.modified > currentContentProposal.$$meta.modified) {
          map.set(contentHref, proposal);

          if (currentContentProposal) {
            proposal.olderProposals = [...proposal.olderProposals || [], currentContentProposal];
          }
        }
      });
      return map;
    }, new Map());

  return proposalsMap;
};

export const getExternalDocumentFlatTree = (externalDocumentKey, externalDocumentApi, state) => {
  const documentWithProposals = updateApiWithPendingChanges(externalDocumentApi, {}, 'SUGGESTING');

  const tree = createDocumentTree(
    externalDocumentKey,
    documentWithProposals.content,
    documentWithProposals.contentRelations.to
  );
  return treeToFlatVM(tree, state, true);
};

export const hasToPatchNodeAttachments = (node, { attachmentKey, patch, forcePatchNode }) => {
  if (forcePatchNode) {
    return true;
  }
  let attachment = node.attachments.find(a => a.key === attachmentKey);
  let update = attachment && Object.keys(patch).every(field => patch[field] || (!patch[field] && attachment[field]));
  return update;
};

export const isExternalLinkHttp = (href) => {
  return href && (href.startsWith('https://') || href.startsWith('http://'));
};

function getNextUniquePathSequence(path, count, pathsRelatedToWebpage) {
  const newPath = `${path}-${count}`;
  if (!pathsRelatedToWebpage.includes(newPath)) {
    return newPath;
  }
  return getNextUniquePathSequence(path, count + 1, pathsRelatedToWebpage);
}

export const getUniquePath = (webpage, pathsRelatedToWebpage = []) => {
  if (!pathsRelatedToWebpage.includes(webpage.path)) {
    return webpage.path;
  }
  return getNextUniquePathSequence(webpage.path, 1, pathsRelatedToWebpage);
};

/**
 * Get an array of readorders (top-down) for a node.
 * @param {string} nodeKey The key of the node.
 * @param {array} treeAsLeaf The flattened node tree elements.
 * @returns {array} An array of readorders for the node.
 */
export const getReadorders = (nodeKey, treeAsLeaf) => {
  if (!treeAsLeaf) {
    return [];
  }
  const readorders = [];
  let current = treeAsLeaf.find(x => x.key === nodeKey);
  let parentRelation = current.$$relationsFrom.find(x => x.$$expanded.relationtype === relationTypes.isPartOf);
  while (parentRelation) {
    readorders.unshift(parentRelation.$$expanded.readorder);
    // eslint-disable-next-line no-loop-func
    current = treeAsLeaf.find(x => parentRelation.$$expanded.to.href === `/content/${x.key}`);
    parentRelation = current.$$relationsFrom.find(x => x.$$expanded.relationtype === relationTypes.isPartOf);
  }
  return readorders;
};

/**
 * Compares two read order arrays recursively.
 * @param {array} readorders1 Array with read orders (top-down) of the first node.
 * @param {array} readorders2 Array with read orders (top-down) of the second node.
 * @param {number} index Index of the read orders array. Starts at zero.
 * @returns {number} The comparison result.
 */
export const compareReadorderArrays = (readorders1, readorders2, index = 0) => {
  // first check if we've arrived at the end of the tree
  if (readorders1.length === index) {
    return readorders2.length === index ? 0 : -1;
  }
  if (readorders2.length === index) {
    return 1;
  }
  if (readorders1[index] === readorders2[index]) {
    return compareReadorderArrays(readorders1, readorders2, index + 1);
  }
  return readorders1[index] < readorders2[index]
    ? -1
    : 1;
};

/**
 * Sorts nodes by read order.
 * @param {array} nodes An array of nodes with $$treeAsLeaf filled in.
 * @returns {array} The sorted array.
 */
export const sortByReadorder = (nodes) => {
  const readordersMap = new Map();
  nodes.forEach(node => {
    readordersMap.set(node.key, getReadorders(node.key, node.$$treeAsLeaf));
  });
  return nodes.sort((node1, node2) => {
    return compareReadorderArrays(readordersMap.get(node1.key), readordersMap.get(node2.key));
  });
};

/**
 * The root is the node without an IS_PART_OF from relation.
 * @param {array} treeAsLeaf A node with it all its parent nodes.
 * @returns The root document of a tree.
 */
export const getRoot = (treeAsLeaf) => {
  return treeAsLeaf.find(
    (node) =>
      !node.$$relationsFrom.some((r) => r.$$expanded.relationtype === relationTypes.isPartOf)
  );
};

/**
 * Compares nodes first by root identifier, then by read order.
 * @param {object} node1 Node with $$treeAsLeaf filled in.
 * @param {object} node2 Node with $$treeAsLeaf filled in.
 * @returns {number} The comparison result.
 */
export const sortByGoalIdentifier = (node1, node2) => {
  const root1 = getRoot(node1.$$treeAsLeaf);
  const root2 = getRoot(node2.$$treeAsLeaf);

  if (root1.identifiers && root2.identifiers && root1.identifiers[0] !== root2.identifiers[0]) {
    return root1.identifiers[0].toLowerCase() > root2.identifiers[0].toLowerCase() ? 1 : -1;
  }
  return compareReadorderArrays(getReadorders(node1.key, node1.$$treeAsLeaf), getReadorders(node2.key, node2.$$treeAsLeaf));
};

Array.prototype.diff = function (a) {
  return this.filter(function (i) { return a.indexOf(i) < 0; });
};

export const getWebConfigurationBatch = (webConfiguration, contentKey, contentTitle) => {
  const webConfigurationKey = uuidv4();

  return webConfiguration
    ? [
      {
        verb: 'PUT',
        href: `${apiRoutes.webpages}/${webConfigurationKey}`,
        body: {
          key: webConfigurationKey,
          path: `/${replacePathSpecialCharacters(sanitizeHTML(contentTitle, 'title').toLowerCase())}`,
          source: {
            href: `/content/${contentKey}`
          },
          externalReferences: [
            `/content/${contentKey}`
          ],
          ...webConfiguration
        }
      }
    ]
    : [];
};

/**
 * Recursively determines the given node's tree as leaf sorted from top to bottom (root comes first).
 * @param {String} nodeKey The node's key.
 * @param {Array} flatWithHiddens The flattened document tree with hidden nodes (in view model).
 * @returns {Array} The node's tree as leaf sorted from bottom to top (root comes first).
 */
const getTreeAsLeaf = (nodeKey, flatWithHiddens) => {
  const node = flatWithHiddens.find(n => n.key === nodeKey);
  return node.$$parent
    ? [...getTreeAsLeaf(node.$$parent.key, flatWithHiddens), node]
    : [node];
};

/**
 * Get a text representation of the leaf's path, including the leaf itself.
 * For each node either the title, description or type name is shown.
 * @param {String} nodeKey The node's key.
 * @param {Array} flatWithHiddens The flattened document tree with hidden nodes (in view model).
 * @returns {String} The leaf's path in text form.
 */
export const getPath = (nodeKey, flatWithHiddens) => {
  return getTreeAsLeaf(nodeKey, flatWithHiddens)
    .map(node => {
      if (node.title) {
        return sanitizeHTML(node.title, 'clearAll');
      }
      if (node.description) {
        return sanitizeHTML(node.description, 'clearAll');
      }
      return node.$$typeConfig.information.single;
    })
    .join(' > ');
};

/**
 * Returns the teaser's position for the sync.
 * If there's a calendar the teaser is placed just above it.
 * If not it is put at the bottom of the section.
 * @param {array} children The section's children nodes.
 * @returns The teaser's position.
 */
export const getTeaserPosition = (children) => {
  if (!children || !children.length) {
    return 1;
  }
  // for simplicity we assume the first reference group is the calendar
  const calendar = children.find(c => c.$$type === 'REFERENCE_GROUP');
  if (calendar) {
    return calendar.position;
  }

  return children[children.length - 1].position + 1;
};

export const isSuggestionAllowed = (document) => {
  return documentTypesAllowingSuggestions.some(
    documentType => documentType.node.type === document.type
      && documentType.node.tags.diff(document.tags).length === 0
  );
};

export const getReplacesRelationsHref = (document) => {
  if (!document) {
    return null;
  }

  const relations = document.$$relationsFrom.filter(
    (relation) => relation.$$expanded.relationtype === 'REPLACES'
  );

  return relations.map((relation) => relation.$$expanded.to.href);
};

export const formatVersion = (version) => {
  return version?.replace('.0.0', '');
};

export const allowPublishItemSelected = (selected, publishedEditables) => {
  const llinkidCurr = selected.filter((s) => s.type === constants.llinkidCurriculum);
  const isAnyPublished = selected.some((s) => s.issued);

  if (llinkidCurr.length > 0) {
    const llinkidCurrConfig = publishedEditables.find(
      (e) => e.type === constants.llinkidCurriculum
    );

    if (llinkidCurrConfig) {
      return !isAnyPublished && llinkidCurrConfig.isPublishedEditable;
    }

    return false;
  }

  return true;
};

export const transformInput = (node, field, value, sanitize = true) => {
  const sanitizedInput = sanitize ? sanitizeHTML(value, field) : value;
  let newValue = sanitizedInput;
  let newField = field;

  if (field === '$$identifier') {
    newValue = sanitizedInput.length > 0 ? [sanitizedInput] : [];
    newField = 'identifiers';
  } else if (field === 'html') {
    newValue = node.attachments.map((attachment) => {
      const newAttachmnent = { ...attachment };
      if (attachment.type === 'CONTENT') {
        newAttachmnent.text = sanitizedInput;
      }
      return newAttachmnent;
    });

    newField = 'attachments';
  } else if (field === 'attachments') {
    newValue = value.map((attachment) => {
      if (attachment.type === 'CONTENT') {
        attachment.text = node.html;
      }
      return attachment;
    });
  } else {
    // always need to keep the attachment type CONTENT text
    node.attachments = node.attachments.map((attachment) => {
      const newAttachmnent = { ...attachment };
      if (attachment.type === 'CONTENT') {
        newAttachmnent.text = attachment.text || node.html;
      }
      return newAttachmnent;
    });
  }

  return {
    field: newField,
    value: newValue,
  };
};
