import { put, takeEvery, call } from 'redux-saga/effects';
import { initUser, userFetched, securityDataFetched, initUserFailed } from './userState';
import { getMe, getPerson, getSecurityData } from './userDataAccess';
import { PayloadAction } from '@reduxjs/toolkit';
import { setPersons } from '@newStore/externalData/externalDataState';

function* getSecurityDataSaga(payload: PayloadAction<{ uuid: string; username: string }>) {
  try {
    const { uuid } = payload.payload;
    const ret = yield call(getSecurityData, uuid);
    yield put(securityDataFetched(ret));
  } catch (error) {
    console.error(error);
    yield put(initUserFailed(error));
  }
}

function* getPersonSaga(payload: PayloadAction<{ uuid: string; username: string }>) {
  try {
    const { uuid } = payload.payload;
    const person = yield getPerson(`/persons/${uuid}`);
    yield put(setPersons({ persons: [person] }));
    /// temporarily still put the user in the documentReducer as well.
    // TODO: remove this when the documentReducer is removed
    yield put({
      type: 'SET_CURRENT_USER',
      payload: {
        user: person,
      },
    });
  } catch (personError) {
    // we don't care
    console.error('An error occured fetching the personal data of the user', personError);
  }
}

function* initUserData() {
  try {
    const me = yield call(getMe);
    yield put(userFetched(me));
  } catch (error) {
    console.error(error);
    yield put(initUserFailed(error));
  }
}

function* initUserFailedSaga() {
  yield put({
    type: 'ADD_NOTIFICATION',
    payload: {
      type: 'ERROR',
      message:
        'Er is een onverwachte fout opgetreden bij het ophalen van de gebruiker en zijn security.',
    },
  });
}

export function* userSaga() {
  yield takeEvery(initUser, initUserData);
  yield takeEvery(initUserFailed, initUserFailedSaga);
  yield takeEvery(userFetched, getPersonSaga);
  yield takeEvery(userFetched, getSecurityDataSaga);
}
