/* eslint-disable max-len */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable func-names */
module.exports = ['websitesApi', function (websitesApi) {
  let service = {};
  let noFacetWarningMessage = new Map();
  noFacetWarningMessage.set('MINI_DATABASE', 'components.proWebsite.configurationModalForm.noMiniDatabaseFacetConfirmation');
  noFacetWarningMessage.set('DOWNLOAD_PAGE', 'components.proWebsite.configurationModalForm.noDownloadPageFacetConfirmation');
  noFacetWarningMessage.set('BLOG', 'components.proWebsite.configurationModalForm.noBlogFacetConfirmation');

  const typesWithMultipleAlternativeMenus = ['FALLBACK_MENU_LEVEL_2'];

  service.clearConfiguration = function (configuration) {
    let webpage = angular.copy(configuration);

    webpage.website.href = webpage.website.href || webpage.website.$$meta.permalink;
    webpage.template.href = webpage.template.href || webpage.template.$$meta.permalink;
    webpage.type = webpage.template.code;

    if (webpage.options && webpage.options.facets && webpage.options.facets.length > 0) {
      for (let facet of webpage.options.facets) {
        if (facet.source) {
          facet.source.href = facet.source.href || facet.source.$$meta.permalink;
        }
      }
    }

    return webpage;
  };

  function resourceTypeHasConfigurations(resource, parentDocumentType) {
    return (resource.type === 'SECTION' && parentDocumentType === 'WEBPAGE2') || resource.type === 'WEBPAGE2';
  }

  service.getConfigurations = async function (key) {
    let resourceHref = '/content/' + key;

    try {
      let configurations = await websitesApi.getAll('/web/pages?source.href=' + resourceHref);

      for (let configuration of configurations) {
        configuration.website = await websitesApi.get(configuration.website.href);

        if (configuration.options && configuration.options.facets && configuration.options.facets.length > 0) {
          for (let facet of configuration.options.facets) {
            if (facet.source) {
              facet.source = await websitesApi.get(facet.source.href);
            }
          }
        }
      }

      return configurations;
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  service.isPathUnique = async function (path) {
    let configurations = await websitesApi.getAll('/web/pages?path=' + path);

    return configurations.length === 0;
  };

  service.getFacetComponentTypes = function (template) {
    return template.options || [];
  };

  service.getNoFacetMessageFor = function (code) {
    return noFacetWarningMessage.get(code);
  };

  service.typeHasFacets = function (template) {
    return template && template.options && template.options.length > 0;
  };

  service.typeHasMultipleAlternativeMenus = (template) => {
    return template && typesWithMultipleAlternativeMenus.includes(template.code);
  };

  service.getWebsites = async function () {
    let response = await websitesApi.getAll('/web/sites');
    return response;
  };

  service.save = async function (configuration) {
    let webpage = service.clearConfiguration(configuration);

    try {
      await websitesApi.put('/web/pages/' + webpage.key, webpage);
    } catch (e) {
      throw Error(e);
    }
  };

  service.delete = async function (key) {
    try {
      await websitesApi.delete('/web/pages/' + key);
    } catch (e) {
      throw Error(e);
    }
  };

  service.deleteFromContentResource = async function (resource, parentDocumentType) {
    if (resourceTypeHasConfigurations(resource, parentDocumentType)) {
      let response = await websitesApi.getAll('/web/pages?source.href=' + resource.href);

      response.forEach(async (result) => {
        await service.delete(result.key);
      });
    }
  };

  return service;
}];
