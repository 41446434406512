module.exports = {
  information: {
    single: 'Voorbeeld',
    plural: 'Voorbeelden'
  },
  node: {
    type: 'EXAMPLE'
  },
  buildingBlocks: [
    'PARAGRAPH',
    {
      type: 'REFERENCE_GROUP',
      whitelist: [
        {
          root: {
            $$type: 'VISION_TEXT'
          }
        }
      ]
    }
  ],
  edit: [
    'importance'
  ],
  allowSibling: true,
  createInModal: false,
  isSearchable: false
};
