import * as ACTION_TYPES from '../constants/actionTypes';
import uuidv4 from 'uuid/v4';

export const doSearchAction = (params) => ({
  type: ACTION_TYPES.DOCUMENT_LIST_SEARCH,
  payload: params
});


export const publishAction = (resources, publishDate, message, locationUrl) => ({
  type: ACTION_TYPES.DOCUMENT_LIST_PUBLISH,
  payload: {
    resources,
    publishDate,
    message,
    locationUrl,
    keys: resources.map(() => uuidv4())
  }
});

export const documentsPublishedAction = (resources) => ({
  type: ACTION_TYPES.DOCUMENT_LIST_PUBLISHED,
  payload: resources
});

export const documentsPublishFailedAction = (error, state) => ({
  type: ACTION_TYPES.DOCUMENT_LIST_PUBLISH_FAILED,
  payload: error
});

export const openedPublishModal = () => ({
  type: ACTION_TYPES.DOCUMENT_LIST_OPENED_PUBLISH_MODAL
});

export const closePublishModal = () => ({
  type: ACTION_TYPES.DOCUMENT_LIST_CLOSE_PUBLISH_MODAL
});


export const validateAndDeleteSelected = () => ({
  type: ACTION_TYPES.DOCUMENT_LIST_VALIDATE_AND_DELETE
});

export const deleteSelected = () => ({
  type: ACTION_TYPES.DOCUMENT_LIST_DELETE
});

export const resultsDeleted = () => ({
  type: ACTION_TYPES.DOCUMENT_LIST_RESULTS_DELETED
});

export const validationFailedAction = (error) => ({
  type: ACTION_TYPES.DOCUMENT_LIST_DELETE_FAILED,
  payload: error
});

export const duplicate = (resources) => ({
  type: ACTION_TYPES.DOCUMENT_LIST_DUPLICATE,
  payload: resources
});


export const openedCreateContentModal = (type) => ({
  type: ACTION_TYPES.OPENED_CREATE_CONTENT_MODAL,
  payload: type
});

export const closeCreateContentModal = () => ({
  type: ACTION_TYPES.CLOSE_CREATE_CONTENT_MODAL
});

export const readFileToImport = (event) => ({
  type: ACTION_TYPES.READ_FILE_TO_IMPORT_DATA,
  payload: event
});

export const setFileToImportData = (file) => ({
  type: ACTION_TYPES.SET_FILE_TO_IMPORT_DATA,
  payload: file
});

export const readFileToUploadAsAttachment = (event) => ({
  type: ACTION_TYPES.READ_FILE_TO_UPLOAD_AS_ATTACHMENT,
  payload: event
});

export const setFileToUploadAsAttachment = (file) => ({
  type: ACTION_TYPES.SET_FILE_TO_UPLOAD_AS_ATTACHMENT,
  payload: file
});

export const validateCreateContentAction = (newContentParams, $http) => ({
  type: ACTION_TYPES.VALIDATE_CREATE_CONTENT,
  payload: {
    newContentParams: { key: uuidv4(), ...newContentParams },
    $http
  }
});

export const queueSubnodeWithRelation = (block, parentKey) => ({
  type: ACTION_TYPES.CREATE_AND_QUEUE_CONTENT_WITH_RELATION,
  payload: {
    contentKey: uuidv4(),
    relationKey: uuidv4(),
    block,
    parentKey
  }
});

export const contentCreated = (rootDocument) => ({
  type: ACTION_TYPES.SUCCESSFULLY_CREATED_CONTENT,
  payload: rootDocument
});

export const createNewVersion = (items) => ({
  type: ACTION_TYPES.NEW_VERSION_OF_CONTENT,
  payload: items
});

export const successfullyCreatedNewVersionOfContent = (key) => ({
  type: ACTION_TYPES.SUCCESSFULLY_CREATED_NEW_VERSION_OF_CONTENT,
  payload: key
});

export const createCopy = (items) => ({
  type: ACTION_TYPES.COPY_OF_CONTENT,
  payload: items
});

export const successfullyCreatedCopyOfContent = (key) => ({
  type: ACTION_TYPES.SUCCESSFULLY_CREATED_COPY_OF_CONTENT,
  payload: key
});


export const clearRedirect = () => ({
  type: ACTION_TYPES.DOCUMENT_LIST_CLEAR_REDIRECT
});

export const sendMailListPublishedAction = (publishedDocuments) => ({
  type: ACTION_TYPES.SEND_LIST_PUBLISHED_EMAIL,
  payload: {
    keys: publishedDocuments.map(() => uuidv4()),
    publishedDocuments
  }
});

export const updateNewsAction = () => ({
  type: ACTION_TYPES.UPDATE_NEWS
});
