const { themeReferencesWithRootsCmd } = require('../../../reduxLoop/commands/documentCommands');

module.exports = {
  information: {
    single: 'Studiegebied',
    plural: 'Studiegebieden'
  },
  node: {
    $$meta: {
      type: 'COMMONS_STUDIEGEBIED'
    }
  },
  indentation: 3,
  buildingBlocks: [
  ],
  edit: [
    'name'
  ],
  isSearchable: false,
  editInModal: false,
  isExternal: true,
  relation: {
    placeholder: 'Studiegebied...',
    label: '(oude structuur)',
    filterUrl: '/sam/commons/studiegebieden'
  },
  deleteValidations: [
    (node) => {
      return {
        cmd: themeReferencesWithRootsCmd,
        params: [node.$$meta.permalink],
        message: 'validation.themeReferenced'
      };
    }
  ],
  confirmDelete: true,
  isUniqueInDienstverleningKov: true,
  themesDisplaySuffix: '(oude structuur) (studiegebied)',
};
