/* eslint-disable max-len */
import { config as CKconfig } from '../../reduxLoop/constants/ckeditor';
import santitizeHTML from 'sanitize-html';
import { sanitizeHTML } from '../../reduxLoop/helpers/documentHelpers';
import { demarcationButtonClicked, termButtonClicked, markerButtonClicked } from '../../services/editorCustomButtonsService';
// this is "dummy" component, only emits events on edition done

class InlineEditor {
  constructor($rootScope, $scope, $sce, $element, $attrs, $timeout, $compile, ModalWindowService) {
    'ngInject';

    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$sce = $sce;
    this.$element = $element;
    this.$attrs = $attrs;
    this.$timeout = $timeout;
    this.$compile = $compile;
    this.modalWindowService = ModalWindowService;
  }

  addCustomButtons(editor) {
    const config = CKconfig[this.sField];
    if (config && !config.customButtons) {
      config.customButtons = [];
    }

    if (config && config.customButtons.indexOf('term') !== -1 && !this.hideTermButton) {
      editor.ui.addButton('InsertTerm', {
        label: 'Begrip',
        command: 'insertTermCmd',
        toolbar: 'custom',
        icon: require('../../../img/termIcon.svg')
      });
    }

    if (config && config.customButtons.indexOf('demarcation') !== -1 && this.sDemarcationButton) {
      editor.ui.addButton('InsertDemarcation', {
        label: 'Afbakening',
        command: 'insertDemarcationCmd',
        toolbar: 'custom',
        icon: require('../../../img/afbakening.png')
      });
    }

    if (config && config.customButtons.indexOf('footnote') !== -1 && !this.sWebpage2Link) {
      editor.ui.addButton('InsertFootnote', {
        label: 'Voetnoot',
        command: 'insertFootnoteCmd',
        toolbar: 'custom',
        icon: require('../../../img/footnote1.png')
      });
    }

    if (config && config.customButtons.indexOf('customLink') !== -1 && this.sWebpage2Link) {
      editor.ui.addButton('InsertCustomLink', {
        label: 'Link',
        command: 'insertCustomLinkCmd',
        toolbar: 'links',
        icon: 'Link'
      });
    }

    if (config && config.customButtons.indexOf('marker') !== -1 && this.sMarkerButton) {
      editor.ui.addButton('InsertMarkExplanation', {
        label: 'Markeerverklaring',
        command: 'insertMarkExplanationCmd',
        toolbar: 'custom',
        icon: require('../../../img/icons/mark.svg')
      });
    }
  }

  addCustomCommands(editor, component) {
    editor.addCommand('insertTermCmd', {
      exec: () => {
        termButtonClicked(editor, this.sRootDocument, this.modalWindowService, this.$scope);
      }
    });

    editor.addCommand('insertDemarcationCmd', {
      exec: () => {
        demarcationButtonClicked(editor);
      }
    });

    editor.addCommand('insertFootnoteCmd', {
      exec: () => {
        component.$scope.$emit('footnote_button_clicked', { key: component.sKey, field: component.sField, editor: editor });
      }
    });

    editor.addCommand('insertCustomLinkCmd', {
      exec: () => {
        component.$scope.$emit('customlink_button_clicked', { key: component.sKey, field: component.sField, editor: editor });
      }
    });
    editor.addCommand('insertMarkExplanationCmd', {
      exec: () => {
        markerButtonClicked(editor, this.modalWindowService);
      }
    });
  }

  // NOTE: we use toolbarGroups in the config because ...
  getToolbarGroups() {
    const config = CKconfig[this.sField];
    let toolbarGroups = [];

    if (config && config.reducedToolbar) {
      toolbarGroups = [
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'custom' }
      ];
    } else {
      toolbarGroups = [
        { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
        { name: 'clipboard', groups: ['undo', 'clipboard'] },
        { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
        { name: 'insert', groups: ['insert'] },
        { name: 'links', groups: ['links'] },
        { name: 'custom' }
      ];
    }

    return toolbarGroups;
  }

  /**
   * Only used to hide all the buttons in the toolbar if config.hideToolbar = true
   */
  getToolbar() {
    const config = CKconfig[this.sField];
    return config.hideToolbar ? 'toolbar,pastefromword, tableselection, uploadwidget, clipboard, pastetext, widget, uploadimage' : '';
  }

  getRemovedButtons() {
    const config = CKconfig[this.sField];
    let extra = config && this.sWebpage2Link ? ',Link' : '';

    return 'Source,Save,Templates,NewPage,Preview,Print,Cut,Copy,Paste,PasteText,PasteFromWord,SelectAll,Scayt,Find,Replace,Form,Checkbox,TextField,Radio,Textarea,Select,Button,ImageButton,HiddenField,Underline,Strike,CopyFormatting,Outdent,Indent,Blockquote,JustifyLeft,JustifyCenter,JustifyRight,JustifyBlock,CreateDiv,BidiLtr,BidiRtl,Language,Anchor,Flash,Image,HorizontalRule,Smiley,SpecialChar,PageBreak,Iframe,Styles,TextColor,Maximize,About,ShowBlocks,BGColor,Format,Font,FontSize' + extra;
  }

  getForbiddenKeys() {
    const config = CKconfig[this.sField];
    return config && config.blockedKeystrokes ? config.blockedKeystrokes : [];
  }

  $onDestroy() {
    if (CKEDITOR.instances[this.sField + '-' + this.sKey]) {
      CKEDITOR.instances[this.sField + '-' + this.sKey].destroy();
    }
  }

  $onChanges() {
    this.$scope.$broadcast('$$rebind::change');
  }

  async $onInit() {
    this.id = this.sField + '-' + this.sKey;
    const element = this.$element.parent();
    element.on('click', () => {
      if (!this.isLoaded && !this.sDisabled) {
        this.initEditor();
      }
    });

    if (this.sFocusOnInit) {
      this.initEditor();
    }
  }

  isReadOnly() {
    return this.sDisabled === true;
  }

  getValue() {
    return santitizeHTML(this.sInitial, {
      allowedTags: false,
      allowedAttributes: false,
      transformTags: {
        a: (tagName, attribs) => {
          if (attribs.href && attribs.href.startsWith('/content/')) {
            switch (attribs.rel) {
              case 'term':
                return {
                  tagName: 'inline-term',
                  attribs: {
                    's-href': attribs.href
                  }
                };
              case 'demarcation':
                return {
                  tagName: 'inline-demarcation',
                  attribs: {
                    's-href': attribs.href
                  }
                };
              case 'footnote':
                return {
                  tagName: 'inline-footnote',
                  attribs: {
                    ...attribs,
                    's-href': attribs.href
                  }
                };
              default:
                break;
            }
          }
          return {
            tagName,
            attribs
          };
        },
        span: (tagName, attribs) => {
          const href = attribs['data-href'];
          if (href && href.startsWith('/content/')) {
            return {
              tagName: 'inline-mark-explanation',
              attribs: {
                's-href': href
              }
            };
          }
          return {
            tagName,
            attribs
          };
        }
      }
    });
  }

  /**
   * gets the current caret position and the DOM node in which the cursor is. 
   * if you have a paragraph with an <em> tag in it, the caret position will be the position of the cursor in the <em> tag.
   * @returns {object} the current caret position and the DOM node in which the cursor is
   */
  getCaretPosition() {
    var caretPos = 0;
    var sel;
    var range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        caretPos = range.endOffset;
      }
    }
    return { caretPosition: caretPos, node: sel?.anchorNode };
  }

  initEditor() {
    const config = CKconfig[this.sField];

    // v.4 do your stuff here as the DOM has finished rendering already
    const component = this;
    const { caretPosition, node: caretNode } = this.getCaretPosition();

    try {
      this.isLoaded = true;
      let editor = CKEDITOR.inline(this.$element.children().first()[0], {
        toolbarGroups: this.getToolbarGroups(),
        removePlugins: this.getToolbar(),
        removeButtons: this.getRemovedButtons(),
        extraPlugins: 'justify', // needed to show paragraph justify buttons
        extraAllowedContent: 'a inline-footnote inline-demarcation inline-term inline-mark-explanation[*]',
        title: false,
        on: {
          instanceReady: () => {
            editor.snap = editor.getData();
            // this $compile doesn't affect watchers, is not the directive compile.js
            this.$compile(editor.getData())(this.$scope);
            let emptyText = false;
            if (editor.snap === '') {
              emptyText = true;
            }

            editor.focus();
            editor.opened = true;
            editor.name = this.sField + '-' + this.sKey;

            // needed to avoid a problem when copying text into the inline editor and we have more than one recently created in the document (#18940)
            if (!CKEDITOR.instances[editor.name]) {
              CKEDITOR.instances[editor.name] = editor;
              delete CKEDITOR.instances['{{::ctrl.id}}'];
            }

            if (editor.elementPath()) {
              if (!emptyText) {
                let el = editor.elementPath().elements[editor.elementPath().elements.length - 2];
                let ranges = editor.getSelection().getRanges();
                let elementNode = el.getFirst();
                // loop through all the elements in the node until we find the dom node where the cursor was.
                // the only way i could match the dom node was by comparing the textContent
                while (elementNode && elementNode.$.textContent.trim() !== caretNode.textContent.replace('\n', '').trim()) {
                  elementNode = elementNode.getNext();
                }

                try {
                  ranges[0].setStart(elementNode, caretPosition);
                  ranges[0].setEnd(elementNode, caretPosition);
                  editor.getSelection().selectRanges([ranges[0]]);
                } catch (e) {
                  console.log('ERROR on selectRanges');
                }
              }
              editor.focus();
            }

            editor.on('key', (event) => {
              let key = event.data.keyCode;
              let forbiddenKeys = config.blockedKeystrokes ? config.blockedKeystrokes : [];
              if (forbiddenKeys.includes(key)) {
                event.cancel();
              }
            });

            component.$scope.$emit('inline_editor_opened', {
              key: component.sKey, field: component.sField, value: editor.getData(), original: editor.snap
            });
          },

          change: () => {
            let html = editor.getData();
            this.$scope.$emit('inline_edit_changed', {
              key: this.sKey, field: this.sField, value: html, original: editor.snap, editor: editor
            });
          },

          blur: () => {
            let html = editor.getData();
            if (sanitizeHTML(editor.snap, this.sField) !== sanitizeHTML(html, this.sField)) {
              // only if there are modifications
              this.$scope.$emit('inline_edit_done', {
                key: this.sKey, field: this.sField, value: html, original: editor.snap, editor: editor
              });
              editor.snap = html;
            } else {
              this.$scope.$emit('inline_edit_without_changes_done', {
                key: this.sKey, field: this.sField, value: html, original: editor.snap, editor: editor
              });
            }

            editor.opened = false;
            this.isLoaded = false;
          }
        }
      });

      this.addCustomButtons(editor);
      this.addCustomCommands(editor, this);

      // disable any default ckeditor action on double click (eg. double click on link opens pop dialog)
      editor.on('doubleclick', (evt) => {
        var element = evt.data.element;
        if (element.is('a')) {
          evt.stop(); // don't do the other listeners
          // optionally put your code
          component.$scope.$emit('customlink_button_clicked', { key: component.sKey, field: component.sField, editor: editor });
        }
      }, null, null, 1); // 1 is a high priority
      editor.on('click', (evt) => {
        var element = evt.data.element;
        if (element.is('a')) {
          evt.stop(); // don't do the other listeners
        }
      }, null, null, 1); // 1 is a high priority
    } catch (e) {
      // This catch means that we already created the instance for the clicked row.
      // We don't need to do anything as the already created instance keeps working.
      let editor = CKEDITOR.instances[this.id];
      if (editor) {
        component.$scope.$emit('inline_editor_opened', {
          key: component.sKey,
          field: component.sField,
          value: editor.getData(),
          original: editor.snap
        });
      }
    }
  }
}

export default {
  controllerAs: 'ctrl',
  bindings: {
    sInitial: '@?',
    sField: '@?',
    sKey: '@?',
    sFocusOnInit: '<?',
    hideTermButton: '<?',
    sDemarcationButton: '<?',
    sMarkerButton: '<?',
    sWebpage2Link: '<?',
    sDisabled: '<?',
    sRootDocument: '<?'
  },
  template: '<div id="{{::ctrl.id}}" contenteditable="{{:proposal:!ctrl.isReadOnly()}}" compile-inline="::ctrl.getValue()"></div>',
  controller: InlineEditor
};
