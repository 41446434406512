import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideRealisationPeriod {
  constructor($scope, $ngRedux, $translate) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$translate = $translate;
    this.realisationOptions = [];

    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.document.viewModel.aside,
        document: state.document.viewModel.document,
        realisationPeriod: state.document.viewModel.document?.realisationPeriod,
      };
    })(this);
  }

  $onInit() {
    for (let year = 1; year <= this.sConfig.options.schoolyears; year += 1) {
      this.realisationOptions.push({
        name: year === 1 ? `${year} schooljaar` : `${year} schooljaren`,
        value: 12 * year,
      });
    }
  }

  change() {
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(this.editDocument.key, {
        realisationPeriod: this.realisationPeriod,
      })
    );
  }
}

export default {
  template: require('./asideRealisationPeriod.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<',
  },
  controller: asideRealisationPeriod,
};
