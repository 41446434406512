const constants = require('../../../reduxLoop/constants/constants');

module.exports = {
  information: {
    single: 'Faq',
    plural: 'Faqs',
    ribonIcon: require('../../../../img/buildingBlocks/FAQ.svg')
  },
  node: {
    type: 'FAQ'
  },
  indentation: 1,
  buildingBlocks: [
    'PARAGRAPH',
    'IMAGE_GROUP',
    'IMAGE',
    'VIDEO',
    'ATTACHMENTS_GROUP',
    'QUOTE',
    'LEGAL'
  ],
  edit: [
    {
      component: 'title',
      label: 'Vraag'
    },
    {
      component: 'namedSet',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    },
    {
      component: 'coverage',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    }
  ],
  asDocumentView: true
};
