export const attachmentTypeConversionMap = {
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': {
    name: 'Ms Word-document',
    icon: require('../../../img/icons/doc.svg')
  },
  'application/vnd.openxmlformats-officedocument.wordprocessingml.template': {
    name: 'Ms Word-document',
    icon: require('../../../img/icons/doc.svg')
  },
  'application/msword': {
    name: 'Ms Word-document',
    icon: require('../../../img/icons/doc.svg')
  },
  'application/pdf': {
    name: 'Adobe Acrobat Document',
    icon: require('../../../img/icons/pdf.svg')
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': {
    name: 'Ms Excel-document',
    icon: require('../../../img/icons/xls.svg')
  },
  'application/vnd.openxmlformats-officedocument.spreadsheetml.template': {
    name: 'Ms Excel-document',
    icon: require('../../../img/icons/xls.svg')
  },
  'application/vnd.ms-excel': {
    name: 'Ms Excel-document',
    icon: require('../../../img/icons/xls.svg')
  },
  'video/mp4': {
    name: 'Video MP4',
    icon: require('../../../img/icons/mp4.svg')
  },
  'audio/mp3': {
    name: 'Audio MP3',
    icon: require('../../../img/icons/mp3.svg')
  },
  'audio/mpeg': {
    name: 'Audio MP3',
    icon: require('../../../img/icons/mp3.svg')
  },
  'image/jpeg': {
    name: 'Image JPEG',
    icon: require('../../../img/icons/jpg.svg')
  },
  'image/jpg': {
    name: 'Image JPG',
    icon: require('../../../img/icons/jpg.svg')
  },
  'image/png': {
    name: 'Image PNG',
    icon: require('../../../img/icons/jpg.svg')
  },
  'image/tiff': {
    name: 'Image TIFF',
    icon: require('../../../img/icons/jpg.svg')
  },
  'application/vnd.ms-powerpoint': {
    name: 'Ms PowerPoint-document',
    icon: require('../../../img/icons/ppt.svg')
  },
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': {
    name: 'Ms PowerPoint-document',
    icon: require('../../../img/icons/ppt.svg')
  },
  'application/vnd.openxmlformats-officedocument.presentationml.template': {
    name: 'Ms PowerPoint-document',
    icon: require('../../../img/icons/ppt.svg')
  },
  'application/vnd.openxmlformats-officedocument.presentationml.slideshow': {
    name: 'Ms PowerPoint-document',
    icon: require('../../../img/icons/ppt.svg')
  }
};
