import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
require('./asideNamedSet.scss');

class asideNamedSet {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.disabled = false;
    this.mandatory = false;
    this.showInconsistent = false;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const editDocument = state.document.viewModel.aside.editDocument;
      return {
        hasRootNamedSets: editDocument.$$root
          && editDocument.$$root[this.sConfig.property]
          && editDocument.$$root[this.sConfig.property].length > 0,
        isDocumentRoot: editDocument.$$isRoot,
        isNamedSetsInconsistent: editDocument.isNamedSetsInconsistent.get(this.sConfig.property)
          && editDocument.type !== 'TEASER',
        namedSets: editDocument[this.sConfig.property],
        namedSetsOptions: editDocument.namedSetsOptions.get(this.sConfig.property),
        editKey: editDocument.key,
        isReadOnly: state.document.viewModel.aside.isReadOnly
          || (this.sConfig.property === 'positions'
            && editDocument.type === 'TEASER'
            && editDocument.themes
            && editDocument.themes.value.length > 0),
        isInherited: !editDocument.$$isRoot
          && !editDocument[this.sConfig.property],
        isEmpty: !editDocument.namedSetsOptions.get(this.sConfig.property).some(n => n.selected)
      };
    })(this);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.loadNamedSetsAction(this.sConfig.tag));
  }

  async $onDestroy() {
    this.unsubscribe();
  }

  change() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.updateNamedSetsAction(this.sConfig.property, this.namedSetsOptions));
  }

  clearAll() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.selectNamedSetsAction(this.sConfig.property, false));
  }

  selectAll() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.selectNamedSetsAction(this.sConfig.property, true));
  }

  backToInherited() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.resetNamedSetsAction(this.sConfig.property, this.editKey));
  }
}

export default {
  template: require('./asideNamedSet.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<'
  },
  controller: asideNamedSet
};
