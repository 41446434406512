import SECTION from './BUILDING_BLOCKS/SECTION';
import PARAGRAPH from './BUILDING_BLOCKS/PARAGRAPH';
import REFERENCE_GROUP from './BUILDING_BLOCKS/REFERENCE_GROUP';
import REFERENCE_FRAME from './THEME/REFERENCE_FRAME';
import THEME from './THEME/THEME';
import CURRICULUM_CLUSTER from './THEME/CURRICULUM_CLUSTER';
import CURRICULUM_THEME from './THEME/CURRICULUM_THEME';
import SHARED_MINI_DATABASE_ITEM from './SHARED_MINI_DATABASE_ITEM';
import SHARED_FAQ from './SHARED_FAQ';
import SHARED_ATTACHMENTS_GROUP from './SHARED_ATTACHMENTS_GROUP';

export const types = {
  // If type not defined explicitly, will take this config
  GENERIC: require('./GENERIC'),

  // ZILL
  CURRICULUM_ZILL: require('./ZILL/CURRICULUM_ZILL').default,
  CURRICULUM_ZILL_CLUSTER: require('./ZILL/CURRICULUM_ZILL_CLUSTER'),
  CURRICULUM_ZILL_DEVELOPMENT_FIELD: require('./ZILL/CURRICULUM_ZILL_DEVELOPMENT_FIELD'),
  CURRICULUM_ZILL_DEVELOPMENT_THEME: require('./ZILL/CURRICULUM_ZILL_DEVELOPMENT_THEME'),
  CURRICULUM_ZILL_GENERIC_GOAL: require('./ZILL/CURRICULUM_ZILL_GENERIC_GOAL'),
  CURRICULUM_ZILL_CONTENT_GROUP: require('./ZILL/CURRICULUM_ZILL_CONTENT_GROUP'),
  CURRICULUM_ZILL_DEVELOPMENT_CONTENT: require('./ZILL/CURRICULUM_ZILL_DEVELOPMENT_CONTENT'),
  CURRICULUM_ZILL_LEERLIJN: require('./ZILL/CURRICULUM_ZILL_LEERLIJN'),
  CURRICULUM_ZILL_LEERLIJN_AN: require('./ZILL/CURRICULUM_ZILL_LEERLIJN_AN'),
  CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE: require('./ZILL/CURRICULUM_ZILL_LEERLIJN_PRE_REFERENCE'),
  CURRICULUM_ZILL_LEERLIJN_CLUSTER: require('./ZILL/CURRICULUM_ZILL_LEERLIJN_CLUSTER'),
  CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE: require('./ZILL/CURRICULUM_ZILL_LEERLIJN_POST_REFERENCE'),
  ZILL_ILLUSTRATION: require('./ZILL_ILLUSTRATION'),

  // ODET
  CURRICULUM_ODET: require('./ODET/CURRICULUM_ODET'),
  CURRICULUM_ODET_LEVEL: require('./ODET/CURRICULUM_ODET_LEVEL'),
  CURRICULUM_ODET_LEERGEBIED: require('./ODET/CURRICULUM_ODET_LEERGEBIED'),
  CURRICULUM_ODET_SUBSECTION: require('./ODET/CURRICULUM_ODET_SUBSECTION'),
  CURRICULUM_ODET_DEVELOPMENT_GOAL: require('./ODET/CURRICULUM_ODET_DEVELOPMENT_GOAL'),
  CURRICULUM_ODET_ENDTERM: require('./ODET/CURRICULUM_ODET_ENDTERM'),

  // LLINKID
  LLINKID_CURRICULUM: require('./LLINKID/LLINKID_CURRICULUM.js').default,
  LLINKID_GOAL_LIST: require('./LLINKID/LLINKID_GOAL_LIST.js'),
  LLINKID_GOAL_SECTION: require('./LLINKID/LLINKID_GOAL_SECTION.js'),
  LLINKID_GOAL: require('./LLINKID/LLINKID_GOAL.js'),
  LLINKID_GOAL_CONTENT: require('./LLINKID/LLINKID_GOAL_CONTENT.js'),
  LLINKID_GOAL_DEMARCATION: require('./LLINKID/LLINKID_GOAL_DEMARCATION.js'),
  LLINKID_PEDAGOGICAL_TIP: require('./LLINKID/LLINKID_PEDAGOGICAL_TIP.js'),
  LLINKID_EXTRA_GOAL_INFORMATION: require('./LLINKID/LLINKID_EXTRA_GOAL_INFORMATION.js'),
  LLINKID_GOAL_EXPLANATION: require('./LLINKID/LLINKID_GOAL_EXPLANATION.js'),
  LLINKID_INITIAL_SITUATION: require('./LLINKID/LLINKID_INITIAL_SITUATION.js').default,
  LLINKID_GUIDING_PRINCIPLE_GROUP: require('./LLINKID/LLINKID_GUIDING_PRINCIPLE_GROUP.js'),
  LLINKID_GUIDING_PRINCIPLE: require('./LLINKID/LLINKID_GUIDING_PRINCIPLE.js'),
  LLINKID_STATIC_PART: require('./LLINKID/LLINKID_STATIC_PART.js'),
  LLINKID_CONTEXT: require('./LLINKID/LLINKID_CONTEXT.js'),
  LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT: require('./LLINKID/LLINKID_PEDAGOGICAL_DIDACTICAL_CONTEXT.js'),
  LLINKID_BASIC_MATERIAL_REQUIREMENTS: require('./LLINKID/LLINKID_BASIC_MATERIAL_REQUIREMENTS.js'),
  LLINKID_CORRELATION_ENDTERMS: require('./LLINKID/LLINKID_CORRELATION_ENDTERMS.js'),
  LLINKID_CONCORDANCE: require('./LLINKID/LLINKID_CONCORDANCE'),

  // Vision text
  VISION_TEXT: require('./VISION_TEXT'),

  // Term
  TERM: require('./TERM').default,

  // Mark explanation
  MARK_EXPLANATION: require('./MARK_EXPLANATION').default,

  // Practical Example
  PRACTICAL_EXAMPLE: require('./PRACTICAL_EXAMPLE').default,

  // Web pages
  WEBPAGE: require('./WEBPAGE'),
  PRESS: require('./PRESS').default,

  // Web pages 2 => Webpages that are not from drupal
  WEBPAGE2: require('./WEBPAGE2').default,
  GLOBAL_DOCUMENT: require('./GLOBAL_DOCUMENT').default,
  WWW: require('./WWW').default,
  WWWNEWSITEM: require('./WWWNEWSITEM').default,

  TEASER: require('./TEASER').default,
  PRONEWSITEM: require('./PRONEWSITEM').default,
  PRONEWSLETTER: require('./PRONEWSLETTER').default,
  PRONEWSLETTERTEMPLATE: require('./PRONEWSLETTERTEMPLATE').default,

  JOB_OFFER: require('./JOB_OFFER').default,

  // Themes
  REFERENCE_FRAME: REFERENCE_FRAME,
  THEME: THEME,
  SUBJECT: require('./THEME/SUBJECT'),
  STUDY_AREA: require('./THEME/STUDY_AREA'),
  DOMAIN: require('./THEME/DOMAIN'),
  BASIC_OPTION: require('./THEME/BASIC_OPTION'),
  STUDY_DIRECTION: require('./THEME/STUDY_DIRECTION'),
  CURRICULUM_CLUSTER: CURRICULUM_CLUSTER,
  CURRICULUM_THEME: CURRICULUM_THEME,

  // Shared mini database item
  SHARED_MINI_DATABASE_ITEM: SHARED_MINI_DATABASE_ITEM,

  // Shared faq item
  SHARED_FAQ: SHARED_FAQ,

  // Shared attachments group
  SHARED_ATTACHMENTS_GROUP: SHARED_ATTACHMENTS_GROUP,

  // BUILDING BLOCKS
  REFERENCE_GROUP: REFERENCE_GROUP,
  REFERENCE: require('./BUILDING_BLOCKS/REFERENCE'),
  SOURCE: require('./BUILDING_BLOCKS/SOURCE'),
  SECTION: SECTION,
  PARAGRAPH: PARAGRAPH,
  IMAGE: require('./BUILDING_BLOCKS/IMAGE'),
  IMAGE_GROUP: require('./BUILDING_BLOCKS/IMAGE_GROUP'),
  VIDEO: require('./BUILDING_BLOCKS/VIDEO'),
  VIDEO_GROUP: require('./BUILDING_BLOCKS/VIDEO_GROUP'),
  EXAMPLE: require('./BUILDING_BLOCKS/EXAMPLE'),
  ATTACHMENT: require('./BUILDING_BLOCKS/ATTACHMENT'),
  ATTACHMENTS_GROUP: require('./BUILDING_BLOCKS/ATTACHMENTS_GROUP'),
  MUSIC: require('./BUILDING_BLOCKS/MUSIC'),
  QUOTE: require('./BUILDING_BLOCKS/QUOTE'),
  FAQ: require('./BUILDING_BLOCKS/FAQ'),
  FAQ_GROUP: require('./BUILDING_BLOCKS/FAQ_GROUP'),
  SUMMARY: require('./BUILDING_BLOCKS/SUMMARY'),
  LEGAL: require('./BUILDING_BLOCKS/LEGAL'),
  WORD_IMPORT: require('./BUILDING_BLOCKS/WORD_IMPORT'),
};

/**
 * some weird sh*t that was done in the securityCommands.
 * apparently the type is expected to be set in each of the configs like this.
 */
function setallTypesOfTypes(typesObj) {
  Object.keys(typesObj).forEach((type) => {
    typesObj[type].type = type;
  });
}

setallTypesOfTypes(types);

export const documentTypesAllowingSuggestions = Object.values(types).filter(
  (type) => type.allowSuggestions
);
