const { descriptionNotEmpty } = require('../../../validations/descriptionNotEmpty');

module.exports = {
  information: {
    single: 'Duiding',
  },
  node: {
    type: 'LLINKID_GOAL_EXPLANATION',
  },
  indentation: 4,
  edit: [
    {
      component: 'description',
      options: {
        showMarkerButton: true,
      },
      validations: [descriptionNotEmpty],
    },
    {
      component: 'previousVersionItems',
      type: 'previousVersionItems',
      label: 'components.llinkid.previousVersionItems.title',
      options: {
        relationTypes: ['REPLACES'],
        modal: 'llinkidPreviousVersionModal',
        listColumn: 'description',
        placeholder: 'components.llinkid.explanationReplaces.placeholder',
        relation: 'components.llinkid.explanationReplaces.relation',
        errorMessage: 'components.llinkid.explanationReplaces.error.invalidRelation',
        type: 'LLINKID_GOAL_EXPLANATION',
      },
    },
  ],
  buildingBlocks: [
    'SECTION',
    'PARAGRAPH',
    'IMAGE',
    'IMAGE_GROUP',
    'REFERENCE_GROUP',
    'EXAMPLE',
    'ATTACHMENTS_GROUP',
    'MUSIC',
    'VIDEO',
    'VIDEO_GROUP',
    'QUOTE',
  ],
  customRender: true,
};
