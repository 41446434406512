import { titleNotEmpty } from '../../validations/titleNotEmpty';
import { settings } from '../settings';
import { pathIsNotReserved } from '../../validations/pathIsNotReserved';
import { pathIsPermitted } from '../../validations/pathIsPermitted';
import { oldUrlIsPermitted } from '../../validations/oldUrlIsPermitted';
import constants from '../../reduxLoop/constants/constants';
import { documentTags } from '../../reduxLoop/constants/documentTags';
import { websiteUniqueWithinNode } from '../../validations/websiteUniqueWithinNode';
import { urlUniqueApiWithinApi } from '../../validations/urlUniqueWithinApi';
import { urlUniqueWithinDocument } from '../../validations/urlUniqueWithinDocument';

export default {
  information: {
    definiteArticle: true,
    single: 'WWW.-inhoud',
    plural: 'WWW.-inhoud',
    icon: require('../../../img/icons/webpage.svg'),
    category: 'WWW'
  },
  node: {
    type: 'STRUCTURED_DOCUMENT',
    tags: [
      documentTags.wwwContent
    ]
  },
  indentation: 1,
  createModal: {
    theme: {
      filter: {
        root: '085bc39d-c7a6-4209-bc7d-22962d23724b',
        typeIn: 'THEME'
      }
    },
    authors: {
      addUser: true
    },
    importDocx: {
    }
  },
  buildingBlocks: [
    'SECTION',
    'PARAGRAPH',
    'IMAGE',
    'IMAGE_GROUP',
    'ATTACHMENTS_GROUP',
    'VIDEO',
    'VIDEO_GROUP',
    'QUOTE',
    'SUMMARY',
    'LEGAL',
    'REFERENCE_GROUP',
    'EXAMPLE'
  ],
  referenceGroupTypes: [
    constants.referenceGroupTypes.WWW_NEWS_ITEM,
    constants.referenceGroupTypes.WWW_CURRENT_THEME
  ],
  tocTypes: [
    'SECTION',
    'FAQ_GROUP'
  ],
  edit: [
    {
      component: 'title',
      validations: [
        titleNotEmpty
      ]
    },
    {
      component: 'description',
      width: '12',
      label: 'Tegeltekst',
      view: {
        hideIfEmpty: true
      },
      maxAmountOfCharacters: 60,
      options: {
        type: 'shortdescription'
      }
    },
    {
      component: 'description',
      label: 'Inleiding'
    },
    {
      component: 'image',
      width: '6',
      label: 'Omslagfoto',
      options: {
        type: 'COVER_IMAGE',
        crop: {
          type: 'rectangle'
        },
        fields: []
      }
    },
    {
      component: 'image',
      width: '6',
      label: 'Tegelfoto',
      options: {
        type: 'THUMBNAIL',
        crop: true,
        fields: [
          'alt'
        ]
      }
    },
    {
      component: 'authors',
      label: 'Auteurs'
    },
    'issued',
    {
      component: 'themes',
      options: { referenceFrameKey: '085bc39d-c7a6-4209-bc7d-22962d23724b', types: ['THEME'] },
    },
    {
      component: 'contacts',
      width: '12'
    },
    {
      component: 'linkGroup',
      width: '12',
      label: 'Gerelateerd thema on PRO',
      options: {
        type: 'group',
        modalTitle: 'Gerelateerd thema on PRO invoegen'
      }
    }
  ],
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  allowSuggestions: true,
  websiteEdition: [
    {
      domain: settings.domains.www
    },
    {
      domain: settings.domains.pro
    }
  ],
  websiteValidations: [
    pathIsNotReserved,
    oldUrlIsPermitted,
    pathIsPermitted,
    websiteUniqueWithinNode,
    urlUniqueApiWithinApi,
    urlUniqueWithinDocument
  ]
};
