import { documentTags } from '../reduxLoop/constants/documentTags';
import oauthSettings from './vsko-oauth-configuration-stripped.json';

export const settings = {
  angularModuleName: 'VSKO Redactie Project',
  projectName: 'redactie',
  projectTitle: 'Redactie',
  projectVersion: 'dev',
  projectDescription: 'A structured content editor',
  environment: 'local',
  enableMswInBrowser: false,
  apisAndUrls: {
    api: 'https://testapi.katholiekonderwijs.vlaanderen',
    namedSetsApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    securityApi: 'https://testbeveiliging-nodejs.katholiekonderwijs.vlaanderen',
    oldSecurityApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    cachedVakkenApi: 'https://testcached-api.katholiekonderwijs.vlaanderen',
    myVsko: 'https://testmijn.katholiekonderwijs.vlaanderen',
    personsApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    contentApi: 'https://vsko-content-api-test.herokuapp.com',
    trainingApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    websitesApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    privateStateApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    cachedSamenscholingApi: 'https://testcached-api.katholiekonderwijs.vlaanderen',
    proposalApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    mailerApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    auditApi: 'http://localhost:4000',
    searchApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    zillSelector: 'https://testzill-selector.katholiekonderwijs.vlaanderen',
    zill: 'https://testzill.katholiekonderwijs.vlaanderen',
    llinkid: 'http://testllinkid.katholiekonderwijs.vlaanderen',
    proWebsite: 'https://testpro.katholiekonderwijs.vlaanderen',
    newsletterApi: 'https://testapi.katholiekonderwijs.vlaanderen',
    newsletterPreview: 'http://testnieuwsbrief-2.katholiekonderwijs.vlaanderen/{%key}',
    cachedApi: 'https://testcached-api.katholiekonderwijs.vlaanderen',
  },
  domains: {
    pro: 'testpro.katholiekonderwijs.vlaanderen',
    www: 'testwww.katholiekonderwijs.vlaanderen',
  },
  websites: {
    pro: '70b12dcc-724b-46b8-9ab9-b8ecdf35a0ad',
    www: 'f8a0049e-d0c0-4bff-81f1-4403297395f3',
  },
  privateState: {
    component: '/security/components/redactie',
  },
  emails: {
    tagAddressMap: new Map([
      [documentTags.jobOffer, 'www.eindredactie@katholiekonderwijs.vlaanderen'],
      [documentTags.newsItem, 'www.eindredactie@katholiekonderwijs.vlaanderen'],
      [documentTags.pressRelease, 'www.eindredactie@katholiekonderwijs.vlaanderen'],
      [documentTags.proNewsItem, 'pro.eindredactie@katholiekonderwijs.vlaanderen'],
      [documentTags.teaser, 'pro.eindredactie@katholiekonderwijs.vlaanderen'],
      [documentTags.webPage2, 'pro.eindredactie@katholiekonderwijs.vlaanderen'],
      [documentTags.wwwContent, 'www.eindredactie@katholiekonderwijs.vlaanderen'],
      [documentTags.sharedMiniDatabaseItem, 'pro.eindredactie@katholiekonderwijs.vlaanderen'],
      [documentTags.sharedFaq, 'pro.eindredactie@katholiekonderwijs.vlaanderen'],
      [documentTags.sharedAttachmentsGroup, 'pro.eindredactie@katholiekonderwijs.vlaanderen'],
    ]),
    template: {
      status: 'QUEUED',
      sender: {
        name: 'Redactie',
        email: 'redactie@katholiekonderwijs.vlaanderen',
      },
      application: {
        href: '/security/components/redactie',
      },
      globalMergeVariables: [
        {
          name: 'FOOTERWEBSITE',
          content: 'https://redactie.katholiekonderwijs.vlaanderen/',
        },
        {
          name: 'FOOTERCONTACTURL',
        },
        {
          name: 'TWITTERURL',
        },
        {
          name: 'FACEBOOKURL',
        },
        {
          name: 'HEADER',
          content:
            '<h1 style="font-size: 28px; font-weight:normal; font-family: arial,sans-serif; text-align: right; padding-right: 15px;">redactie</h1>',
        },
        {
          name: 'TITLE',
          content: 'Redactie',
        },
      ],
    },
    submitSuggestions: {
      content: {
        body: { href: '/mailer/templates/1122664a-0b02-45d8-80bb-d95356bcfaf0' },
        frame: { href: '/mailer/templates/ad395f55-afa9-44c1-bd04-18990e80a20b' }, // general template frame
      },
    },
    publishDocument: {
      content: {
        body: { href: '/mailer/templates/1122664a-0b02-45d8-80bb-d95356bcfaf0' },
        frame: { href: '/mailer/templates/ad395f55-afa9-44c1-bd04-18990e80a20b' }, // general template frame
      },
    },
  },
  samenscholing: {
    name: 'SAM-ACCESSTOKEN-TEST',
    token: '9f775e43-bdb9-48f0-b7a6-04860a1b4aa8',
    prefix: '/sam',
  },
  resourcesNames: {
    content: '/content',
    relations: '/content/relations',
    responsibilities: '/responsibilities',
    privatestates: '/privatestates',
  },
  logging: {
    logrocket: {
      enabled: false,
      key: 'ydysdg/redactie-prod-0djzk',
      users: [],
    },
    sentry: {
      enabled: true,
      url: 'https://c7a3f97253c747f3995fd5dd9d4f15b6@o268751.ingest.sentry.io/1479203',
      sentryPerformanceSampleRate: 1.0,
    },
  },
  trustedOrigins: ['*'],
  oauth: oauthSettings,
  loginRequired: true,
  debug: true,
  newsHash: '6c48604b-1549-410c-9c81-5467f239800d',
  securityModes: [
    {
      type: 'UPDATE_CONTENT',
      ability: 'update',
      componentHref: '/security/components/content-api',
    },
    {
      type: 'REVIEW',
      ability: 'review',
      componentHref: '/security/components/proposals-api',
      rawUrl: '/proposals',
      useOldSecurity: true,
    },
    {
      type: 'SUGGEST',
      ability: 'suggest',
      componentHref: '/security/components/proposals-api',
      rawUrl: '/proposals',
      useOldSecurity: true,
    },
    {
      type: 'APPROVE',
      ability: 'execute',
      componentHref: '/security/components/newsletter-api',
      rawUrl: '/newslettersender/sendwithjob',
    },
  ],
  prerender: {
    enabled: false,
    url: 'https://api.prerender.io/recache',
    token: 'XviHbBzWrXNJwKRYTvpa',
  },
};
