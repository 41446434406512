/* eslint-disable max-len */
import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class ContactPersonPicker {
  constructor($scope, $ngRedux, searchApi) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.loadedSelectedContacts = false;
    this.searchApi = searchApi;

    this.resourcePickerAuthorTemplate = require('../../../../screen/optionTemplates/authorOption.html');
    this.searchParameters = {
      expand: 'SUMMARY',
      types: 'person',
      'responsibilities.organisationalUnit.root': '/organisations/c000eaea-c451-2590-e044-d4856467bfb8',
      limit: 30,
      highlight: 'false'
    };
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (state.document.viewModel.aside.editDocument.selectedContacts.length && !this.loadedSelectedContacts) {
        // initial load of selected contacts into a component model to avoid problems of disappear/appear when selecting new options
        this.localContactsModel = state.document.viewModel.aside.editDocument.selectedContacts;
        this.loadedSelectedContacts = true;
      }

      return {
        contacts: {
          ...state.document.viewModel.aside.editDocument.contacts,
          key: 'contacts'
        },
        selectedContacts: this.localContactsModel,
        editKey: state.document.viewModel.aside.editDocument.key,
        isReadOnly: state.document.viewModel.aside.isReadOnly
      };
    })(this);
  }

  $onDestroy() {
    this.unsubscribe();
  }

  change(e, selection, list) {
    this.localContactsModel = list;
    this.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(this.editKey, { contacts: list.map(s => s.$$meta.permalink) })
    );
  }

  contact2String(resource) {
    if (resource) {
      if (resource.firstName) {
        return resource.firstName + ' ' + resource.lastName;
      }

      if (resource.$$name) {
        return resource.$$name;
      }

      if (resource.name) {
        return resource.name;
      }
    }
    return '<NONE>';
  }
}

export default {
  template: require('./asideContactPerson.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<'
  },
  controller: ContactPersonPicker
};
