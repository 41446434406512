import { mainstructuresOuTypeCombinationsInconsistent } from '../../../validations/mainstructuresOuTypeCombinationsInconsistent';
import { positionsInconsistent } from '../../../validations/positionsInconsistent';
import { coverageInconsistent } from '../../../validations/coverageInconsistent';
import { themeReferencesWithRootsCmd } from '../../../reduxLoop/commands/documentCommands';
import constants from '../../../reduxLoop/constants/constants';

export default {
  information: {
    single: 'Item',
    plural: 'Items'
  },
  node: {
    type: 'THEME'
  },
  indentation: 3,
  buildingBlocks: [
    'THEME',
    'DOMAIN',
    'SUBJECT',
    'STUDY_AREA',
    'BASIC_OPTION',
    'STUDY_DIRECTION',
    'CURRICULUM_CLUSTER',
    'CURRICULUM_THEME',
  ],
  edit: [
    {
      component: 'title',
      width: '8'
    },
    {
      component: 'color',
      width: '4'
    },
    {
      component: 'image',
      width: '6',
      label: 'Tegelfoto',
      options: {
        type: 'THUMBNAIL',
        fields: [
          'alt'
        ]
      }
    },
    {
      component: 'image',
      width: '6',
      label: 'Icon',
      options: {
        type: 'ICON'
      }
    },
    'issued',
    {
      component: 'current',
      width: '6',
      label: 'edit.current',
      options: {
        field: 'startDate'
      }
    },
    {
      component: 'current',
      width: '6',
      label: 'whiteSpace',
      options: {
        field: 'endDate'
      }
    },
    {
      component: 'subjects',
      options: {
        rawLabel: true
      }
    },
    {
      component: 'namedSet',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      validations: [
        mainstructuresOuTypeCombinationsInconsistent
      ],
      tab: constants.asideTabs.AUDIENCE
    },
    {
      component: 'coverage',
      tab: constants.asideTabs.AUDIENCE,
      validations: [
        coverageInconsistent
      ]
    },
    {
      component: 'namedSet',
      label: 'components.functions.title',
      tag: 'doelgroepen',
      property: 'positions',
      validations: [
        positionsInconsistent
      ],
      tab: constants.asideTabs.AUDIENCE
    },
  ],
  isSearchable: false,
  customRender: false,
  ribbon: {
    openInLine: 'title'
  },
  deleteValidations: [
    (node) => {
      return {
        cmd: themeReferencesWithRootsCmd,
        params: [node.$$meta.permalink],
        message: 'validation.themeReferenced'
      };
    }
  ],
  confirmDelete: true
};
