export const attachmentDocumentTypes = [
  {
    display: 'advies',
    value: 'ADVISE'
  },
  {
    display: 'afbeelding',
    value: 'IMAGE'
  },
  {
    display: 'arrest',
    value: 'ARREST'
  },
  {
    display: 'artikel',
    value: 'ARTICLE'
  },
  {
    display: 'beslissingen',
    value: 'DECISIONS'
  },
  {
    display: 'brief',
    value: 'LETTER'
  },
  {
    display: 'CAO',
    value: 'CAO'
  },
  {
    display: 'convenant',
    value: 'CONVENANT'
  },
  {
    display: 'formulier',
    value: 'FORM'
  },
  {
    display: 'handleiding',
    value: 'MANUAL'
  },
  {
    display: 'kalender',
    value: 'CALENDAR'
  },
  {
    display: 'leerplan',
    value: 'CURRICULUM'
  },
  {
    display: 'lezing',
    value: 'LECTURE'
  },
  {
    display: 'lijst',
    value: 'LIST'
  },
  {
    display: 'mededeling',
    value: 'ANNOUNCEMENT'
  },
  {
    display: 'model',
    value: 'MODEL'
  },
  {
    display: 'nieuwsbrief',
    value: 'NEWSLETTER'
  },
  {
    display: 'nota',
    value: 'BILL'
  },
  {
    display: 'ontwerp wetgeving',
    value: 'DRAFT_LEGISLATION'
  },
  {
    display: 'parlementaire vraag',
    value: 'PARLIAMENTARY_QUESTION'
  },
  {
    display: 'persmedeling',
    value: 'PRESS_RELEASE'
  },
  {
    display: 'presentatie',
    value: 'PRESENTATION'
  },
  {
    display: 'publicatie',
    value: 'PUBLICATION'
  },
  {
    display: 'reglement',
    value: 'RULES'
  },
  {
    display: 'uitnodiging',
    value: 'INVITATION'
  },
  {
    display: 'vacature',
    value: 'VACANCY'
  },
  {
    display: 'verslag',
    value: 'REPORT'
  },
  {
    display: 'visietekst',
    value: 'VISIONTEXT'
  },
  {
    display: 'wetgeving',
    value: 'LEGISLATION'
  }
];
