require('./facetShowList.scss');

// this is a "dumb" component

class FacetShowList {
  $onInit() {
  }
}

module.exports = {
  template: require('./facetShowList.html'),
  controllerAs: 'ctrl',
  bindings: {
    sFacets: '<'
  },
  controller: FacetShowList
};
