import { titleNotEmpty } from '../../validations/titleNotEmpty';
import { coverageNotEmpty } from '../../validations/coverageNotEmpty';
import { descriptionNotEmpty } from '../../validations/descriptionNotEmpty';
import { mainstructuresOuTypeCombinationsNotEmpty } from '../../validations/mainstructuresOuTypeCombinationsNotEmpty';
import { settings } from '../settings';
import constants from '../../reduxLoop/constants/constants';
import { pathIsNotReserved } from '../../validations/pathIsNotReserved';
import { pathIsPermitted } from '../../validations/pathIsPermitted';
import { oldUrlIsPermitted } from '../../validations/oldUrlIsPermitted';
import { isMenuItemReferenceExternalLinkValid } from '../../validations/menuReferenceExternalLink';
import { websiteUniqueWithinNode } from '../../validations/websiteUniqueWithinNode';
import { documentTags } from '../../reduxLoop/constants/documentTags';
import { urlUniqueApiWithinApi } from '../../validations/urlUniqueWithinApi';
import { urlUniqueWithinDocument } from '../../validations/urlUniqueWithinDocument';
import { accessRights } from '../../reduxLoop/helpers/accessRights';
import { webConfigurationRequired } from '../../validations/webConfigurationRequired';
import { mainAndSubCurriculumThemesConsistent } from '../../validations/mainAndSubCurriculumThemesConsistent';

export default {
  information: {
    definiteArticle: true,
    single: 'PRO.-thema',
    plural: "PRO.-thema's",
    icon: require('../../../img/icons/webpage.svg'),
    category: 'PRO',
  },
  node: {
    type: 'STRUCTURED_DOCUMENT',
    tags: [documentTags.webPage2],
  },
  createDefaults: {
    accessRights: accessRights[0].namedsets,
    descendantsAccessRights: accessRights[0].namedsets,
  },
  indentation: 1,
  buildingBlocks: [
    'SECTION',
    'PARAGRAPH',
    'IMAGE',
    'IMAGE_GROUP',
    {
      type: 'ATTACHMENTS_GROUP',
    },
    'VIDEO',
    'VIDEO_GROUP',
    'QUOTE',
    'SUMMARY',
    'LEGAL',
    'REFERENCE_GROUP',
    'EXAMPLE',
  ],
  tocTypes: ['SECTION', 'FAQ_GROUP'],
  edit: [
    {
      component: 'title',
      validations: [titleNotEmpty],
    },
    {
      component: 'description',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
      validations: [descriptionNotEmpty],
      whitelist: [
        {
          webconfiguration: {
            self: [
              'THEME_HOME_TEXT',
              'THEME_HOME_PICTURE',
              'THEME_HOME_FULL',
              'THEME_PAGE',
              'TEMPORARY_PAGE',
            ],
          },
        },
      ],
    },
    {
      component: 'description',
      label: 'Inleiding (Belangrijk: De zoeker toont de inleiding in de zoekresultaten)',
      blacklist: [
        {
          webconfiguration: {
            self: [
              'THEME_HOME_TEXT',
              'THEME_HOME_PICTURE',
              'THEME_HOME_FULL',
              'THEME_PAGE',
              'TEMPORARY_PAGE',
            ],
          },
        },
      ],
    },
    {
      component: 'image',
      width: '6',
      label: 'Tegelfoto',
      options: {
        type: 'THUMBNAIL',
        crop: true,
        fields: ['alt'],
      },
    },
    {
      component: 'authors',
      label: 'Themaverantwoordelijke',
    },
    'issued',
    {
      component: 'subjects',
      options: { rawLabel: true },
      whitelist: [
        {
          webconfiguration: {
            self: [
              'BLOG',
              'MINI_DATABASE',
              'THEME_PAGE',
              'THEME_HOME_FULL',
              'THEME_HOME_PICTURE',
              'THEME_HOME_TEXT',
            ],
          },
        },
      ],
    },
    {
      component: 'themes',
      options: {
        referenceFrameKey: constants.dienstverleningKovKey,
        types: [
          'THEME',
          'BASIC_OPTION',
          'DOMAIN',
          'COMMONS_STUDIEGEBIED',
          'COMMONS_STUDY_PROGRAMME',
          'COMMONS_SUBJECT',
          'CURRICULUM_THEME',
          'CURRICULUM_CLUSTER',
        ],
        highlightOldCurrItems: true,
      },
      validations: [mainAndSubCurriculumThemesConsistent],
    },
    {
      component: 'curriculumSelector',
      options: {
        // do not send main curriculum themes
        filter: (theme) => {
          return (
            theme.type !== 'CURRICULUM_THEME' ||
            !theme.$$children.some((c) => c.type === 'CURRICULUM_THEME')
          );
        },
      },
    },
    {
      component: 'contacts',
      width: '12',
    },
    {
      component: 'namedSet',
      validations: [mainstructuresOuTypeCombinationsNotEmpty],
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'coverage',
      validations: [coverageNotEmpty],
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'accessRights',
      label: 'components.accessRights.descendantsAccessRights',
      options: {
        field: 'descendantsAccessRights',
      },
      tab: constants.asideTabs.AUDIENCE,
    },
    {
      component: 'themes',
      label: 'Databanktype',
      options: {
        referenceFrameKey: constants.contentTypesKey,
        types: ['THEME'],
      },
      whitelist: [
        {
          webconfiguration: {
            self: ['MINI_DATABASE'],
          },
        },
      ],
    },
    {
      component: 'menuItemReference',
      options: {
        filter: {
          typeIn: 'STRUCTURED_DOCUMENT,SECTION',
          rootTag: [documentTags.webPage2],
          $$expandPathToRoot: true,
          orderBy: '$$meta.modified',
          descending: true,
        },
      },
      validations: [isMenuItemReferenceExternalLinkValid],
    },
    {
      component: 'attachmentsGroup',
      options: { global: true },
    },
    {
      component: 'linkGroup',
      width: '12',
      label: 'Links',
      options: {
        type: 'group',
      },
    },
  ],
  asDocumentView: true,
  isCreatable: true,
  isSearchable: true,
  isImportable: true,
  isCloneable: true,
  websiteEdition: [
    {
      domain: settings.domains.www,
    },
    {
      domain: settings.domains.pro,
    },
  ],
  websiteValidations: [
    pathIsNotReserved,
    oldUrlIsPermitted,
    pathIsPermitted,
    websiteUniqueWithinNode,
    urlUniqueApiWithinApi,
    urlUniqueWithinDocument,
    webConfigurationRequired,
  ],
  allowSuggestions: true,
  globalAttachmentsGroupContainer: true,
  hideChildrenInDocument: [
    {
      type: 'ATTACHMENTS_GROUP',
      tags: ['GLOBAL'],
    },
  ],
};
