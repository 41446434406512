
module.exports = ['$rootScope', '$notification', 'Utils', 'settings', 'contentApi', 'constants',
  function ($rootScope, $notification, Utils, settings, contentApi, constants) {
    let service = {
      data: {}
    };

    service.loadDemarcations = async (goalKey) => {
      const params = {
        to: '/content/' + goalKey,
        relationtype: 'IS_PART_OF',
        expand: 'results.from'
      };
      let demarcations = await contentApi.getAll('/content/relations', params);
      demarcations = demarcations
        .filter(relation => relation.from.$$expanded.type === constants.llinkidGoalDemarcationType)
        .map(relation => relation.from.$$expanded);

      $rootScope.$emit('demarcationsLoaded', demarcations);

      return demarcations;
    };

    // @deprecated
    service.summerNoteDemarcationLinkAction = function (context) {
        var ui = $.summernote.ui;//eslint-disable-line

      // create button
      var button = ui.button({
        contents: '<i class="fa"><img src="../../../img/termIcon.svg" /></i>',
        tooltip: 'Afbakening',
        click: async function () {
          var selectedText = context.invoke('editor.createRange');

          if (selectedText && selectedText.toString().trim() === '') {
            $notification.error('Selecteer een stuk tekst om een afbakening aan te te voegen.');
          } else {
            context.invoke('editor.saveRange');

            const demarcationKey = Utils.getUUID();

            console.log('Summernote button clicked');

            // 1. create demarcation, refresh list of demarcations below
            // $rootScope.$emit('addLlinkidDemarcationEvent', selectedText, demarcationKey);

            // 2. wrap the selected region with a link to the created demarcation (similar to term)

            var span = document.createElement('demarcation');

            var text = document.createTextNode('[' + selectedText + ']');

            var style = document.createAttribute('class');
            style.value = 'termInText';

            var type = document.createAttribute('data-title');
            type.value = selectedText;

            var value = document.createAttribute('rel');
            value.value = 'demarcation';

            var value = document.createAttribute('value');
            value.value = '/content/' + demarcationKey;

            span.setAttributeNode(style);
            span.setAttributeNode(type);
            span.setAttributeNode(value);

            span.appendChild(text);

            context.invoke('editor.restoreRange');
            context.invoke('editor.insertText', ' ');
            context.invoke('editor.insertNode', span);
            context.invoke('editor.insertText', ' ');

            // 3. show differently in summernote (similar to a term)
          }
        }
      });

      return button.render(); // return button as jquery object
    };


    service.createDemarcation = function (llinkidGoal, selectedText, demarcationKey) {
      demarcationKey = demarcationKey || Utils.getUUID();

      const demarcation = {
        isNew: true,
        $$meta: { permalink: settings.resourcesNames.content + '/' + demarcationKey },
        key: demarcationKey,
        type: constants.llinkidGoalDemarcationType,
        importance: 'MEDIUM',
        created: (new Date()).toISOString(),
        modified: (new Date()).toISOString(),
        attachments: [],
        selectedText: selectedText.toString().trim(),
        parentKey: llinkidGoal.key
      };

      return demarcation;
    };

    // will create a demarcation resource anmd link it with is_part_of to the goven llikid goal
    // called from: event handler in editController
    service.addDemarcation = async (llinkidGoal, selectedText, demarcationKey, batch) => {
      const demarcation = service.createDemarcation(llinkidGoal, selectedText, demarcationKey);

      if (llinkidGoal.newDemarcations) {
        llinkidGoal.newDemarcations.push(demarcation);
      } else {
        llinkidGoal.newDemarcations = [demarcation];
      }

      if (!batch) {
        try {
          await contentApi.put('/content/' + demarcationKey, JSON.stringify(demarcation));
          // await contentApi.put('/content/relations/' + relKey, relation)

          console.log('Demarcation created in api');

          // TODO emit event to notify new demarcation (will be catched and reach demarcations section)
          $rootScope.$emit('demarcationAdded', demarcation);
        } catch (error) {
          console.log('Error creating demarcation:', error);
        }
      } else {
        batch.put('/content/' + demarcationKey, demarcation);
      }
    };


    service.transformDemarcationReferencesIntoLinks = (textWithDemarcationReferences) => {
      var domParser = new DOMParser();
      var docElement = domParser.parseFromString(textWithDemarcationReferences, 'text/html').documentElement;
      var allDemarcationReferencesElements = docElement.getElementsByTagName('demarcation');

      for (var j = 0; j < allDemarcationReferencesElements.length; j++) {
        var text = allDemarcationReferencesElements[j].getAttribute('data-title');
        var href = allDemarcationReferencesElements[j].getAttribute('value');

        var linkRepresentation = '<a href="' + href + '" rel="demarcation">' + text + '</a>';
        // replace <demarcation ....> with <a href='/content/uuid'>
        var text = allDemarcationReferencesElements[j].outerHTML;
        text = Utils.replaceAll(text, '&nbsp;', ' ');

        textWithDemarcationReferences = Utils.replaceAll(textWithDemarcationReferences, text, linkRepresentation);
      }

      return textWithDemarcationReferences;
    };

    service.transformLinksIntoDemarcation = function (textWithDemarcationLinks) {
      var domParser = new DOMParser();
      var docElement = domParser.parseFromString(textWithDemarcationLinks, 'text/html').documentElement;
      var allLinksElements = docElement.getElementsByTagName('a');

      for (var j = 0; j < allLinksElements.length; j++) {
        var linkHref = allLinksElements[j].getAttribute('href');
        var linkType = allLinksElements[j].getAttribute('rel');
        var innerText = allLinksElements[j].innerText;

        if (!linkType || linkType.indexOf('demarcation') !== -1) {
          var linkToBeReplaced = allLinksElements[j].outerHTML;

          var representation = '<demarcation class="termInText" value="' + linkHref + '" data-title="' + innerText + '">[' + innerText + ']</demarcation>';
          // replace <a> with <demarcation ...bla >
          textWithDemarcationLinks = Utils.replaceAll(textWithDemarcationLinks, linkToBeReplaced, representation);
        }
      }
      return textWithDemarcationLinks;
    };

    /**
     * Get a list of demarcation hrefs using the given text with <demarcation> tags
     */
    service.getDemarcationReferencesAfterEdition = function (text) {
      let afterEdition = [];

      var domParser = new DOMParser();

      var docElement = domParser.parseFromString(text, 'text/html').documentElement;
      var demarcationReferencesInContent = docElement.getElementsByTagName('demarcation');
      for (var i = 0; i < demarcationReferencesInContent.length; i++) {
        if (demarcationReferencesInContent[i].innerText.trim() != '') {
          afterEdition.push(demarcationReferencesInContent[i].getAttribute('value'));
        }
      }

      return afterEdition;
    };

    /**
     * Get a list of demarcation object creating it using the given text with <demracation> tags
     */
    service.demarcationsInText = function (text) {
      let demarcations = [];

      var domParser = new DOMParser();

      var docElement = domParser.parseFromString(text, 'text/html').documentElement;
      var demarcationReferencesInContent = docElement.getElementsByTagName('demarcation');
      for (var i = 0; i < demarcationReferencesInContent.length; i++) {
        if (demarcationReferencesInContent[i].innerText.trim() != '') {
          const href = demarcationReferencesInContent[i].getAttribute('value');
          demarcations.push({
            key: href.split('/')[href.split('/').length - 1],
            href: href,
            title: demarcationReferencesInContent[i].getAttribute('data-title')
          });
        }
      }

      return demarcations;
    };

    service.getDemarcationRelationDifferences = function (demarcationReferencesAfterEdition, demarcationReferencesBeforeEdition) {
      Array.prototype.diff = function (a) {
        return this.filter(function (i) { return a.indexOf(i) < 0; });
      };

      // now we need to compare $scope.demarcationReferences vs demarcationReferencesAfterEdition
      var newDemarcationReferenceRelations = demarcationReferencesAfterEdition.diff(demarcationReferencesBeforeEdition);
      var demarcationReferenceRelationsToBeDeleted = demarcationReferencesBeforeEdition.diff(demarcationReferencesAfterEdition);

      const result = {
        relationsToAdd: newDemarcationReferenceRelations,
        relationsToDelete: demarcationReferenceRelationsToBeDeleted
      };

      console.log('Demarcations diff relations result:', result);

      return result;
    };

    return service;
  }];
