import { createSelector } from '@reduxjs/toolkit';
import { settings } from '../../config/settings';
import { types } from '../../config/types/index';
import { RootState } from '../../types/rootStateTypes';
import { getAllowedTypes, getUrlsForPath } from './userHelpers';
import { sortBy } from 'lodash';

const selectSecurityData = (state: RootState): Record<string, string[]> | null =>
  state.newUser.securityData;

export const selectUser = (state: RootState): string | null =>
  state.newUser.personHref && state.externalData.persons[state.newUser.personHref];

export const selectUserHref = (state: RootState): string | null => state.newUser.personHref;

export const selectIsUserLoaded = (state: RootState): boolean =>
  state.newUser.isLoading === false && selectUser(state) !== undefined;

export const selectAllowedAbilities = createSelector(
  selectSecurityData,
  (securityData: Record<string, string[]> | null): string[] => {
    if (!securityData) {
      return [];
    }
    const allowedAbilities: string[] = [];

    settings.securityModes.forEach((mode) => {
      if (mode.rawUrl && securityData[mode.type].includes(mode.rawUrl)) {
        allowedAbilities.push(mode.type);
      }
    });

    if (
      getUrlsForPath(securityData.UPDATE_CONTENT, '/content/hashes').some((rawUrl) => {
        const keys = rawUrl.searchParams.get('keyIn')?.split(',') || [];
        return keys && keys.includes(settings.newsHash);
      })
    ) {
      allowedAbilities.push('UPDATE_NEWS');
    }

    return allowedAbilities;
  }
);

export const selectHasUserReviewAbility = (state: RootState) =>
  selectAllowedAbilities(state).includes('REVIEW');

export const selectHasUserUpdateNewsAbility = (state: RootState) =>
  selectAllowedAbilities(state).includes('UPDATE_NEWS');

// legacy viewmodel for document list which is not part of the refactor => we did not type the returned vm because it is still a mess
export const selectUserVmForDocumentList = createSelector(
  selectSecurityData,
  selectAllowedAbilities,
  (securityData: Record<string, string[]> | null, allowedAbilities: string[]) => {
    if (!securityData) {
      return {
        creatables: [],
        searchables: [],
        cloneables: [],
        publishedEditables: [],
        searchCategories: [],
      };
    }
    const allowedTypes = getAllowedTypes(securityData);
    const searchableTypes = allowedTypes.filter((t) => t.isSearchable);
    const vm = {
      creatables: sortBy(
        allowedTypes.filter((t) => t.isCreatable).map((t) => ({ ...types[t.type], type: t.type })),
        [(o) => o.information.single]
      ),
      searchables: searchableTypes.map((t) => ({ ...types[t.type], type: t.type })),
      cloneables: allowedTypes
        .filter((t) => t.isCloneable)
        .map((t) => ({ ...types[t.type], type: t.type })),
      publishedEditables: allowedTypes
        .filter((t) => t.isCreatable && t.isPublishedEditable)
        .map((t) => ({ ...types[t.type], type: t.type })),
      searchCategories: searchableTypes.reduce(
        (ret: any[], t: any) => {
          const category = ret.find(
            (c) => c.category === t.category || (!t.category && c.category === 'GENERAL')
          );
          if (category) {
            category.searchables.push({ ...types[t.type], type: t.type });
          }
          return ret;
        },
        [
          { category: 'PRO', searchables: [] },
          { category: 'WWW', searchables: [] },
          { category: 'LEERPLAN', searchables: [] },
          { category: 'ZILL', searchables: [] },
          { category: 'GENERAL', searchables: [] },
        ]
      ),
      allowedAbilities,
    };
    console.log('VM for documentList', vm);
    return vm;
  }
);
