/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { getUiType, getTypeConfig } from '../../services/types';
import { getReferenceFramesFacet, isAgeRangeFacetRequired, hasGoalSelectorFacet } from '../../services/proWebsite/configurationsFacets';
import { settings } from '../../config/settings';
import {
  filterConfigBasedOnNode, getResourceKey, isUserEditingNotAllowedRootNode
} from '../helpers/documentHelpers';
import { fillAsideNodeWithProposalViewModel, fillWebsiteConfigurationWithProposalViewModel, getProposalPropertyForReferenceRelation } from './proposalViewModel';
import {
  getLinkedContent,
  getImagesInGroup,
  getReferenceRelationsTo,
  getWebsitesConfiguration,
  getReferenceRelationsFrom,
  get$$attachments,
  getInheritedWebConfigurations,
  getNamedSetsOptions,
  getInheritedCoverage,
  getReferenceRelations,
  getExpandedFieldResources,
  getIsNamedSetsInconsistent,
  getInheritedAccessRights,
  getIsAccessRightsInconsistent,
  getSelectedAccessRights,
  getAttachmentResources,
  createReferenceFrameOptions,
} from './viewModelHelpers';
import { config as documentTypes } from '../constants/documentTypes';
import { setOldUrls } from '../helpers/webConfigHelpers';
import { getNodeStateFromTypeRelations, getConcordantiesFromDocument } from '../helpers/documentAsideHelpers';

// TODO remove when all are converted
const transformFields = ['title', 'identifiers', 'importance', 'description', 'html', 'shortdescription', 'coverage', 'mainstructuresOuTypeCombinations', 'outypes', 'creators', 'contacts', 'attachments'];
const excludeFields = ['key', 'type', 'tags', 'attachments', 'accessRights'];

/**
 * Enriches the node with some extra $$ properties
 * The property name will be used as the extra property for the node.
 * This is expected to be a function. This function recieves: node, state, flat
 */
const nodeExtensions = {
  $$type: (node) => {
    return getUiType(node);
  },
  $$typeConfig: (node) => {
    return getTypeConfig(node.$$type) || {};
  },
  $$color: (node) => {
    const parentColor = node.$$parent && node.$$parent.$$color
      ? node.$$parent.$$color
      : null;
    return node.color ? node.color : parentColor;
  },
  $$attachments: (node, state) => {
    return get$$attachments(node, state, settings);
  },
  $$parent: (node, state) => {
    if (!node.$$parent) {
      return null;
    }

    // eslint-disable-next-line max-len
    node.$$parent.websitesConfiguration = nodeExtensions.websitesConfiguration(node.$$parent, state);

    return node.$$parent;
  },
  linkRelations: (node, state) => {
    // links could be inside a link_group or directly related to the node
    const linkGroup = node.$$children.find(c => c.$$type === 'LINK_GROUP');

    const key = linkGroup ? linkGroup.key : node.key;

    const linkRelations = (state.apiWithPendingChanges.contentRelations.to['/content/' + key] || [])
      .reduce((result, relation) => {
        const content = state.apiWithPendingChanges.content.get(relation.from.href);
        if (relation.relationtype === 'IS_PART_OF' && content && content.type === 'REFERENCE') {
          relation.from.$$expanded = content;
          const referenceRelations = state.apiWithPendingChanges.contentRelations.from['/content/' + content.key]
            .filter(rel => rel.relationtype === 'REFERENCES');
          if (referenceRelations.length > 0) {
            relation.from.$$expanded.$$url = referenceRelations[0].to.href;

            result.push(relation);
          }
        }
        return result;
      }, []);

    linkRelations.forEach(r => {
      const linkVM = state.viewModel.flatWithHiddens.find(
        n => n.key === getResourceKey(r.from.href)
      );
      r.proposal = linkVM.proposal;
    });

    return linkRelations;
  },
  referenceRelationsTo: (node, state) => {
    const refRelationsTo = getReferenceRelationsTo(node, state);

    if (state.selectChoices.references) {
      refRelationsTo.forEach(r => {
        const refNode = state.selectChoices.references
          .find(ref => ref.$$meta.permalink === r.to.href);
        if (refNode) {
          r.to.$$expanded.$$color = refNode.$$color;
          r.to.$$expanded.$$prefix = refNode.$$prefix;
        }
      });
    }

    // set proposal data to each reference in case it has it
    refRelationsTo.forEach(r => {
      const referenceVM = state.viewModel.flatWithHiddens.find(
        n => n.key === getResourceKey(r.from.href)
      );
      r.proposal = referenceVM.proposal;
    });

    return refRelationsTo;
  },
  linkedContent: (node, state) => {
    return getLinkedContent(node, state);
  },
  referenceRelationsFrom: (node, state) => {
    return getReferenceRelationsFrom(node, state);
  },
  zillGoalReferenceRelations: (node, state) => {
    return node.referenceRelationsFrom
      .filter(rel => {
        return rel.to.$$expanded && (rel.to.$$expanded.type === 'CURRICULUM_ZILL_GENERIC_GOAL' || rel.to.$$expanded.type === 'CURRICULUM_ZILL_DEVELOPMENT_CONTENT');
      })
      .map(rel => {
        if (!rel.to.$$expanded.$$treeAsLeaf) {
          if (state.resourcesWithTreeAsLeaf[rel.to.href]) {
            rel.to.$$expanded = {
              ...rel.to.$$expanded,
              $$treeAsLeaf: state.resourcesWithTreeAsLeaf[rel.to.href]
            };
          } else if (!state.resourcesToGetTreeAsLeaf.find(r => r.href === rel.to.href)) {
            state.resourcesToGetTreeAsLeaf.push({
              href: rel.to.href
            });
          }
        }
        rel.proposal = getProposalPropertyForReferenceRelation('/content/relations/' + rel.key, node, state);
        return { ...rel };
      });
  },
  websitesConfiguration: (node, state) => {
    return getWebsitesConfiguration(node, state);
  },
  websitesReferenceFramesMap: (node, state) => {
    // get the node reference frame facet selections
    const nodeWebsitesReferenceFrame = state.websitesReferenceFramesMap['/content/' + node.key];
    // mark those selected themes proposed to be deleted
    if (nodeWebsitesReferenceFrame) {
      [...nodeWebsitesReferenceFrame.values()].forEach(themesSelectedInRefFrame => {
        return themesSelectedInRefFrame.map(themeNode => {
          const themePermalink = themeNode.$$expanded ? themeNode.$$expanded.$$meta.permalink : themeNode.$$meta.permalink;
          const relationWithChanges = [...state.apiWithPendingChanges.relations.values()]
            .find(relation => relation.from.href === themePermalink);

          if (relationWithChanges) {
            themeNode.deleteProposal = relationWithChanges.deleteProposal;
          }
          return themeNode;
        });
      });
    }
    return nodeWebsitesReferenceFrame;
  },
  inheritedWebConfigurations: (node, state) => {
    return getInheritedWebConfigurations(node, state);
  },
  $$editSections: (node) => {
    return filterConfigBasedOnNode(node.$$typeConfig.edit, node);
  },
  imagesInGroup: (node, state) => {
    return getImagesInGroup(node, state);
  },
  isDownloadAttachmentsGroup: (node) => {
    return (
      (node.$$parent &&
        node.$$parent.websitesConfiguration.find((c) =>
          documentTypes.downloadWebconfigurationTypes.includes(c.type)
        )) ||
      node.inheritedWebConfigurations.find((c) =>
        documentTypes.downloadWebconfigurationTypes.includes(c.type)
      ) ||
      node.$$type === 'SHARED_ATTACHMENTS_GROUP'
    );
  },
  attachmentResources: (node, state) => {
    const opts = {
      node,
      state,
      fromAside: true
    };
    return getAttachmentResources(opts);
  },
  downloadAttachment: (node) => {
    return node.$$attachments && node.$$attachments.has('ATTACHMENT')
      ? node.$$attachments.get('ATTACHMENT').original
      : null;
  },
  selectedThemes: (node, state) => {
    return node.themes && state.viewModel.referenceFrameThemes && state.viewModel.referenceFrameThemes.options
      ? state.viewModel.referenceFrameThemes.options.filter(l => node.themes.value.includes(l.$$meta.permalink))
      : [];
  },
  selectedSubjects: (node, state) => {
    const subjectOptions = state.subjects || [];
    return node.subjects
      ? subjectOptions.filter(opt => node.subjects.value.includes(opt.$$meta.permalink))
      : [];
  },
  selectedContacts: (node, state) => {
    return getExpandedFieldResources(node, 'contacts', state);
  },
  inheritedCoverage: (node) => {
    return getInheritedCoverage(node);
  },
  inheritedAccessRights: (node) => {
    return getInheritedAccessRights(node);
  },
  selectedAccessRights: (node) => {
    return getSelectedAccessRights(node);
  },
  isAccessRightsInconsistent: (node) => {
    return getIsAccessRightsInconsistent(node);
  },
  referenceRelations: (node, state) => {
    return getReferenceRelations(node.key, state.apiWithPendingChanges);
  },
  namedSetsOptions: (node, state) => {
    return getNamedSetsOptions(node, state);
  },
  isNamedSetsInconsistent: (node) => {
    return getIsNamedSetsInconsistent(node);
  },
  color: (node) => {
    return node.color || {};
  },
  concordanties: (node, state) => {
    const documentToGetReferencesFrom = state.zillOdetCurriculum;
    const relations = getNodeStateFromTypeRelations(node.key, state);
    return getConcordantiesFromDocument(documentToGetReferencesFrom, relations);
  },
  selectedSecondaryEducationType: (node, state) => {
    return state.viewModel.aside.secondaryEducationTypes?.find((l) =>
      node.secondaryEducationTypes?.value.includes(l.$$meta.permalink)
    );
  },
  facetSourceWebConfigs: (node, state) => {
    if (state.facetSourceWebConfigsMap.has(node.key)) {
      return state.facetSourceWebConfigsMap.get(node.key);
    }
    return [];
  },
};

function allowWebsiteEdition(editDocument, root) {
  return editDocument.$$typeConfig && editDocument.$$typeConfig.websiteEdition
    && (!root
      || (root && root.$$typeConfig.websiteEdition));
}

function getWebsiteDomains(editDocument, root) {
  if (!editDocument) {
    return undefined;
  }

  if (!editDocument.$$typeConfig || !editDocument.$$typeConfig.websiteEdition) {
    return getWebsiteDomains(editDocument.$$parent, root);
  }

  return editDocument.$$typeConfig.websiteEdition.filter(w => {
    return (!w.whitelist
      || (root && w.whitelist.some(i => i.root && i.root.$$type === root.$$type)));
  }).map(w => w.domain);
}

function getTypeTitle(editDocument) {
  if (editDocument && editDocument.$$typeConfig) {
    if (editDocument.$$typeConfig.information
      && editDocument.$$typeConfig.information.conditionalTitle) {
      return editDocument.$$typeConfig.information.conditionalTitle(editDocument);
    }
    if (editDocument.$$typeConfig.information
      && editDocument.$$typeConfig.information.single) {
      return editDocument.$$typeConfig.information.single;
    }
    return editDocument.$$type;
  }
  return '';
}

function isReadOnly(node, state) {
  const proposal = state.api.proposals.get('/content/' + node.key);

  const childrenWithProposal = node.$$children.reduce((list, child) => {
    const childProposal = state.api.proposals.get('/content/' + child.key);
    if (childProposal) {
      list.push({
        proposal: childProposal,
        child: state.apiWithPendingChanges.content.get('/content/' + child.key)
      });
    }
    return list;
  }, []);

  return state.mode === 'READ_ONLY' || (state.mode === 'SUGGESTING'
    && ((proposal && proposal.status === 'SUBMITTED_FOR_REVIEW')
      || (childrenWithProposal.some(c => c.proposal.status === 'SUBMITTED_FOR_REVIEW' && c.$$isHidden))))
    || ((state.tree.issued || [...state.api.proposals.values()].length > 0) && state.mode === 'EDIT' && state.viewModel.suggestions.isSuggestionModeAllowed && !state.viewModel.suggestions.isReviewModeAllowed)
    || isUserEditingNotAllowedRootNode(node.key, state);
}

/**
 * Creates a view model for the aside screen.
 * @param {string} key
 * @param {object} state
 */
export const generateAsideViewModel = (key, root, state) => {
  const node = { ...state.apiWithPendingChanges.content.get('/content/' + key) };

  const resourcesToExpand = new Set();

  if (node) {
    Object.keys(node).forEach((field) => {
      if (node[field] && ((!field.startsWith('$$') && !excludeFields.some(f => f === field)) || transformFields.some(f => f === field))) {
        node[field] = {
          value: node[field]
        };
      }
    });

    Object.keys(nodeExtensions).forEach((extension) => {
      if (nodeExtensions[extension]) {
        node[extension] = nodeExtensions[extension](node, state);
      }
    });

    if (state.mode !== 'EDIT') {
      fillAsideNodeWithProposalViewModel(node, state, nodeExtensions, resourcesToExpand);
    }

    fillWebsiteConfigurationWithProposalViewModel(node, state);

    // websites api refactor: map oldLocations to oldUrls
    setOldUrls(node.websitesConfiguration);
  }

  return {
    ...state.viewModel.aside,
    rootDocument: state.tree,
    editDocument: node,
    loading: false,
    titleLabel: getTypeTitle(node),
    webpages: {
      allowEdition: node ? allowWebsiteEdition(node, root) : false,
      allSites: state.websites,
      domains: getWebsiteDomains(node, root),
      templates: (state.webtemplates || []),
      facets: {
        showReferenceFrame:
          getReferenceFramesFacet(
            node.facetSourceWebConfigs,
            node.$$type,
            node.websitesReferenceFramesMap
          ).length > 0,
        showAgeRange: isAgeRangeFacetRequired(node.facetSourceWebConfigs),
        showZillGoals: hasGoalSelectorFacet(node.facetSourceWebConfigs),
      },
    },
    documentSections: state.viewModel.flatWithHiddens.filter(n => ['SECTION', 'WEBPAGE2'].includes(n.$$type)),
    goals: state.viewModel.flatWithHiddens.filter(n => n.$$type === 'CURRICULUM_ZILL_GENERIC_GOAL'),
    llinkid: {
      educationalActivityTypes: state.educationalActivityTypes
    },
    referenceFrames: Object.fromEntries(
      Object.entries(state.referenceFrame).map(([k, v]) => [k, createReferenceFrameOptions(v)])
    ),
    isReadOnly: isReadOnly(node, state),
    isUserEditingNotAllowedRootNode: isUserEditingNotAllowedRootNode(node.key, state),
    resourcesToExpand,
    expandedResources: state.viewModel.aside.expandedResources || [],
    subjectOptions: state.subjects || [],
    choices: state.selectChoices.references && state.selectChoices.references.filter(choice => !state.referenceRelations.find(p => p.to.$$expanded.$$meta.permalink === choice.$$meta.permalink)),
    dateToSend: state.apiWithPendingChanges.newsletterSettings.values().next().value ? state.apiWithPendingChanges.newsletterSettings.values().next().value.dateToSend : undefined
  };
};
