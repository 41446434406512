import * as constants from '@store/constants/constants';

// Main curriculum themes and one or more of its sub-curriculum themes may not be selected at the same time
export const mainAndSubCurriculumThemesConsistent = (node, state) => {
  if (!node.themes || !node.themes.length) {
    return true;
  }

  const primaryReferenceFrame = state.referenceFrame[constants.dienstverleningKovKey];

  return primaryReferenceFrame &&
    primaryReferenceFrame.some(
      (i) =>
        i.type === 'CURRICULUM_THEME' &&
        node.themes.includes(i.$$meta.permalink) &&
        i.$$children?.some(
          (c) => c.type === 'CURRICULUM_THEME' && node.themes.includes(c.$$meta.permalink)
        )
    )
    ? { type: 'ERROR', message: 'validation.mainAndSubCurriculumThemesSelected' }
    : true;
};
