module.exports = {
  information: {
    single: 'Krachtlijnen',
    plural: 'Krachtlijnen'
  },
  node: {
    type: 'LLINKID_GUIDING_PRINCIPLE_GROUP'
  },
  indentation: 2,
  edit: [],
  buildingBlocks: [
    'LLINKID_GUIDING_PRINCIPLE'
  ],
  showPlaceholder: true,
  editInModal: false,
  createInModal: false,
  isDraggable: false
};
