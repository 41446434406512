import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class rowCurriculumReference {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        referenceRelations: state.document.viewModel.flat.find(f => f.key === this.sItem.key)
          && state.document.viewModel.flat.find(f => f.key === this.sItem.key).referenceRelations
      };
    })(this);

    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.expandRelationsFromAction(
      null, this.referenceRelations, true
    ));
  }

  $onChanges() {
  }

  saveInline(scope, event, editorData) {
    const patch = {
      [editorData.field]: editorData.value
    };
    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(editorData.key, patch)
    );
  }

  referenceTitle(reference) {
    return this.getReferenceIdentifier(reference) + ' ' + reference.title;
  }

  getReferenceIdentifier(reference) {
    if (reference.identifiers && reference.identifiers.length > 0) {
      return reference.identifiers[0];
    }
    if (reference.data && reference.data.identifiers && reference.data.identifiers.length > 0) {
      return reference.data.identifiers[0];
    }
    return '';
  }

  display(item) {
    if (!item.to.$$expanded) {
      return '';
    }
    const reference = item.to.$$expanded;
    let display = angular.isFunction(item.type.display)
      ? item.type.display(reference)
      : reference[item.type.display];

    // add root title in parentheses
    if (reference.$$root && reference.$$root.title) {
      display += ` (${reference.$$root.title.trim()})`;
    }
    return display;
  }
}

export const rowCurriculumReferenceComponent = {
  template: require('./rowCurriculumReference.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<'
  },
  controller: rowCurriculumReference
};
