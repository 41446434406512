module.exports = {
  information: {
    single: 'Goal content',
    plural: 'Goal contents'
  },
  node: {
    type: 'LLINKID_GOAL_CONTENT'
  },
  indentation: 4,
  edit: [
    {
      component: 'title',
      options: {
        showMarkerButton: true
      }
    }
  ],
  buildingBlocks: [
    'LLINKID_GOAL_CONTENT'
  ]
};
