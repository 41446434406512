class rowExternalResource {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  typeLabel() {
    return ' ' + (this.sItem.label || this.sConfig.relation.label || '');
  }
}

export const rowExternalResourceComponent = {
  template: require('./rowExternalResource.html'),
  controllerAs: 'ctrl',
  bindings: {
    sItem: '<',
    sConfig: '<'
  },
  controller: rowExternalResource
};
