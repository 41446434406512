const constants = require('../../../reduxLoop/constants/constants');

module.exports = {
  information: {
    single: 'Wettekst',
    plural: 'Wettekst',
    ribonIcon: require('../../../../img/buildingBlocks/LEGAL.svg')
  },
  node: {
    type: 'LEGAL',
    attachments: [
      {
        type: 'CONTENT',
        name: 'content.html',
        contentType: 'text/html'
      }
    ]
  },
  indentation: 1,
  buildingBlocks: [
    'PARAGRAPH',
    'IMAGE_GROUP',
    'IMAGE',
    'VIDEO'
  ],
  edit: [
    {
      component: 'identifier',
      width: 3
    },
    {
      component: 'title',
      width: 9
    },
    {
      component: 'attachedContent',
      label: ' '
    },
    {
      component: 'namedSet',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    },
    {
      component: 'coverage',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    }
  ],
  asDocumentView: true,
  customRender: true
};
