import constants from '../reduxLoop/constants/constants';

export const pathIsPermitted = (node) => {
  const pathForbidden = node.websitesConfiguration.some(c => constants.reservedCharacters.test(c.path));

  return !pathForbidden ? true : {
    type: 'ERROR',
    message: 'components.proWebsite.configurations.error.pathIsForbidden',
    params: {}
  };
};
