import * as DOCUMENT_LIST_ACTIONS from '../../../reduxLoop/actions/documentListActions';
import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';
import { selectSelectedResults } from '@newStore/documentList/newDocumentListSelectors';

export class publishDateModal {
  constructor($scope, $ngRedux, $uibModalInstance, $location) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$uibModalInstance = $uibModalInstance;
    this.$location = $location;
    this.params = $scope.$resolve.modalData || {};
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (!state.documentList.publishModalOpen && !state.document.publishModalOpen) {
        this.$uibModalInstance.close();
      }

      let selected = selectSelectedResults(state);
      if (this.params.document) {
        selected = [this.params.document];
      }

      return {
        selected,
        saveAttempted: state.documentList.saveAttempted || state.document.publishAttempted,
        publishModalOpen: state.documentList.publishModalOpen || state.document.publishModalOpen,
        allowSuggestions: selected.some((s) => s.$$typeConfig.allowSuggestions),
      };
    })(this);
  }

  updatePublishDate(event, date) {
    this.publishDate = date;
  }

  $onDestroy() {
    this.unsubscribe();
  }

  submit() {
    if (this.publishDate) {
      this.$ngRedux.dispatch(
        this.params.type === 'DOCUMENT'
          ? DOCUMENT_ACTIONS.publishDocumentAction(
              this.publishDate,
              this.message,
              this.$location.absUrl()
            )
          : DOCUMENT_LIST_ACTIONS.publishAction(
              this.selected,
              this.publishDate,
              this.message,
              this.$location.absUrl()
            )
      );
    }
  }

  cancel() {
    this.$ngRedux.dispatch(DOCUMENT_LIST_ACTIONS.closePublishModal());
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.closePublishModal());
  }

  change(scope, event, data) {
    // key pressed
    scope.ctrl.message = data.value;
  }

  blur(scope, event, data) {
    scope.ctrl.message = data.value;
  }
}
