module.exports = {
  information: {
    single: 'Statisch gedeelte',
    plural: 'Statisch gedeelte',
  },
  node: {
    type: 'LLINKID_STATIC_PART',
  },
  indentation: 2,
  edit: [],
  buildingBlocks: [
    {
      type: 'PARAGRAPH',
      max: 1,
    },
  ],
  showPlaceholder: true,
  editInModal: false,
};
