import { getStore } from '../reduxLoop/store';
import { initRouter } from './routes';

export default ['$translateProvider', '$stateProvider',
  '$urlRouterProvider', 'ngToastProvider', '$locationProvider', '$ngReduxProvider',
  function ($translateProvider, $stateProvider,
    $urlRouterProvider, ngToast, $locationProvider, $ngReduxProvider) {
    // Configure translations
    function getLocale() {
      return (
        window.navigator.language
        || window.navigator.browserLanguage
        || window.navigator.systemLanguage
        || window.navigator.userLanguage || ''
      ).split('-').join('_');
    }

    $translateProvider
      .translations('en', require('../config/translations/en'))
      .translations('nl', require('../config/translations/nl'))
      .determinePreferredLanguage(
        function () {
          const language = getLocale().toLowerCase().substring(0, 2);

          if (language && language.indexOf('nl') !== -1) {
            return 'nl';
          }

          return 'en';
        }
      )
      .fallbackLanguage('en');

    // Configure Router
    initRouter($stateProvider, $urlRouterProvider, $locationProvider);

    // Configure Notifications
    ngToast.configure({
      verticalPosition: 'bottom',
      horizontalPosition: 'left',
      animation: 'slide'
    });

    // Initiate the store
    $ngReduxProvider.provideStore(getStore());
  }
];
