class asideValidityPeriod {
  constructor($scope, $ngRedux, $translate) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
    this.$translate = $translate;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.document.viewModel.aside,
        validityPeriodString: state.document.viewModel.document
          ? this.getValidityPeriodString(
              state.document.viewModel.document.$$validityPeriod
            )
          : '',
      };
    })(this);
  }

  $onInit() {}

  getValidityPeriodString(validityPeriod) {
    if (!validityPeriod) {
      return this.$translate.instant('edit.validityPeriodMsg.empty');
    }

    let msg = this.$translate.instant(
      'edit.validityPeriodMsg.start',
      validityPeriod
    );

    if (validityPeriod.end) {
      msg += this.$translate.instant('edit.validityPeriodMsg.end', validityPeriod);
    }

    return msg;
  }
}

export default {
  template: require('./asideValidityPeriod.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<'
  },
  controller: asideValidityPeriod,
};
