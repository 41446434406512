import * as constants from '@store/constants/constants';

export const databaseTypeNotEmpty = (node, state) => {
  const databaseTypeReferenceFrame = state.referenceFrame[constants.contentTypesKey];

  return databaseTypeReferenceFrame &&
    databaseTypeReferenceFrame.some((i) => node.themes?.includes(i.$$meta.permalink))
    ? true
    : { type: 'WARNING', message: 'validation.databaseTypeNotEmpty' };
};
