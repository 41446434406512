module.exports = ['$scope', '$uibModalInstance', '$q', 'data', function($scope, $uibModalInstance, $q, data) {  //eslint-disable-line
  $scope.title = data.title;
  $scope.confirmText = data.confirmText;
  $scope.cancelText = data.cancelText;
  $scope.saveAttempted = false;

  $scope.isSubmitting = () => {
    return $scope.saveAttempted;
  };

  $scope.submit = () => {
    const defer = $q.defer();
    $scope.saveAttempted = true;
    data.onConfirm(defer);

    defer.promise.then(() => {
      $scope.saveAttempted = false;
      $uibModalInstance.close();
    });
  };

  $scope.cancel = () => {
    if (data.onCancel) {
      data.onCancel();
    }

    $uibModalInstance.dismiss('cancel');
  };
}];
