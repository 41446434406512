import { getEmbedVideoLinkFrom } from '../../../reduxLoop/helpers/documentHelpers';

class rowVideoGroup {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
  }

  $onChanges() {
    this.videos = this.sItem.$$children.filter(c => c.$$attachments.has('VIDEO')).map(c => c.$$attachments.get('VIDEO').original.playbackUrl);
  }

  videoSrc(videoPlaybackUrl) {
    if (!videoPlaybackUrl) {
      return false;
    }
    return getEmbedVideoLinkFrom(videoPlaybackUrl);
  }
}

export const rowVideoGroupComponent = {
  template: require('./rowVideoGroup.html'),
  controllerAs: 'ctrl',
  bindings: {
    sHash: '<',
    sItem: '<'
  },
  controller: rowVideoGroup
};
