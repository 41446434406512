import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';

class ConcordantieModal {
  constructor($notification, $translate, $ngRedux, $scope, contentApi) {
    'ngInject';

    this.notificationService = $notification;
    this.$translate = $translate;
    this.resourcePickerDocumentTemplate = require('../../../../screen/optionTemplates/documentOption.html');

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
    this.contentApi = contentApi;
  }

  hasError() {
    if (!this.odet && this.resolve.modalData.isRoot) {
      this.notificationService.error(this.$translate.instant('components.zill.error.invalidOdetSelection'));
      return true;
    }
    if (!this.resolve.modalData.isRoot && !this.endterm && !this.endterms) {
      this.notificationService.error(this.$translate.instant('components.zill.error.invalidEndTermsSelection'));
      return true;
    }
    return false;
  }

  async submit() {
    if (!this.hasError()) {
      this.modalInstance.close({
        odet: this.odet,
        endterm: this.endterm,
        endterms: this.endterms,
        initialRelation: this.initialRelation
      });
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  $onInit() {
    this.initialRelation = this.resolve.modalData.relation;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      const stateZillCurriculum = state.document.zillOdetCurriculum;
      const stateZillCurriculumChildren = (stateZillCurriculum
        && stateZillCurriculum.$$fullTree) || [];

      return {
        odet: stateZillCurriculum,
        odetChildren: stateZillCurriculumChildren
      };
    })(this);

    if (this.initialRelation && !this.resolve.modalData.isRoot) {
      this.endterm = this.initialRelation;
    }
  }

  $onDestroy() {
    this.unsubscribe();
  }

  document2String(d) {
    return d ? d.title : '<NONE>';
  }

  eindterm2String(node) {
    let identifier = '';
    if (node && node.$$prefix) {
      identifier = node.$$prefix;
    }

    let response = '<NONE>';

    if (node) {
      response = (identifier ? identifier + ' - ' : '') + node.title;
      if (response.length > 115) {
        response = response.substr(0, 115) + '...';
      }
    }

    return response;
  }
}


export default {
  template: require('./concordantieModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<'
  },
  controller: ConcordantieModal
};
