module.exports = {
  information: {
    icon: require('../../../img/icons/document.svg')
  },
  indentation: 1,
  buildingBlocks: [
    'SECTION',
    'PARAGRAPH'
  ],
  edit: [
    'title',
    'description',
    'attachedContent',
    'issued',
    'authors',
    'themes',
    'importance'
  ],
  asDocumentView: true,
};
