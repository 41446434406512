module.exports = {
  information: {
    single: 'Source',
    plural: 'Sources'
  },
  node: {
    type: 'SOURCE'
  },
  edit: [
    'title'
  ],
  hideInDocument: true,
  isSearchable: false
};
