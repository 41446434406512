const constants = require('../../../reduxLoop/constants/constants');

module.exports = {
  information: {
    single: 'Afbeeldingsgroep',
    plural: 'Afbeeldingsgroepen',
    ribonIcon: require('../../../../img/buildingBlocks/IMAGE_GROUP.svg')
  },
  node: {
    type: 'IMAGE_GROUP'
  },
  buildingBlocks: [],
  edit: [
    'imageGroup',
    {
      component: 'namedSet',
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    },
    {
      component: 'coverage',
      whitelist: [
        {
          root: {
            $$type: 'WEBPAGE2'
          }
        }
      ],
      tab: constants.asideTabs.AUDIENCE
    }
  ],
  allowSibling: true,
  isSearchable: false,
  hideChildrenInDocument: true,
  customRender: true
};
