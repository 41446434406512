import { string } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { KovResourcePicker } from '@kathondvla/react-shared-components/src/components';

import * as DOCUMENT_ACTIONS from '../../../../reduxLoop/actions/documentActions';
import {
  selectIsNewsletterSenderComponentLoading,
  selectNewsletterSenderOptions,
  selectSelectedNewsletterSender,
} from './asideNewsletterSenderSelectors';
import { selectAsideContentKey, selectIsAsideReadonly } from '@store/selectors/asideSelectors';

const AsideNewsletterSender = ({ label }) => {
  const $translate = getAngularService('$translate');
  const dispatch = useDispatch();

  const isReadonly = useSelector(selectIsAsideReadonly);
  const isLoading = useSelector(selectIsNewsletterSenderComponentLoading);
  const senderOptions = useSelector(selectNewsletterSenderOptions);
  const selectedSender = useSelector(selectSelectedNewsletterSender);
  const asideContentKey = useSelector(selectAsideContentKey);

  const onChange = (selection) => {
    dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(asideContentKey, {
        newsletterSender: { href: selection.href },
      })
    );
  };

  const addCursorStyle = (provided) => ({ ...provided, cursor: 'pointer' });

  return (
    <div className="form-group">
      <label className="control-label">{$translate.instant(label)}</label>
      {isLoading ? (
        <div className="form-group shimmer narrowLine"></div>
      ) : (
        <KovResourcePicker
          value={selectedSender}
          localData={senderOptions}
          display="display"
          clearable={false}
          disabled={isReadonly}
          disableSearch={true}
          style={{ option: addCursorStyle, valueContainer: addCursorStyle }}
          onChange={onChange}
        />
      )}
    </div>
  );
};

AsideNewsletterSender.propTypes = { label: string };

export default AsideNewsletterSender;
