import * as DOCUMENT_ACTIONS from '../../reduxLoop/actions/documentActions';
require('./teaserButtons.scss');

class teaserButtons {
  constructor($scope, $state, $ngRedux, $http, $timeout, constants) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$ngRedux = $ngRedux;
    this.$timeout = $timeout;
    this.$http = $http;

    this.teaserLinkOptions = constants.teaserLinkOptions;
  }

  $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      if (state.document.redirect) {
        this.$state.go(state.document.redirect.path, { key: state.document.redirect.key });
        this.$ngRedux.dispatch(DOCUMENT_ACTIONS.clearRedirect());
      }

      return {
        key: state.document.viewModel.document && state.document.viewModel.document.key,
        title: state.document.viewModel.document && state.document.viewModel.document.title,
        linkedContent: state.document.viewModel.document && state.document.viewModel.document.linkedContent,
        itemsToSave: state.document.viewModel.itemsToSave,
        attachments: state.document.viewModel.document && state.document.viewModel.document.attachments,
        creators: state.document.viewModel.document && state.document.viewModel.document.creators,
        isValid: state.document.viewModel.isValid,
        isEditMode: state.document.mode === 'EDIT',
        isReadOnly: state.document.viewModel.isReadOnlyDocument
      };
    })(this);
  }

  goToItem() {
    if (this.linkedContent.type === this.teaserLinkOptions.EVENT.name) {
      window.open('https://nascholing.be/redirectTo.aspx?redirectID=' + this.linkedContent.referencedResourceHref, '_blank');
    } else {
      this.$state.go('edit', { key: this.linkedContent.referencedResourceKey });
    }
  }

  openEdit() {
    this.$state.go('edit.aside', { editKey: this.key });
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.updateAsideViewModelAction(this.key));
    this.$timeout(() => document.getElementById('linkedContent').scrollIntoView());
  }

  createNewsItem() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.createNewsItemAction(this.key, this.title, this.creators, this.attachments, this.$http));
  }

  $onDestroy() {
    this.unsubscribe();
  }
}

export default {
  template: require('./teaserButtons.html'),
  controllerAs: 'ctrl',
  controller: teaserButtons
};
