import React from 'react';
import Button from "@UI/button/Button";
import { getAngularService } from '@kathondvla/react-shared-components/src/helpers/angularReactHelper';
import { useSelector } from 'react-redux';

const AsideCurriculumSelectorButton=({label, icon, onClick}) => {
  const $translate = getAngularService('$translate');
  const disabled = useSelector((state) => state.document.viewModel.aside.isReadOnly);

  return (
    <div className="form-group">
      <span className="input-group">
        <span className="input-group-btn">
          <Button
            icon={icon}
            onClick={onClick}
            disabled={disabled}
          >
            {$translate.instant(label)}
          </Button>
        </span>
      </span>
    </div>
  )
}

export default AsideCurriculumSelectorButton;