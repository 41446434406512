/* eslint-disable max-len */
import { settings } from '../../config/settings';
import {
  filterConfigBasedOnNode, getResourceKey, isMatch, isUserEditingNotAllowedRootNode
} from '../helpers/documentHelpers';
import { fillTreeNodeProposalViewModel, createTocNodeProposalViewModel } from './proposalViewModel';
import {
  getLinkedContent,
  getLinkedTeasers,
  getReferenceRelationsTo,
  getReferenceRelationsFrom,
  getWebsitesConfiguration,
  treeToFlatWithHiddens,
  getOdetIdentifier,
  getDocumentReferencedTerms,
  getDocumentTermOptions,
  otherCreatorsInSuggestions,
  getImagesInGroup,
  get$$attachments,
  getAttachmentResources,
  getExpandedFieldResources,
  getNumberOfModifications,
  getModificationAfterLastRead,
  getReferenceFrameThemes,
  getInheritedWebConfigurations,
  getReferenceRelations,
  getSelectedAccessRights,
  getInheritedAccessRights
} from './viewModelHelpers';
import { config as constants } from '../constants/documentTypes';
import uuidv4 from 'uuid/v4';
import { getProposalForIssuedDate } from '../helpers/documentProposalsHelpers';
import _ from 'lodash';

/**
 * Enriches the node with some extra $$ properties
 * The property name will be used as the extra property for the node.
 * This is expected to be a function. This function recieves: node, state, flat
 */
export const nodeExtensions = {
  $$identifier: (node) => {
    return node.identifiers && node.identifiers.length > 0 ? node.identifiers.join(' ') : '';
  },
  $$selection: (node, state) => {
    const selections = state.selections;
    const $$selection = {
      self: selections.includes(node.$$relation && node.$$relation.key),
      parent: false
    };

    if (node.$$parent) {
      if (node.$$parent.$$selection.self) {
        $$selection.parent = node.$$parent.$$level;
      } else if (node.$$parent.$$selection.parent) {
        $$selection.parent = node.$$parent.$$selection.parent;
      }
    }

    return $$selection;
  },
  $$prefix: (node) => {
    function findParentWithPrefix(childNode) {
      if (childNode.$$parent) {
        if (childNode.$$parent.$$prefix) {
          return childNode.$$parent.$$prefix;
        }
        return findParentWithPrefix(childNode.$$parent);
      }
      return '';
    }

    const identifier = node.$$identifier;
    if (node.$$typeConfig.documentViewIdentifier === 'i') {
      return identifier + '';
    }
    if (node.$$typeConfig.documentViewIdentifier === 'pi' && node.$$parent) {
      return node.$$parent.$$prefix && identifier ? node.$$parent.$$prefix + identifier : identifier;
    }
    if (node.$$typeConfig.documentViewIdentifier === 'p i' && node.$$parent) {
      return node.$$parent.$$prefix && identifier ? node.$$parent.$$prefix + ' ' + identifier : identifier;
    }
    if (node.$$typeConfig.documentViewIdentifier === 'p.i') {
      return node.$$parent.$$prefix && identifier ? node.$$parent.$$prefix + '.' + identifier : identifier;
    }
    if (node.$$typeConfig.documentViewIdentifier === '(p)i') {
      return findParentWithPrefix(node) + identifier;
    }
    if (node.$$typeConfig.documentViewIdentifier === '(p).i') {
      return findParentWithPrefix(node) + '.' + identifier;
    }
    if (node.$$typeConfig.documentViewIdentifier === '(p).i.') {
      return findParentWithPrefix(node) + '.' + identifier + '.';
    }
    if (node.$$typeConfig.documentViewIdentifier === '(p)i.i') {
      if (node.$$parent && node.$$parent.$$type === node.$$type) {
        return node.$$parent.$$prefix + '.' + identifier;
      }
      return findParentWithPrefix(node) + identifier;
    }
    if (node.$$typeConfig.documentViewIdentifier === 'o(p*)(.)i') {
      return getOdetIdentifier(node);
    }

    if (identifier) {
      return identifier;
    }
    return '';
  },
  $$color: (node) => {
    const parentColor = node.$$parent && node.$$parent.$$color
      ? node.$$parent.$$color
      : null;
    return node.color ? node.color : parentColor;
  },
  $$attachments: (node, state) => {
    return get$$attachments(node, state, settings);
  },
  $$isRoot: (node, state, flat) => {
    return flat.length === 0 || flat[0].key === node.key;
  },
  $$isHidden: (node) => {
    return (node.$$typeConfig.hideInDocument && !node.$$isRoot)
      || !node.$$typeConfig.type
      || (node.$$parent
        && (node.$$parent.$$isHidden
          || node.$$parent.$$isCollapsed
          || !node.$$parent.$$typeConfig.type
          || node.$$parent.$$typeConfig.hideChildrenInDocument === true
          || (node.$$parent.$$typeConfig.hideChildrenInDocument && node.$$parent.$$typeConfig.hideChildrenInDocument.some(c => isMatch(node, c)))
          || (node.$$parent.proposal && node.$$parent.proposal.isDeleted)));
  },
  websitesConfiguration: (node, state) => {
    return getWebsitesConfiguration(node, state);
  },
  inheritedWebConfigurations: (node, state) => {
    return getInheritedWebConfigurations(node, state);
  },
  inheritedAccessRights: (node) => {
    return getInheritedAccessRights(node);
  },
  selectedAccessRights: (node) => {
    return getSelectedAccessRights(node);
  },
  isDownloadAttachmentsGroup: (node) => {
    return (node.$$parent && node.$$parent.websitesConfiguration.find(c => constants.downloadWebconfigurationTypes.includes(c.type)))
      || node.inheritedWebConfigurations.find(c => constants.downloadWebconfigurationTypes.includes(c.type))
      || node.$$type === 'SHARED_ATTACHMENTS_GROUP';
  },
  $$editSections: (node) => {
    return filterConfigBasedOnNode(node.$$typeConfig.edit, node);
  },
  $$validations: (node, state) => {
    const $$validations = [];
    node.$$editSections.filter(o => o.validations).forEach(component => {
      component.validations.forEach(validation => {
        const ranValidation = validation(node, state);
        if (ranValidation !== true) {
          $$validations.push({ error: ranValidation, component: component.component });
        }
      });
    });

    if (!node.$$typeConfig.websiteValidations) return $$validations;

    const validations = node.$$typeConfig.websiteValidations.reduce((validations, v) => {
      const isValid = v(node, state);
      if (isValid !== true) {
        return [...validations, { error: isValid }];
      }
      return validations;
    }, []);

    return [...$$validations, ...validations];
  },
  $$isCollapsed: (node, state) => {
    if (state.collapsedNodes.hasOwnProperty('/content/' + node.key)) {
      return state.collapsedNodes['/content/' + node.key];
    }
    return node.$$typeConfig.collapsedByDefault;
  },
  $$hoverOnCollapse: (node, state) => {
    const parentValue = node.$$parent
      ? node.$$parent.$$hoverOnCollapse.self || node.$$parent.$$hoverOnCollapse.parent
      : false;
    return {
      self: node.key === state.hoverOnCollapse,
      parent: parentValue
    };
  },
  $$hash: (node, state) => {
    function createHash(s) {
      let hash = 0;
      let i;
      let char;
      let l;
      if (s.length === 0) return hash;
      for (i = 0, l = s.length; i < l; i += 1) {
        char = s.charCodeAt(i);
        hash = ((hash << 5) - hash) + char;
        hash |= 0; // Convert to 32bit integer
      }
      return hash;
    }
    if (state.forceHashUpdateToAll) {
      return createHash(uuidv4());
    }
    if (!node.$$isHidden) {
      return createHash(`${state.dirtyNodes[node.key] ? state.dirtyNodes[node.key] : ''}${node.$$relation ? node.$$relation.key : ''}`);
    }
    return 'HIDDEN';
  },
  imagesInGroup: (node, state) => {
    node.$$children.forEach(c => c.$$attachments = nodeExtensions.$$attachments(c, state));
    const removedChildren = [...state.apiWithPendingChanges.proposals.values()]
      .map(p => p.listOfRequestedChanges)
      .flat()
      .filter(c => c.type === 'DELETE')
      .map(c => c.appliesTo.href);
    return getImagesInGroup(node, state, removedChildren);
  },
  $$children: (node, state, flat) => {
    return node.$$children.map(c => {
      // add extra extensions for VM node child which doesn't have it yet
      c.isInTOC = nodeExtensions.isInTOC(c, state, flat);
      c.$$attachments = nodeExtensions.$$attachments(c, state);
      return c;
    });
  },
  proposal: fillTreeNodeProposalViewModel,
  $$buildingBlocks: (node, state) => {
    return filterConfigBasedOnNode(node.$$typeConfig.buildingBlocks, node, state);
  },
  $$dropZones: (node, state, flat) => {
    function getPosibleDropZones(posibleDropZones, parentNode, position) {
      // Set Dropzones Of higher levels
      if (!parentNode.$$isCollapsed || (parentNode.$$children && parentNode.$$children.length === 0)) {
        posibleDropZones[parentNode.$$level + 1] = {
          types: parentNode.$$buildingBlocks.filter(o => {
            if (o.max) {
              return parentNode.$$children
                && parentNode.$$children.filter(c => c.$$type === o.type).length < o.max;
            }
            return !o.disableDropInDocument;
          }).map(o => o.type),
          level: parentNode.$$level + 1,
          levelParent: parentNode,
          position: position
        };
      }

      if (parentNode.$$parent) {
        getPosibleDropZones(posibleDropZones, parentNode.$$parent, parentNode.$$index + 2);
      }

      return posibleDropZones;
    }
    if (!(node.$$isHidden)) {
      if (flat.length > 0) {
        Object.keys(flat[flat.length - 1].$$dropZones).forEach(level => {
          if (node.$$level > level) {
            delete flat[flat.length - 1].$$dropZones[level];
          }
        });
      }
    }

    return getPosibleDropZones({}, node, 1);
  },
  isReadOnly: (node, state, flat) => {
    const root = flat.length > 0 ? flat[0] : null;
    return (
      (node.proposal !== undefined && node.proposal.isSubmitted && state.mode === 'SUGGESTING') ||
      ((state.tree.issued || [...state.api.proposals.values()].length > 0) &&
        state.mode === 'EDIT' &&
        state.viewModel.suggestions.isSuggestionModeAllowed &&
        !state.viewModel.suggestions.isReviewModeAllowed) ||
      (state.tree.issued &&
        (node.$$typeConfig.disableDeleteWhenIssued ||
          (root && root.$$typeConfig.disableDeleteWhenIssued))) || 
          // TODO: isReadOnly is not enough. in LLINKID we cannot delete nodes once the root is issued.
          // we need to add a new property in the VM to disable delete when issued, but still allow editing. now editing inline is disabled,
          // but editing in the aside is still works. that makes no sense.
      isUserEditingNotAllowedRootNode(node.key, state)
    );
  },
  isInTOC: (node, state, flat) => {
    const root = flat.length > 0 ? flat[0] : null;
    return root && node && root.$$typeConfig
        && root.$$typeConfig.tocTypes
        && root.$$typeConfig.tocTypes.indexOf(node.$$type) !== -1
        && node.$$level > 1
        && node.$$level <= 4
        && ['MEDIUM', 'HIGH'].indexOf(node.importance) > -1;
  },
  attachmentResources: (node, state) => {
    const opts = {
      node,
      state,
      fromAside: false
    };
    return getAttachmentResources(opts);
  },
  referenceRelationsTo: (node, state) => {
    const references = getReferenceRelationsTo(node, state);

    // set proposal data to each reference in case it has it
    references.forEach(ref => {
      ref.proposal = fillTreeNodeProposalViewModel({
        key: getResourceKey(ref.from.href)
      }, state);
    });
    return references;
  },
  referenceRelationsFrom: (node, state) => {
    return getReferenceRelationsFrom(node, state);
  },
  expandedAuthors: (node, state) => {
    return getExpandedFieldResources(node, 'creators', state);
  },
  selectedContacts: (node, state) => {
    return getExpandedFieldResources(node, 'contacts', state);
  },
  numberOfModifications: (node, state) => {
    return getNumberOfModifications(node, state);
  },
  modificationAfterLastRead: (node, state) => {
    return getModificationAfterLastRead(node, state);
  },
  linkedContent: (node, state) => {
    return getLinkedContent(node, state);
  },
  linkedTeasers: (node, state) => {
    return getLinkedTeasers(state);
  },
  referenceRelations: (node, state) => {
    return getReferenceRelations(node.key, state.apiWithPendingChanges);
  }
};


function tocRows(rows, state) {
  function getChildsWithProposalNotInTOC(node) {
    return node.$$children.reduce((list, child) => {
      const n = rows.find(r => r.key === child.key);
      if (n && (!n.isInTOC || n.$$isHidden) && n.proposal) {
        list.push(n);
      }
      return list;
    }, []);
  }

  function getChildsWithLastReadIndicatorNotInTOC(node) {
    return node.$$children.reduce((list, child) => {
      const n = rows.find(r => r.key === child.key);
      if (n && (!n.isInTOC || n.$$isHidden) && n.modificationAfterLastRead) {
        list.push(n.modificationAfterLastRead);
      }
      return list;
    }, []);
  }

  if (rows.length > 0) {
    return [...rows]
      .reverse() // reverse to start checking from leaf children to root
      .map(n => {
        const childsWithProposalNotInTOC = getChildsWithProposalNotInTOC(n);
        // look for nodes that has the proposal indicator for their children
        if (!n.proposal && childsWithProposalNotInTOC.length && state.mode !== 'EDIT') {
          n.proposal = createTocNodeProposalViewModel(n, childsWithProposalNotInTOC, state);
        }
        const childsWithLastReadIndicatorNotInTOC = getChildsWithLastReadIndicatorNotInTOC(n);
        // look for nodes that need to have the last read indicator for their children
        if (!n.modificationAfterLastRead && childsWithLastReadIndicatorNotInTOC.length) {
          n.modificationAfterLastRead = {
            ...childsWithLastReadIndicatorNotInTOC[0],
            isDisplayedOnlyInToc: true
          };
        }
        return n;
      })
      .reverse()
      .filter(n => n.isInTOC && !n.$$isHidden);
  }
  return [];
}

export const treeToFlatVM = (treeRoot, state, includeHiddens = false) => {
  if (includeHiddens) {
    return treeToFlatWithHiddens(treeRoot, state, nodeExtensions);
  }
  let flat = [];
  function addChildren(node) {
    if (node && node.$$typeConfig && node.$$typeConfig.type && !node.$$isHidden) {
      if (node.$$children && node.$$children.length > 0) {
        node.$$children
          .sort((a, b) => a.readorder - b.readorder)
          .map(child => {
            let childVM = { ...child };
            childVM.$$parent = node;
            if (state) {
              Object.keys(nodeExtensions).forEach((extension) => {
                childVM[extension] = nodeExtensions[extension](childVM, state, flat);
              });
            }
            if (!childVM.$$isHidden && childVM.$$typeConfig.type) {
              flat.push(childVM);
            }
            return addChildren(childVM);
          });
      }
    }
    return node;
  }

  if (!treeRoot.$$isHidden) {
    let rootVM = { ...treeRoot };
    if (state) {
      Object.keys(nodeExtensions).forEach((extension) => {
        rootVM[extension] = nodeExtensions[extension](rootVM, state, flat);
      });
    }
    flat.push(rootVM);
    addChildren(rootVM);
  }
  return flat;
};

const getMarkExplanationReferences = (relationsMap) => {
  const markExplanationReferences = [...relationsMap.values()]
    .filter(r => r.relationtype === 'REFERENCES'
      && r.to.$$expanded && r.to.$$expanded.type === 'MARK_EXPLANATION')
    .map(r => r.to.$$expanded);
  return _(markExplanationReferences)
    .uniqBy('key')
    .orderBy([meRef => meRef.title.toLowerCase()])
    .value();
};

/**
 * Creates a view model for the document tree main screen.
 * @param {object} documentSubState
 * @param {object} allowedAbilities
 */
export const generateDocumentViewModel = (documentSubState, allowedAbilities) => {
  const tree = documentSubState.tree;
  let allBuildingBlocks = [];

  const flatWithHiddens = treeToFlatVM(tree, documentSubState, true);

  const flat = flatWithHiddens.filter(n => {
    // if (n.$$parent) {
    //   console.log('Node:', n.key, n.$$parent.key, n.$$relation.key,
    //     n.proposal ? n.proposal.isSubmitted : undefined);
    // }
    return !n.$$isHidden || n.$$isRoot;
  });

  const document = flat[0];

  flat.forEach(node => {
    if (node.$$buildingBlocks) {
      allBuildingBlocks = [...new Set([
        ...allBuildingBlocks,
        ...node.$$buildingBlocks.map(o => o.type)
      ])];
    }
  });

  const documentAllowSuggestions = document.$$typeConfig && document.$$typeConfig.allowSuggestions
  && (document.issued || documentSubState.api.proposals.values().length > 0);

  const userAllowedToApprove = allowedAbilities.includes('APPROVE');
  const userAllowedToSuggest = allowedAbilities.includes('SUGGEST');
  const userAllowedToReview = allowedAbilities.includes('REVIEW');
  const isSuggestionModeAllowed = documentAllowSuggestions && userAllowedToSuggest;
  const isReviewModeAllowed = documentAllowSuggestions && userAllowedToReview;
  const isValid =
    flatWithHiddens &&
    !flatWithHiddens.some(
      (node) => node.$$validations && node.$$validations.some((v) => v.error.type === 'ERROR')
    );

  const terms = getDocumentReferencedTerms(documentSubState);
  const loading = documentSubState.resourcesToExpand.length > 0 || documentSubState.gettingTreeAsLeaf;

  return {
    ...documentSubState.viewModel,
    flat,
    flatWithHiddens,
    document,
    loading,
    initialLoadOngoing: documentSubState.viewModel.initialLoadOngoing && loading, // initially set as true until loading turns to false, then we know the initial loading is over
    selectionCount: documentSubState.allSelections.length,
    itemsToSave: documentSubState.pendingActions.length,
    hasPendingActions: documentSubState.pendingActions.length > 0,
    isSaving: isValid && documentSubState.saving.length > 0,
    allBuildingBlocks,
    confirmDeleteMessages: documentSubState.allSelections.reduce((list, nodeKey) => {
      const node = flatWithHiddens.find(n => n.key === nodeKey);
      if (node.$$typeConfig.confirmDeleteMessage) {
        const confirmMessage = node.$$typeConfig.confirmDeleteMessage(node);
        if (confirmMessage) {
          list.add(confirmMessage);
        }
      }
      if (node.$$typeConfig.confirmDelete === true) {
        list.add('delete.warningMessage');
      }
      return list;
    }, new Set()),
    toc: tocRows(flatWithHiddens, documentSubState),
    isValid,
    documentSections: flatWithHiddens.filter(n => ['SECTION', 'WEBPAGE2'].includes(n.$$type)),
    previewLocations: [
      ...document && document.$$typeConfig && document.$$typeConfig.previewModes
        ? document.$$typeConfig.previewModes : [],
      ...document.websitesConfiguration
        ? document.websitesConfiguration.map((o) => {
          return {
            type: 'URL',
            name: o.website.domain + o.path,
            location: 'https://' + o.website.domain + o.path + '?preview&redactie'
          };
        })
        : []
    ],
    terms,
    termOptions: getDocumentTermOptions(documentSubState, document, terms.local),
    isPublishAvailable: documentSubState.mode === 'EDIT' && tree.$$typeConfig.allowSuggestions && !tree.issued
      && [...documentSubState.api.proposals.values()].length === 0,
    allReadButton: {
      isVisible: (tree.$$type === 'WEBPAGE2' || (tree.$$type === 'PRONEWSLETTER' && userAllowedToApprove)) && documentSubState.mode === 'EDIT',
      isDisabled: documentSubState.markedAllRead
    },
    suggestions: {
      ...documentSubState.viewModel.suggestions,
      isSuggestionModeAllowed,
      isReviewModeAllowed,
      isSuggesting: documentSubState.mode === 'SUGGESTING',
      isReviewing: documentSubState.mode === 'REVIEWING',
      countSuggestionsToSubmitSelected: documentSubState.proposalsToSubmit.length,
      countSuggestionsToReviewSelected: documentSubState.proposalsToReview.length,
      otherCreatorsInSuggestionsToSubmit:
        otherCreatorsInSuggestions(documentSubState.proposalsToSubmit, documentSubState),
      isAllPendingSuggestionsToSubmit:
        documentSubState.proposalsToSubmit.length === documentSubState.allProposalsToSubmit.length,
      isAllPendingSuggestionsToReview:
        documentSubState.proposalsToReview.length === documentSubState.allProposalsToReview.length,
      groupedProposalsToSubmitByAuthor: documentSubState.groupedProposalsToSubmitByAuthor.filter(group => !group.authors.includes(`/persons/${documentSubState.me.key}`)),
      hasProposalForIssuedDate: getProposalForIssuedDate(documentSubState.proposalsToReview) !== undefined
    },
    isReadOnlyDocument: documentSubState.mode === 'READ_ONLY' || (documentSubState.mode === 'EDIT' && isSuggestionModeAllowed && !isReviewModeAllowed),
    allowedAbilities,
    referenceFrameThemes: getReferenceFrameThemes(documentSubState),
    markExplanationReferences: getMarkExplanationReferences(documentSubState.apiWithPendingChanges.relations)
  };
};
