import * as NOTIFICATION_ACTIONS from '../../../../reduxLoop/actions/notificationActions';
import { isValidMicrosoftstreamUrl, isValidVimeoUrl, isValidYouTubeUrl } from '../../../../reduxLoop/helpers/documentHelpers';

class videoGroupModal {
  constructor($notification, $translate, $ngRedux, $scope, Utils) {
    'ngInject';

    this.$ngRedux = $ngRedux;
    this.$scope = $scope;
    this.$notification = $notification;
    this.utils = Utils;

    this.videoAttachment = {};
  }

  hasError() {
    const isValidUrl = isValidYouTubeUrl(this.videoAttachment.playbackUrl)
      || isValidVimeoUrl(this.videoAttachment.playbackUrl)
      || isValidMicrosoftstreamUrl(this.videoAttachment.playbackUrl);

    const hasError = this.videoAttachment.playbackUrl === undefined
      || this.videoAttachment.playbackUrl.trim() === ''
      || !isValidUrl;

    if (hasError) {
      this.$ngRedux.dispatch(NOTIFICATION_ACTIONS.addNotificationAction({
        type: 'ERROR',
        message: 'create.video.error.invalidUrl'
      }));
    }

    return hasError;
  }

  async submit() {
    if (!this.hasError()) {
      this.modalInstance.close(this.videoAttachment.playbackUrl);
    }
  }

  cancel() {
    this.modalInstance.dismiss();
  }

  removeVideo() {
    this.videoAttachment = {};
  }

  $onInit() {
    this.videoAttachment.playbackUrl = this.resolve.data;
  }
}


export default {
  template: require('./videoGroupModal.html'),
  controllerAs: 'ctrl',
  bindings: {
    modalInstance: '<',
    resolve: '<'
  },
  controller: videoGroupModal
};
