import { Content } from "../../types/apiTypes";

export function areAllResultsSelected(results: Content[], selected: Record<string, boolean>): boolean {
  const allResultsSelected = results.every((result) => selected[result.$$meta.permalink] === true);
  return allResultsSelected;
};

export function getSelectedResults(results: Content[], selected: Record<string, boolean>) {
  const selectedResults = results.filter((result) => selected[result.$$meta.permalink] === true);
  return selectedResults;
};
