import * as newDocumentListSagas from './documentList/newDocumentListStateSagas';
import * as userSagas from './user/userSagas';
import * as externalDataSagas from './externalData/externalDataSagas';

export function initSagas(sagaMiddleware) {
  Object.values(newDocumentListSagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
  Object.values(userSagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
  Object.values(externalDataSagas).forEach(sagaMiddleware.run.bind(sagaMiddleware));
}

export default initSagas;
