class Relations {
  constructor() {
    'ngInject';
  }
}

export default {
  template: require('./goalRelations.html'),
  controllerAs: 'ctrl',
  bindings: {
    sDocument: '<',
    sConfig: '<',
  },
  controller: Relations,
};
