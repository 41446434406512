module.exports = {
  information: {
    single: 'Inhouds Groep',
    plural: 'Inhouds Groepen'
  },
  node: {
    type: 'CURRICULUM_ZILL_CONTENT_GROUP'
  },
  buildingBlocks: [
    'CURRICULUM_ZILL_DEVELOPMENT_CONTENT'
  ],
  showPlaceholder: true,
  indentation: 1,
  collapsedByDefault: true,
  isSearchable: false,
  disableDeleteWhenIssued: true
};
