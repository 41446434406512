module.exports = {
  information: {
    single: 'Stap',
    plural: 'Stappen '
  },
  node: {
    type: 'CURRICULUM_ZILL_LEERLIJN_CLUSTER'
  },
  minIndentation: 4,
  edit: [
    {
      component: 'ageRange',
      options: {
        editMonth: true
      }
    },
    {
      component: 'developmentPhase',
      whitelist: [
        {
          parent: {
            specificForAN: true
          }
        }
      ]
    },
    'description'
  ],
  rowClass: 'goal-timeline-row',
  collapsedByDefault: true,
  isSearchable: false,
  customRender: true,
  disableDeleteWhenIssued: true
};
