module.exports = {
  information: {
    single: 'Doel',
    plural: 'Doelen'
  },
  node: {
    type: 'CURRICULUM_ZILL_GENERIC_GOAL'
  },
  indentation: 2,
  buildingBlocks: [
    {
      type: 'CURRICULUM_ZILL_CONTENT_GROUP',
      max: 1
    },
    {
      type: 'CURRICULUM_ZILL_LEERLIJN',
      max: 1
    },
    {
      type: 'CURRICULUM_ZILL_LEERLIJN_AN',
      max: 1
    }
  ],
  edit: [
    {
      component: 'identifier',
      width: 2
    },
    {
      component: 'title',
      width: 10
    },
    'concordanties',
    'goalIdentifier'
  ],
  rowClass: 'goal-row',
  allowSibling: true,
  documentViewIdentifier: 'pi',
  isSearchable: false,
  collapsedByDefault: true,
  identifier: 'parent-1',
  disableDeleteWhenIssued: true
};
