module.exports = {
  information: {
    definiteArticle: false,
    single: 'Web Pagina',
    plural: 'Web Pagina\'s',
    icon: require('../../../img/icons/webpage.svg')
  },
  node: {
    type: 'WEBPAGE',
    tags: [
      'WEBPAGE'
    ]
  },
  createModal: {
    authors: {
      addUser: true
    }
  },
  indentation: 1,
  buildingBlocks: [
    'SECTION',
    'PARAGRAPH'
  ],
  tocTypes: [
    'SECTION'
  ],
  edit: [
    'title',
    'description',
    'issued',
    'authors',
    'themes'
  ],
  asDocumentView: true,
  isCreatable: true,
  isSearchable: true,
};
