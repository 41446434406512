import * as constants from '@store/constants/constants';

export const curriculumThemeNotEmpty = (node, state) => {
  const primaryReferenceFrame = state.referenceFrame[constants.dienstverleningKovKey];

  // Reference frame is still loading
  if (!primaryReferenceFrame) {
    return true;
  }

  return primaryReferenceFrame.some(
    (i) => i.type === 'CURRICULUM_THEME' && node.themes?.includes(i.$$meta.permalink)
  )
    ? true
    : { type: 'ERROR', message: 'edit.curriculumTheme.curriculumThemeNotEmpty' };
};
