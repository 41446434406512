import constants from '../reduxLoop/constants/constants';

export const oldUrlIsPermitted = (node) => {
  const oldUrlForbidden = node.websitesConfiguration.find(c => {
    if (c.oldUrlsVm) {
      const urls = c.oldUrlsVm.map(o => o.split(c.website.domain)[1]);
      return urls.some(u => constants.reservedCharacters.test(u));
    }
    return false;
  });

  return !oldUrlForbidden ? true : {
    type: 'ERROR',
    message: 'components.proWebsite.configurations.error.oldUrlIsForbidden',
    params: {}
  };
};
