const getMessage = (node) => {
  if (node.$$root.$$type === 'WEBPAGE2') {
    return 'validation.introductionRequired';
  }
  return 'validation.descriptionNotEmpty';
};

export const descriptionNotEmpty = (node) => {
  return node.description && node.description.trim()
    ? true
    : { type: 'WARNING', message: getMessage(node) };
};
