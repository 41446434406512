import constants from '../reduxLoop/constants/constants';

function getMessage(node, reference) {
  let type = 'Web pagina';
  let refTitle = '';
  if (!reference.to.href.startsWith('/')) {
    type = 'Externe url';
    refTitle = reference.to.href;
  } else {
    refTitle = reference.to.$$expanded ? reference.to.$$expanded.title : undefined;
  }
  let message = `<li>${node.title} navigeert naar ${type} ${refTitle}. Ben je zeker dat je dit wil verwijderen?</li>`;
  return message;
}

export const menuItemWithReference = (node) => {
  const isMenuItem = node.websitesConfiguration
    .some(wc => constants.menuItemWebconfigurations.includes(wc.type))
  || node.inheritedWebConfigurations
    .some(wc => constants.menuItemWebconfigurations.includes(wc.type));
  const confirmDelete = isMenuItem && node.referenceRelationsFrom.length > 0;
  return confirmDelete ? getMessage(node, node.referenceRelationsFrom[0]) : undefined;
};
