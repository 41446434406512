import { mainstructuresOuTypeCombinationsInconsistent } from '../../../validations/mainstructuresOuTypeCombinationsInconsistent';
import { coverageInconsistent } from '../../../validations/coverageInconsistent';
import { mainstructuresOuTypeCombinationsNotEmpty } from '../../../validations/mainstructuresOuTypeCombinationsNotEmpty';
import { coverageNotEmpty } from '../../../validations/coverageNotEmpty';
import { positionsInconsistent } from '../../../validations/positionsInconsistent';
import { positionsNotEmpty } from '../../../validations/positionsNotEmpty';

const constants = require('../../../reduxLoop/constants/constants');

export default {
  information: {
    single: 'Alinea',
    plural: 'Alinea\'s',
    ribonIcon: require('../../../../img/buildingBlocks/PARAGRAPH.svg')
  },
  node: {
    type: 'PARAGRAPH',
    attachments: [
      {
        type: 'CONTENT',
        name: 'content.html',
        contentType: 'text/html'
      }
    ]
  },
  buildingBlocks: [
  ],
  edit: [
    {
      component: 'attachedContent',
      label: ' ',
      blacklist: [
        { root: { $$type: 'PRONEWSLETTER' } },
        { root: { $$type: 'LLINKID_CURRICULUM' } },
        {
          webconfiguration: {
            inherited: ['STATIC', 'WWW_HARD_CODED']
          }
        }
      ]
    },
    {
      component: 'attachedContent',
      label: ' ',
      whitelist: [
        { root: { $$type: 'PRONEWSLETTER' } }
      ],
      options: {
        hideTermButton: true
      }
    },
    {
      component: 'attachedContent',
      label: ' ',
      whitelist: [
        { root: { $$type: 'LLINKID_CURRICULUM' } }
      ],
      options: {
        showMarkerButton: true
      }
    },
    {
      component: 'staticHtml',
      label: ' ',
      whitelist: [
        {
          webconfiguration: {
            inherited: ['STATIC', 'WWW_HARD_CODED']
          }
        }
      ]
    },
    {
      component: 'image',
      label: 'Afbeelding',
      options: {
        type: 'ILLUSTRATION',
        fields: [
          'alt'
        ]
      },
      blacklist: [
        {
          parent: {
            $$type: 'LLINKID_STATIC_PART'
          }
        }
      ]
    },
    {
      component: 'themes',
      whitelist: [{ root: { $$type: 'PRONEWSLETTERTEMPLATE' } }],
    },
    {
      component: 'namedSet',
      validations: [
        mainstructuresOuTypeCombinationsNotEmpty,
        mainstructuresOuTypeCombinationsInconsistent
      ],
      tag: 'mainstructure_outype_ext_comm',
      property: 'mainstructuresOuTypeCombinations',
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } }
      ],
      tab: constants.asideTabs.AUDIENCE
    },
    {
      component: 'coverage',
      validations: [
        coverageNotEmpty,
        coverageInconsistent
      ],
      whitelist: [
        { root: { $$type: 'WEBPAGE2' } },
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } }
      ],
      tab: constants.asideTabs.AUDIENCE
    },
    {
      component: 'namedSet',
      validations: [
        positionsNotEmpty,
        positionsInconsistent
      ],
      label: 'components.functions.title',
      tag: 'doelgroepen',
      property: 'positions',
      tab: constants.asideTabs.AUDIENCE,
      whitelist: [
        { root: { $$type: 'PRONEWSLETTERTEMPLATE' } },
        { root: { $$type: 'PRONEWSLETTER' } }
      ]
    }
  ],
  allowSibling: true,
  isSearchable: false,
  ribbon: {
    openInLine: 'html'
  }
};
