import * as DOCUMENT_ACTIONS from '../../../reduxLoop/actions/documentActions';

class asideTitle {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;

    this.unsubscribe = this.$ngRedux.connect((state) => {
      return {
        ...state.document.viewModel.aside
      };
    })(this);
  }

  $onInit() {
  }

  change(scope, event, title) {
    scope.ctrl.$ngRedux.dispatch(DOCUMENT_ACTIONS.patchNodeAction(scope.ctrl.editDocument.key, {
      title: title.value
    }));
  }
}

export default {
  template: require('./asideTitle.html'),
  controllerAs: 'ctrl',
  bindings: {
    sConfig: '<'
  },
  controller: asideTitle
};
