class AttachmentUpload {
  constructor($scope, $ngRedux) {
    'ngInject';

    this.$scope = $scope;
    this.$ngRedux = $ngRedux;
  }

  $onInit() {
    if (this.sConfig && this.sConfig.options) {
      this.sValidExtensions = this.sConfig.options.validExtensions || this.sValidExtensions;
      this.maxSizeInBytes = this.sConfig.options.maxSizeInBytes || this.maxSizeInBytes || 10485760;
      this.sType = this.sConfig.options.type || this.sType;
    }

    if (this.sDocument) {
      this.sAttachment = this.sDocument;
    }

    if (this.sAttachment) {
      this.sAttachment.name = this.sAttachment.originalName || this.sAttachment.name;
    }
  }

  uploadFile(scope, event) {
    let file = event.target.files[0];
    scope.ctrl.sAttachment = file;
    scope.$emit('changed_attachment', { type: scope.ctrl.sType, file: file });
  }

  removeAttachment() {
    this.sAttachment = {};
    document.getElementById('uploadFile').value = '';
    this.$scope.$emit('removed_attachment', { type: this.sType });
  }
}

export default {
  template: require('./attachmentUpload.html'),
  controllerAs: 'ctrl',
  bindings: {
    sType: '<',
    sAttachment: '<',
    sValidExtensions: '<',
    maxSizeInBytes: '<',
    sConfig: '<',
    sDocument: '<',
    sDisabled: '<',
    sHideRemove: '<?',
    sShowFilenameWarning: '<?'
  },
  controller: AttachmentUpload
};
