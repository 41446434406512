import * as DOCUMENT_ACTIONS from '../../reduxLoop/actions/documentActions';
import * as DOCUMENT_DRAG_ACTIONS from '../../reduxLoop/actions/documentDragActions';
import {
  isEmpty, keysUnderNode, getBase64, getImage
} from '../../reduxLoop/helpers/documentHelpers';
import { patchNodeAction } from '../../reduxLoop/actions/documentActions';
import { customlinkButtonClicked, footnoteButtonClicked } from '../../services/editorCustomButtonsService';
import { config as constants } from '../../reduxLoop/constants/documentTypes';

require('./contentRow.scss');

class contentRow {
  constructor($scope, $state, $ngRedux, $element, $translate, ModalWindowService) {
    'ngInject';

    this.$scope = $scope;
    this.$state = $state;
    this.$ngRedux = $ngRedux;
    this.$element = $element;
    this.$translate = $translate;
    this.modalWindowService = ModalWindowService;
    this.constants = constants;

    this.dropZones = [];
    this.isTogglingCollapse = false;
  }

  async $onInit() {
    this.unsubscribe = this.$ngRedux.connect((state) => {
      const node = state.document.viewModel.flat.find(n => n.$$hash === this.sItem.$$hash);
      this.onChanges(node);

      return {
        documentSections: state.document.viewModel.documentSections,
        rootDocument: state.document.viewModel.document,
        allReadButton: state.document.viewModel.allReadButton,
        referenceFrameThemes: state.document.viewModel.referenceFrameThemes
      };
    })(this);

    this.createDraggableDroppable();

    if (this.sItem.$$new) {
      this.openInline = this.sItem.$$typeConfig.ribbon && this.sItem.$$typeConfig.ribbon.openInLine
        ? this.sItem.$$typeConfig.ribbon.openInLine
        : false;
      if (!this.openInline) {
        this.openEdit();
      }
      this.$ngRedux.dispatch(patchNodeAction(this.sItem.key, { $$new: false }, false));
    }
  }

  $onDestroy() {
    this.unsubscribe();
  }

  isCustomRender() {
    // one time binding improve needed to avoid extra watcher
    if (!this.sItem.$$typeConfig.customRender) {
      this.sItem.$$typeConfig.customRender = false;
    }
    return this.sItem.$$typeConfig.customRender;
  }

  onChanges(newItem) {
    if (!this.sItem || !newItem) {
      return;
    }

    if (newItem.$$selection.self !== this.sItem.$$selection.self
      || newItem.$$selection.parent !== this.sItem.$$selection.parent) {
      this.$scope.$broadcast('$$rebind::selection');
    }
    if (this.isCollapseble(newItem) !== this.isCollapseble(this.sItem)) {
      this.isTogglingCollapse = false;
      this.$scope.$broadcast('$$rebind::collapse');
    }
    if (newItem.$$hoverOnCollapse.self !== this.sItem.$$hoverOnCollapse.self
      || newItem.$$hoverOnCollapse.parent !== this.sItem.$$hoverOnCollapse.parent) {
      this.$scope.$broadcast('$$rebind::hover');
    }
    if (newItem.$$validations.length !== this.sItem.$$validations.length) {
      this.$scope.$broadcast('$$rebind::validations');
    } else if (newItem.$$validations.filter(v => v.error.type === 'ERROR').length !== this.sItem.$$validations.filter(v => v.error.type === 'ERROR').length) {
      this.$scope.$broadcast('$$rebind::validations');
    }
    if (newItem.modificationAfterLastRead !== this.sItem.modificationAfterLastRead) {
      this.$scope.$broadcast('$$rebind::hasChanges');
    }
    if ((newItem.proposal && newItem.proposal.hash && !this.sItem.proposal)
      || (!newItem.proposal && this.sItem.proposal && this.sItem.proposal.hash)
      || (newItem.proposal && this.sItem.proposal && newItem.proposal.hash !== this.sItem.proposal.hash)
    ) {
      this.$scope.$broadcast('$$rebind::proposal');
      this.$scope.$broadcast('$$rebind::collapse');
    }
    if (newItem.isDownloadAttachmentsGroup !== this.sItem.isDownloadAttachmentsGroup) {
      this.$scope.$broadcast('$$rebind::typeName');
    }
    if (newItem.$$level !== this.sItem.$$level) {
      this.$scope.$broadcast('$$rebind::changeLevel');
      this.$scope.$broadcast('$$rebind::collapse');
    }

    this.sItem = { ...newItem };
  }

  isReadOnly() {
    return this.sItem.isReadOnly === true;
  }

  rebindHoverProposal(scope) {
    scope.ctrl.$scope.$broadcast('$$rebind::hoverProposal');
  }

  openEdit() {
    this.$state.go('edit.aside', { editKey: this.sItem.key });
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.updateAsideViewModelAction(this.sItem.key));
  }

  saveInline(scope, event, editorData) {
    const patch = {
      [editorData.field]: editorData.value
    };
    scope.ctrl.$ngRedux.dispatch(
      DOCUMENT_ACTIONS.patchNodeAction(editorData.key, patch)
    );
  }

  showCustomLinkButton() {
    return !this.sItem.$$isRoot && this.sItem.$$root.$$type === 'WEBPAGE2';
  }

  async customlinkButtonClicked(scope, event, data) {
    customlinkButtonClicked(
      scope.ctrl.sItem,
      scope.ctrl.documentSections,
      data.editor,
      scope.ctrl.modalWindowService
    );
  }

  async footnoteButtonClicked(scope, event, data) {
    footnoteButtonClicked(
      scope.ctrl.sItem,
      scope.ctrl.sItem.$$root,
      data.editor,
      scope.ctrl.modalWindowService
    );
  }

  getPrefixToShow() {
    if (this.sItem.$$prefix && this.sItem.identifiers && this.sItem.identifiers.length > 0) {
      return this.sItem.$$prefix.slice(0, this.sItem.$$prefix.length - this.sItem.$$identifier.length);
    }
    return '';
  }

  getTypeName() {
    if (this.sItem.$$typeConfig.information) {
      if (this.sItem.$$typeConfig.information.conditionalTitle) {
        return this.sItem.$$typeConfig.information.conditionalTitle(this.sItem);
      }
      return this.sItem.$$typeConfig.information.single;
    }
    return this.sItem.$$type;
  }

  validationPopoverMessage() {
    let html = '<div>';
    this.sItem.$$validations.forEach((validation => {
      if (validation.error.type === 'WARNING') {
        html += `<div>
        <span class="label label-warning popover-label-validation"> </span>
        <span>${this.$translate.instant(validation.error.message, validation.error.params)}</span>
        </div>`;
      } else if (validation.error.type === 'ERROR') {
        html += `<div>
        <span class="label label-danger popover-label-validation"> </span>
        <span>${this.$translate.instant(validation.error.message, validation.error.params)}</span>
        </div>`;
      } else {
        html += `<div>
        <span class="label label-default popover-label-validation"> </span>
        <span>${this.$translate.instant(validation.error.message, validation.error.params)}</span>
        </div>`;
      }
    }));
    return html + '</div>';
  }

  validationColorOfRow() {
    if (this.sItem.$$validations.filter(o => o.error.type === 'ERROR').length > 0) {
      return 'red';
    }
    if (this.sItem.$$validations.filter(o => o.error.type === 'WARNING').length > 0) {
      return 'orange';
    }
    return 'gray';
  }

  getIllustration() {
    return getImage(this.sItem.$$attachments, 'ILLUSTRATION', 300);
  }

  hasEditSection(name, value) {
    const component = this.sItem.$$editSections.filter(o => o.component === name);
    if (component.length > 0) {
      if (component[0].showPlaceholder) {
        return false;
      }
      if (component[0].view && component[0].view.hideIfEmpty && isEmpty(value)) {
        return false;
      }
      return true;
    }
    return false;
  }

  // == Collapse ==
  isCollapseble(item) {
    if (!item.$$parent || item.$$isCollapsed) {
      return false;
    }
    return item.$$typeConfig.hideChildrenInDocument !== true
      && item.$$children.filter(c => !c.$$isHidden).length > 0;
  }

  isExpandable() {
    if (!this.sItem.$$parent || !this.sItem.$$isCollapsed) {
      return false;
    }
    return this.sItem.$$typeConfig.hideChildrenInDocument !== true
      && this.sItem.$$children.length > 0;
  }

  toggleCollapse() {
    this.isTogglingCollapse = true;
    this.$scope.$broadcast('$$rebind::collapse');

    // this.$ngRedux.dispatch(DOCUMENT_ACTIONS.clearHoverOnCollapseAction());
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.toggleCollapseAction(this.sItem.key));
  }

  hoverOnCollapse() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.hoverOnCollapseAction(this.sItem.key));
  }

  stopHoverOnCollapse() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.clearHoverOnCollapseAction());
  }


  // == SELECTION OF ROW ==
  hideSelect() {
    return this.sItem.$$isRoot || this.sItem.$$selection.parent;
  }

  selectionLineLevelStyle() {
    return 'level' + this.sItem.$$selection.parent;
  }

  proposalLineStyle() {
    if (this.sItem.proposal) {
      const line = this.sItem.proposal.isSubmitted ? 'submitted' : 'not-submitted';
      const color = this.sItem.proposal.isSameUser ? 'same-user' : 'different-user';
      const isDeleted = this.sItem.proposal.isDeleted ? ' is-deleted' : '';
      const isReviewing = this.sItem.proposal.isReviewingMode ? ' is-reviewing' : '';
      return line + ' ' + color + isDeleted + isReviewing;
    }
    return '';
  }

  hasDeleteProposal() {
    if (!this.sItem.proposal) {
      return false;
    }
    return this.sItem.proposal.type === 'DELETE';
  }

  select() {
    this.$ngRedux.dispatch(DOCUMENT_ACTIONS.toggleSelectionAction(this.sItem.$$relation.key, true, true));
  }

  // == DRAG OF ROW ==
  hideDraggable() {
    const hideDraggable = () => {
      return this.sItem.$$typeConfig.isDraggable === undefined
        ? false
        : !this.sItem.$$typeConfig.isDraggable;
    };

    return this.hideSelect() || hideDraggable();
  }

  createDraggableDroppable() {
    this.$element.find('.draggable').draggable({
      revert: 'invalid',
      opacity: 1,
      // cursorAt: { top: 0, left: 0 },
      refreshPositions: true,
      helper: () => {
        const itemsDraggedCount = this.sItem.$$selection.self
          ? this.$ngRedux.getState().document.allSelections.length
          : this.$ngRedux.getState().document.allSelections.length + keysUnderNode(this.sItem).length;
        const showedText = this.$translate.instant('dragAndDrop.dragged', { count: itemsDraggedCount });
        const dragIcon = require('../../../img/drag.svg');

        if (itemsDraggedCount === 1) {
          return $('<div class="drag-information"><img src="' + dragIcon + '"/>\n' + showedText + '</div>');
        }
        return $('<div class="drag-information-container">'
          + '<div class="drag-information"><img src="' + dragIcon + '">' + showedText + '</div>'
          + '<div class="drag-information2"></div>'
          + '<div class="drag-information3"></div>'
          + '</div>');
      },
      start: () => {
        this.$ngRedux.dispatch(DOCUMENT_DRAG_ACTIONS.startDraggingNodeAction(this.sItem.$$relation.key));
      },
      stop: () => {
        this.$ngRedux.dispatch(DOCUMENT_DRAG_ACTIONS.stopDraggingNodeAction());
      }
    });

    this.$element.droppable({
      refreshPositions: true,
      tolerance: 'pointer',
      drop: async (event, ui) => {
        if (!this.dropZones || this.dropZones.length === 0) {
          this.$ngRedux.dispatch(DOCUMENT_DRAG_ACTIONS.finishDraggingNodeAction());
          if (this.dropLineLevel) {
            const isNewNode = ui.draggable.context.attributes.dragType.value === 'RIBBON';
            const level = this.sItem.$$dropZones[this.dropLineLevel];
            if (ui.draggable.context.attributes.type.value === 'WORD_IMPORT') {
              const opts = {
                component: 'documentImportModal',
                dataForModal: { parentDocument: level.levelParent }
              };

              const importResult = await this.modalWindowService.open(opts);

              console.log('Imported nodes:', importResult.resources);

              importResult.attachmentsToUpload.forEach(async upload => {
                const base64 = await getBase64(upload.file);
                upload.$$base64 = base64;
              });
              this.$ngRedux.dispatch(DOCUMENT_ACTIONS.importDocumentInSectionAction(
                importResult, level.levelParent.key
              ));
            } else if (!isNewNode) {
              this.$ngRedux.dispatch(DOCUMENT_ACTIONS.moveSelectionsToParentNodeAction(level.levelParent.key, level.position));
            } else if (Object.keys(constants.referenceFrameExternalTypes).includes(ui.draggable.context.attributes.type.value)) {
              this.$ngRedux.dispatch(DOCUMENT_ACTIONS.openSelectExternalRelationModalAction());

              const opts = {
                component: 'selectExternalRelationModal',
                dataForModal: {
                  parentDocument: level.levelParent,
                  type: ui.draggable.context.attributes.type.value,
                  position: level.position
                }
              };

              const selection = await this.modalWindowService.open(opts);

              console.log('Selected relation:', selection);

              if (selection) {
                this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addExternalRelationAction(
                  level.levelParent.key,
                  level.position,
                  selection
                ));
              }
            } else if (ui.draggable.context.attributes.type.value === 'TEASER') {
              const opts = {
                component: 'teaserModal',
                dataForModal: {
                  referenceFrameThemes: this.referenceFrameThemes,
                  parentDocumentThemes: level.levelParent.themes
                }
              };

              const importResult = await this.modalWindowService.open(opts);

              this.$ngRedux.dispatch(DOCUMENT_ACTIONS.addTeaser(level.levelParent.key, level.position, importResult.teaser));
            } else {
              this.$ngRedux.dispatch(
                DOCUMENT_ACTIONS.addNodeToParentNodeAction(
                  level.levelParent.key,
                  level.position,
                  ui.draggable.context.attributes.type.value
                )
              );
            }
          }
        }
        this.dropLineLevel = undefined;
        this.dropZones = [];
        this.$scope.$broadcast('$$rebind::dropZones');
        this.$scope.$apply();
        clearTimeout(this.hoveringTimeout);
      },
      over: (event, ui) => {
        const isNewNode = ui.draggable.context.attributes.dragType.value === 'RIBBON';
        const typesToDrop = isNewNode
          ? [ui.draggable.context.attributes.type.value]
          : this.$ngRedux.getState().document.selectionTypes;
        const keysSelectedToDrop = !isNewNode
          ? this.$ngRedux.getState().document.allSelections : ['ribbon'];

        let allowedLevels = Object.keys(this.sItem.$$dropZones).reduce((dropLevels, level) => {
          const isAllowedToDrop = typesToDrop?.some((type) => {
            const dropZone = this.sItem.$$dropZones[level];
            if (dropZone.types.includes(type)) {
              const typeConfig = dropZone.levelParent.$$buildingBlocks.find(
                (block) => block.type === type
              );
              return typeConfig?.position ? dropZone.position === typeConfig.position : true;
            }
            return false;
          });
          if (isAllowedToDrop) {
            dropLevels.push(level);
          }
          return dropLevels;
        }, []);

        if (keysSelectedToDrop.some(key => key === this.sItem.key)) {
          // this avoid dropping selection over itself or own children (#18599)
          allowedLevels = [];
        }
        console.log('ALLOWED', allowedLevels, this.sItem.key);
        if (allowedLevels.length > 0 && keysSelectedToDrop.length) {
          this.dropLineLevel = Math.max(...allowedLevels);
          this.$scope.$broadcast('$$rebind::dropZones');
          this.$scope.$apply();
        }
        this.hoveringTimeout = setTimeout(() => {
          if (allowedLevels.length > 0 && keysSelectedToDrop.length) {
            this.dropZones = allowedLevels.map(o => this.sItem.$$dropZones[o]).sort((a, b) => b.level - a.level);
            this.$scope.$broadcast('$$rebind::dropZones');
            this.$scope.$apply();
          }
        }, 1500);
      },
      out: (event, ui) => {
        this.dropLineLevel = undefined;
        this.dropZones = [];
        this.$scope.$broadcast('$$rebind::dropZones');
        this.$scope.$apply();
        clearTimeout(this.hoveringTimeout);
        $(document.body).css({ cursor: 'move' });
      }
    });
  }
}

export default {
  template: require('./contentRow.html'),
  controllerAs: 'ctrl',
  bindings: {
    sItem: '<?',
    sHash: '<?'
  },
  controller: contentRow
};
